import { ProductAssets } from '../../modules/pages/components/products-banners/components/product-banners-form';
import {
  ProductBannerAsset,
  ProductCardAsset,
  ProductPostAsset,
  ProductVideoAsset,
} from '../../modules/pages/page-form/page-form';
import { AmazonProduct, Product, WalmartProduct } from '../interfaces/agg-page';

export const isBannerAsset = (asset: ProductAssets): asset is ProductBannerAsset =>
  (asset as ProductBannerAsset).assets_type === 'assets'
  && (asset as ProductBannerAsset).file_data.type === 'banner';

export const isVideoAsset = (asset: ProductAssets): asset is ProductVideoAsset =>
  (asset as ProductVideoAsset).assets_type === 'video';

export const isPostAsset = (asset: ProductAssets): asset is ProductPostAsset =>
  (asset as ProductPostAsset).assets_type === 'blogs';

export const isCardAsset = (asset: ProductAssets): asset is ProductCardAsset =>
  (asset as ProductCardAsset).assets_type === 'assets'
  && (asset as ProductCardAsset).file_data.type === 'card';

export const isAmazonProduct = (
  product: Product,
): product is AmazonProduct => Object.hasOwn(product, 'asin');

export const isWalmartProduct = (
  product: Product,
): product is WalmartProduct => Object.hasOwn(product, 'w_item_id');
