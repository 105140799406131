import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { lengthRestrictions } from '../../../../../../common/constants/length-restrictions';
import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import styles from './pros-item.module.scss';

interface Props {
  onDeleteButton: () => void;
  prefix?: string;
  index: number;
}

const ProsItem = ({ index, onDeleteButton, prefix = '' }: Props) => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({ control, name: `${prefix}pros.${index}` as any });

  return (
    <Container
      className={styles.pros}
      onDeleteButton={onDeleteButton}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <FormItem className="w-100" label={`Pros #${index + 1}`} message={fieldState.error?.message}>
        <TextField
          {...field}
          fullWidth
          invalid={fieldState.invalid}
          limit={lengthRestrictions.product.content.pros}
          placeholder="Short text here"
          rows={5}
        />
      </FormItem>
    </Container>
  );
};

export { ProsItem };
