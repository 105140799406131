import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { Control, FieldErrors, useFieldArray } from 'react-hook-form';

import { SortableContainer } from '../../../../common/ui/draggable-container';
import { AssetsContainer } from '../../../pages/components';
import { TPageForm } from '../../../pages/page-form/page-form';
import { CreateReviewCard } from './components/create-review-card';
import { ReviewCard } from './components/review-card';
import styles from './reviews-form.module.scss';

type Props = PropsWithChildren<{
  control: Control<TPageForm>;
  className?: string;
  productIdx?: number;
  // eslint-disable-next-line react/no-unused-prop-types
  errors?: FieldErrors<TPageForm['reviews'][number]>;
}>;

const ReviewsForm = ({ className, control, productIdx }: Props) => {
  const { append, fields, move, remove } = useFieldArray({
    control,
    name: `reviews.${productIdx}.reviews`,
  });

  return (
    <div className="flex-column raw-gap-20 w-100">
      <AssetsContainer basedVisibleCount={2} className={clsx(styles.reviews_container, className)}>
        <div className={clsx(styles.review_card_wrapper)}>
          <CreateReviewCard onChange={(review) => append(review)} />
        </div>
        {fields.length > 0
          && fields.map((elem, reviewIdx) => (
            <SortableContainer.ItemWrapper
              key={elem.id}
              className={clsx(styles.review_card_wrapper, styles.movable)}
              id={elem.id}
              index={reviewIdx}
              moveItem={(from, to) => move(from, to)}
              type="review"
            >
              <ReviewCard
                control={control}
                onDelete={() => remove(reviewIdx)}
                productIdx={productIdx}
                reviewIdx={reviewIdx}
              />
            </SortableContainer.ItemWrapper>
          ))}
      </AssetsContainer>
    </div>
  );
};

export { ReviewsForm };
