import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import clsx from 'clsx';
import { Product } from 'common/interfaces/agg-page';
import { AggPageFormContext } from 'context/page-form-context';
import React, { FocusEvent, useContext, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import {
  ArrowBackIcon,
  CopyIcon,
  LinkIcon,
  PlusOutlinedIcon,
  SearchIcon,
} from '../../../assets/images/icons';
import { Marketplaces } from '../../../common/constants/enums';
import { lengthRestrictions } from '../../../common/constants/length-restrictions';
import { productsNumber } from '../../../common/constants/values';
import { getProductsApiClient } from '../../../common/services/products-api.service';
import { getProductsManagementApiClient } from '../../../common/services/products-management-api.service';
import { isAmazonProduct } from '../../../common/type-guards';
import { Button } from '../../../common/ui/button';
import { Container } from '../../../common/ui/container';
import { FormItem } from '../../../common/ui/formItem';
import { Image } from '../../../common/ui/image';
import { Modal } from '../../../common/ui/modal';
import { Notification } from '../../../common/ui/notification';
import { TextField } from '../../../common/ui/text-field';
import { utilFormatMoney, utilProductDataPreparing } from '../../../common/utils';
import { utilCopyToClipboard } from '../../../common/utils/utilCopyToClipboard';
import {
  amazonProductAsinValidationSchema,
  walmartProductIdValidationSchema,
} from '../../../common/validationSchemas';
import { useAppSelector } from '../../../hooks';
import { DeleteButton } from '../../common/partials/buttons/DeleteButton';
import { ProductId } from '../page-form/page-form';
import { SearchProductForm } from '../page-product-input/PageProductInput';
import { CouponsModal } from './components/coupons-modal';
import styles from './page-product.module.scss';

interface IPageProductProps {
  product: Product;
  index: number;
  marketplace?: Marketplaces;
}

export const PageProduct = ({
  index,
  product,
  marketplace = Marketplaces.Amazon,
}: IPageProductProps) => {
  const [error, setError] = useState('');
  const [productTitle, setProductTitle] = useState<string>(
    product?.subtitle || product?.title || '',
  );
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
  const [searchProductLoading, setSearchProductLoading] = useState(false);
  const [newProduct, setNewProduct] = useState<Product>(null);
  const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
  const { coupons } = useAppSelector(({ couponsReducer }) => couponsReducer.getProductsCouponList);
  const productCoupons = coupons.filter((coupon) => coupon.asins.includes(product.product_id));

  const validationSchema = marketplace === Marketplaces.Walmart
    ? walmartProductIdValidationSchema
    : amazonProductAsinValidationSchema;

  const {
    addContentCopies,
    addExcludeProductId,
    changeProductValue,
    clearChangedProductData,
    deleteProduct,
    deleteProductContentByProductId,
    deleteProductReviewsByProductId,
    productTitlesForUpdateHandler,
    products,
    replaceProduct,
    setProductsAssets,
  } = useContext(AggPageFormContext);

  const searchProductForm = useForm<SearchProductForm>({
    resolver: yupResolver(validationSchema),
  });

  const onChangeHandler = (value: string) => {
    setError('');

    const productWithBestPick = products.find((item: Product) => item.value === 'Best Pick');
    if (value === 'Best Pick' && productWithBestPick) {
      setError('Product with "Best Pick" already exists');
      return;
    }

    changeProductValue(index, value);
  };

  const replaceProductHandler = async () => {
    try {
      const { assets, reviews } = utilProductDataPreparing(newProduct);

      const deletedProductId = product.product_id;

      addExcludeProductId(product.product_id, product.id);
      replaceProduct(product.product_id, newProduct);
      setProductsAssets((prevState) =>
        prevState.map((elem) => {
          if (elem.product_id !== deletedProductId) return elem;

          return { assets, product_id: newProduct.product_id };
        }),);
      if (isAmazonProduct(product)) {
        deleteProductReviewsByProductId(product.product_id, [reviews]);
        clearChangedProductData(product.product_id);
      }

      const contentCopiesResponse = await getProductsManagementApiClient().getProductContentCopies(
        newProduct.product_id,
      );

      const contentCopies = contentCopiesResponse.data.products.reduce((result, elem) => {
        deleteProductContentByProductId(deletedProductId, [
          {
            _id: uuidv4(),
            product_id: elem.asin,
            type: 'origin',
            ...({
              ...elem.content,
              faq: elem.content?.faq || [],
              pros: elem.content?.pros?.filter((p) => p?.trim().length > 0) || [],
            } || {}),
            tied: !products.find((p) => p.id === elem.id)?.picked_product_copy_id,
          },
        ]);
        // @ts-ignore
        const copies = [];
        elem.product_copies.forEach((copy) => {
          if (copy.content) {
            copies.push({
              _id: uuidv4(),
              copy_id: copy.copy_id,
              product_id: elem.asin,
              tied: !!products.find((elem) => elem.picked_product_copy_id === copy.copy_id),
              ...{
                ...copy.content.content,
                faq: copy.content.content?.faq || [],
                pros: copy.content.content?.pros?.filter((p) => p?.trim().length > 0) || [],
              },
            });
          }
        });
        // @ts-ignore
        result = [...result, ...copies];

        return result;
      }, []);

      addContentCopies(contentCopies, true, deletedProductId);
    } catch (e) {
      console.info(e);
    }
  };

  const onProductTitleBlur = ({ target: { value } }: FocusEvent<HTMLTextAreaElement>) => {
    if (value?.trim().length) {
      productTitlesForUpdateHandler({ product_id: product.product_id, title: value });
    }
  };

  const deleteProductHandler = () => {
    addExcludeProductId(product.product_id, product.id);
    deleteProduct(index);
    addContentCopies([], true, `${product.product_id}`);
    deleteProductContentByProductId(product.product_id);
  };

  const findProduct = async ({ product_id }: { product_id: ProductId }) => {
    const isExist = products
      .slice(0, productsNumber)
      .some((elem) => elem.product_id === String(product_id));

    if (isExist) {
      searchProductForm.setError('product_id', {
        message: 'Product is already added',
        type: 'value',
      });
      return;
    }

    setSearchProductLoading(true);

    try {
      let foundedProduct: Product;

      if (marketplace === Marketplaces.Amazon) {
        const productResponse = await getProductsApiClient().getProductByAsin(
          product_id as string,
          {
            features: true,
          },
        );

        foundedProduct = productResponse.data?.data?.products[0];
        if (foundedProduct) foundedProduct.product_id = productResponse.data?.data?.products[0].asin;
      }

      if (marketplace === Marketplaces.Walmart) {
        const productResponse = await getProductsApiClient().getProductById(product_id, {
          include_product_features: true,
        });

        foundedProduct = productResponse.data?.products[0];
        if (foundedProduct) foundedProduct.product_id = productResponse.data?.products[0].w_item_id;
      }

      if (!foundedProduct) {
        searchProductForm.setError('product_id', {
          message: 'Not found',
        });
        return;
      }

      if (product.value === 'Best Pick') {
        foundedProduct.value = 'Best Pick';
        foundedProduct.order = 1;
      }

      setNewProduct(foundedProduct);
    } catch (e) {
      let error;
      if (axios.isAxiosError(e)) {
        error = e.message;
      } else {
        error = 'Request failed, try later';
      }
      searchProductForm.setError('product_id', {
        message: error,
        type: 'validate',
      });
    } finally {
      setSearchProductLoading(false);
    }
  };

  return (
    <Container
      className={styles.page_product_wrapper}
      onDeleteButton={deleteProductHandler}
      withDeleteButton
      withoutBorder
      withShadow
    >
      {isReplaceModalOpen && (
        <Modal open>
          <Modal.Title>Product replacing</Modal.Title>
          <Modal.Body>
            {!newProduct && (
              <section className="mt-30 mb-30 ml-50 mr-50">
                <p className="mb-10">
                  {marketplace === Marketplaces.Walmart
                    ? 'Please, enter product id'
                    : 'Please, enter product ASIN'}
                </p>
                <div className="d-flex column-gap-10">
                  <FormItem message={searchProductForm.formState.errors?.product_id?.message}>
                    <TextField
                      disabled={searchProductLoading}
                      invalid={!!searchProductForm.formState.errors?.product_id?.message}
                      {...searchProductForm.register('product_id')}
                    />
                  </FormItem>
                  <Button
                    disabled={searchProductLoading}
                    iconStart={<SearchIcon />}
                    loading={searchProductLoading}
                    onClick={searchProductForm.handleSubmit((data) => findProduct(data))}
                  />
                </div>
              </section>
            )}
            {newProduct !== null && !searchProductLoading && (
              <section className={styles.product_preview}>
                <div className={styles.product_preview__image}>
                  <img alt="product" src={newProduct.img_medium || newProduct.img_large} />
                </div>
                <div className="flex-column raw-gap-10">
                  <h5 className={styles.product_preview__subtitle}>{newProduct.subtitle}</h5>
                  <span className={styles.product_preview__price}>
                    {!!newProduct.price && utilFormatMoney(newProduct.price)}
                  </span>
                  <span className={styles.product_preview__asin}>{newProduct.product_id}</span>
                </div>
                <DeleteButton
                  handler={() => {
                    setNewProduct(null);
                    searchProductForm.reset();
                  }}
                />
              </section>
            )}
          </Modal.Body>
          <Modal.Actions>
            <Button
              onClick={() => {
                setIsReplaceModalOpen(false);
                setNewProduct(null);
                searchProductForm.setValue('product_id', '');
              }}
              outlined
              variant="secondary"
            >
              Cancel
            </Button>
            <Button disabled={!newProduct} onClick={replaceProductHandler}>
              Replace product
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <CouponsModal
        onClose={() => setIsCouponModalOpen(false)}
        open={isCouponModalOpen}
        product_id={product.product_id}
        productCoupons={productCoupons}
      />

      <div className={styles.page_product}>
        <TextField
          disabled={product.value === 'Best Pick'}
          fullWidth
          onChange={(e) => onChangeHandler(e.target.value)}
          placeholder="Sticker"
          value={products[index].value || ''}
        />
        <div className="d-flex column-gap-10 mt-10">
          <TextField disabled fullWidth value={product.product_id} variant="secondary" />
          <Button
            iconStart={<CopyIcon />}
            onClick={() => utilCopyToClipboard(product.product_id)}
            variant="secondary"
          />
        </div>
        <div className="d-flex column-gap-10 mt-10 w-100 justify-end">
          <div className={clsx(styles.tooltip, !!product.is_hardcoded_link && styles.link_visible)}>
            <a className={styles.link} href={product.page_url} rel="noreferrer" target="_blank">
              {product.page_url}
            </a>
            <Button
              disabled={!product.is_hardcoded_link}
              iconStart={<LinkIcon />}
              onClick={() => utilCopyToClipboard(product.page_url)}
              variant="secondary"
            />
          </div>
        </div>

        {error && <Notification message={error} type="error" />}

        <Image
          alt={`product_image_${index}`}
          className={styles.image}
          src={product.img_medium || product.img_large}
        />

        <div className={styles.coupon_button_wrapper}>
          <Button
            ref={clickMeButtonRef}
            className={styles.coupon_btn}
            fullWidth
            iconStart={!productCoupons.length ? <PlusOutlinedIcon /> : null}
            onClick={() => setIsCouponModalOpen(true)}
            size="small"
            variant="secondary"
          >
            {productCoupons.length ? 'Coupons' : 'Add coupon'}
            {!!productCoupons.length && (
              <span className={styles.count_label}>
                {productCoupons[0].discount_amount * 100}
                %
              </span>
            )}
          </Button>
        </div>

        <div className={clsx('flex-column raw-gap-10 w-100')}>
          <FormItem message={!productTitle.length && 'Field is required'}>
            <TextField
              fullWidth
              invalid={!productTitle.length}
              limit={lengthRestrictions.product.title}
              onBlur={onProductTitleBlur}
              onChange={(e) => setProductTitle(e.target.value)}
              rows={5}
              value={productTitle}
            />
          </FormItem>
          <Button
            iconStart={<ArrowBackIcon />}
            onClick={() => setIsReplaceModalOpen(true)}
            outlined
            size="small"
            variant="secondary"
          >
            Replace product
          </Button>
        </div>
      </div>
    </Container>
  );
};
