import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './Loader.module.scss';

export interface ILoaderProps extends PropsWithChildren {
  loading?: boolean;
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Loader = ({ children, className, loading = true, size = 'medium' }: ILoaderProps) =>
  (loading ? (
    <div
      className={clsx(
        styles.loader,
        styles[`loader__${size}`],
        className && className,
      )}
    >
      <div />
      {children && <span>{children}</span>}
    </div>
  ) : null);

export { Loader };
