import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DraftStatusNames } from '../../../common/constants/enums';

type TError = { message: string; code?: number };

type TPageStatusesListInitialState = {
  loading: boolean;
  error: TError;
  pageStatuses: DraftStatusNames[]
};

const initialState: TPageStatusesListInitialState = {
  error: null,
  loading: false,
  pageStatuses: []
};

const pageStatusesListSlice = createSlice({
  initialState,
  name: 'pagesStatusList',
  reducers: {
    fetchPageStatusesListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchPageStatusesListRequest(state) {
      state.error = null;
    },
    fetchPageStatusesListResponse(state, action: PayloadAction<DraftStatusNames[]>) {
      state.pageStatuses = action.payload;
    },
    setPageStatusesListLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  }
});

export const { actions, reducer } = pageStatusesListSlice;
