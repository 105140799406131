import { AxiosResponse } from 'axios';

import { BreadcrumbsListResponse } from '../interfaces/breadcrumbs';
import { ApiService } from './api.service';

class PagesServiceApi extends ApiService {
  dailyDeals = (publisher: string): Promise<AxiosResponse<BreadcrumbsListResponse>> =>
    this.get('/pages/events/daily/v2', {
      headers:
        publisher !== null && publisher !== undefined ? { 'x-picks-publisher': publisher } : {},
      params: { no_cache: 1 },
    });
}

export const PagesService = new PagesServiceApi(process.env.REACT_APP_API_HOST);
