import { useController, useForm } from 'react-hook-form';

import { PlusOutlinedIcon } from '../../../../../../assets/images/icons';
import { Button } from '../../../../../../common/ui/button';
import { Container } from '../../../../../../common/ui/container';
import { FileUploader } from '../../../../../../common/ui/file-uploader/FileUploader';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { ProductBannerAsset, ProductId } from '../../../../page-form/page-form';
import styles from './product-banner-creator.module.scss';

type Props = {
  currentProductId: ProductId;
  onAddButton?: (data: ProductBannerAsset) => void;
  onDeleteButton?: () => void;
};

const ProductBannerCreator = ({ currentProductId, onAddButton, onDeleteButton }: Props) => {
  const { control, handleSubmit, setValue } = useForm<ProductBannerAsset>({
    defaultValues: {
      assets_type: 'assets',
      file_data: { type: 'banner' },
      type: 'added',
    },
  });

  const fileField = useController({
    control,
    name: 'file',
    rules: { required: 'Field is required' },
  });
  const titleField = useController({ control, name: 'file_data.title' });
  const textField = useController({ control, name: 'file_data.text' });

  const clearForm = () => {
    setValue('file_data.text', '');
    setValue('file_data.title', '');
    setValue('file', null);
    setValue('product_id', '');
    setValue('preview', null);
  };

  const onSubmit = (data: ProductBannerAsset) => {
    data.product_id = currentProductId;

    if (onAddButton) onAddButton(data);

    clearForm();
  };

  const onDeleteHandler = () => {
    if (onDeleteButton) onDeleteButton();

    clearForm();
  };

  return (
    <Container
      className="flex-column raw-gap-10 w-100 h-100"
      onDeleteButton={onDeleteHandler}
      withDeleteButton
    >
      <div className={styles.image_container}>
        <FileUploader
          error={fileField.fieldState.error?.message}
          format={['image/jpeg', 'image/png', 'image/svg', 'image/jpg']}
          onChange={(file, preview) => {
            fileField.field.onChange(file);
            setValue('preview', preview);
          }}
          value={fileField.field.value}
        />
      </div>
      <div className={styles.fields_container}>
        <FormItem label="Title" message={titleField.fieldState.error?.message}>
          <TextField
            {...titleField.field}
            invalid={titleField.fieldState.invalid}
            placeholder="Enter title"
          />
        </FormItem>
        <FormItem label="Text" message={textField.fieldState.error?.message}>
          <TextField
            {...textField.field}
            invalid={textField.fieldState.invalid}
            placeholder="Enter your simple wording"
            rows={5}
          />
        </FormItem>
        <Button
          iconStart={<PlusOutlinedIcon />}
          onClick={handleSubmit(onSubmit)}
          size="small"
          type="button"
        >
          Add
        </Button>
      </div>
    </Container>
  );
};

export { ProductBannerCreator };
