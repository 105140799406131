import { AxiosError, AxiosResponse } from 'axios';

type TRequest<T extends any> = () => Promise<AxiosResponse<T, AxiosError>>;

const utilConsecutiveRequests = async <K extends any>(
  requests: TRequest<K>[],
) => {
  if (!requests.length) {
    return undefined;
  }

  const result: AxiosResponse[] = [];
  const cloneRequests = [...requests];

  // @ts-ignore
  const requestsChain = async (requests: TRequest<K>[]): AxiosResponse<K, AxiosError>[] => {
    const promise = requests.shift();
    const response = await promise();
    result.push(response);

    return requests.length ? requestsChain(requests) : result;
  };

  return requestsChain(cloneRequests);
};

export { utilConsecutiveRequests };
