import React, { useState } from 'react';

import {
  MinusOutlinedIcon,
  PlusOutlinedIcon,
  RefundIcon,
} from '../../../../../assets/images/icons';
import {
  PaymentType,
  RefundOrderItemData,
  TOrderStatus,
} from '../../../../../common/interfaces/order-api';
import { Button } from '../../../../../common/ui/button';
import { Modal } from '../../../../../common/ui/modal';
import { Table } from '../../../../../common/ui/table';
import { utilFormatMoney } from '../../../../../common/utils';
import { useActions } from '../../../../../hooks';
import { ProductLabel } from '../../../components';
import styles from './RefundConfirmationModal.module.scss';

interface Props {
  isModalVisible?: boolean;
  isProcessLoading?: boolean;
  orderData: {
    address: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    phone: string;
    productId: string;
    imgMedium: string;
    imgSmall: string;
    title: string;
    subtitle: string;
    orderId: number;
    status: TOrderStatus;
    price: number;
    payment: PaymentType;
    quantity: number;
    refund_quantity: number;
  };
}

const RefundConfirmationModal = ({
  isModalVisible = false,
  isProcessLoading = false,
  orderData,
}: Props) => {
  const { changeConfirmModalVisibility, refundOrderProcessRequest } = useActions();
  const [refundedItemCount, setRefundedItemCount] = useState(1);

  const refundedItemCountHandler = (type: 'inc' | 'dec') => {
    const quantity = orderData?.quantity || 0;
    const refundQuantity = orderData?.refund_quantity || 0;
    const totalOrderItemQuantity = quantity - refundQuantity;

    setRefundedItemCount((prevState) => {
      let newValue = 0;
      if (type === 'inc' && prevState + 1 <= totalOrderItemQuantity) {
        newValue = prevState + 1;
      }
      if (type === 'dec' && prevState - 1 > 0) {
        newValue = prevState - 1;
      }
      if (newValue) {
        return newValue;
      }
      return prevState;
    });
  };

  const confirmRefundHandler = () => {
    const data: RefundOrderItemData = {
      orderId: orderData?.orderId,
      refundProducts: [
        {
          id: orderData?.productId,
          quantity: refundedItemCount,
        },
      ],
    };
    refundOrderProcessRequest({ data, payment: orderData?.payment });
  };

  if (!isModalVisible) {
    return null;
  }

  return (
    <Modal open>
      <Modal.Body>
        <p className={styles.modal_title}> Enter the quantity of the item to be returned</p>
        <div className="d-flex flex-column raw-gap-20" style={{ maxWidth: '750px' }}>
          <Table className={styles.modal_table} tableLayout="fixed">
            <Table.Header>
              <Table.Row>
                {['product', 'price', 'quality', 'refundable amount'].map((elem) => (
                  <Table.Column key={elem}>{elem}</Table.Column>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Column>
                  <ProductLabel
                    asin={orderData?.productId}
                    imgUrl={orderData?.imgSmall || orderData?.imgMedium}
                    title={orderData?.title}
                  />
                </Table.Column>
                <Table.Column>{utilFormatMoney(orderData?.price)}</Table.Column>
                <Table.Column>
                  <div className="d-flex column-gap-10 align-center">
                    <Button
                      disabled={isProcessLoading}
                      iconStart={<MinusOutlinedIcon />}
                      onClick={() => refundedItemCountHandler('dec')}
                      outlined
                      size="small"
                      variant="secondary"
                    />
                    {refundedItemCount}
                    <Button
                      disabled={isProcessLoading}
                      iconStart={<PlusOutlinedIcon />}
                      onClick={() => refundedItemCountHandler('inc')}
                      outlined
                      size="small"
                      variant="secondary"
                    />
                  </div>
                </Table.Column>
                <Table.Column>{utilFormatMoney(refundedItemCount * orderData.price)}</Table.Column>
              </Table.Row>
            </Table.Body>
          </Table>
          <span className={styles.footer_total_amount}>
            Total refund amount:
            {` ${utilFormatMoney(refundedItemCount * orderData.price)}`}
          </span>
        </div>
      </Modal.Body>
      <Modal.Actions>
        <div className="d-flex justify-end column-gap-20">
          <Button
            disabled={isProcessLoading}
            onClick={() => {
              changeConfirmModalVisibility(false);
              setRefundedItemCount(1);
            }}
            outlined
            size="small"
            variant="primary"
          >
            Cancel
          </Button>
          <Button
            iconStart={<RefundIcon />}
            loading={isProcessLoading}
            onClick={confirmRefundHandler}
            size="small"
          >
            Confirm refunding
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export { RefundConfirmationModal };
