import { useFieldArray, useFormContext } from 'react-hook-form';

import { Container } from '../../../../../../common/ui/container';
import { Image } from '../../../../../../common/ui/image';
import { CompareProduct, CompareTableForm } from '../../edit-compare-table-form';
import { ProductAttribute } from '../product-attribute';
import styles from './compare-table-item.module.scss';

interface Props {
  productIdx: number;
  product: CompareProduct;
}

const CompareTableItem = ({ product, productIdx }: Props) => {
  const { control } = useFormContext<CompareTableForm>();
  const attributesFields = useFieldArray({ control, name: `products.${productIdx}.attributes` });

  return (
    <Container className={styles.compare_product}>
      <div className={styles.compare_product_image_wrapper}>
        <Image className={styles.compare_product__image} src={product.image} />
      </div>
      <span className={styles.compare_product__title}>{product.title}</span>
      <div className={styles.attributes_container}>
        {attributesFields.fields.map((attribute, attributeIdx) => {
          if (attribute.status === 'deleted') return null;

          return (
            <ProductAttribute
              key={attribute.id}
              attributeIndex={attributeIdx}
              productsIndex={productIdx}
            />
          );
        })}
      </div>
    </Container>
  );
};

export { CompareTableItem };
