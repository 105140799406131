import clsx from 'clsx';

import { TOrderStatus } from '../../../../common/interfaces/order-api';
import { Dropdown, IDropdownProps } from '../../../../common/ui/dropdown';
import { options, orderOptions } from './constants';
import styles from './OrderStatus.module.scss';

interface IOrderStatusProps {
  status: TOrderStatus;
  statusHandler?: (status: TOrderStatus) => void;
  position?: IDropdownProps['position'];
  disabled?: boolean;
}

const OrderStatus = ({ disabled, position, status, statusHandler }: IOrderStatusProps) => {
  const currentOptions = orderOptions[status];

  const handler = (newStatus: number | string) => {
    if (statusHandler) statusHandler(newStatus as TOrderStatus);
  };
  return (
    <Dropdown
      disabled={disabled}
      onOptionClick={handler}
      options={options}
      position={position}
      value={status}
    >
      <div
        className={clsx(
          styles.order_status,
          currentOptions?.type && styles[`order_status__${currentOptions.type}`],
          currentOptions?.outlined && styles.order_status__outlined,
          disabled && styles.order_status__disabled,
        )}
      >
        {status}
      </div>
    </Dropdown>
  );
};

export { OrderStatus };
