import { useController, useForm } from 'react-hook-form';

import { PlusOutlinedIcon } from '../../../../../../assets/images/icons';
import { Button } from '../../../../../../common/ui/button';
import { Container } from '../../../../../../common/ui/container';
import { FileUploader } from '../../../../../../common/ui/file-uploader/FileUploader';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { ProductId, ProductPostAsset } from '../../../../page-form/page-form';
import styles from './product-post-creator.module.scss';

type Props = {
  currentProductId: ProductId;
  onAddButton?: (data: ProductPostAsset) => void;
  onDeleteButton?: () => void;
};

const ProductPostCreator = ({ currentProductId, onAddButton, onDeleteButton }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<ProductPostAsset>({
    defaultValues: {
      assets_type: 'blogs',
      type: 'added',
    },
  });

  const clearForm = () => {
    setValue('file_data.text', '');
    setValue('file', null);
    setValue('product_id', '');
    setValue('preview', null);
  };

  const fileField = useController({
    control,
    name: 'file',
    rules: { required: 'File is required' },
  });
  const textField = useController({ control, name: 'file_data.text' });

  const onSubmit = (data: ProductPostAsset) => {
    data.product_id = currentProductId;

    if (onAddButton) onAddButton(data);

    clearForm();
  };

  const onDeleteHandler = () => {
    if (onDeleteButton) onDeleteButton();

    clearForm();
  };

  return (
    <Container className={styles.container} onDeleteButton={onDeleteHandler} withDeleteButton>
      <div className={styles.image_container}>
        <FileUploader
          className={styles.image_uploader}
          error={errors?.file?.message}
          format={['image/jpeg', 'image/png', 'image/svg', 'image/jpg']}
          onChange={(file, preview) => {
            fileField.field.onChange(file);
            setValue('preview', preview);
          }}
          value={fileField.field.value}
        />
      </div>
      <div className={styles.fields_container}>
        <FormItem label="Text" message={textField.fieldState.error?.message}>
          <TextField
            fullWidth
            {...textField.field}
            invalid={textField.fieldState.invalid}
            placeholder="Enter your simple wording"
            rows={6}
          />
        </FormItem>
        <Button
          fullWidth
          iconStart={<PlusOutlinedIcon />}
          onClick={handleSubmit(onSubmit)}
          size="small"
          type="button"
        >
          Add
        </Button>
      </div>
    </Container>
  );
};
export { ProductPostCreator };
