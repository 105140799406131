import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = { message: string; code?: number };

export interface CouponDto {
  id: number;
  code: string;
  discount_amount: number;
  expired_at: string;
  asins: string[];
  created_at?: string;
  updated_at?: string;
}

export type TGetProductsCouponListInitialState = {
  error: TError;
  message: {
    type: 'success' | 'error';
    text: string;
  };
  loading: boolean;
  coupons: CouponDto[];
};

const initialState: TGetProductsCouponListInitialState = {
  coupons: [],
  error: null,
  loading: false,
  message: null,
};

export const getProductsCouponListSlice = createSlice({
  initialState,
  name: 'getProductsCouponList',
  reducers: {
    getProductsCouponListClearState() {
      return initialState;
    },
    getProductsCouponListError(
      state,
      action: PayloadAction<TGetProductsCouponListInitialState['error']>,
    ) {
      state.error = action.payload;
      state.loading = false;
      state.coupons = [];
    },
    getProductsCouponListRequest(
      ...args: [TGetProductsCouponListInitialState, PayloadAction<string[]>]
    ) {
      const [state] = args;
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    getProductsCouponListResponse(state, action: PayloadAction<CouponDto[]>) {
      state.loading = false;
      state.coupons = action.payload;
    },
    replaceProductsCouponListItem(state, action: PayloadAction<CouponDto>) {
      return {
        ...state,
        coupons: state.coupons.map((coupon) => {
          if (coupon.id === action.payload.id) {
            return action.payload;
          }
          return coupon;
        })
      };
    }
  },
});

export const { actions, reducer } = getProductsCouponListSlice;
