import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChangeOrderStatusData } from '../../../common/interfaces/order-api';

type TError = { message: string; code?: number };

type TOrderStatusState = {
  error: TError,
  loading: boolean,
};

const initialState: TOrderStatusState = {
  error: null,
  loading: false,
};

export const orderStatusSlice = createSlice({
  initialState,
  name: 'orderStatus',
  reducers: {
    changeOrderStatusError(state, action: PayloadAction<TError>) {
      state.loading = false;
      state.error = action.payload;
    },
    changeOrderStatusRequest(...args: [TOrderStatusState, PayloadAction<ChangeOrderStatusData>]) {
      const [state] = args;
      state.loading = true;
    },
    changeOrderStatusResponse(state) {
      state.loading = false;
    },
  },
});

export const { actions, reducer } = orderStatusSlice;
