import axios, { AxiosResponse } from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import { useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { Marketplaces } from '../../common/constants/enums';
import { marketplaceOptions } from '../../common/constants/select-options';
import {
  IAggPageDeleteProductAssets,
  IAggPageProductAssets,
  Product,
} from '../../common/interfaces/agg-page';
import { getAggPageApiClient } from '../../common/services/agg-pages-api.service';
import { getProductsApiClient } from '../../common/services/products-api.service';
import { getProductsManagementApiClient } from '../../common/services/products-management-api.service';
import { isAmazonProduct, isWalmartProduct } from '../../common/type-guards';
import { Button } from '../../common/ui/button';
import { Container } from '../../common/ui/container';
import { ErrorMessage } from '../../common/ui/error-message/ErrorMessage';
import { FormItem } from '../../common/ui/formItem';
import { Modal } from '../../common/ui/modal';
import { Select } from '../../common/ui/select';
import { TextField } from '../../common/ui/text-field';
import { utilConsecutiveRequests, utilProductDataPreparing } from '../../common/utils';
import { IProductContent } from '../../context/page-form-context';
import { DeleteButton } from '../common/partials/buttons/DeleteButton';
import { PageTitle } from '../common/partials/PageTitle/PageTitle';
import { ProductContentForm } from '../common/products/product-content-form';
import { ReviewsForm } from '../common/products/product-reviews-form';
import { ProductVideoAssetsForm } from '../pages/components/products-videos/product-video-assets-form';
import { ProductId, TPageForm } from '../pages/page-form/page-form';
import styles from './VWContentPage.module.scss';

type SearchProductForm = {
  product_id: ProductId;
  marketplace: Marketplaces;
};

const VWContentPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [product, setProduct] = useState<Product>(null);

  const searchProductForm = useForm<SearchProductForm>();
  const productIdField = useController({
    control: searchProductForm.control,
    name: 'product_id',
    rules: { required: 'Required' },
  });

  const productForm = useForm<TPageForm>();
  const marketplace = useController({
    control: searchProductForm.control,
    defaultValue: Marketplaces.Amazon,
    name: 'marketplace',
    rules: { required: 'Field is required' },
  });

  const isSaveDisabled = _.isEmpty(productForm.formState.dirtyFields);

  const clearProductInfo = () => {
    productForm.reset();
    searchProductForm.setValue('product_id', '');
    setProduct(null);
  };

  const getProduct = async ({ marketplace, product_id }: SearchProductForm) => {
    try {
      setIsLoading(true);
      let product: Product;

      if (marketplace === Marketplaces.Amazon) {
        const productResponse = await getProductsApiClient().getProductByAsin(product_id, {
          features: true,
        });
        product = productResponse.data?.data?.products.length
          ? productResponse.data?.data?.products[0]
          : null;
      }

      if (marketplace === Marketplaces.Walmart) {
        const productResponse = await getProductsApiClient().getProductById(product_id, {
          include_product_features: true,
        });
        product = productResponse.data.products.length ? productResponse.data.products[0] : null;
      }

      if (!product) {
        searchProductForm.setError('product_id', { message: 'Not Found' });
        return;
      }

      if (isWalmartProduct(product)) {
        product.product_id = product.w_item_id;
      }

      if (isAmazonProduct(product)) {
        product.product_id = product.asin;
      }

      setProduct(product);

      const { assets, reviews } = utilProductDataPreparing(product);

      const contentCopiesResponse = await getProductsManagementApiClient().getProductContentCopies([
        product.product_id,
      ]);

      const productCopy = contentCopiesResponse.data.products[0];

      const content: IProductContent = {
        _id: uuidv4(),
        product_id: productCopy.asin,
        type: 'origin',
        ...productCopy.content,
      };

      localStorage.setItem('lastUsedProduct', product_id);

      productForm.reset({
        content: [content],
        productAssets: [{ assets, product_id: product.product_id }],
        reviews: [reviews],
      });
    } catch (e) {
      let error: string;
      if (axios.isAxiosError(e)) {
        error = e.message;
      } else {
        error = 'Request failed, something wrong';
      }
      searchProductForm.setError('product_id', { message: error, type: 'validate' });
    } finally {
      setIsLoading(false);
    }
  };

  const saveProduct = async (data: TPageForm) => {
    setIsLoading(true);
    setError(null);

    try {
      // content
      await getAggPageApiClient().submitProductContent({
        bottom_line: data.content[0]?.bottomLine,
        faq: data.content[0]?.faq,
        marketplace: marketplace.field.value,
        marketplace_item_id: product.product_id,
        pros: data.content[0]?.pros,
        summary: data.content[0]?.summary,
      });

      // videos
      const formAssetsForDelete: IAggPageDeleteProductAssets[] = [];
      const formAssetsForUpload: IAggPageProductAssets[] = [];
      const formAssetsForUpdate: {
        marketplace_item_id: ProductId;
        marketplace: Marketplaces;
        assets_type: 'video';
        product_assets: { link: string; data: string }[];
      }[] = [];

      data.productAssets[0].assets.forEach(({ assets_type, product_id, type, url }) => {
        if (type === 'deleted') {
          formAssetsForDelete.push({
            assets_type,
            link: url,
            marketplace: marketplace.field.value,
            marketplace_item_id: product_id,
          });
        }
      });

      const updatedProductVideosAssets: { link: string; data: string }[] = [];

      data.productAssets[0].assets
        .filter((elem) => elem.type !== 'deleted')
        .forEach((asset, i) => {
          asset.file_data.order = i + 1;
          const { assets_type, file, file_data, product_id, type, url } = asset;

          if (type === 'added') {
            formAssetsForUpload.push({
              assets_type,
              file,
              file_data: JSON.stringify(file_data),
              marketplace: marketplace.field.value,
              marketplace_item_id: product_id,
            });
          }

          if (type !== 'added') {
            updatedProductVideosAssets.push({ data: JSON.stringify(file_data), link: url });
          }
        });

      formAssetsForUpdate.push({
        assets_type: 'video',
        marketplace: marketplace.field.value,
        marketplace_item_id: product.product_id,
        product_assets: updatedProductVideosAssets,
      });

      if (formAssetsForUpdate.length) {
        const productAssetsUpdatedPromise: (() => Promise<AxiosResponse<any, any>>)[] = [];

        formAssetsForUpdate.forEach((elem) => {
          if (elem.product_assets.length) {
            productAssetsUpdatedPromise.push(
              getAggPageApiClient().updateProductAssets.bind(getAggPageApiClient(), elem),
            );
          }
        });

        await utilConsecutiveRequests(productAssetsUpdatedPromise);
      }

      if (formAssetsForUpload.length) {
        const productAssetsUploadPromise = formAssetsForUpload.map((asset) =>
          getAggPageApiClient().submitProductAssets.bind(getAggPageApiClient(), asset),);

        await utilConsecutiveRequests(productAssetsUploadPromise);
      }

      if (formAssetsForDelete.length) {
        const productAssetsDeletePromise = formAssetsForDelete.map((asset) =>
          getAggPageApiClient().deleteProductAssets.bind(getAggPageApiClient(), asset),);

        await utilConsecutiveRequests(productAssetsDeletePromise);
      }

      // reviews
      await getProductsManagementApiClient().renewProductReviews({
        marketplace: marketplace.field.value,
        marketplace_item_id: product.product_id,
        reviews: data.reviews[0].reviews,
      });

      setProduct(null);
      setIsModalOpen(true);

      searchProductForm.reset();
    } catch (e) {
      let error: string;

      if (axios.isAxiosError(e)) {
        error = e.message;
      } else {
        error = 'Request failed, something wrong';
      }

      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const labelProductType = marketplace.field.value === Marketplaces.Walmart ? 'product ID' : 'Asin';
  const placeholder = marketplace.field.value === Marketplaces.Walmart ? '1840281673' : 'B08ZVWDQQ1';

  return (
    <div className="flex-column raw-gap-20">
      <PageTitle className="mb-30" title="Video widget content editor" withReturn />
      <div className="d-flex">
        {isModalOpen && (
          <Modal open>
            <Modal.Title>Success!</Modal.Title>
            <Modal.Body>Product changed successfully</Modal.Body>
            <Modal.Actions>
              <Button onClick={() => setIsModalOpen(false)} outlined size="small">
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        <form
          className={clsx('flex-column raw-gap-10')}
          onSubmit={searchProductForm.handleSubmit((data) => getProduct(data))}
        >
          <div className={styles.search_field_wrapper}>
            <FormItem label="Marketplace">
              <Select
                isDisabled
                onChange={(option) => {
                  // @ts-ignore
                  marketplace.field.onChange(option.value);
                }}
                options={marketplaceOptions}
                placeholder="Marketplace ..."
                value={marketplaceOptions.find((el) => el.value === marketplace.field.value)}
              />
            </FormItem>
          </div>
          <FormItem
            className={styles.button}
            label={`Enter ${labelProductType}`}
            message={productIdField.fieldState.error?.message}
          >
            <div className={styles.search_field_wrapper}>
              <TextField
                {...productIdField.field}
                className={styles.asin_field}
                invalid={productIdField.fieldState.invalid}
                placeholder={placeholder}
                variant="secondary"
              />
              {product && <DeleteButton handler={clearProductInfo} />}
            </div>
          </FormItem>
          {/* {lastUsedAsin && ( */}
          {/*  <FormItem label="Last used"> */}
          {/*    <span */}
          {/*      className={styles.prev_asin} */}
          {/*      onClick={() => { */}
          {/*        searchProductForm.reset({ product_id: lastUsedAsin }); */}
          {/*        getProduct({ product_id: lastUsedAsin }); */}
          {/*      }} */}
          {/*    > */}
          {/*      {lastUsedAsin} */}
          {/*    </span> */}
          {/*  </FormItem> */}
          {/* )} */}
          <Button
            className={styles.button}
            disabled={!!product}
            fullWidth
            loading={isLoading}
            type="submit"
          >
            Add
          </Button>
        </form>
      </div>

      {product && (
        <FormProvider {...productForm}>
          <form
            className={clsx('flex-column raw-gap-20')}
            id="contentForm"
            onSubmit={productForm.handleSubmit(saveProduct)}
          >
            <div
              className={clsx(
                styles.container,
                'd-flex flex-column raw-gap-20 justify-center align-center',
              )}
            >
              <div className={styles.product}>
                <img
                  alt="product"
                  className={styles.product_image}
                  height={150}
                  src={product?.img_medium || product?.img_large}
                  width={150}
                />
                <span className={styles.product_title}>{product?.title}</span>
              </div>
            </div>

            <ProductContentForm control={productForm.control} index={0} />
            <Container className="w-100 flex-column raw-gap-20">
              <h5>Videos</h5>
              <ProductVideoAssetsForm
                control={productForm.control}
                currentProductId={product.product_id}
                productIdx={0}
              />
            </Container>
            <Container className="w-100 flex-column raw-gap-20">
              <h5>Amazon reviews</h5>
              <ReviewsForm control={productForm.control} productIdx={0} />
            </Container>

            <div className="d-flex align-center column-gap-20">
              <Button
                className={styles.button}
                disabled={isSaveDisabled}
                form="contentForm"
                fullWidth
                loading={isLoading}
                type="submit"
              >
                Save
              </Button>
              <Button onClick={clearProductInfo} outlined>
                Cancel
              </Button>
              {error && <ErrorMessage error={error} />}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export { VWContentPage };
