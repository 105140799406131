import clsx from 'clsx';
import React, { ChangeEvent, forwardRef, InputHTMLAttributes, ReactNode, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import styles from './Input.module.scss';

type InputProps<T = unknown> = InputHTMLAttributes<HTMLInputElement> & {
  size?: 'medium';
  variant?: 'primary' | 'secondary';
  invalid?: boolean;
  fullWidth?: boolean;
  iconAfter?: ReactNode;
  iconBefore?: ReactNode;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: T;
  textBefore?: string;
  limit?: number;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      fullWidth = false,
      iconAfter,
      iconBefore,
      invalid = false,
      limit,
      max,
      min,
      onChange,
      placeholder = '',
      size = 'medium',
      textBefore,
      type = 'text',
      value,
      variant = 'primary',
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRefs = mergeRefs([inputRef, ref]);

    const isCountError = limit && typeof value === 'string' && value.length > limit;

    const classes = clsx(
      styles.input,
      styles[size],
      styles[variant],
      (invalid || isCountError) && styles.invalid,
      fullWidth && styles.fullWidth,
    );

    return (
      <div className={classes}>
        {iconBefore && <span className={styles.iconBefore}>{iconBefore}</span>}
        {textBefore && <span>{textBefore}</span>}
        <input
          ref={mergedRefs}
          max={max}
          min={min}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        {iconAfter && <span className={styles.iconAfter}>{iconAfter}</span>}
        {Boolean(limit) && type === 'text' && (
          <span className={clsx(styles.count, isCountError && styles.count__warning)}>
            {`${String(value).length}/${limit}`}
          </span>
        )}
      </div>
    );
  },
);

export { Input };
