import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CompareTableRequestData
} from '../../../modules/pages/components/edit-compare-table-form/edit-compare-table-form';

type TError = { message: string; code?: number };

type TUpdateCompareProductTableInitialState = {
  loading: boolean;
  error: TError;
  isRequestComplete: boolean;
};

const initialState: TUpdateCompareProductTableInitialState = {
  error: null,
  isRequestComplete: false,
  loading: false,
};

const updateCompareProductTableSlice = createSlice({
  initialState,
  name: 'updateCompareProductTable',
  reducers: {
    setUpdateCompareProductTableLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateCompareProductTableRequest(
      ...args: [
        TUpdateCompareProductTableInitialState,
        PayloadAction<{
          data: CompareTableRequestData;
          cb?: () => void;
        }>,
      ]
    ): TUpdateCompareProductTableInitialState {
      const [state] = args;
      return {
        ...state,
        error: null,
        ...(state.isRequestComplete ? { isRequestComplete: false } : {}),
      };
    },
    updateCompareProductTableResponse(state) {
      state.isRequestComplete = true;
    },
    updateCompareProductTabletError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
  },
});

export const { actions, reducer } = updateCompareProductTableSlice;
