import axios from 'axios';
import { debounce } from 'lodash';
import { FocusEventHandler, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { BreadcrumbsService } from '../../../../common/services/breadcrumbs-api.service';
import { Select } from '../../../../common/ui/select';
import { IBreadcrumb } from '../../types';
import { ProductCategorySuggestion } from '../product-category-suggestion';
import styles from './product-category-select.module.scss';

interface ProductCategorySelectProps {
  onChange?: (value: SelectOption) => void;
  value?: IBreadcrumb;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  name?: string;
  disabled?: boolean;
  productsIds?: number[];
  isSuggestionVisible?: boolean;
}

interface SelectOption {
  label: string;
  value: string;
}

const ProductCategorySelect = ({
  disabled = false,
  name,
  onBlur,
  onChange,
  value,
  productsIds = [],
  isSuggestionVisible = false,
}: ProductCategorySelectProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectOptions, setSelectOptions] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (option: SelectOption) => {
    if (option && onChange) {
      onChange(option);
    } else {
      onChange(null);
    }
  };

  const fetchProductsCategories = async (search: string) => {
    setIsLoading(true);
    try {
      const { data } = await BreadcrumbsService.getList({ name: search });
      const options: SelectOption[] = data.breadcrumbs.map((elem) => ({
        label: elem.name,
        value: elem.code,
      }));
      setSelectOptions(options);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        toast.error(`Status ${err.status}: ${err.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const [error, setError] = useState('');
  const [suggestion, setSuggestion] = useState<{ code: string; name: string }>(null);
  const [suggestionLoading, setSuggestionLoading] = useState(false);

  const getCategorySuggestion = async (products: number[]) => {
    setSuggestionLoading(true);
    try {
      const { data } = await BreadcrumbsService.getSuggestion(products);
      setSuggestion({ code: data.suggested.code, name: data.suggested.name });
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setError(err.message);
      }
    } finally {
      setSuggestionLoading(false);
    }
  };

  const onSuggestionClick = async (category: { code: string; name: string }) => {
    if (!selectOptions.some((elem) => elem.value === category.code)) {
      setSelectOptions((prevState) => [
        ...prevState,
        { label: category.name, value: category.code },
      ]);
      setTimeout(() => {
        handleChange({ label: category.name, value: category.code });
      }, 0);
    }
  };

  useEffect(() => {
    if (isSuggestionVisible) {
      getCategorySuggestion(productsIds);
    }
  }, [productsIds.length]);

  useEffect(() => {
    const debouncedFetch = debounce(fetchProductsCategories, 400);

    debouncedFetch(value?.name || searchValue);

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchValue, value?.name]);

  return (
    <div className={styles.container}>
      <Select
        className={styles.select}
        defaultValue={selectOptions.find((elem) => elem?.value === value?.code)}
        inputValue={searchValue}
        isClearable
        isDisabled={disabled}
        isLoading={isLoading}
        isSearchable
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onInputChange={(value) => setSearchValue(value)}
        options={selectOptions}
        placeholder="Category ..."
        value={selectOptions.find((elem) => elem.value === value?.code)}
      />
      {isSuggestionVisible && (
        <ProductCategorySuggestion
          error={error}
          loading={suggestionLoading}
          onClick={() => onSuggestionClick(suggestion)}
          suggestion={suggestion?.name}
        />
      )}
    </div>
  );
};

export { ProductCategorySelect };
