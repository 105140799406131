import { ChangeEvent, useContext } from 'react';

import { AiIcon, QuestionCircleIcon } from '../../../../assets/images/icons';
import { Checkbox } from '../../../../common/ui/checkbox';
import { Tooltip } from '../../../../common/ui/tooltip';
import { AggPageFormContext } from '../../../../context/page-form-context';
import styles from './content-generation.module.scss';

const ContentGeneration = () => {
  const { isGenerateContent, products, setIsGenerateContent } = useContext(AggPageFormContext);

  const isContentGenerated = products?.some((elem) => elem.ai_content_renewed_at);

  const checkboxHandler = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    setIsGenerateContent(checked);
  };
  return (
    <div className="d-flex column-gap-10">
      {isContentGenerated ? (
        <div className={styles.ai_label}>
          <span>Page content is generated by</span>
          <AiIcon />
        </div>
      ) : (
        <Checkbox
          checked={isGenerateContent}
          disabled={isContentGenerated}
          name="isContentGenerate"
          onChange={checkboxHandler}
        >
          Generate content
        </Checkbox>
      )}
      <div className={styles.question}>
        <Tooltip
          placement="left"
          title={
            'Generating page content by IA service. '
          + 'It applies to product content. '
          + "If you see a label 'Page content is generated by AI' that mean the process has been already used "
          + 'for the current page. If not, you can started process marking checkbox, the process will start '
          + 'with the page saving, content will be added automatically in a few minutes.'
          }
        >
          <QuestionCircleIcon />
        </Tooltip>
      </div>
    </div>
  );
};

export { ContentGeneration };
