import { TemporaryLinkService } from 'common/services/temporary-link-service.api';
import { TextField } from 'common/ui/text-field';
import { useActions, useAppSelector } from 'hooks';
import { TemporaryLinksTable } from 'modules/temporary-links/components/temporary-links-table/temporary-links-table';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  TEMPORARY_LINKS_DEFAULT_LIMIT,
  TEMPORARY_LINKS_DEFAULT_OFFSET,
} from 'store/reducers/temporary-links/temporaryLinksSlice';

import importImage from '../../../../assets/images/link.png';
import { TemporaryLinksForm } from '../../components';
import styles from './temporary-links-page.module.scss';

const TemporaryLinksPage = () => {
  const { getPublisherTemporaryLinks, setTemporaryLinksLimit, setTemporaryLinksOffset } = useActions();

  const [selectedPublisher, setSelectedPublisher] = useState<number>(0);
  const [showTagsTable, setShowTagsTable] = useState(false);
  const [search, setSearch] = useState('');
  const [searchDebounce, setSearchDebounce] = useState<NodeJS.Timeout>();
  const [offsetResetBySearch, setOffsetResetBySearch] = useState(false);

  const count = useAppSelector((state) => state.temporaryLinks.count);
  const limit = useAppSelector((state) => state.temporaryLinks.limit);
  const offset = useAppSelector((state) => state.temporaryLinks.offset);

  useEffect(() => {
    if (showTagsTable && selectedPublisher) {
      if (search && offset === 0 && offsetResetBySearch) {
        debouncedDispatchGetPublisherTemporaryLinks(700);
        return;
      }
      clearTimeout(searchDebounce);
      dispatchGetPublisherTemporaryLinks();
    }
  }, [showTagsTable, selectedPublisher, limit, offset, search, offsetResetBySearch]);

  const debouncedDispatchGetPublisherTemporaryLinks = (delayms: number) => {
    if (searchDebounce) {
      clearTimeout(searchDebounce);
    }

    setSearchDebounce(setTimeout(() => dispatchGetPublisherTemporaryLinks(), delayms));
  };

  const dispatchGetPublisherTemporaryLinks = () => {
    getPublisherTemporaryLinks({
      publisherId: selectedPublisher,
      query: {
        limit,
        offset,
        search,
      },
    });
  };

  const handleSearchChange = (newSearch: string) => {
    setSearch(newSearch);
    setTemporaryLinksOffset(TEMPORARY_LINKS_DEFAULT_OFFSET);
    setOffsetResetBySearch(true);
  };

  const handlePublisherSelect = (publisherId: number) => {
    setSelectedPublisher(publisherId);
    setTemporaryLinksLimit(TEMPORARY_LINKS_DEFAULT_LIMIT);
    setTemporaryLinksOffset(TEMPORARY_LINKS_DEFAULT_OFFSET);
    setSearch('');
    setOffsetResetBySearch(false);
  };

  const handleLimitChange = (newLimit: number) => {
    setOffsetResetBySearch(false);
    setTemporaryLinksLimit(newLimit);
    setTemporaryLinksOffset(TEMPORARY_LINKS_DEFAULT_OFFSET);
  };

  const handleOffsetChange = (offset: number) => {
    setTemporaryLinksOffset(offset);
    setOffsetResetBySearch(false);
  };

  const handleDeleteLink = async (link: string) => {
    try {
      await TemporaryLinkService.deleteTemporaryLink(link, selectedPublisher);
      dispatchGetPublisherTemporaryLinks();
      toast.success('Hardcoded link deleted succesfully');
    } catch {
      toast.error('Error deleting hardcoded link');
    }
  };

  const handleShowTagsTableButtonClick = () => {
    setShowTagsTable((prev) => !prev);
  };

  const handleCreateLink = () => {
    if (selectedPublisher && showTagsTable) {
      dispatchGetPublisherTemporaryLinks();
    }
  };

  return (
    <div className={styles.tag_creator}>
      <img alt="Import" className={styles.logo} src={importImage} />
      <h1 className={styles.title}>Hardcode Tags</h1>
      <span className={styles.hint}>Hardcode the tags below</span>
      <TemporaryLinksForm
        onCreateTag={handleCreateLink}
        onPublisherSelect={handlePublisherSelect}
        onShowTagsButtonClick={handleShowTagsTableButtonClick}
      />
      {showTagsTable && selectedPublisher !== 0 && (
        <div className={styles.temporary_links_table_container}>
          <TextField
            className={styles.temporary_links_search_input}
            id="search"
            name="search"
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder="Enter link or ASIN"
            value={search}
          />
          <TemporaryLinksTable
            count={count}
            handleDeleteLink={handleDeleteLink}
            limit={limit}
            onLimitChange={handleLimitChange}
            onOffsetChange={handleOffsetChange}
          />
        </div>
      )}
    </div>
  );
};

export { TemporaryLinksPage };
