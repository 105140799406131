import { useContext, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AggPageFormContext } from '../../../../context/page-form-context';
import { ReviewsForm } from '../../../common/products/product-reviews-form';
import { ProductReviewsType, TPageForm } from '../../page-form/page-form';

type Props = {
  currentProductId: string;
};

const ProductReviews = ({ currentProductId }: Props) => {
  const { products, productsReviews } = useContext(AggPageFormContext);

  const { control } = useFormContext<TPageForm>();
  const { append, fields, remove, update } = useFieldArray({ control, name: 'reviews' });

  // Form update process if products list was changed
  useEffect(() => {
    // Updating
    if (productsReviews.length && productsReviews.length === fields.length) {
      const filteredReviews = productsReviews.filter(
        (elem) => !fields.some((field) => field.product_id === elem.product_id),
      );
      if (filteredReviews.length) {
        const indexToReplace = fields.findIndex(
          (field) => !productsReviews.some((elem) => elem.product_id === field.product_id),
        );
        update(indexToReplace, filteredReviews[0] as ProductReviewsType);
      }
      return;
    }

    // Adding
    if (productsReviews.length > fields.length) {
      const newProductReviews = productsReviews.filter(
        (elem) => !fields.some((field) => field.product_id === elem.product_id),
      );
      if (newProductReviews.length) {
        newProductReviews.forEach((reviews) => append(reviews as ProductReviewsType));
      }
    }

    // Deleting
    if (productsReviews.length < fields.length) {
      fields.forEach((field, i) => {
        if (!productsReviews.some((elem) => elem.product_id === field.product_id)) {
          remove(i);
        }
      });
    }
  }, [productsReviews]);

  if (products?.length === 0) {
    return null;
  }

  return (
    <div className="flex-column raw-gap-20 w-100">
      {fields.map((field, productIdx) => {
        if (field.product_id === currentProductId) {
          return <ReviewsForm key={field.product_id} control={control} productIdx={productIdx} />;
        }
        return null;
      })}
    </div>
  );
};

export { ProductReviews };
