import { Button } from 'common/ui/button';
import { Pagination } from 'common/ui/pagination';
import { Table } from 'common/ui/table';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from 'hooks';
import { useState } from 'react';

import { BinIcon } from '../../../../assets/images/icons';
import styles from './temporary-links-table.module.scss';

interface TemporaryLinksTableProps {
  count: number;
  limit: number;
  onLimitChange(limit: number): void;
  onOffsetChange(offset: number): void;
  handleDeleteLink(link: string): Promise<void>;
}

const TemporaryLinksTable = ({
  count,
  handleDeleteLink,
  limit,
  onLimitChange,
  onOffsetChange,
}: TemporaryLinksTableProps) => {
  const links = useAppSelector((state) => state.temporaryLinks.tempLinks);
  const status = useAppSelector((state) => state.temporaryLinks.status);

  const [hoveredLink, setHoveredLink] = useState<string | null>(null);

  if (status === 'succeeded' && links.length === 0) {
    return (
      <Table tableLayout="auto">
        <Table.Body>
          <Table.NoResult visible />
          <div style={{ marginBottom: '20px' }} />
        </Table.Body>
      </Table>
    );
  }

  return (
    <Table tableLayout="auto">
      {(() => {
        if (status === 'pending') {
          return (
            <Table.Body>
              <div style={{ margin: '50px 0' }}>
                <Table.Loader loading size="medium">
                  Loading
                </Table.Loader>
              </div>
            </Table.Body>
          );
        }

        if (status === 'succeeded' && links.length === 0) {
          return (
            <Table.Body>
              <Table.NoResult visible />
              <div style={{ marginBottom: '20px' }} />
            </Table.Body>
          );
        }

        if (status === 'succeeded' && links.length > 0) {
          return (
            <Table.Body>
              {status === 'succeeded'
                && links.length !== 0
                && links.map((link) => (
                  <Table.Row key={link.link}>
                    <Table.Column
                      className={`${styles.linkColumn} ${
                        hoveredLink === link.link ? styles.hovered : styles.notHovered
                      }`}
                      onMouseEnter={() => setHoveredLink(link.link)}
                      onMouseLeave={() => setHoveredLink(null)}
                      style={{ fontWeight: 400 }}
                    >
                      {link.link}
                    </Table.Column>
                    <Table.Column>{format(parseISO(link.created_at), 'dd MMM yyyy')}</Table.Column>
                    <Table.Column>{format(parseISO(link.expired_at), 'dd MMM yyyy')}</Table.Column>
                    <Table.Column className={styles.actionColumn}>
                      <Button
                        iconStart={<BinIcon />}
                        onClick={() => handleDeleteLink(link.link)}
                        outlined
                        placeholder="tags"
                        size="small"
                        variant="danger"
                      />
                    </Table.Column>
                  </Table.Row>
                ))}
            </Table.Body>
          );
        }

        return null;
      })()}
      <Table.Footer>
        <Pagination
          count={count}
          limit={limit}
          limitHandler={onLimitChange}
          onChange={(offset) => onOffsetChange(offset)}
        />
      </Table.Footer>
    </Table>
  );
};

export { TemporaryLinksTable };
