import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFetchPublisherImagesOptions, PagePublisherImage } from '../../../common/interfaces/agg-page';

type TError = { message: string; code?: number };

type TPublisherImagesListInitialState = {
  loading: boolean;
  error: TError;
  loadedPublisherImages: PagePublisherImage[];
  nextPage: string;
};

const initialState: TPublisherImagesListInitialState = {
  error: null,
  loadedPublisherImages: [],
  loading: false,
  nextPage: null,
};

const publisherImagesListSlice = createSlice({
  initialState,
  name: 'publisherImagesList',
  reducers: {
    fetchPublisherImagesListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchPublisherImagesListRequest(
      ...args: [
        TPublisherImagesListInitialState,
        PayloadAction<Partial<IFetchPublisherImagesOptions>>,
      ]
    ) {
      const [state] = args;
      state.error = null;
    },
    fetchPublisherImagesListResponse(
      state,
      action: PayloadAction<{
        publisherImages: PagePublisherImage[];
        nextPage: string;
      }>,
    ) {
      state.loadedPublisherImages = [
        ...state.loadedPublisherImages,
        ...action.payload.publisherImages,
      ];
      state.nextPage = action.payload?.nextPage || null;
    },
    resetPublisherImagesListState(
      state,
      action: PayloadAction<Partial<TPublisherImagesListInitialState>>,
    ) {
      return { ...state, ...action.payload };
    },
    setPublisherImagesListFetchingLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = publisherImagesListSlice;
