import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBestPage } from '../../../common/interfaces/agg-page';
import { PagesFilterOptions } from '../../../modules/pages/pages/pages/pages';

type TError = { message: string; code?: number };

type TFetchAllPagesInitialState = {
  pages: IBestPage[];
  loading: boolean;
  error: TError;
  totalCount: number
};

const initialState: TFetchAllPagesInitialState = {
  error: null,
  loading: false,
  pages: [],
  totalCount: null
};

const pagesListSlice = createSlice({
  initialState,
  name: 'fetchAllPages',
  reducers: {
    deletePageFromListById(state, action: PayloadAction<number>) {
      state.pages = state.pages.filter((page) => page.page_id !== action.payload);
    },
    fetchPagesListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchPagesListRequest(
      ...args: [
        TFetchAllPagesInitialState,
        PayloadAction<{
          filters: PagesFilterOptions;
          onSuccess?: () => void;
        }>,
      ]
    ) {
      const [state] = args;
      state.error = null;
    },
    fetchPagesListResponse(state, action: PayloadAction<{ pages: IBestPage[], totalCount: number }>) {
      state.pages = action.payload.pages;
      state.totalCount = action.payload.totalCount;
    },
    setPagesListLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    }
  },
});

export const { actions, reducer } = pagesListSlice;
