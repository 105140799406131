import axios, { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { DomainsListResponse } from '../../common/interfaces/domains';
import { getAggPageApiClient } from '../../common/services/agg-pages-api.service';
import { domainsApiService } from '../../common/services/domains-api.service';
import { actions } from '../actions';

type FetchPublisherListSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{
  publisherImages: string[];
  nextPage: string;
}>
>;

function* fetchPublisherImagesList(
  action: ReturnType<typeof actions.fetchPublisherImagesListRequest>,
): FetchPublisherListSagaType {
  yield put(actions.setPublisherImagesListFetchingLoading(true));
  try {
    const aggPageClient = getAggPageApiClient();
    const response = yield call(
      aggPageClient.getPublisherImages.bind(aggPageClient, action.payload),
    );
    if (response.status === 200) {
      yield put(
        actions.fetchPublisherImagesListResponse({
          nextPage: response.data?.nextPage,
          publisherImages: response.data.publisherImages.map((elem) => ({
            assets_type: 'publisherImages',
            file_data: null,
            url: elem,
          })),
        }),
      );
    } else {
      yield put(
        actions.fetchPublisherImagesListError({
          code: response?.status,
          message: response.statusText,
        }),
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(
        actions.fetchPublisherImagesListError({
          code: err?.response?.status,
          message: err.message,
        }),
      );
    } else {
      yield put(actions.fetchPublisherImagesListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setPublisherImagesListFetchingLoading(false));
  }
}

type DomainListSagaType = Generator<StrictEffect, any, AxiosResponse<DomainsListResponse>>;

function* fetchDomainList(
  action: ReturnType<typeof actions.fetchDomainListRequest>,
): DomainListSagaType {
  yield put(actions.setDomainsListLoading(true));
  try {
    const { data } = yield call(domainsApiService.getDomains, action.payload);
    yield put(
      actions.fetchDomainListResponse({
        domains: data.domains.filter((d) => ![4, 5, 6].includes(d.id)),
        totalCount: data.totalCount,
      }),
    );
    yield put(actions.setDomainsListLoading(false));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(
        actions.fetchDomainListError({ code: err?.response?.status, message: err.message }),
      );
    } else {
      yield put(actions.fetchDomainListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setDomainsListLoading(false));
  }
}

export function* commonSagasWatcher() {
  yield takeLatest(actions.fetchPublisherImagesListRequest, fetchPublisherImagesList);
  yield takeLatest(actions.fetchDomainListRequest, fetchDomainList);
}
