import axios, { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { ICustomerDto, IOrderItemDto, TGetOneOrderResponse } from '../../common/interfaces/order-api';
import { getOrderApi } from '../../common/services/order-api';
import { actions } from '../actions';

type GetOrdersRequestActionType = ReturnType<typeof actions.getOrdersRequest>;

function* getOrdersSaga(action: GetOrdersRequestActionType) {
  try {
    const { data } = yield call(getOrderApi().getAll, action.payload);
    yield put(actions.getOrdersResponse({ count: data.totalCount, orders: data.orders }));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.getOrdersError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.getOrdersError({ message: 'Something wrong' }));
    }
  }
}

type GetOrderByIdRequestActionType = ReturnType<typeof actions.getOrderByIdRequest>;

function* getOrderByIdSaga(action: GetOrderByIdRequestActionType) {
  try {
    const { data } = yield call(getOrderApi().getOne, action.payload);
    yield put(actions.getOrderByIdResponse(data));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.getOrderByIdError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.getOrderByIdError({ message: 'Something wrong' }));
    }
  }
}

type ChangeOrderStatusRequestActionType = ReturnType<typeof actions.changeOrderStatusRequest>;
type ChangeOrderStatusRequestSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{
  customer: ICustomerDto;
  order_items: IOrderItemDto[];
}>
>;

function* changeOrderStatusSaga(
  action: ChangeOrderStatusRequestActionType,
): ChangeOrderStatusRequestSagaType {
  try {
    yield call(getOrderApi().changeStatus, action.payload);
    yield put(actions.changeOrderStatusResponse());
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.changeOrderStatusError(error));
  }
}

type changeOrdersStatusFromDetailsActionType = ReturnType<
    typeof actions.changeOrdersStatusFromDetails
>;

function* changeOrdersStatusFromDetailsSaga(action: changeOrdersStatusFromDetailsActionType) {
  yield put(actions.changeOrderStatusRequest(action.payload));
  yield put(actions.getOrderByIdRequest(action.payload.id));
}

type changeOrdersStatusFromTableActionType = ReturnType<typeof actions.changeOrdersStatusFromTable>;

function* changeOrdersStatusFromTableSaga(action: changeOrdersStatusFromTableActionType) {
  yield put(actions.changeOrderStatusRequest(action.payload.data));
  yield put(actions.getOrdersRequest(action.payload.orderFilter));
}

type RefundOrderRequestActionType = ReturnType<typeof actions.refundOrderRequest>;
type RefundOrderRequestSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{
  customer: ICustomerDto;
  order_items: IOrderItemDto[];
}>
>;

function* refundOrderSaga(action: RefundOrderRequestActionType): RefundOrderRequestSagaType {
  try {
    return yield call(getOrderApi().refund, action.payload.payment, action.payload.data);
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.refundOrderError(error));
    return error;
  }
}

type RefundOrderProcessRequestActionType = ReturnType<typeof actions.refundOrderProcessRequest>;

function* refundOrderProcessSaga(action: RefundOrderProcessRequestActionType) {
  yield put(actions.changeRefundOrderLoading(true));
  try {
    yield call(getOrderApi().refund, action.payload.payment, action.payload.data);

    const getOrderResponse: TGetOneOrderResponse = yield call(getOrderApi().getOne, action.payload.data.orderId);

    yield put(actions.getOrderByIdResponse(getOrderResponse.data));
    yield put(actions.changeConfirmModalVisibility(false));
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.refundOrderProcessError(error));
  } finally {
    yield put(actions.changeRefundOrderLoading(false));
  }
}

export function* orderSagasWatcher() {
  yield takeEvery(actions.getOrdersRequest.type, getOrdersSaga);
  yield takeEvery(actions.getOrderByIdRequest.type, getOrderByIdSaga);
  yield takeEvery(actions.changeOrderStatusRequest.type, changeOrderStatusSaga);
  yield takeEvery(actions.changeOrdersStatusFromDetails.type, changeOrdersStatusFromDetailsSaga);
  yield takeEvery(actions.changeOrdersStatusFromTable.type, changeOrdersStatusFromTableSaga);
  yield takeEvery(actions.refundOrderRequest.type, refundOrderSaga);
  yield takeEvery(actions.refundOrderProcessRequest.type, refundOrderProcessSaga);
}
