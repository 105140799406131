import { useState } from 'react';

export interface IBaseFilterOptions {
  offset: number,
  limit: number
}

export type TSetFilter<T> = (values: Partial<T>) => void;

export const useTableFilter = <T extends IBaseFilterOptions>(initialState: T): [T, TSetFilter<T>] => {
  const [filter, setFilter] = useState<T>(initialState);

  const updateFilter: TSetFilter<T> = (values) => {
    setFilter((prevState) => {
      if (Object.hasOwn(values, 'offset')) {
        return {
          ...prevState,
          ...values,
        };
      }

      const keys = Object.keys(values) as Array<keyof T>;

      const resetOffset = keys.reduce<boolean>((result, key) => {
        if (values[key] !== prevState[key]) {
          result = true;
        }

        return result;
      }, false);

      return {
        ...prevState,
        ...values,
        offset: resetOffset ? 0 : prevState.offset,
      };
    });
  };

  return [filter, updateFilter];
};
