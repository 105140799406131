import { Button } from '../../../../common/ui/button';
import { Container } from '../../../../common/ui/container';
import { Image } from '../../../../common/ui/image';
import { IDealProduct } from '../../types';
import styles from './deal-product.module.scss';

interface Props {
  product: IDealProduct;
  onDelete?: () => void;
}

const DealProduct = ({ onDelete = () => {}, product }: Props) => {
  const title = product.subtitle
    ? product.subtitle
    : product.title.length > 40
      ? product.title.substring(0, 40)
      : product.title;

  const saleValue = product.price
    ?? product.basis_price
    ?? `${((1 - product.price / product.basis_price) * 100).toFixed(1)}%`;

  return (
    <Container className={styles.deal_product} onDeleteButton={() => onDelete()} withDeleteButton>
      <div className={styles.image_wrapper}>
        <Image alt="product_img" src={product.img_medium || product.img_large} />
      </div>
      <div className={styles.data_container}>
        <span className={styles.title}>{title}</span>
        <div className={styles.price_container}>
          {!!product.price && (
            <div className={styles.sale_price_wrapper}>
              <span className={styles.sale_price}>{`$${product.price.toFixed(2)}`}</span>
              {!!product.basis_price && <span className={styles.sale}>{saleValue}</span>}
            </div>
          )}
          {!!product.basis_price && (
            <span className={styles.price}>{`$${product.basis_price.toFixed(2)}`}</span>
          )}
          <a className={styles.page_url} href={product?.page_url} rel="noreferrer" target="_blank">
            <Button className={styles.button} fullWidth variant="secondary">
              Shop
            </Button>
          </a>
        </div>
      </div>
    </Container>
  );
};

export { DealProduct };
