import { Link, NavLink, useLocation } from 'react-router-dom';

import { DomainDto } from '../../../../common/interfaces/domains';
import { utilPagePathGenerator } from '../../../../common/utils';
import styles from './success-page.module.scss';

export const SuccessPage = () => {
  const location = useLocation();
  const { brand, domain, domains, marketplace, publisher, slug } = location.state;

  const localeLinkToEditing = utilPagePathGenerator({
    brand,
    params: { domain, marketplace },
    publisher,
    slug,
  });
  const absoluteLinkToEditing = utilPagePathGenerator(
    { brand, params: { domain, marketplace }, publisher, slug },
    {
      absolute: true,
      public: false,
    },
  );

  return (
    <div className={styles.success_page}>
      <div className={styles.title}>Success!</div>
      <p className={styles.notice}>Will take approximately 5 minutes to update the page</p>
      <p className={styles.section_title}>Editing link:</p>
      <Link className={styles.link} to={localeLinkToEditing}>
        {absoluteLinkToEditing}
      </Link>
      <p className={styles.section_title}>Public link:</p>
      {(domains as DomainDto[]).map((domain) => {
        const url = utilPagePathGenerator(
          { brand, publisher, slug },
          { absolute: true, domain: domain.name, public: true },
        );
        return (
          <a key={domain.id} className={styles.link} href={url} rel="noreferrer" target="_blank">
            {url}
          </a>
        );
      })}

      <p className={styles.section_title}>Public link without brand in URL:</p>
      {(domains as DomainDto[]).map((domain) => {
        const url = utilPagePathGenerator(
          { brand, publisher, slug },
          {
            absolute: true,
            domain: domain.name,
            public: true,
            withHash: true,
          },
        );
        return (
          <a key={domain.id} className={styles.link} href={url} rel="noreferrer" target="_blank">
            {url}
          </a>
        );
      })}

      <NavLink className={styles.btn_back} to="/pages">
        All pages
      </NavLink>
    </div>
  );
};
