import axios, { AxiosResponse } from 'axios';
import * as _ from 'lodash';
import { call, delay, put, StrictEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { DraftStatusNames, Marketplaces } from '../../common/constants/enums';
import { IBestPage } from '../../common/interfaces/agg-page';
import { getAggPageApiClient } from '../../common/services/agg-pages-api.service';
import { BrandService } from '../../common/services/brand-service.api';
import {
  BrandListResponseType,
  getProductsApiClient,
  PublisherListResponseType,
} from '../../common/services/products-api.service';
import { actions } from '../actions';

type DeletePageSagaType = Generator<StrictEffect, any, AxiosResponse>;

function* deletePageSaga(action: ReturnType<typeof actions.deletePageRequest>): DeletePageSagaType {
  try {
    const response = yield call(
      getAggPageApiClient().deletePage.bind(getAggPageApiClient(), action.payload.pageId),
    );
    if (response.status === 201) {
      yield put(actions.deletePageResponse());
      action.payload.onSuccess();
    } else {
      yield put(actions.deletePageError({ code: response?.status, message: response.statusText }));
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.deletePageError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.deletePageError({ message: 'Something wrong' }));
    }
  }
}

type GetAllPagesSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{ data: { bestPages: IBestPage[]; totalCount: number } }>
>;

function* fetchPagesListSaga(
  action: ReturnType<typeof actions.fetchPagesListRequest>,
): GetAllPagesSagaType {
  yield delay(300);
  yield put(actions.setPagesListLoading(true));
  const { keyword, ...filters } = action.payload.filters;
  try {
    const response = yield call(
      getProductsApiClient().getBestPages.bind(
        getProductsApiClient(),
        _.isEmpty(keyword) ? null : keyword,
        {
          ...filters,
          attribution: filters.marketplace === Marketplaces.Walmart ? null : true,
          marketplace: filters.marketplace,
        },
      ),
    );

    if (response.status === 200) {
      const { bestPages, totalCount } = response.data.data;
      yield put(actions.fetchPagesListResponse({ pages: bestPages, totalCount }));
    } else {
      yield put(
        actions.fetchPagesListError({ code: response?.status, message: response.statusText }),
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.fetchPagesListError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.fetchPagesListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setPagesListLoading(false));
  }
}

type FetchPublisherListSagaType = Generator<
StrictEffect,
any,
AxiosResponse<PublisherListResponseType>
>;

function* fetchPublisherListSaga(): FetchPublisherListSagaType {
  try {
    yield put(actions.setPublisherListFetchingLoading(true));
    const response = yield call(
      getProductsApiClient().getPublishersList.bind(getProductsApiClient()),
    );
    if (response.status === 200) {
      yield put(actions.fetchPublisherListResponse(response.data.publishers));
    } else {
      yield put(
        actions.fetchPublisherListError({ code: response?.status, message: response.statusText }),
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(
        actions.fetchPublisherListError({ code: err?.response?.status, message: err.message }),
      );
    } else {
      yield put(actions.fetchPublisherListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setPublisherListFetchingLoading(false));
  }
}

type FetchPageStatusesListSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{ statuses: DraftStatusNames[] }>
>;

function* fetchPageStatusesListSaga(): FetchPageStatusesListSagaType {
  yield put(actions.setPageStatusesListLoading(true));
  try {
    const response = yield call(getAggPageApiClient().getPagesStatutes.bind(getAggPageApiClient()));
    if (response.status === 200) {
      yield put(actions.fetchPageStatusesListResponse(response.data.statuses));
    } else {
      yield put(
        actions.fetchPageStatusesListError({
          code: response?.status,
          message: response.statusText,
        }),
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(
        actions.fetchPageStatusesListError({ code: err?.response?.status, message: err.message }),
      );
    } else {
      yield put(actions.fetchPageStatusesListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setPageStatusesListLoading(false));
  }
}

type FetchBrandListSagaType = Generator<StrictEffect, any, AxiosResponse<BrandListResponseType>>;

function* fetchBrandList(
  action: ReturnType<typeof actions.fetchBrandListRequest>,
): FetchBrandListSagaType {
  yield delay(300);
  yield put(actions.setBrandListFetchingLoading(true));
  try {
    const response = yield call(
      BrandService.getBrands, { limit: 100, offset: 0, ...(action.payload.length ? { search: action.payload } : {}) }
    );
    if (response.status === 200) {
      yield put(actions.fetchBrandListResponse(response.data.brands));
    } else {
      yield put(
        actions.fetchBrandListError({
          code: response?.status,
          message: response.statusText,
        }),
      );
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.fetchBrandListError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.fetchBrandListError({ message: 'Something wrong' }));
    }
  } finally {
    yield put(actions.setBrandListFetchingLoading(false));
  }
}

export function* pagesSagasWatcher() {
  yield takeEvery(actions.deletePageRequest, deletePageSaga);
  yield takeLatest(actions.fetchPagesListRequest, fetchPagesListSaga);
  yield takeLatest(actions.fetchBrandListRequest, fetchBrandList);
  yield takeEvery(actions.fetchPublisherListRequest, fetchPublisherListSaga);
  yield takeEvery(actions.fetchPageStatusesListRequest, fetchPageStatusesListSaga);
}
