import clsx from 'clsx';
import { ChangeEvent, forwardRef } from 'react';

import { CrossIcon } from '../../../assets/images/icons';
import styles from './MultiTextField.module.scss';

type TMultiTextFieldProps = {
  placeholder?: string;
  onChange: (inputValue: string, items?: string[]) => void;
  name?: string;
  id?: string;
  values: string[];
  inputValue: string
  variant?: 'secondary'
};

const MultiTextField = forwardRef<HTMLInputElement, TMultiTextFieldProps>(
  ({ id, inputValue, name, onChange, placeholder = '', values, variant }, ref) => {
    const inputHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value.trim().length > 0 && value[value.length - 1] === ' ') {
        onChange('', [...values, value]);
      } else if (value.trim().length > 0 && value.trim().includes(' ')) {
        const newItems = value.trim().replace(/ {1,}/g, ' ').split(' ');
        onChange('', [...values, ...newItems]);
      } else {
        onChange(value, [...values]);
      }
    };

    const onDeleteBtnClick = (item: string) => {
      if (onChange) {
        onChange(
          inputValue,
          values.filter((elem) => elem !== item),
        );
      }
    };

    return (
      <div className={clsx(styles.multi_input, variant && styles[`multi_input__${variant}`])}>
        {values.map((item) => (
          <div key={item} className={styles.tag}>
            {item}
            <div className={styles.delete} onClick={() => onDeleteBtnClick(item)}>
              <CrossIcon />
            </div>
          </div>
        ))}
        <input
          ref={ref}
          id={id}
          name={name}
          onChange={inputHandler}
          placeholder={!values.length && placeholder ? placeholder : ''}
          type="text"
          value={inputValue}
        />
      </div>
    );
  },
);

export { MultiTextField };
