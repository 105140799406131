import clsx from 'clsx';
import { useController, UseFieldArrayUpdate, useFormContext } from 'react-hook-form';

import { Container } from '../../../../../../../common/ui/container';
import { FormItem } from '../../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../../common/ui/text-field';
import { ProductVideoAsset, TPageForm } from '../../../../../page-form/page-form';
import styles from './video-asset.module.scss';

type VideoAssetProps = {
  onDeleteButton?: () => void;
  data: ProductVideoAsset;
  prefix?: string;
  assetIdx?: number;
  update?: UseFieldArrayUpdate<TPageForm, `productAssets.${number}.assets`>
};

const VideoAsset = ({ assetIdx, data, onDeleteButton, prefix, update }: VideoAssetProps) => {
  const { control } = useFormContext();
  const textField = useController({ control, name: `${prefix}${assetIdx}.file_data.text` as any });
  const handleTextFieldBlur = () => {
    textField.field.onBlur();
    if (!update) {
      return;
    }
    if (!data?.type) {
      update(assetIdx, { ...data, type: 'updated' });
    }
  };

  return (
    <Container
      className={clsx(styles.container)}
      onDeleteButton={onDeleteButton}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <div className={styles.video_wrapper}>
        <video className={styles.video} controls muted src={data.url || data.preview}>
          <track kind="captions" label="english_captions" src="" srcLang="en" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <FormItem className="w-100" label="Text for video" message={textField.fieldState.error?.message}>
        <TextField
          {...textField.field}
          autoFocus
          invalid={textField.fieldState.invalid}
          onBlur={handleTextFieldBlur}
          placeholder="Enter text for video"
          resizable
          rows={9}
        />
      </FormItem>
    </Container>
  );
};

export { VideoAsset };
