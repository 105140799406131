import { DraftStatusNames, Marketplaces } from './enums';

export interface DraftOptionType {
  label: DraftStatusNames
  value: DraftStatusNames
}

export const draftOptions: DraftOptionType[] = [
  { label: DraftStatusNames.BannerGallery, value: DraftStatusNames.BannerGallery },
  { label: DraftStatusNames.BuyingGuide, value: DraftStatusNames.BuyingGuide },
  { label: DraftStatusNames.ProductContent, value: DraftStatusNames.ProductContent },
  { label: DraftStatusNames.Videos, value: DraftStatusNames.Videos },
];

export interface MarketplaceOptionType {
  label: 'Amazon' | 'Walmart'
  value: Marketplaces
}

export const marketplaceOptions: MarketplaceOptionType[] = [
  { label: 'Amazon', value: Marketplaces.Amazon },
  { label: 'Walmart', value: Marketplaces.Walmart },
];

export const publisherNames: Record<string, string> = {
  miamiherald: 'Miami Herald',
  reviewed: 'Reviewed',
};

export const genderOptions = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
];
