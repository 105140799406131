import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useController, useForm } from 'react-hook-form';
import { Rating } from 'react-simple-star-rating';
import * as yup from 'yup';

import { PlusOutlinedIcon } from '../../../../../../assets/images/icons';
import { Button } from '../../../../../../common/ui/button';
import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { ProductReviewsType } from '../../../../../pages/page-form/page-form';

type Props = {
  onChange?: (review: ProductReviewsType['reviews'][number]) => void;
  className?: string;
};

export const reviewValidation = yup.object({
  review: yup.object({
    link: yup.string().required('Required field'),
    rating: yup.string().required('Required field'),
    text: yup.string().required('Required field'),
  }),
});

const CreateReviewCard = ({ className, onChange }: Props) => {
  const {
    clearErrors,
    control,
    formState: { isValid },
    handleSubmit,
    setValue,
  } = useForm<ProductReviewsType['reviews'][number]>({
    resolver: yupResolver(reviewValidation),
  });

  const resetForm = () => {
    setValue('review', { link: '', rating: '', text: '' });
    if (!isValid) {
      clearErrors();
    }
  };

  const onSubmit = (data: ProductReviewsType['reviews'][number]) => {
    if (onChange) onChange(data);
    resetForm();
  };

  const ratingField = useController({ control, name: 'review.rating' });
  const textField = useController({ control, name: 'review.text' });
  const linkField = useController({ control, name: 'review.link' });

  return (
    <Container
      border={{ type: 'solid', variant: 'secondary' }}
      className={clsx('flex-column justify-space-between w-100 h-100', className)}
      onDeleteButton={resetForm}
      withDeleteButton
    >
      <FormItem label="Rating" message={ratingField.fieldState.error?.message}>
        <div className="d-flex column-gap-10 align-center">
          <Rating
            initialValue={0}
            onClick={(value) => ratingField.field.onChange(value)}
            size={18}
          />
          <span>{ratingField.field.value}</span>
        </div>
      </FormItem>
      <FormItem label="Review text" message={textField.fieldState.error?.message}>
        <TextField
          invalid={textField.fieldState.invalid}
          {...textField.field}
          placeholder="Enter your text"
          resizable
          rows={2}
        />
      </FormItem>
      <FormItem label="Review url" message={linkField.fieldState.error?.message}>
        <TextField
          invalid={linkField.fieldState.invalid}
          {...linkField.field}
          placeholder="Enter url"
        />
      </FormItem>
      <Button iconStart={<PlusOutlinedIcon />} onClick={handleSubmit(onSubmit)} type="button">
        Add
      </Button>
    </Container>
  );
};

export { CreateReviewCard };
