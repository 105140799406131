import * as _ from 'lodash';

import { Brand, Publisher } from '../interfaces/agg-page';
import { packProductID } from './utilEncryptBrand';

interface PageDataPropsType {
  brand?: Brand;
  publisher: Publisher;
  slug: string;
  params?: Record<string, any>;
}

interface AbsoluteLinkOptionsProps {
  domain?: string;
  absolute?: true;
  public: boolean;
  withHash?: boolean;
}

interface LocalLinkOptionsProps {
  absolute?: false;
}

type Options = AbsoluteLinkOptionsProps | LocalLinkOptionsProps;

export const utilPagePathGenerator = (pageData: PageDataPropsType, options?: Options): string => {
  const protocol = options?.absolute && options?.public ? 'https:' : window.location.protocol;
  const origin = window.location.origin;
  const queryParams = new URLSearchParams(_.omitBy(pageData.params, _.isNil));

  const defaultDomain = 'productpicks.ai';
  let domain: string = null;

  if (pageData?.publisher?.name) {
    const { publisher } = pageData;
    const domainURL = new URL(publisher?.reverse_proxy_path, publisher.domain);
    domainURL.protocol = protocol;

    domain = domainURL.toString();
  } else if (options?.absolute && options.public) {
    domain = `${protocol}//${options?.domain || defaultDomain}`;
  }

  const baseUrl = domain;
  let link = '';

  if (
    pageData?.publisher?.name
    && (!options?.absolute || (options?.absolute && !options?.public))
  ) {
    link += `${pageData?.publisher?.name}/`;
  }

  if (pageData?.brand?.name) {
    const replacedBrand = pageData?.brand?.name.replace(/\s/g, '-');
    const brandName = options?.absolute && options?.public && options?.withHash
      ? `similar/nb/${packProductID(pageData?.brand?.id)}`
      : options?.absolute && options?.public && !options?.withHash
        ? `similar/${replacedBrand}`
        : replacedBrand;
    link += `${brandName}`;
  } else if (options?.absolute && options?.public) {
    link += 'similar/walmart';
  } else {
    link += 'walmart';
  }

  link += `/${pageData.slug}`;

  if (options?.absolute && !options.public) {
    return `${origin}/pages/${link}${`?${queryParams}` || ''}`;
  }

  if (options?.absolute && options.public) {
    return `${baseUrl}/${link}`;
  }

  return `/pages/${link}${`?${queryParams}` || ''}`;
};
