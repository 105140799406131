import { useEffect } from 'react';

import { PagePublisherImage } from '../../../../../common/interfaces/agg-page';
import { Button } from '../../../../../common/ui/button';
import { Container } from '../../../../../common/ui/container';
import { Loader } from '../../../../../common/ui/loader';
import { useActions, useAppSelector } from '../../../../../hooks';
import { PublisherImage } from '../publisher-image';
import styles from './publisher-image-list.module.scss';

interface Props {
  onImageClick?: (item: PagePublisherImage) => void;
  publisherImages?: PagePublisherImage[];
}

const PublisherImageList = ({ onImageClick, publisherImages }: Props) => {
  const { fetchPublisherImagesListRequest } = useActions();
  const {
    loadedPublisherImages,
    loading,
    nextPage
  } = useAppSelector(({ publisherImagesList }) => publisherImagesList);

  useEffect(() => {
    if (!loadedPublisherImages.length) {
      fetchPublisherImagesListRequest({ limit: 20 });
    }
  }, []);

  return (
    <>
      {loading && !loadedPublisherImages.length && (
        <div className="w-100 d-flex justify-center mb-20">
          <Loader>Loading...</Loader>
        </div>
      )}
      {loadedPublisherImages?.length > 0 && (
        <Container className={styles.items_container}>
          {loadedPublisherImages.map((lpi, idx) => {
            const checked = publisherImages.some(
              (asset) => asset.url === lpi.url && asset.type !== 'deleted',
            );
            const item: PagePublisherImage = {
              ...lpi,
              type: 'renew',
            };

            return (
              <div key={lpi.url} className={styles.item_wrapper} onClick={() => onImageClick(item)}>
                <PublisherImage assetIdx={idx} checked={checked} item={lpi} />
              </div>
            );
          })}

          {nextPage && (
            <div className={styles.item_wrapper}>
              <Button
                onClick={() => fetchPublisherImagesListRequest({ page: nextPage })}
                type="button"
              >
                Load more
              </Button>
            </div>
          )}
          {loading && (
            <div className="w-100 d-flex justify-center mt-20">
              <Loader>Loading...</Loader>
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export { PublisherImageList };
