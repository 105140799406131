import { yupResolver } from '@hookform/resolvers/yup';
import { Product } from 'common/interfaces/agg-page';
import { getProductsApiClient } from 'common/services/products-api.service';
import { AggPageFormContext } from 'context/page-form-context';
import { PagesContext } from 'context/pages-context';
import { useContext, useState } from 'react';
import { useController, useForm, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { AggPageComponent, Marketplaces } from '../../../common/constants/enums';
import { getProductsManagementApiClient } from '../../../common/services/products-management-api.service';
import { Button } from '../../../common/ui/button';
import { Container } from '../../../common/ui/container';
import { FormItem } from '../../../common/ui/formItem';
import { TextField } from '../../../common/ui/text-field';
import { utilProductDataPreparing } from '../../../common/utils';
import {
  amazonProductAsinValidationSchema,
  walmartProductIdValidationSchema,
} from '../../../common/validationSchemas';
import { DeleteButton } from '../../common/partials/buttons/DeleteButton';
import { ProductId } from '../page-form/page-form';
import styles from './PageProductInput.module.scss';

interface Props {
  marketplace?: Marketplaces;
}

export interface SearchProductForm {
  product_id: ProductId;
}

export const PageProductInput = ({ marketplace = Marketplaces.Walmart }: Props) => {
  const [loading, setLoading] = useState(false);
  const { changeError } = useContext(PagesContext);

  const validationSchema = marketplace === Marketplaces.Walmart
    ? walmartProductIdValidationSchema
    : amazonProductAsinValidationSchema;

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    setError,
    setValue,
  } = useForm<SearchProductForm>({ mode: 'onChange', resolver: yupResolver(validationSchema) });

  const productIdField = useController({ control, name: 'product_id' });

  const formValue = useWatch({ control });
  const {
    addContentCopies,
    addProduct,
    changeProductsContent,
    products,
    setProductsAssets,
    setProductsReviews
  } = useContext(AggPageFormContext);

  const clearInput = () => {
    setValue('product_id', '');
  };

  const onSubmit = async ({ product_id }: SearchProductForm) => {
    const doubleProduct = products.find((item: Product) => item.product_id === String(product_id));

    if (doubleProduct) {
      setError('product_id', { message: 'Product already exists' });
      return;
    }

    setLoading(true);

    try {
      let product: Product;

      if (marketplace === Marketplaces.Amazon) {
        const productResponse = await getProductsApiClient().getProductByAsin(String(product_id), {
          features: true,
        });

        product = productResponse.data.data.products[0];

        if (product) product.product_id = productResponse.data.data.products[0].asin;
      }

      if (marketplace === Marketplaces.Walmart) {
        const productResponse = await getProductsApiClient().getProductById(product_id, {
          include_product_features: true,
        });

        product = productResponse.data.products[0];
        if (product) product.product_id = productResponse.data.products[0].w_item_id;
      }

      if (!product) {
        setError('product_id', { message: 'Not found' });
        return;
      }

      changeError(null, AggPageComponent.Product);

      if (!products.length) {
        product.value = 'Best Pick';
        product.order = 1;
      }

      addProduct(product);

      const { assets, reviews } = utilProductDataPreparing(product);

      const contentCopiesResponse = await getProductsManagementApiClient().getProductContentCopies(
        product.product_id
      );

      const contentCopies = contentCopiesResponse.data.products.reduce((result, elem) => {
        changeProductsContent({
          _id: uuidv4(),
          product_id: elem.asin,
          type: 'origin',
          ...({
            ...elem.content,
            faq: elem.content?.faq || [],
            pros: elem.content?.pros?.filter((p) => p?.trim().length > 0),
          } || {}),
          tied: !product.picked_product_copy_id
        });

        // @ts-ignore
        const copies = [];
        elem.product_copies.forEach((copy) => {
          if (copy.content) {
            copies.push({
              _id: uuidv4(),
              copy_id: copy.copy_id,
              product_id: elem.asin,
              tied: product?.picked_product_copy_id === copy.copy_id,
              ...{
                ...copy.content.content,
                faq: copy.content.content?.faq || [],
                pros: copy.content.content?.pros?.filter((p) => p?.trim().length > 0) || [],
              },
            });
          }
        });
        // @ts-ignore
        result = [...result, ...copies];

        return result;
      }, []);

      addContentCopies(contentCopies, true);

      setProductsReviews('add', reviews);
      setProductsAssets((prevState) => [...prevState, { assets, product_id: product.product_id }]);
      clearInput();
    } catch (e) {
      changeError(`Server error: ${e}`, AggPageComponent.Default);
    } finally {
      setLoading(false);
    }
  };

  const labelProductType = marketplace === Marketplaces.Walmart ? 'product ID' : 'Asin';
  const placeholder = marketplace === Marketplaces.Walmart ? '1840281673' : 'B08ZVWDQQ1';

  return (
    <div className={styles.product_input_wrapper}>
      <Container className={styles.page_product}>
        <FormItem
          className="w-100"
          label={`Enter ${labelProductType}`}
          message={errors?.product_id?.message}
        >
          <div className="w-100 position-relative">
            <TextField
              {...productIdField.field}
              fullWidth
              invalid={!!errors?.product_id?.message}
              placeholder={placeholder}
            />
            {formValue?.product_id && <DeleteButton handler={clearInput} />}
          </div>
        </FormItem>

        <Button disabled={!isValid} loading={loading} onClick={handleSubmit(onSubmit)}>
          Add
        </Button>

        {errors?.product_id?.message === 'Not found'
          && marketplace === Marketplaces.Amazon && (
          <Link
            className={styles.import_link}
            onClick={() => changeError(null, AggPageComponent.Product)}
            target="_blank"
            to={`/product-import?asin=${formValue.product_id}`}
          >
            Product not found. Click to import.
          </Link>
        )}
      </Container>
    </div>
  );
};
