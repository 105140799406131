import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { PlusOutlinedIcon, SaveIcon } from '../../../../assets/images/icons';
import { Marketplaces } from '../../../../common/constants/enums';
import { Button } from '../../../../common/ui/button';
import { Loader } from '../../../../common/ui/loader';
import { Tooltip } from '../../../../common/ui/tooltip';
import { useActions, useAppSelector } from '../../../../hooks';
import { CompareTableItem } from './components/compare-product';
import styles from './edit-compare-table-form.module.scss';

export interface CompareTableRequestData {
  page_id: number;
  exclude_attribute_names?: string[];
  attributes_order: { attribute_name: string; order: number }[];
  product_attributes: {
    product_id: number;
    attribute_name: string;
    attribute_value: string;
    attribute_score?: number;
    attribute_previous_name?: string;
  }[];
}

export interface CompareProductAttribute {
  name: string;
  value: string;
  score?: string;
  status?: 'deleted' | 'new';
  previousName?: string;
}

export interface CompareProduct {
  productId: number;
  price?: number;
  title: string;
  image: string;
  rating?: number;
  attributes: CompareProductAttribute[];
  feedback_count?: number;
}

export interface CompareTableForm {
  products: CompareProduct[];
}

export enum StandardAttributeNames {
  FreeShipping = 'Free shipping',
  Reviews = 'Reviews',
  Price = 'Price',
  Shipping = 'Shipping',
}

const getStandardAttributes = (price: number, reviews: number): CompareProductAttribute[] => [
  { name: StandardAttributeNames.Reviews, status: 'new', value: reviews?.toString() },
  { name: StandardAttributeNames.FreeShipping, status: 'new', value: '' },
  { name: StandardAttributeNames.Price, status: 'new', value: `${price || ''}` },
  { name: StandardAttributeNames.Shipping, status: 'new', value: '' },
];

const compareTableFormValidateSchema = yup.object().shape({
  products: yup.array().of(
    yup.object().shape({
      attributes: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Required field').trim(),
          score: yup
            .string()
            .trim()
            .matches(/^\d+(\.\d)?\d*$/, {
              excludeEmptyString: true,
              message: 'Should be number',
            }),

          value: yup.string().when('name', {
            is: StandardAttributeNames.Price,
            otherwise: (schema) => schema.required('Required field'),
            then: (schema) => schema.optional(),
          }),
        }),
      ),
    }),
  ),
});

const EditCompareTableForm = () => {
  const [params] = useSearchParams();
  const { brand, publisher, slug } = useParams<{
    brand: string;
    slug: string;
    publisher: string;
  }>();
  const marketplace = params.get('marketplace') as Marketplaces;
  const domain = params.get('domain');
  const compareForm = useForm<CompareTableForm>({
    resolver: yupResolver(compareTableFormValidateSchema),
  });
  const productsFields = useFieldArray({ control: compareForm.control, name: 'products' });
  const updateTableData = useAppSelector(
    ({ compareProductTable }) => compareProductTable.updateCompareProductTable,
  );
  const getTableData = useAppSelector(
    ({ compareProductTable }) => compareProductTable.getCompareProductTable,
  );

  const { getCompareProductTableRequest, updateCompareProductTableRequest } = useActions();

  const onAddAttributeClick = () => {
    const products = compareForm.getValues('products');
    products.forEach((product, index) => {
      compareForm.setValue(
        `products.${index}.attributes`,
        [...product.attributes, { name: '', score: '', status: 'new', value: '' }],
        { shouldValidate: false },
      );
    });

    compareForm.setFocus(
      `products.${products.length - 1}.attributes.${
        products[products.length - 1].attributes.length - 1
      }.name`,
      { shouldSelect: true },
    );
  };

  const addConstantAttributes = () => {
    const products = compareForm.getValues('products');
    products.forEach((product, index) => {
      compareForm.setValue(`products.${index}.attributes`, [
        ...getStandardAttributes(product.price, product.feedback_count).filter(
          (attr) =>
            !product.attributes
              .filter((elem) => elem.status !== 'deleted')
              .some((elem) => elem.name === attr.name),
        ),
        ...product.attributes,
      ]);
    });
  };

  const handleSubmit = (data: CompareTableForm) => {
    const requestData: CompareTableRequestData = {
      attributes_order: data.products[0].attributes.map((attr, index) => ({
        attribute_name: attr.name,
        order: index + 1,
      })),
      exclude_attribute_names: data.products[0].attributes
        .filter((attr) => attr.status === 'deleted')
        .map((attr) => attr.name),
      page_id: getTableData.data.page.id,
      product_attributes: data.products.reduce<CompareTableRequestData['product_attributes']>(
        (result, product) => [
          ...result,
          ...product.attributes.map((attr) => ({
            attribute_name: attr.name,
            ...(attr.score ? { attribute_score: parseFloat(String(attr.score)) } : {}),
            attribute_value: attr.value,
            product_id: product.productId,
            ...(attr.previousName && attr.name !== attr.previousName
              ? { attribute_previous_name: attr.previousName }
              : {}),
          })),
        ],
        [],
      ),
    };

    updateCompareProductTableRequest({
      cb: () => {
        getCompareProductTableRequest({ data: { brand, marketplace, slug }, domain });
      },
      data: requestData,
    });
  };

  useEffect(() => {
    if (getTableData.data?.productsCompareTable?.tableRows.length) {
      compareForm.setValue(
        'products',
        getTableData.data?.productsCompareTable.tableRows.map((row) => ({
          attributes: row.row.map((value, index) => ({
            name: getTableData.data?.productsCompareTable.tableColumns[index],
            score: value.attribute_score ? String(value.attribute_score) : '',
            value: value.attribute_value || '',
          })),
          feedback_count: row?.feedback_count,
          image: row.img_medium || row.img_large,
          price: row?.price,
          productId: row.id,
          rating: 10,
          title: row.title,
        })),
        { shouldDirty: false, shouldTouch: false, shouldValidate: false },
      );
    } else {
      const products = getTableData.data?.mainProduct.id
        ? [getTableData.data?.mainProduct].concat(getTableData?.data?.additionalProducts)
        : [].concat(getTableData?.data?.additionalProducts);
      compareForm.setValue(
        'products',
        products.map((product) => ({
          attributes: [],
          feedback_count: product?.feedback_count,
          image: product?.img_medium || product?.img_large,
          price: product?.price,
          productId: product?.id,
          title: product?.subtitle || product?.title,
        })),
        { shouldDirty: false, shouldTouch: false, shouldValidate: false },
      );
    }
  }, [getTableData.data?.productsCompareTable]);

  useEffect(() => {
    getCompareProductTableRequest({ data: { brand, marketplace, publisher, slug }, domain });
  }, []);

  const currentAttributes = compareForm.watch('products.0.attributes') || [];

  const currentAttributesNames = currentAttributes
    .filter((elem) => elem.status !== 'deleted')
    .map((elem) => elem.name);
  const isButtonDisabled = Object.values(StandardAttributeNames).every((elem) =>
    currentAttributesNames.includes(elem),);

  if (getTableData.loading) {
    return (
      <div className="d-flex justify-center mt-80">
        <Loader>Loading...</Loader>
      </div>
    );
  }

  return (
    <FormProvider {...compareForm}>
      <form className={styles.form} onSubmit={compareForm.handleSubmit(handleSubmit)}>
        <div className={styles.top_actions}>
          <Button
            iconStart={<PlusOutlinedIcon />}
            onClick={onAddAttributeClick}
            type="button"
            variant="secondary"
          >
            Add attribute
          </Button>
          <Tooltip
            placement="top"
            title={isButtonDisabled ? 'All standard attributes were added' : ''}
          >
            <Button
              disabled={isButtonDisabled}
              iconStart={<PlusOutlinedIcon />}
              onClick={addConstantAttributes}
              type="button"
              variant="secondary"
            >
              Add standard attributes
            </Button>
          </Tooltip>
        </div>
        <div className={styles.products_container}>
          {productsFields.fields.map((product, productIdx) => (
            <CompareTableItem key={product.id} product={product} productIdx={productIdx} />
          ))}
        </div>
        <div className={styles.bottom_actions}>
          <Tooltip title={compareForm.formState.isDirty ? '' : 'No changes'}>
            <Button
              disabled={!compareForm.formState.isDirty}
              iconStart={<SaveIcon />}
              loading={updateTableData.loading}
              type="submit"
              variant="secondary"
            >
              Save
            </Button>
          </Tooltip>
        </div>
      </form>
    </FormProvider>
  );
};

export { EditCompareTableForm };
