import { format, formatRelative, isToday } from 'date-fns';

type FormatParams = { formatStr?: string; showRelativeToday?: boolean };

export const utilFormatDate = (date: string | null | undefined, formatParams?: FormatParams) => {
  const { formatStr, showRelativeToday } = formatParams || {
    formatStr: 'dd MMMM yyyy',
    showRelativeToday: false,
  };

  if (date) {
    let time = '';

    if (isToday(new Date(date)) && showRelativeToday) {
      time = formatRelative(new Date(date), new Date(), {
      });

      time = time[0].toUpperCase() + time.substring(1);
    }

    if (time !== '') {
      return (
        `${time}, `
        + `${format(new Date(date), formatStr, {
        })}`
      );
    }

    return `${format(new Date(date), formatStr, {
    })}`;
  }

  return '';
};
