import { Link } from 'react-router-dom';

import { ChangeOrderStatusData, IOrderDto } from '../../../../../common/interfaces/order-api';
import { Pagination } from '../../../../../common/ui/pagination';
import { Table } from '../../../../../common/ui/table';
import { utilFormatDate, utilFormatMoney } from '../../../../../common/utils';
import { TSetFilter, useActions } from '../../../../../hooks';
import { OrderStatus, ProductLabel } from '../../../components';
import { IOrderFilter } from '../../OrdersPage';
import styles from './OrdersTable.module.scss';

const ordersTableStructure = [
  'product',
  'order id',
  'user',
  'date',
  'price',
  'quantity',
  'total price',
  'payment',
  'status',
];

interface IOrdersTableProps {
  orders?: IOrderDto[];
  loading?: boolean;
  ordersCount?: number;
  filter: IOrderFilter;
  updateFilter: TSetFilter<IOrderFilter>;
}

const OrdersTable = ({ filter, loading, orders, ordersCount, updateFilter }: IOrdersTableProps) => {
  const { changeOrdersStatusFromTable } = useActions();

  const ordersStatusHandler = (data: ChangeOrderStatusData) => {
    changeOrdersStatusFromTable(data, filter);
  };

  return (
    <Table className={styles.table} tableLayout="fixed">
      {orders?.length > 0 && (
        <Table.Header>
          <Table.Row>
            {ordersTableStructure.map((elem) => (
              <Table.Column key={elem}>{elem}</Table.Column>
            ))}
          </Table.Row>
        </Table.Header>
      )}

      <Table.Body>
        <Table.Loader loading={loading} />
        <Table.NoResult visible={orders?.length === 0 && !loading} />
        {orders?.length > 0
          && orders.map(
            ({
              created_at,
              first_name,
              id,
              last_name,
              payment,
              price,
              product,
              product_id,
              quantity,
              status,
            }) => {
              const fullName = `${first_name} ${last_name}`;
              const isOrderStatusDisabled: boolean = ![
                'purchased',
                'shipped to customer',
                'new order',
              ].includes(status);
              return (
                <Table.Row key={product.id}>
                  <Table.Column className={styles.first_col_width}>
                    <Link to={`/orders/${id}?orderItemId=${product_id}`}>
                      <ProductLabel
                        asin={product.asin}
                        imgUrl={product.img_small || product.img_medium}
                        title={product.title}
                      />
                    </Link>
                  </Table.Column>
                  <Table.Column>{id}</Table.Column>
                  <Table.Column>{fullName}</Table.Column>
                  <Table.Column>
                    {utilFormatDate(created_at, { formatStr: 'dd MMM, yy' })}
                  </Table.Column>
                  <Table.Column>{utilFormatMoney(price)}</Table.Column>
                  <Table.Column>{quantity}</Table.Column>
                  <Table.Column>{utilFormatMoney(price * quantity)}</Table.Column>
                  <Table.Column>
                    <span className={styles.text_transform}>{payment}</span>
                  </Table.Column>
                  <Table.Column>
                    <OrderStatus
                      disabled={isOrderStatusDisabled}
                      status={status}
                      statusHandler={(orderStatus) =>
                        ordersStatusHandler({
                          id,
                          order_items: [
                            {
                              product_id,
                              status: orderStatus,
                            },
                          ],
                        })
                      }
                    />
                  </Table.Column>
                </Table.Row>
              );
            },
          )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Column colSpan={ordersTableStructure.length}>
            <Pagination
              className={styles.pagination}
              count={ordersCount}
              fullwidth
              limit={filter.limit}
              limitHandler={(limit) => updateFilter({ limit })}
              onChange={(offset) => updateFilter({ offset })}
            />
          </Table.Column>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export { OrdersTable };
