import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

import styles from './Dropdown.module.scss';

type IOptionValue = number | string;

type TDropdownPosition = 'top' | 'topLeft' | 'topRight' | 'bottom' | 'bottomLeft' | 'bottomRight';

interface IDropdownOption {
  label: string;
  value: IOptionValue;
}

export interface IDropdownProps extends PropsWithChildren {
  options?: IDropdownOption[];
  value?: number | string;
  onOptionClick?: (value: IOptionValue) => void;
  position?: TDropdownPosition;
  disabled?: boolean;
}

export const useDropdown = (disabled?: boolean, openByClick = true) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const close = (e: MouseEvent) => {
    if (!ref.current.contains(e.target) && !disabled) {
      setIsVisible(false);
    }
  };

  const open = (e: MouseEvent) => {
    if (ref.current.contains(e.target) && !disabled) {
      setIsVisible(true);
    }
  };

  const visibleHandler = (isVisible: boolean) => {
    if (!disabled) {
      setIsVisible(isVisible);
    }
  };

  useEffect(() => {
    document.addEventListener('click', close);
    if (openByClick) ref?.current?.addEventListener('click', open);

    return () => {
      document.removeEventListener('click', close);
      ref?.current?.removeEventListener('click', open);
    };
  }, []);

  return {
    isVisible,
    ref,
    setIsVisible: visibleHandler,
  };
};

const Dropdown = ({
  children = false,
  options = [],
  value,
  onOptionClick,
  position = 'bottomLeft',
  disabled = false,
}: IDropdownProps) => {
  const { isVisible, ref, setIsVisible } = useDropdown(disabled);

  const optionHandler = (value: IOptionValue) => {
    if (onOptionClick) onOptionClick(value);
    setIsVisible(false);
  };

  return (
    <div ref={ref} className={clsx(styles.dropdown, disabled && styles.dropdown__disabled)}>
      {children}
      {isVisible && !!options.length && (
        <ul className={clsx(styles.list, position && styles[`list__${position}`])}>
          {options.map((elem) => (
            <li
              key={elem.label}
              className={clsx(
                styles.list__item,
                value === elem.value && styles.list__item__checked,
              )}
              onClick={() => optionHandler(elem.value)}
            >
              {elem.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { Dropdown };
