import { AxiosError } from 'axios';
import { TemporaryLinkService } from 'common/services/temporary-link-service.api';
import toast from 'react-hot-toast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/actions';

type GetPublisherTemporaryLinksActionType = ReturnType<typeof actions.getPublisherTemporaryLinks>;

function* getPublisherTemporaryLinks(action: GetPublisherTemporaryLinksActionType) {
  try {
    const { data } = yield call(
      TemporaryLinkService.getPublisherLinks,
      action.payload.publisherId,
      action.payload.query,
    );
    yield put(actions.setPublisherTemporaryLinks(data));
  } catch (err) {
    toast.error('Error getting hardcoded links');
    if (err instanceof AxiosError) {
      yield put(actions.setTemporaryLinksError(err));
    }
  }
}

export function* temporaryLinksSagaWatcher() {
  yield takeLatest(actions.getPublisherTemporaryLinks.type, getPublisherTemporaryLinks);
}
