import axios, { AxiosResponse } from 'axios';
import { call, delay, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { getAggPageApiClient } from '../../common/services/agg-pages-api.service';
import { actions } from '../actions';
import { TKeywordCheckingState } from '../reducers/products/checkKeywordSlice';

type CheckKeywordActionType = ReturnType<typeof actions.checkKeyword>;
type CheckKeywordSagaType = Generator<
StrictEffect,
any,
AxiosResponse<{ exists: boolean; keyword: string }>
>;

function* checkKeywordSaga(action: CheckKeywordActionType): CheckKeywordSagaType {
  yield delay(500);
  yield put(actions.checkKeywordRequest(action.payload));
  try {
    let message: TKeywordCheckingState['message'];

    if (!action.payload.keyword.length) {
      message = {
        text: 'Field is required',
        type: 'error',
      };

      yield put(actions.checkKeywordsResponse(message));

      return;
    }

    const { data } = yield call(getAggPageApiClient().validateKeyword, action.payload);

    if (data.exists) {
      message = {
        text: 'Keyword already exists',
        type: 'error',
      };
    } else {
      message = {
        text: 'Keyword available',
        type: 'success',
      };
    }

    yield put(actions.checkKeywordsResponse(message));
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    put(actions.checkKeywordError(error));
  }
}

export function* productsSagaWatcher() {
  yield takeLatest(actions.checkKeyword.type, checkKeywordSaga);
}
