import { useActions } from '../../../hooks';
import { TConfirmModalInitialState } from '../../../store/reducers/common/confirm-modal-slice';

export const useConfirmModal = <T extends unknown>(
  confirmModalData: (data: T) => Omit<TConfirmModalInitialState, 'isOpen'>,
) => {
  const { openConfirmModal } = useActions();

  const openModal = (data: T) => {
    openConfirmModal(confirmModalData(data));
  };

  return {
    openModal,
  };
};
