import axios, { AxiosError, AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { CouponsService } from '../../common/services/coupons-service.api';
import { actions } from '../actions';
import { CouponDto } from '../reducers/coupons/getCouponListReducer';

function* createCouponSaga(action: ReturnType<typeof actions.createCouponRequest>): SagaIterator {
  try {
    const { status } = yield call(CouponsService.create, action.payload.data);
    if (status === 201) {
      yield put(actions.createCouponResponse());
      action.payload.cb();
    } else {
      yield put(actions.createCouponError({ message: 'Something went wrong' }));
    }
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = {
        code: err?.response?.status,
        message:
          (
            err as AxiosError<{
              errors: { message: string }[];
            }>
          ).response.data.errors[0].message || err?.message,
      };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.createCouponError(error));
  }
}

function* getListSaga(action: ReturnType<typeof actions.getCouponList>): SagaIterator {
  yield delay(400);
  yield put(actions.getCouponListRequest());
  try {
    const { data, status }: AxiosResponse<CouponDto[]> = yield call(
      CouponsService.getList,
      action.payload,
    );
    if (status === 200) {
      yield put(actions.getCouponListResponse(data));
    } else {
      yield put(actions.getCouponListError({ message: 'Something went wrong' }));
    }
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.getCouponListError(error));
  }
}

function* getProductsCouponListSaga(
  action: ReturnType<typeof actions.getProductsCouponListRequest>,
): SagaIterator {
  try {
    const data: AxiosResponse<CouponDto[]>[] = yield all(
      action.payload.map((asin) => call(CouponsService.getList, { asin })),
    );
    yield put(
      actions.getProductsCouponListResponse(
        data.reduce<CouponDto[]>((result, res) => {
          if (res.data.length) {
            res.data.forEach((elem) => {
              if (elem && result.some((item) => item.id === elem.id)) {
                return;
              }
              result = [...result, elem];
            });
          }
          return result;
        }, []),
      ),
    );
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.getProductsCouponListError(error));
  }
}

function* couponAttachingSaga(
  action: ReturnType<typeof actions.couponAttachingRequest>,
): SagaIterator {
  try {
    if (action.payload.mode === 'attach') {
      yield call(CouponsService.attachProducts, action.payload.data);
    } else {
      yield call(CouponsService.detachProducts, action.payload.data);
    }

    if (action.payload.productIds.length) {
      yield put(actions.getProductsCouponListRequest(action.payload.productIds));
    }

    yield put(actions.couponAttachingResponse());
    if (action.payload.cb) action.payload.cb();
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = { code: err?.response?.status, message: err.message };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.couponAttachingError(error));
  }
}

function* updateCouponSaga(action: ReturnType<typeof actions.updateCouponRequest>): SagaIterator {
  try {
    yield call(CouponsService.update, action.payload.data);
    const { data } = yield call(CouponsService.getList, { code: action.payload.data.code });
    if (action.payload?.place === 'productList') {
      yield put(actions.replaceProductsCouponListItem(data[0]));
    }

    if (action.payload?.place === 'searchList') {
      yield put(actions.replaceCouponListItem(data[0]));
    }

    yield put(actions.updateCouponResponse());
    toast.success('Updated successfully.');
  } catch (err) {
    let error;
    if (axios.isAxiosError(err)) {
      error = {
        code: err?.response?.status,
        message:
          (
            err as AxiosError<{
              errors: { message: string }[];
            }>
          ).response.data.errors[0].message || err?.message,
      };
    } else {
      error = { message: 'Something wrong' };
    }
    yield put(actions.updateCouponError(error));
    toast.error(error.message);
  }
}

export function* couponSagasWatcher() {
  yield takeEvery(actions.createCouponRequest.type, createCouponSaga);
  yield takeLatest(actions.getCouponList.type, getListSaga);
  yield takeEvery(actions.getProductsCouponListRequest.type, getProductsCouponListSaga);
  yield takeEvery(actions.couponAttachingRequest.type, couponAttachingSaga);
  yield takeEvery(actions.updateCouponRequest.type, updateCouponSaga);
}
