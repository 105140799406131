import toast from 'react-hot-toast';

export const utilCopyToClipboard = (string: string, notification = true) => {
  navigator.clipboard
    .writeText(string)
    .then(() => {
      if (notification) toast.success('Copied');
    })
    .catch(() => {
      if (notification) toast.error('Error');
    });
};
