import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';

import { productsNumber } from '../../../../common/constants/values';
import { Button } from '../../../../common/ui/button';
import { Container } from '../../../../common/ui/container';
import { AggPageFormContext } from '../../../../context/page-form-context';
import { ProductId } from '../../page-form/page-form';
import styles from './editable-product-switcher.module.scss';

type Props = {
  children: (
    currentProductId: string,
    product_id: ProductId,
    setCurrenContentId: (value: ((prevState: string) => string) | string) => void,
  ) => React.ReactNode;
  className?: string;
};

const EditableProductSwitcher = ({ children, className }: Props) => {
  const { contentCopies, products, productsContent } = useContext(AggPageFormContext);

  // const productsNumber = utilProductsNumber(publisher?.name);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentContentId, setCurrenContentId] = useState<string>(null);
  const stateInit = useRef(false);

  useEffect(() => {
    if (products.length > 0 && productsContent.length > 0 && !stateInit.current) {
      setCurrentProductId(products[0]?.product_id);
      stateInit.current = true;

      if (products[0].picked_product_copy_id) {
        setCurrenContentId(
          contentCopies.find((elem) => elem?.copy_id === products[0]?.picked_product_copy_id)?._id,
        );
      } else {
        setCurrenContentId(
          productsContent.find((elem) => elem?.product_id === products[0]?.product_id)?._id,
        );
      }
    }

    if (products.length && !products?.some((elem) => elem.product_id === currentProductId)) {
      setCurrentProductId(products[0]?.product_id);
    }
  }, [products, productsContent, contentCopies]);

  return (
    <Container className={clsx(styles.section_container, className)} withoutBorder>
      <div className={styles.products_slider}>
        {!!products.length
          && products.slice(0, productsNumber).map((product) => {
            const renderItems = [
              ...productsContent.filter((pc) => pc?.product_id === product.product_id),
              ...contentCopies.filter((pc) => pc.product_id === product.product_id),
            ];
            return (
              <div key={product.product_id} className={styles.item_wrapper}>
                <div
                  className={clsx(
                    styles.products_slider__item,
                    product.product_id === currentProductId && styles.products_slider__item__active,
                  )}
                  onClick={() => {
                    setCurrentProductId(product.product_id);
                    setCurrenContentId(
                      contentCopies.find((elem) => elem?.copy_id === product?.picked_product_copy_id)
                        ?._id
                        || productsContent.find((elem) => elem.product_id === product.product_id)?._id,
                    );
                  }}
                >
                  <img alt={product.subtitle} src={product.img_medium || product.img_large} />
                  <span className={styles.products_slider__item__title}>
                    {product.subtitle || product.title}
                  </span>
                </div>
                <div className={styles.content_copies_container}>
                  {renderItems.map((elem, i) => (
                    <Button
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={i}
                      disabled={elem.product_id !== currentProductId}
                      onClick={() => {
                        setCurrenContentId(elem._id);
                      }}
                      outlined={elem._id !== currentContentId}
                      size="small"
                      variant="secondary"
                    >
                      #
                      {i + 1}
                    </Button>
                  ))}
                  <div id={`add-new-product-content-copy-${product.product_id}`} />
                </div>
              </div>
            );
          })}
      </div>
      <div className={styles.assets_container}>{children(currentProductId, currentContentId, setCurrenContentId)}</div>
    </Container>
  );
};

export { EditableProductSwitcher };
