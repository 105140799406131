import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AuthorDto,
  AuthorListItemDto,
  CreateAuthorActionPayload,
  GetAllAuthorsOptions,
} from '../../common/interfaces/authors-api';
import { AuthorFormType } from '../../modules/authors/create-author-page';

type TError = { message: string; code?: number };

type TAuthorInitialState = {
  authors: AuthorListItemDto[];
  pending: boolean;
  error: TError;
  totalAuthorsCount: number;
  successModalOpen: boolean;
  author: AuthorDto;
};

const initialState: TAuthorInitialState = {
  author: null,
  authors: [],
  error: {
    code: null,
    message: null,
  },
  pending: false,
  successModalOpen: false,
  totalAuthorsCount: null,
};

export const authorSlice = createSlice({
  initialState,
  name: 'author',
  reducers: {
    changeSuccessModalSTate(state, action: PayloadAction<boolean>) {
      state.successModalOpen = action.payload;
    },
    createAuthorError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },
    createAuthorRequest(...args: [TAuthorInitialState, CreateAuthorActionPayload]) {
      const [state] = args;
      return {
        ...state,
        error: null,
        pending: true,
      };
    },

    createAuthorResponse(state) {
      state.pending = false;
      state.error = null;
    },

    deleteAuthorByIdError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
      state.pending = false;
    },

    deleteAuthorByIdRequest(...args: [TAuthorInitialState, PayloadAction<number>]) {
      const [state] = args;
      state.pending = true;
      state.error = null;
    },

    deleteAuthorByIdResponse(state, action: PayloadAction<number>) {
      state.pending = false;
      state.authors = state.authors.filter((elem) => elem.id !== action.payload);
      state.totalAuthorsCount -= 1;
    },

    getAuthorByIdError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
      state.pending = false;
    },

    getAuthorByIdRequest(...args: [TAuthorInitialState, PayloadAction<number>]) {
      const [state] = args;
      state.error = null;
      state.pending = true;
    },
    getAuthorByIdResponse(state, action: PayloadAction<AuthorDto>) {
      state.author = action.payload;
      state.pending = false;
    },
    getAuthors(...args: [TAuthorInitialState, PayloadAction<GetAllAuthorsOptions>]) {
      const [state] = args;
      return state;
    },
    getAuthorsError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },

    getAuthorsRequest(state) {
      return {
        ...state,
        error: null,
        pending: true,
      };
    },

    getAuthorsResponse(state, action: PayloadAction<{ authors: AuthorDto[]; totalCount: number }>) {
      state.pending = false;
      state.authors = action.payload.authors;
      state.totalAuthorsCount = action.payload.totalCount;
      state.error = null;
    },
    updateAuthorError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },
    updateAuthorImgError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },
    updateAuthorImgRequest(
      ...args: [TAuthorInitialState, PayloadAction<{ id: number; img: File }>]
    ) {
      const [state] = args;
      return {
        ...state,
        error: null,
        pending: true,
      };
    },
    updateAuthorImgResponse(state, action: PayloadAction<AuthorDto['img']>) {
      return {
        ...state,
        author: {
          ...state.author,
          img: action.payload,
        },
        pending: false,
      };
    },
    updateAuthorRequest(
      ...args: [TAuthorInitialState, PayloadAction<{ data: AuthorFormType; id: number }>]
    ) {
      const [state] = args;
      return {
        ...state,
        error: null,
        pending: true,
      };
    },
    updateAuthorResponse(state, action: PayloadAction<AuthorDto>) {
      state.author = { img: state.author.img, ...action.payload };
      state.pending = false;
    },
  },
});

export const authorReducer = authorSlice.reducer;
