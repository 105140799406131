import 'react-quill/dist/quill.snow.css';

import ReactQuill, { Value } from 'react-quill';

import styles from './RichTextEditor.module.scss';

export interface IRichTextEditorProps {
  onChange: ReactQuill.ReactQuillProps['onChange'];
  value: Value;
}

const RichTextEditor = ({ onChange, value }: IRichTextEditorProps) => {
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  const modules = {
    toolbar: [
      [{ header: [3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <div className={styles.input_wrapper}>
      <ReactQuill
        className={styles.input}
        formats={formats}
        modules={modules}
        onChange={onChange}
        placeholder="Write yout buying guide..."
        theme="snow"
        value={value}
      />
    </div>
  );
};

export { RichTextEditor };
