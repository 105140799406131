import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'common/ui/modal';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { SaveIcon } from '../../../../../assets/images/icons';
import { CreateCouponRequestBody } from '../../../../../common/services/coupons-service.api';
import { Button } from '../../../../../common/ui/button';
import { FormItem } from '../../../../../common/ui/formItem';
import { Input } from '../../../../../common/ui/Input';
import { Notification } from '../../../../../common/ui/notification';
import { SingleDatePicker } from '../../../../../common/ui/single-date-picker';
import { utilFormatDate } from '../../../../../common/utils';
import { useActions, useAppSelector } from '../../../../../hooks';

interface CreateCouponModalProps {
  open: boolean;
  onCLose?: () => void;
}

interface CreateCouponForm {
  code: string;
  discount_amount: number;
  expired_at: Date;
}

const createCouponValidationScheme = yup.object({
  code: yup.string()
    .trim()
    .required('Required field'),
  discount_amount: yup
    .number()
    .typeError('Required field')
    .required('Required field')
    .min(1, ({ min }) => `Must be greater than or equal ${min}`)
    .max(99, ({ max }) => `Must bbe less than or equal ${max}`),
  expired_at: yup.date()
    .required('Required field'),
});

const CreateCouponModal = ({
  onCLose,
  open,
}: CreateCouponModalProps) => {
  const {
    error,
    loading,
  } = useAppSelector(({ couponsReducer }) => couponsReducer.createCoupon);
  const { createCouponRequest } = useActions();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<CreateCouponForm>({
    criteriaMode: 'all',
    resolver: yupResolver(createCouponValidationScheme),
  });

  const onSubmit = ({
    code,
    discount_amount,
    expired_at,
  }: CreateCouponForm) => {
    const requestBody: CreateCouponRequestBody = {
      code,
      discount_amount: discount_amount / 100,
      expired_at: utilFormatDate(expired_at.toString(), { formatStr: 'yyyy-MM-dd' }),
    };

    createCouponRequest({
      cb: () => {
        reset();
        onCLose();
      },
      data: requestBody,
    });
  };

  if (!open) return null;

  return (
    <Modal className="w-20" locked open>
      <Modal.Title>Create coupon</Modal.Title>
      <Modal.Body className="mb-70">
        <div className="flex-column raw-gap-10">
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <FormItem label="Coupon code" message={errors[field.name]?.message}>
                <Input
                  invalid={!!errors[field.name]?.message}
                  onChange={(e) => field.onChange(e)}
                  placeholder="Coupon code..."
                  value={field.value}
                  variant="secondary"
                />
              </FormItem>
            )}
          />

          <Controller
            control={control}
            name="discount_amount"
            render={({ field }) => (
              <FormItem label="Discount amount, %" message={errors[field.name]?.message}>
                <Input
                  invalid={!!errors[field.name]?.message}
                  onChange={(e) => field.onChange(e)}
                  placeholder="Discount amount..."
                  type="number"
                  value={field.value}
                  variant="secondary"
                />
              </FormItem>
            )}
          />

          <Controller
            control={control}
            name="expired_at"
            render={({ field }) => (
              <FormItem label="Expired date" message={errors[field.name]?.message}>
                <SingleDatePicker
                  disabled={{ before: new Date() }}
                  onChange={(day) => field.onChange(day)}
                  value={field.value}
                />
              </FormItem>
            )}
          />

          {error && <Notification fullWidth message={error?.message} type="error" />}
        </div>
      </Modal.Body>

      <Modal.Actions>
        <div className="w-70 d-flex column-gap-10">
          <Button fullWidth onClick={onCLose} variant="secondary">
            Cancel
          </Button>
          <Button
            fullWidth
            iconStart={<SaveIcon />}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export { CreateCouponModal };
