import { Control, FieldArrayWithId, useFieldArray } from 'react-hook-form';

import { isCardAsset } from '../../../../../../common/type-guards';
import { SortableContainer } from '../../../../../../common/ui/draggable-container';
import { ProductId, TPageForm } from '../../../../page-form/page-form';
import { AssetsContainer } from '../../../assets-container';
import { ProductCard } from '../product-card';
import { ProductCardCreator } from '../product-cards-creator';
import styles from './product-cards-form.module.scss';

type Props = {
  productIdx: number;
  control: Control<TPageForm>;
  currentProductId: ProductId;
};

const ProductsCardsForm = ({ control, currentProductId, productIdx }: Props) => {
  const { append, fields, move, remove, update } = useFieldArray({
    control,
    name: `productAssets.${productIdx}.assets`,
  });

  const onDeleteButton = (
    assetIdx: number,
    asset: FieldArrayWithId<TPageForm, `productAssets.${number}.assets`>,
  ) => {
    if (asset.type === 'deleted') return;

    if (asset.type === 'added') {
      remove(assetIdx);
    } else {
      update(assetIdx, { ...asset, type: 'deleted' });
    }
  };

  return (
    <div className="w-100 h-100 flex-column raw-gap-20">
      <AssetsContainer basedVisibleCount={4} className={styles.items_container}>
        <div className={styles.item_wrapper}>
          <ProductCardCreator
            currentProductId={currentProductId}
            onAddButton={(data) => append(data)}
          />
        </div>
        {fields.map((asset, assetIdx) => {
          if (!isCardAsset(asset) || asset.type === 'deleted') return null;

          return (
            <SortableContainer.ItemWrapper
              key={asset.id}
              className={styles.item_wrapper}
              id={asset.id}
              index={assetIdx}
              moveItem={(from, to) => move(from, to)}
            >
              <ProductCard
                asset={asset}
                assetIdx={assetIdx}
                onDeleteButton={() => onDeleteButton(assetIdx, asset)}
                productIdx={productIdx}
              />
            </SortableContainer.ItemWrapper>
          );
        })}
      </AssetsContainer>
    </div>
  );
};

export { ProductsCardsForm };
