import axios from 'axios';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';

import { PlusOutlinedIcon } from '../../../../assets/images/icons';
import { getProductsApiClient } from '../../../../common/services/products-api.service';
import { isAmazonProduct, isWalmartProduct } from '../../../../common/type-guards';
import { Button } from '../../../../common/ui/button';
import { Container } from '../../../../common/ui/container';
import { FormItem } from '../../../../common/ui/formItem';
import { TextField } from '../../../../common/ui/text-field';
import { IDailyDealsForm, IDealProduct } from '../../types';
import styles from './deal-product-input.module.scss';

interface Props {
  onChange?: (product: IDealProduct) => void;
  rowIndex: number;
}

const DealProductInput = ({ onChange, rowIndex }: Props) => {
  const { getValues } = useFormContext<IDailyDealsForm>();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [error, setError] = useState('');
  const findProduct = async (productId: string) => {
    if (productId.length !== 10) {
      setError('Invalid value');
      return;
    }
    if (getValues(`rows.${rowIndex}.products`).some((elem) => elem.product_id === productId)) {
      setError('Already exist');
      return;
    }
    if (error) {
      setError('');
    }

    setLoading(true);

    try {
      const { data } = await getProductsApiClient().getProductByAsin(productId, {
        features: false,
      });

      const product = data.data.products[0];

      if (isAmazonProduct(product)) {
        product.product_id = product.asin;
      }

      if (isWalmartProduct(product)) {
        product.product_id = product.w_item_id;
      }

      if (onChange) {
        onChange({
          basis_price: product.basis_price,
          id: product.id,
          img_large: product.img_large,
          img_medium: product.img_medium,
          page_url: product.page_url,
          price: product.price,
          product_id: product.product_id,
          status: 'new',
          subtitle: product.subtitle,
          title: product.title
        });
      }
      setSearchValue('');
      toast.success('Success!');
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        toast.error(`Code ${err.status}: ${err.message}}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className={styles.deal_product_input}>
      <div />
      <FormItem className="w-100" label="Find product" message={error}>
        <TextField
          disabled={loading}
          fullWidth
          invalid={!!error.length}
          onChange={({ target: { value } }) => setSearchValue(value)}
          placeholder="ASIN ..."
          value={searchValue}
        />
      </FormItem>
      <Button
        fullWidth
        iconStart={<PlusOutlinedIcon />}
        loading={loading}
        onClick={() => findProduct(searchValue)}
        variant="secondary"
      >
        Add product
      </Button>
    </Container>
  );
};
export { DealProductInput };
