import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import { ArrowDownIcon } from '../../../assets/images/icons';
import { Button } from '../button';
import { Select } from '../select';
import { TSelectOption } from '../select-old';
import styles from './Pagination.module.scss';

interface IPaginationProps {
  count: number;
  onChange: (offset: number, limit: number) => void;
  limit: number;
  className?: string;
  fullwidth?: boolean;
  limitHandler?: (limit: number) => void;
}

const itemsCountOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
];

const Pagination = React.memo(
  ({ className, count = 0, fullwidth, limit = 25, limitHandler, onChange }: IPaginationProps) => {
    const [page, setPage] = useState<number>(1);
    const [position, setPosition] = useState(0);

    const buttons = useMemo(
      () => new Array(Math.ceil(count / limit)).fill(1).map((item, index) => index + 1),
      [count, limit],
    );

    const renderButtons = useMemo(() => buttons.slice().splice(position, 3), [position, buttons]);
    const currentItemsDiapason = `
    ${limit * page - limit + 1} - ${limit * page > count ? count : limit * page} of ${count}`;

    const onPrev = () => {
      setPosition((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    };

    const onNext = () => {
      setPosition((prevState) => (prevState < buttons.length - 1 ? prevState + 1 : prevState));
    };

    const onPageButtonClick = (pageNumber: number) => {
      setPage(pageNumber);
      const offset = limit * pageNumber - limit;
      onChange(offset, limit);
    };

    const onItemsCount = (value: number) => {
      if (limitHandler) limitHandler(value);
      setPosition(0);
      setPage(1);
    };

    useEffect(() => {
      setPage(1);
    }, [count]);

    return (
      !!renderButtons.length && (
        <div
          className={clsx(
            styles.pagination,
            className && className,
            fullwidth && styles.pagination__fullwidth,
          )}
        >
          <span className={clsx(styles.limit, styles.text)}>{currentItemsDiapason}</span>
          <div className={styles.actions}>
            <Button
              disabled={position === 0}
              onClick={onPrev}
              outlined
              size="small"
              variant="secondary"
            >
              <ArrowDownIcon className={styles.arrow_left} />
            </Button>
            {renderButtons.map((button) => {
              const selected = button === page;
              return (
                <Button
                  key={button}
                  onClick={() => onPageButtonClick(button)}
                  outlined={!selected}
                  size="small"
                  variant={selected ? 'primary' : 'secondary'}
                >
                  {button}
                </Button>
              );
            })}
            <Button
              disabled={page === buttons[buttons.length - 1]}
              onClick={onNext}
              outlined
              size="small"
              variant="secondary"
            >
              <ArrowDownIcon className={styles.arrow_right} />
            </Button>
          </div>
          <div className={styles.offset}>
            <span className={styles.text}>Show</span>
            <Select
              menuPosition="fixed"
              onChange={(option) => onItemsCount((option as TSelectOption).value as number)}
              options={itemsCountOptions}
              value={itemsCountOptions.find((elem) => elem.value === limit)}
            />
          </div>
        </div>
      )
    );
  },
);

export { Pagination };
