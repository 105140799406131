import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = { message: string; code?: number };

export type TKeywordCheckingState = {
  error: TError,
  message: {
    type: 'success' | 'error'
    text: string
  },
};

const initialState: TKeywordCheckingState = {
  error: null,
  message: null,
};

export interface ICheckKeywordActionPayload {
  keyword: string;
  brand_id: number;
  publisher_id: number;
  domain_id?: number
}

export const keywordCheckingSlice = createSlice({
  initialState,
  name: 'keywordChecking',
  reducers: {
    checkKeyword(...args: [TKeywordCheckingState, PayloadAction<ICheckKeywordActionPayload>]) {
      const [state] = args;
      return state;
    },
    checkKeywordError(state, action: PayloadAction<TKeywordCheckingState['error']>) {
      state.error = action.payload;
    },
    checkKeywordRequest(...args: [TKeywordCheckingState, PayloadAction<{
      keyword: string;
      brand_id: number;
      publisher_id: number;
    }>]) {
      const [state] = args;
      state.error = null;
      state.message = null;
    },
    checkKeywordsResponse(state, action: PayloadAction<TKeywordCheckingState['message']>) {
      state.message = action.payload;
    },
    clearCheckKeywordState() {
      return initialState;
    },
  },
});

export const { actions, reducer } = keywordCheckingSlice;
