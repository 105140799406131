import { CSSProperties } from 'react';

import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  error: string;
  style?: CSSProperties;
}

export function ErrorMessage({ error, style }: ErrorMessageProps) {
  return (
    <p className={styles.error_message} style={style}>
      {error}
    </p>
  );
}
