import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateCouponRequestBody } from '../../../common/services/coupons-service.api';

type TError = { message: string; code?: number };

export type TUpdateCouponInitialState = {
  error: TError;
  message: {
    type: 'success' | 'error';
    text: string;
  };
  loading: boolean;
};

const initialState: TUpdateCouponInitialState = {
  error: null,
  loading: false,
  message: null,
};

export const updateCouponSlice = createSlice({
  initialState,
  name: 'updateCoupon',
  reducers: {
    updateCouponClearState() {
      return initialState;
    },
    updateCouponError(state, action: PayloadAction<TUpdateCouponInitialState['error']>) {
      state.error = action.payload;
      state.loading = false;
    },
    updateCouponRequest(
      ...args: [
        TUpdateCouponInitialState,
        PayloadAction<{
          data: CreateCouponRequestBody;
          place?: 'searchList' | 'productList'
          cb?: () => void;
        }>,
      ]
    ) {
      const [state] = args;

      state.loading = true;
      state.error = null;
    },
    updateCouponResponse(state) {
      state.loading = false;
    },
  },
});

export const { actions, reducer } = updateCouponSlice;
