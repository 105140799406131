import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import importImage from '../../assets/images/product-import.png';
import { getImportProductSchedulerApi } from '../../common/services/import-product-scheduler-api.service';
import { Modal } from '../../common/ui/modal';
import { Notification } from '../../common/ui/notification';
import { useLocalStorage } from '../../hooks';
import { ImportPanel } from './components/import-panel';
import { MessagesModalContent } from './components/messages-modal-content';
import styles from './ProductImportPage.module.scss';
import { TAsinHistoryItem, TMessage } from './types';

const ProductImportPage = () => {
  const [params] = useSearchParams();
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [loading, setLoading] = useState(false);
  const [asins, setAsins] = useState([]);
  const [asinsHistory, setAsinsHistory] = useLocalStorage<TAsinHistoryItem[]>('asinsHistory', []);
  const [inputValue, setInputValue] = useState('');

  const addProductToScheduler = async (asins: string[]) => {
    const data = {
      asins,
    };

    setLoading(true);

    try {
      const response = await getImportProductSchedulerApi().addProductToScheduler(data);
      if (response.status === 201 && response.data.available.length) {
        const asins = response.data.available.map((x) => x.asin);

        setMessages((prevState) => [
          ...prevState,
          {
            asins,
            message: 'The Asins already available in the database',
          },
        ]);
      }

      if (response.status === 201 && response.data.scheduled.length) {
        setMessages((prevState) => [
          ...prevState,
          {
            asins: response.data.scheduled,
            message: 'The Asins have been added to the queue',
          },
        ]);
      }
    } catch (e) {
      setMessages([{ asins: [], message: 'Something wrong' }]);
    } finally {
      setAsinsHistory([
        ...asinsHistory,
        ...asins.map((asin) => ({ asin, date: new Date().toISOString() })),
      ]);
      setLoading(false);
    }
  };

  const inputHandler = (value: string, asins: string[]) => {
    setInputValue(value);
    setAsins(asins);
  };

  const onModalButtonClose = () => {
    setMessages([]);
    setAsins([]);
    setInputValue('');
  };

  const onSendClick = () => {
    const data = [...asins];
    if (inputValue.length === 10) {
      data.push(inputValue);
    }
    addProductToScheduler(data);
  };

  useEffect(() => {
    if (params.get('asin')) {
      setAsins([params.get('asin')]);
    }
  }, []);

  return (
    <div className={styles.product_scheduler}>
      <Notification className={styles.notification} message="Only for amazon product yet" type="warn" />
      <img alt="product-import-img" className={styles.logo} src={importImage} />
      <h1 className={styles.title}>Product import scheduler</h1>
      <span className={styles.hint}>Send your ASINs in field below.</span>
      <ImportPanel
        asins={asins}
        inputHandler={inputHandler}
        inputValue={inputValue}
        loading={loading}
        onSendClick={onSendClick}
        sendBtnDisabled={!asins.length && inputValue.length !== 10}
      />
      {!!messages.length && (
        <Modal open={!!messages.length}>
          <MessagesModalContent messages={messages} onModalButtonClose={onModalButtonClose} />
        </Modal>
      )}
    </div>
  );
};

export { ProductImportPage };
