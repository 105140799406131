import clsx from 'clsx';
import { Checkbox } from 'common/ui/checkbox';
import React, { ChangeEvent } from 'react';
import {
  Control,
  FieldErrors,
  useController,
  useFieldArray,
} from 'react-hook-form';

import { lengthRestrictions } from '../../../../common/constants/length-restrictions';
import { SortableContainer } from '../../../../common/ui/draggable-container';
import { FormItem } from '../../../../common/ui/formItem';
import { NoData } from '../../../../common/ui/no-data';
import { TextField } from '../../../../common/ui/text-field';
import { AssetsContainer } from '../../../pages/components';
import { TPageForm } from '../../../pages/page-form/page-form';
import { FaqItem } from './components/faq-Item';
import { ProsItem } from './components/pros-item';
import styles from './product-content-form.module.scss';

type Props = {
  control: Control<TPageForm>;
  className?: string;
  index?: number;
  errors?: FieldErrors<TPageForm['content'][number]>;
  no_visible?: boolean;
  onTiedChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  withSetContentButton?: boolean;
};

const ProductContentForm = ({
  className,
  control,
  onTiedChange,
  withSetContentButton,
  ...props
}: Props) => {
  const prefix = Object.hasOwn(props, 'index') ? `content.${props.index}.` : '';

  const faqItems = useFieldArray({ control, name: `${prefix}faq` as any });
  const prosItems = useFieldArray({ control, name: `${prefix}pros` as any });

  const tiedField = useController({ control, name: `${prefix}tied` as any });
  const summaryField = useController({ control, name: `${prefix}summary` as any });
  const bottomLineField = useController({ control, name: `${prefix}bottomLine` as any });

  const setContentHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      tiedField.field.onChange(e.target.checked);
    }
    if (onTiedChange) {
      onTiedChange(e);
    }
  };

  return (
    <div className={clsx('flex-column raw-gap-20 w-100', className)}>
      <div className="flex-column raw-gap-20 w-100">
        {withSetContentButton && (
          <Checkbox
            ref={tiedField.field.ref}
            checked={tiedField.field.value}
            disabled={tiedField.field.value}
            onChange={setContentHandler}
          >
            Set for the page
          </Checkbox>
        )}
        <div className="d-flex column-gap-20">
          <div className="w-100 flex-column raw-gap-20">
            <h5 className={styles.title}>Summary</h5>
            <FormItem label="Write your summary" message={summaryField.fieldState.error?.message}>
              <TextField
                {...summaryField.field}
                invalid={summaryField.fieldState.invalid}
                limit={lengthRestrictions.product.content.summary}
                placeholder="Write your summary"
                resizable
                rows={8}
              />
            </FormItem>
          </div>
          <div className="w-100 flex-column raw-gap-20">
            <h5 className={styles.title}>Bottom Line</h5>
            <FormItem
              label="Write your bottom line"
              message={bottomLineField.fieldState.error?.message}
            >
              <TextField
                {...bottomLineField.field}
                invalid={bottomLineField.fieldState.invalid}
                placeholder="Write your bottom"
                rows={8}
              />
            </FormItem>
          </div>
        </div>

        <h5 className={styles.title}>Product Pros/Cons</h5>

        <AssetsContainer
          basedVisibleCount={3}
          className={styles.items_container}
          onAddButtonClick={() => prosItems.append('')}
          startPosition={0}
          withAddButton
        >
          {!prosItems.fields.length && <NoData fullWidth />}
          {!!prosItems.fields.length
            && prosItems.fields.map((elem, index) => (
              <SortableContainer.ItemWrapper
                key={elem.id}
                className={styles.pros_wrapper}
                id={elem.id}
                index={index}
                moveItem={(from, to) => prosItems.move(from, to)}
                type="pros"
              >
                <ProsItem
                  index={index}
                  onDeleteButton={() => prosItems.remove(index)}
                  prefix={prefix}
                />
              </SortableContainer.ItemWrapper>
            ))}
        </AssetsContainer>
      </div>
      <div className={clsx('flex-column raw-gap-20 w-100')}>
        <h5 className={styles.title}>FAQs</h5>
        <AssetsContainer
          basedVisibleCount={3}
          className={clsx(styles.items_container)}
          onAddButtonClick={() => faqItems.append({ answer: '', question: '' })}
          startPosition={0}
          withAddButton
        >
          {!faqItems.fields.length && <NoData fullWidth />}
          {faqItems.fields?.length > 0
            && faqItems.fields.map((elem, faqItemIdx) => (
              <SortableContainer.ItemWrapper
                key={elem.id}
                className={styles.faq_wrapper}
                id={elem.id}
                index={faqItemIdx}
                moveItem={(from, to) => faqItems.move(from, to)}
                type="faq"
              >
                <FaqItem
                  faqItemIdx={faqItemIdx}
                  namesPrefix={prefix}
                  onDeleteButton={() => faqItems.remove(faqItemIdx)}
                />
              </SortableContainer.ItemWrapper>
            ))}
        </AssetsContainer>
      </div>
    </div>
  );
};

export { ProductContentForm };
