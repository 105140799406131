import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetListRequestBody } from '../../../common/services/coupons-service.api';

type TError = { message: string; code?: number };

export interface CouponDto {
  id: number,
  code: string,
  discount_amount: number,
  expired_at: string,
  asins: string[],
  created_at?: string
  updated_at?: string
}

export type TGetListInitialState = {
  error: TError;
  message: {
    type: 'success' | 'error';
    text: string;
  };
  loading: boolean;
  coupons: CouponDto[];
};

const initialState: TGetListInitialState = {
  coupons: [],
  error: null,
  loading: false,
  message: null
};

export const getCouponListSlice = createSlice({
  initialState,
  name: 'getCouponList',
  reducers: {
    getCouponList(...args: [TGetListInitialState, PayloadAction<GetListRequestBody>]) {
      const [state] = args;
      return state;
    },
    getCouponListClearState() {
      return initialState;
    },
    getCouponListError(state, action: PayloadAction<TGetListInitialState['error']>) {
      state.error = action.payload;
      state.loading = false;
      state.coupons = [];
    },
    getCouponListRequest(
      ...args: [
        TGetListInitialState,
        PayloadAction<string>,
      ]
    ) {
      const [state] = args;
      state.loading = true;
      state.error = null;
      state.message = null;
    },
    getCouponListResponse(state, action: PayloadAction<CouponDto[]>) {
      state.loading = false;
      state.coupons = action.payload;
    },
    replaceCouponListItem(state, action: PayloadAction<CouponDto>) {
      return {
        ...state,
        coupons: state.coupons.map((coupon) => {
          if (coupon.id === action.payload.id) {
            return action.payload;
          }
          return coupon;
        })
      };
    },
    setCouponListState(state, action: PayloadAction<Partial<TGetListInitialState>>) {
      return { ...state, ...action.payload };
    }
  },
});

export const { actions, reducer } = getCouponListSlice;
