import { useAuth0 } from '@auth0/auth0-react';

import { LogoutIcon } from '../../../assets/images/icons';
import { Button } from '../button';
import styles from './UserInfo.module.scss';

export function UserInfo() {
  const { logout, user } = useAuth0();

  return (
    <div className={styles.user_card}>
      <div className={styles.user_data}>
        <div className={styles.avatar}>
          <img alt="user" src={user?.picture} />
        </div>
        <div className={styles.info}>
          <span className={styles.info__name}>{user?.name}</span>
          <span className={styles.info__role}>{user?.email}</span>
        </div>
      </div>

      <Button
        iconStart={<LogoutIcon />}
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Logout
      </Button>
    </div>
  );
}
