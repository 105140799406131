import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { FormMode } from 'common/constants/enums';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { PublisherSelect } from 'modules/pages/components/publisher-select';
import { ReactNode, useState } from 'react';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { v4 } from 'uuid';

import {
  BinIcon,
  CopyIcon,
  DownloadIcon,
  EyeIcon,
  LogoutIcon,
  PlusOutlinedIcon,
} from '../../../../assets/images/icons';
import amazonLogoUrl from '../../../../assets/images/logos/amazon-icon.png';
import {
  CreateTemporaryLinkBody,
  TemporaryLinkService,
} from '../../../../common/services/temporary-link-service.api';
import { Button } from '../../../../common/ui/button';
import { Loader } from '../../../../common/ui/loader';
import { Modal } from '../../../../common/ui/modal';
import { SingleDatePicker } from '../../../../common/ui/single-date-picker';
import { Tooltip } from '../../../../common/ui/tooltip';
import { utilDownloadCsv } from '../../../../common/utils';
import { utilCopyToClipboard } from '../../../../common/utils/utilCopyToClipboard';
import { createTemporaryLinkValidateSchema } from '../../../../common/validationSchemas';
import styles from './temporary-links-form.module.scss';

interface ITagCreationForm {
  tags: Array<{ asin: string; tag: string }>;
  publisherID: number;
  date: Date;
}

const defaultValues = {
  tags: [
    {
      asin: '',
      tag: '',
    },
  ],
};
const baseAmazonLink = 'https://www.amazon.com';
const buildTemporaryLink = (asin: string, params: string) => {
  const queryParams = new URLSearchParams(params);

  return `${baseAmazonLink}/dp/${asin}?${queryParams.toString()}`;
};

interface TemporaryLinksFormProps {
  onPublisherSelect(publisherId: number): void;
  onShowTagsButtonClick(): void;
  onCreateTag(): void;
}

const TemporaryLinksForm = ({
  onCreateTag,
  onPublisherSelect,
  onShowTagsButtonClick,
}: TemporaryLinksFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [csvLoader, setCsvLoader] = useState(false);
  const [fieldIndex, setFieldIndex] = useState<number>(null);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
  } = useForm<ITagCreationForm>({
    defaultValues,
    resolver: yupResolver(createTemporaryLinkValidateSchema),
  });

  const { append, fields, remove } = useFieldArray({ control, name: 'tags' });
  const expireDate = useController({ control, name: 'date' });
  const publisherID = useController({ control, name: 'publisherID' });

  const onSubmit = async ({ date, publisherID, tags }: ITagCreationForm) => {
    const requestBody: CreateTemporaryLinkBody = {
      expired_at: format(date, 'yyyy-MM-dd'),
      product_links: tags.map(({ asin, tag }) => ({ asin, link: buildTemporaryLink(asin, tag) })),
      publisher_id: publisherID || null,
    };

    setIsLoading(true);

    try {
      await TemporaryLinkService.createLink(requestBody);

      // TODO: Update "PublisherSelect" component to select deafult pub on form reset
      reset({ ...defaultValues, publisherID });
      toast.success('Links created successfully!');
      onCreateTag();
    } catch (err: any) {
      toast.error(err?.message || 'Something went wrong. Try again later');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadCsvFile = async () => {
    setCsvLoader(true);
    try {
      const res = await TemporaryLinkService.downloadCsvFile();
      if (res.data) {
        const fileName = res.headers['content-disposition'].match(/filename="(.*)"/)[1];
        utilDownloadCsv(res.data, fileName);
      }
    } catch (err: any) {
      toast.error(err?.message || 'Something went wrong. Try again later');
    } finally {
      setCsvLoader(false);
    }
  };

  const renderData = (str: string) => {
    const data = new URLSearchParams(str);

    const mappedData: ReactNode[] = [];
    let count = 1;

    data.forEach((value, key) => {
      mappedData.push(
        <span key={v4()}>
          {`${count}. ${encodeURIComponent(key)}: ${encodeURIComponent(value)}`}
        </span>,
      );
      count += 1;
    });

    return mappedData;
  };

  const openElemDetails = (index: number) => {
    const tag = getValues(`tags.${index}.tag`);
    const asin = getValues(`tags.${index}.asin`);
    if (tag && asin) {
      setFieldIndex(index);
    } else if (!asin && tag) {
      toast.error('Type ASIN');
    } else if (!tag && asin) {
      toast.error('Type tags');
    } else {
      toast.error('Type product ASIN and tags');
    }
  };

  const copyLink = (asin: string, tag: string) => {
    if (asin && tag) {
      utilCopyToClipboard(buildTemporaryLink(asin, tag));
    } else if (!asin && tag) {
      toast.error('Type ASIN');
    } else if (!tag && asin) {
      toast.error('Type tags');
    } else {
      toast.error('Type product ASIN and tags');
    }
  };

  return (
    <>
      {fieldIndex !== null && (
        <Modal onClose={() => setFieldIndex(null)} open>
          <Modal.Title>
            {`Product: 
            ${getValues(`tags.${fieldIndex}.asin`)}`}
          </Modal.Title>
          <Modal.Body className={styles.modal_body}>
            <h4 className={styles.modal_subtitle}>Current tags:</h4>
            <div className={styles.link_list}>
              {renderData(getValues(`tags.${fieldIndex}.tag`))}
            </div>
            <h4 className={styles.modal_subtitle}>Link example: </h4>
            <div className={styles.modal_link_wrapper}>
              <img alt="amazon_icon" className={styles.link_logo} src={amazonLogoUrl} />
              <p className={styles.modal_link}>
                {buildTemporaryLink(
                  getValues(`tags.${fieldIndex}.asin`),
                  getValues(`tags.${fieldIndex}.tag`),
                )}
              </p>
              <Button
                className="ml-10"
                iconStart={<CopyIcon />}
                onClick={() =>
                  utilCopyToClipboard(
                    buildTemporaryLink(
                      getValues(`tags.${fieldIndex}.asin`),
                      getValues(`tags.${fieldIndex}.tag`),
                    ),
                  )
                }
                outlined
                placeholder="tags"
                size="small"
                variant="secondary"
              />
            </div>
          </Modal.Body>
          <Modal.Actions>
            <Button
              iconStart={<LogoutIcon />}
              onClick={() => setFieldIndex(null)}
              outlined
              variant="secondary"
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <form className={styles.tag_creation_form} onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <div className={styles.loader_container}>
            <div className={styles.background} />
            <Loader className={styles.loader}>Loading...</Loader>
          </div>
        )}
        <div className="d-flex w-100 justify-space-between mb-30">
          <div className="d-flex column-gap-10">
            <div className={styles.input_wrapper}>
              <SingleDatePicker
                buttonProps={{}}
                onChange={(day) => expireDate.field.onChange(day)}
                value={expireDate.field.value}
              >
                Select end date
              </SingleDatePicker>
              {errors?.date?.message && (
                <p className={styles.error_message}>{errors?.date?.message}</p>
              )}
            </div>
            <div className={styles.input_wrapper}>
              <PublisherSelect
                formMode={FormMode.SEARCH}
                updateFilter={({ publisher }) => {
                  onPublisherSelect(publisher);
                  publisherID.field.onChange(publisher);
                }}
              />
            </div>
          </div>
          <div className="d-flex column-gap-20">
            <Button
              iconStart={<PlusOutlinedIcon />}
              onClick={() => {
                append({ asin: '', tag: '' });
              }}
              variant="secondary"
            >
              Add new
            </Button>
            <Button
              iconStart={<DownloadIcon />}
              loading={csvLoader}
              onClick={downloadCsvFile}
              variant="secondary"
            />
          </div>
        </div>
        <div className={styles.tags_container}>
          {fields.map((field, index) => {
            const tagError = _.get(errors, String(`tags.${index}.tag.message`), undefined);
            const asinError = _.get(errors, String(`tags.${index}.asin.message`), undefined);
            return (
              <div key={field.id} className={styles.form_item}>
                <img alt="amazon_icon" className={styles.link_logo} src={amazonLogoUrl} />
                https://www.amazon.com/dp/
                <div className={styles.input_wrapper}>
                  <input
                    autoComplete="off"
                    className={clsx(styles.input, styles.asin, asinError && styles.input__error)}
                    {...register(`tags.${index}.asin`)}
                    placeholder="ASIN..."
                  />
                  {asinError && <p className={styles.error_message}>{asinError}</p>}
                </div>
                {' ? '}
                <div className={styles.input_wrapper}>
                  <input
                    autoComplete="off"
                    className={clsx(styles.input, styles.tags, tagError && styles.input__error)}
                    {...register(`tags.${index}.tag`)}
                    placeholder="tags..."
                  />
                  {tagError && <p className={styles.error_message}>{tagError}</p>}
                </div>
                <div className={styles.actions}>
                  <Tooltip title="show values">
                    <Button
                      iconStart={<EyeIcon />}
                      onClick={() => openElemDetails(index)}
                      outlined
                      placeholder="tags"
                      size="small"
                      variant="secondary"
                    />
                  </Tooltip>
                  <Tooltip title="copy link">
                    <Button
                      iconStart={<CopyIcon />}
                      onClick={() =>
                        copyLink(getValues(`tags.${index}.asin`), getValues(`tags.${index}.tag`))
                      }
                      outlined
                      placeholder="tags"
                      size="small"
                      variant="secondary"
                    />
                  </Tooltip>
                  <Tooltip title="delete item">
                    <Button
                      disabled={fields.length < 2}
                      iconStart={<BinIcon />}
                      onClick={() => remove(index)}
                      outlined
                      placeholder="tags"
                      size="small"
                      variant="danger"
                    />
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>

        <div className={styles.button_container}>
          <Button onClick={() => onShowTagsButtonClick()} placeholder="tags" type="button" variant="primary">
            Show hardcoded links
          </Button>
          <Button
            placeholder="tags"
            type="submit"
            variant="secondary"
          >
            Create
          </Button>
        </div>
      </form>
    </>
  );
};

export { TemporaryLinksForm };
