import { yupResolver } from '@hookform/resolvers/yup';
import * as _ from 'lodash';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '../../../common/ui/button';
import { Loader } from '../../../common/ui/loader';
import { createAuthorFormSchema } from '../../../common/validationSchemas';
import { useActions, useAppSelector } from '../../../hooks';
import { PageTitle } from '../../common/partials/PageTitle/PageTitle';
import { AuthorForm } from '../components/author-form';
import { AuthorFormType } from '../create-author-page';

const AuthorDetailsPage = () => {
  const { getAuthorByIdRequest, updateAuthorRequest } = useActions();
  const { authorId } = useParams();
  const { author, pending } = useAppSelector(({ authorReducer }) => authorReducer);

  const defaultAuthorValue: AuthorFormType = _.omit(
    { ...author },
    'updated_at',
    'id',
    'created_at',
    'info._id',
    'info.updatedAt',
    'info.idSql',
    'img',
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<AuthorFormType>({
    defaultValues: useMemo(() => defaultAuthorValue, [author]),
    resolver: yupResolver(createAuthorFormSchema),
  });

  const onSubmit = (data: AuthorFormType) => {
    updateAuthorRequest({ data, id: Number(authorId) });
  };

  useEffect(() => {
    reset(defaultAuthorValue);
  }, [author]);

  useEffect(() => {
    getAuthorByIdRequest(Number(authorId));
  }, []);

  return (
    <div className="d-flex flex-column">
      <PageTitle className="mb-30" title="Author details" withReturn />
      {pending && (
        <div className="d-flex justify-center w-100 mb-70">
          <Loader className="mt-70">loading...</Loader>
        </div>
      )}
      <div className="d-flex w-70">
        {!pending && author && (
          <AuthorForm
            author={author}
            control={control}
            errors={errors}
            onSubmit={handleSubmit(onSubmit)}
            pending={pending}
            register={register}
          >
            <div className="d-flex w-100 justify-end column-gap-10 align-center">
              <div className="w-15">
                <Button disabled={!isDirty} fullWidth loading={pending} type="submit">
                  Update
                </Button>
              </div>
            </div>
          </AuthorForm>
        )}
      </div>
    </div>
  );
};

export { AuthorDetailsPage };
