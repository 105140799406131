import { Product } from 'common/interfaces/agg-page';
import { AggPageFormContext } from 'context/page-form-context';
import { PagesContext } from 'context/pages-context';
import { useContext, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { Marketplaces } from '../../../../common/constants/enums';
import { productsNumber } from '../../../../common/constants/values';
import { Notification } from '../../../../common/ui/notification';
import { useActions } from '../../../../hooks';
import { PageProduct } from '../../page-product/page-product';
import { PageProductInput } from '../../page-product-input/PageProductInput';
import styles from './products-list.module.scss';

interface Props {
  marketplace?: Marketplaces;
}

export const ProductsList = ({ marketplace }: Props) => {
  const { products, setProducts } = useContext(AggPageFormContext);
  const { error } = useContext(PagesContext);
  const { getProductsCouponListRequest } = useActions();

  useEffect(() => {
    getProductsCouponListRequest(products.map(({ product_id }) => product_id));
  }, [products]);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? '#f1f7fa' : 'white',
    borderRadius: '10px',
    minWidth: '20%',
    padding: '10px',
    userSelect: 'none',
    width: '20%',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? '#cee5f0' : 'white',
    borderRadius: '10px',
    display: 'flex',
    minHeight: '387px',
    minWidth: '100%',
    overflow: 'scroll',
    position: 'relative' as 'relative',
  });

  const reorder = (products: Product[], startIndex: number, endIndex: number) => {
    const array = [...products];
    const [removed] = array.splice(startIndex, 1);
    array.splice(endIndex, 0, removed);
    const productWithBestPick = array.find((item) => item.value === 'Best Pick');
    if (productWithBestPick) {
      productWithBestPick.value = '';
    }
    array[0].value = 'Best Pick';
    array.forEach((item: Product, index: number) => {
      item.order = index + 1;
    });
    setProducts(array);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    reorder(products, result.source.index, result.destination.index);
  };

  return (
    <div className="d-flex flex-column raw-gap-20">
      {products?.length > 0 && (
        <div className={styles.notification_container}>
          <Notification
            fullWidth
            message='The first product will always be "Best Pick"'
            type="info"
          />
        </div>
      )}
      {error?.product && <Notification fullWidth message={error?.product} type="error" />}
      <div className={styles.products_list}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction="horizontal" droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={styles.slider}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {products.length < productsNumber && <PageProductInput marketplace={marketplace} />}
                {products.slice(0, productsNumber).map((item, index) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <PageProduct index={index} marketplace={marketplace} product={item} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
