import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BinIcon } from '../../../../../assets/images/icons';
import { AuthorListItemDto } from '../../../../../common/interfaces/authors-api';
import { Button } from '../../../../../common/ui/button';
import { Modal } from '../../../../../common/ui/modal';
import { Pagination } from '../../../../../common/ui/pagination';
import { Table } from '../../../../../common/ui/table';
import { utilFormatDate } from '../../../../../common/utils';
import { TSetFilter, useActions, useAppSelector } from '../../../../../hooks';
import { AuthorsFilterType } from '../../authors-page';
import styles from './authors-table.module.scss';

const tableStructure = ['first name', 'last name', 'id', 'updated', 'actions'];

type Props = {
  filter: AuthorsFilterType;
  setFilter: TSetFilter<AuthorsFilterType>;
};

const AuthorsTable = ({ filter, setFilter }: Props) => {
  const { authors, pending, totalAuthorsCount } = useAppSelector(
    ({ authorReducer }) => authorReducer,
  );
  const { deleteAuthorByIdRequest } = useActions();
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletedAuthor, setDeletedAuthor] = useState<AuthorListItemDto>(null);

  const deletedAuthorId = params.get('deleteAuthorId');

  const warningModalHandler = (id: number) => {
    setIsModalVisible(true);
    setParams(`deleteAuthorId=${id}`);
  };

  const deleteAuthor = () => {
    deleteAuthorByIdRequest(Number(deletedAuthorId));
    setIsModalVisible(false);
    params.delete('deleteAuthorId');
    setParams(params);
  };

  const deleteAuthorCancel = () => {
    setIsModalVisible(false);
    params.delete('deleteAuthorId');
    setParams(params);
  };

  useEffect(() => {
    if (deletedAuthorId) {
      setDeletedAuthor(authors?.find((elem) => elem.id === Number(deletedAuthorId)));
    } else {
      setDeletedAuthor(null);
    }
  }, [deletedAuthorId]);

  return (
    <>
      <Table className={styles.table} tableLayout="fixed">
        {authors?.length > 0 && (
          <Table.Header>
            <Table.Row>
              {tableStructure.map((col) => (
                <Table.Column key={col}>{col}</Table.Column>
              ))}
            </Table.Row>
          </Table.Header>
        )}

        <Table.Body>
          <Table.Loader loading={pending} />
          <Table.NoResult visible={authors?.length === 0 && !pending} />
          {authors?.length > 0
            && authors?.map(({ first_name, id, last_name, updated_at }) => (
              <Table.Row key={id}>
                <Table.Column>{first_name}</Table.Column>
                <Table.Column>{last_name}</Table.Column>
                <Table.Column>{id}</Table.Column>
                <Table.Column>
                  {utilFormatDate(updated_at, { formatStr: 'dd MMM yyyy' })}
                </Table.Column>
                <Table.Column>
                  <div className="d-flex justify-space-between column-gap-10">
                    <Button onClick={() => navigate(`/authors/${id}`)} outlined variant="secondary">
                      Details
                    </Button>
                    <Button iconStart={<BinIcon />} onClick={() => warningModalHandler(id)} variant="secondary" />
                  </div>
                </Table.Column>
              </Table.Row>
            ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.Column colSpan={tableStructure.length}>
              <Pagination
                count={totalAuthorsCount}
                fullwidth
                limit={filter.limit}
                limitHandler={(limit) => setFilter({ limit })}
                onChange={(offset) => setFilter({ offset })}
              />
            </Table.Column>
          </Table.Row>
        </Table.Footer>
      </Table>
      {isModalVisible && <Modal className="w-30" open={isModalVisible}>
        <Modal.Title>WARNING</Modal.Title>
        <Modal.Body>
          <div className="d-flex flex-column raw-gap-10">
            <span>You are going to remove the author:</span>
            <h5>{`${deletedAuthor?.first_name} ${deletedAuthor?.last_name}` }</h5>
            <span>It will be deleted forever. Are you sure?</span>
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            onClick={deleteAuthorCancel}
            outlined
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            iconStart={<BinIcon />}
            onClick={deleteAuthor}
            variant="danger"
          >
            Yes, delete
          </Button>
        </Modal.Actions>
      </Modal>}
    </>
  );
};

export { AuthorsTable };
