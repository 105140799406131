import React, { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ArrowBackIcon } from '../../../assets/images/icons';
import { Button } from '../../../common/ui/button';
import { Loader } from '../../../common/ui/loader';
import { useActions, useAppSelector } from '../../../hooks';
import { PageTitle } from '../../common/partials/PageTitle/PageTitle';
import { OrderItemInfo } from './components';
import { RefundConfirmationModal } from './components/RefundConfirmationModal';
import { OrderInfoData } from './helpers';
import styles from './OrderDetailsPage.module.scss';

const OrderDetailsPage = () => {
  const { changeConfirmModalVisibility, getOrderByIdRequest } = useActions();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { orderReducer, refundOrder } = useAppSelector((state) => state);

  const orderId = Number(params.orderId);
  const orderItemId = searchParams.get('orderItemId');

  const order = useMemo(
    () => orderReducer.orderData?.orderItems?.find((elem) => elem.product_id === orderItemId),
    [orderReducer.orderData],
  );

  const structuredOrderInfo = order
    ? new OrderInfoData(order, orderReducer.orderData?.customer)
    : null;
  const isShowOrderInfo = order && orderReducer.orderData?.customer && !orderReducer.pending;

  useEffect(() => {
    getOrderByIdRequest(Number(orderId));
  }, []);

  return (
    <div className={styles.order_details}>
      <PageTitle className="mb-30" title="Order details" withReturn />
      {isShowOrderInfo && (
        <div className="flex-column raw-gap-20">
          <div className={styles.order_items}>
            <OrderItemInfo detailsVisible orderData={structuredOrderInfo} />
          </div>
          <div className="d-flex column-gap-10 justify-end">
            <Button
              disabled={structuredOrderInfo?.quantity === structuredOrderInfo?.refund_quantity}
              iconStart={<ArrowBackIcon />}
              onClick={() => changeConfirmModalVisibility(true)}
              outlined
              size="small"
              variant="primary"
            >
              Refund
            </Button>
          </div>
        </div>
      )}

      <RefundConfirmationModal
        isModalVisible={order && refundOrder.isConfirmModalVisible}
        isProcessLoading={refundOrder.loading}
        orderData={structuredOrderInfo}
      />

      <Loader className={styles.loader_position} loading={orderReducer.pending}>
        Loading...
      </Loader>
    </div>
  );
};

export { OrderDetailsPage };
