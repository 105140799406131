import axios, { AxiosResponse } from 'axios';
import { call, delay, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import { AuthorDto, GetAuthorsResponse } from '../../common/interfaces/authors-api';
import { AuthorService } from '../../common/services/author-service.api';
import { actions } from '../actions';

type GetAuthorsAction = ReturnType<typeof actions.getAuthors>;
type GetAuthorsSagaType = Generator<StrictEffect, any, AxiosResponse<GetAuthorsResponse>>;

export function* getAuthorsSaga(action: GetAuthorsAction): GetAuthorsSagaType {
  yield delay(500);
  yield put(actions.getAuthorsRequest());

  try {
    const { data } = yield call(AuthorService.getAll, action.payload);
    yield put(actions.getAuthorsResponse(data));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.getAuthorsError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.getAuthorsError({ message: 'Something wrong' }));
    }
  }
}

type CreateAuthorAction = ReturnType<typeof actions.createAuthorRequest>;
type CreateAuthorSagaType = Generator<StrictEffect, any, AxiosResponse<{ author: {
  id: number
} }>>;

export function* createAuthorSaga(action: CreateAuthorAction): CreateAuthorSagaType {
  const { img, ...userData } = action.payload;
  try {
    const { data, status } = yield call(AuthorService.create, userData);
    if (status === 201 && img) {
      // @ts-ignore
      yield call(AuthorService.updateImg, data.author.id, img);
    }
    yield put(actions.changeSuccessModalSTate(true));
    yield put(actions.createAuthorResponse);
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.createAuthorError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.createAuthorError({ message: 'Something wrong' }));
    }
  }
}

type UpdateAuthorAction = ReturnType<typeof actions.updateAuthorRequest>;
type UpdateAuthorSagaType = Generator<StrictEffect, any, AxiosResponse<AuthorDto>>;

export function* updateAuthorSaga(action: UpdateAuthorAction): UpdateAuthorSagaType {
  const { img, ...authorData } = action.payload.data;
  try {
    const { status } = yield call(AuthorService.update, action.payload.id, authorData);
    if (status === 201 && img) {
      // @ts-ignore
      yield call(AuthorService.updateImg, action.payload.id, img);
    }
    yield put(actions.getAuthorByIdRequest(action.payload.id));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.updateAuthorError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.updateAuthorError({ message: 'Something wrong' }));
    }
  }
}

type UpdateAuthorImgAction = ReturnType<typeof actions.updateAuthorImgRequest>;
type UpdateAuthorImgSagaType = Generator<StrictEffect, any, AxiosResponse<{ author: AuthorDto }>>;

export function* updateAuthorImgSaga(action: UpdateAuthorImgAction): UpdateAuthorImgSagaType {
  try {
    const data = yield call(AuthorService.updateImg, action.payload.id, action.payload.img);
    if (data.status === 200) {
      yield put(actions.updateAuthorImgResponse(data.data.author.img));
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.updateAuthorImgError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.updateAuthorImgError({ message: 'Something wrong' }));
    }
  }
}

type GetAuthorByIdAction = ReturnType<typeof actions.getAuthorByIdRequest>;
type GetAuthorByIdSagaType = Generator<StrictEffect, any, AxiosResponse<AuthorDto>>;

export function* getAuthorByIdSaga(action: GetAuthorByIdAction): GetAuthorByIdSagaType {
  try {
    const { data, status } = yield call(AuthorService.getById, action.payload);
    if (status === 200) {
      yield put(actions.getAuthorByIdResponse(data));
    }
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.createAuthorError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.createAuthorError({ message: 'Something wrong' }));
    }
  }
}

type DeleteAuthorByIdAction = ReturnType<typeof actions.getAuthorByIdRequest>;
type DeleteAuthorByIdSagaType = Generator<StrictEffect, any, AxiosResponse<AuthorDto>>;

export function* deleteAuthorByIdSaga(action: DeleteAuthorByIdAction): DeleteAuthorByIdSagaType {
  try {
    yield call(AuthorService.deleteById, action.payload);

    yield put(actions.deleteAuthorByIdResponse(action.payload));
  } catch (err) {
    if (axios.isAxiosError(err)) {
      yield put(actions.deleteAuthorByIdError({ code: err?.response?.status, message: err.message }));
    } else {
      yield put(actions.deleteAuthorByIdError({ message: 'Something wrong' }));
    }
  }
}

export function* authorSagasWatcher() {
  yield takeLatest('author/getAuthors', getAuthorsSaga);
  yield takeLatest('author/createAuthorRequest', createAuthorSaga);
  yield takeLatest('author/updateAuthorImgRequest', updateAuthorImgSaga);
  yield takeLatest('author/getAuthorByIdRequest', getAuthorByIdSaga);
  yield takeLatest('author/updateAuthorRequest', updateAuthorSaga);
  yield takeLatest('author/deleteAuthorByIdRequest', deleteAuthorByIdSaga);
}
