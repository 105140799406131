import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './Divider.module.scss';

interface DividerProps {
  orientation?: 'vertical' | 'horizontal';
  variant?: 'primary' | 'secondary';
  className?: string;
}

const Divider = forwardRef<HTMLHRElement, DividerProps>(
  ({ className = '', orientation = 'horizontal', variant = 'primary' }, ref) => (
    <hr
      ref={ref}
      className={clsx(
        styles.divider,
        styles[`divider__${variant}`],
        styles[`divider__${orientation}`],
        className,
      )}
    />
  ),
);

export { Divider };
