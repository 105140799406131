import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';

import { ArrowDownIcon } from '../../assets/images/icons';
import logo from '../../assets/images/productpicks.svg';
import { Button } from '../../common/ui/button';
import { ConfirmModal } from '../../common/ui/confirm-modal';
import { Navigation } from '../../common/ui/navigation/Navigation';
import { UserInfo } from '../../common/ui/user-info/UserInfo';
import styles from './Layout.module.scss';

export function Layout() {
  const [isHideSidebar, setIsHideSidebar] = useState<number>(
    Number(localStorage.getItem('isSidebarOpen')),
  );

  useEffect(() => {
    localStorage.setItem('isSidebarOpen', String(isHideSidebar));
  }, [isHideSidebar]);

  return (
    <div className={clsx(styles.layout)}>
      <div className={clsx(styles.sidebar, isHideSidebar && styles.sidebar_hided)}>
        <div
          className={`d-flex  align-center ${
            !isHideSidebar ? 'justify-space-between' : 'justify-center'
          }`}
        >
          {!isHideSidebar && (
            <a className={styles.logo_link} href="/">
              <img alt="productpicks logo" src={logo} />
            </a>
          )}
          <Button
            iconStart={
              <ArrowDownIcon
                className={clsx(styles.arrow_icon, isHideSidebar && styles.arrow_icon__rotated)}
              />
            }
            onClick={() => setIsHideSidebar((prevState) => (prevState ? 0 : 1))}
            outlined
            size="small"
            variant="secondary"
          />
        </div>
        <hr />
        <Navigation isHided={!!isHideSidebar} />
        {!isHideSidebar && <UserInfo />}
      </div>
      <div className={styles.content}>
        <ConfirmModal />
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
}
