import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Marketplaces } from '../../../common/constants/enums';
import { GetBrandAttributionPageResponse } from '../../sagas/compare-product-table';

type TError = { message: string; code?: number };

type TGetCompareProductTableInitialState = {
  loading: boolean;
  error: TError;
  isRequestComplete: boolean;
  data: GetBrandAttributionPageResponse;
};

const initialState: TGetCompareProductTableInitialState = {
  data: null,
  error: null,
  isRequestComplete: false,
  loading: false,
};

const getCompareProductTableSlice = createSlice({
  initialState,
  name: 'getCompareProductTable',
  reducers: {
    getCompareProductTableRequest(
      ...args: [
        TGetCompareProductTableInitialState,
        PayloadAction<{
          data: {
            slug: string;
            brand?: string;
            marketplace: Marketplaces;
            publisher?: string
          };
          domain: string;
        }>,
      ]
    ): TGetCompareProductTableInitialState {
      const [state] = args;
      return {
        ...state,
        error: null,
        ...(state.isRequestComplete ? { isRequestComplete: false } : {}),
      };
    },
    getCompareProductTableResponse(state, action: PayloadAction<GetBrandAttributionPageResponse>) {
      state.isRequestComplete = true;
      state.data = action.payload;
    },
    getCompareProductTabletError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    setGetCompareProductTableLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = getCompareProductTableSlice;
