import clsx from 'clsx';

import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '../../../assets/images/icons';
import styles from './Notification.module.scss';

interface NotificationProps {
  type?: 'error' | 'warn' | 'info' | 'success';
  message?: string;
  withoutIcon?: boolean;
  className?: string;
  fullWidth?: boolean;
}

const Notification = ({
  className = '',
  fullWidth,
  message,
  type = 'info',
  withoutIcon = false,
}: NotificationProps) => (
  <div
    className={clsx(
      styles.notification,
      styles[`notification__${type}`],
      className,
      fullWidth && styles.notification__fullwith,
    )}
  >
    {!withoutIcon && (
      <div className={styles.icon}>
        {type === 'error' && <ErrorIcon />}
        {type === 'warn' && <WarningIcon />}
        {type === 'info' && <InfoIcon />}
        {type === 'success' && <SuccessIcon />}
      </div>
    )}
    <div className={styles.message}>{message}</div>
  </div>
);

export { Notification };
