import { ChangeEvent, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ArrowDownIcon, BinIcon } from '../../../../../../assets/images/icons';
import { Button } from '../../../../../../common/ui/button';
import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { utilSwapElements } from '../../../../../../common/utils';
import { CompareTableForm, StandardAttributeNames } from '../../edit-compare-table-form';
import styles from './product-attribute.module.scss';

interface Props {
  productsIndex: number;
  attributeIndex: number;
}

const ProductAttribute = ({ attributeIndex, productsIndex }: Props) => {
  const { control, getValues, setValue } = useFormContext<CompareTableForm>();
  const attributeName = useController({
    control,
    name: `products.${productsIndex}.attributes.${attributeIndex}.name`,
  });
  const attributeValue = useController({
    control,
    name: `products.${productsIndex}.attributes.${attributeIndex}.value`,
  });
  const attributeScore = useController({
    control,
    name: `products.${productsIndex}.attributes.${attributeIndex}.score`,
  });

  const [isPanelOpened, setIsPanelOpened] = useState(false);

  const products = getValues('products');

  const isStandardAttribute = Object.values(StandardAttributeNames).includes(
    attributeName.field.value as StandardAttributeNames,
  );

  const isNameFieldDisabled = productsIndex !== 0 || isStandardAttribute;

  const handleNameChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    products.forEach((product, index) => {
      const attr = getValues(`products.${index}.attributes.${attributeIndex}`);

      if (attr.status !== 'new' && !attr?.previousName) {
        setValue(`products.${index}.attributes.${attributeIndex}.previousName`, attr.name);
      }

      setValue(`products.${index}.attributes.${attributeIndex}.name`, e.target.value);
    });
  };

  const onDeleteClick = () => {
    products.forEach((product, index) => {
      if (product.attributes[attributeIndex].status === 'new') {
        setValue(
          `products.${index}.attributes`,
          products[index].attributes.filter((_, i) => i !== attributeIndex),
          { shouldDirty: false, shouldTouch: false },
        );
      } else {
        setValue(
          `products.${index}.attributes`,
          products[index].attributes.map((attr, attrIndex) => {
            if (attrIndex === attributeIndex) {
              return { ...attr, status: 'deleted' as const };
            }
            return attr;
          }),
          { shouldDirty: true },
        );
      }
    });
  };

  function onSwapClick(from: number, to: number) {
    products.forEach((product, index) => {
      setValue(`products.${index}.attributes`, utilSwapElements(product.attributes, from, to));
    });
  }

  return (
    <div
      className={styles.attribute}
      onMouseLeave={() => setIsPanelOpened(false)}
      onMouseMove={() => setIsPanelOpened(true)}
    >
      {productsIndex === 0 && isPanelOpened && (
        <div className={styles.panel_wrapper}>
          <Container className={styles.action_panel} withoutBorder withShadow>
            <Button
              className={styles.button}
              disabled={attributeIndex === 0}
              iconStart={<ArrowDownIcon className={styles.arrow_up} />}
              onClick={() => onSwapClick(attributeIndex, attributeIndex - 1)}
              size="small"
              variant="secondary"
            />
            <Button
              className={styles.button}
              iconStart={<BinIcon />}
              onClick={onDeleteClick}
              size="small"
              variant="secondary"
            />
            <Button
              className={styles.button}
              disabled={attributeIndex === products[productsIndex].attributes.length - 1}
              iconStart={<ArrowDownIcon />}
              onClick={() => onSwapClick(attributeIndex, attributeIndex + 1)}
              size="small"
              variant="secondary"
            />
          </Container>
        </div>
      )}
      <FormItem label="Name" message={attributeName.fieldState?.error?.message}>
        <TextField
          {...attributeName.field}
          disabled={isNameFieldDisabled}
          invalid={attributeName.fieldState.invalid}
          onChange={handleNameChange}
        />
      </FormItem>
      <div className={styles.bottom_line}>
        <FormItem
          className={styles.value}
          label="Value"
          message={attributeValue.fieldState?.error?.message}
        >
          <TextField
            disabled={attributeName.field.value === StandardAttributeNames.Price}
            fullWidth
            invalid={attributeValue.fieldState.invalid}
            {...attributeValue.field}
          />
        </FormItem>
        {!isStandardAttribute && (
          <FormItem
            className={styles.score}
            label="Score"
            message={attributeScore.fieldState?.error?.message}
          >
            <TextField
              fullWidth
              invalid={attributeScore.fieldState.invalid}
              {...attributeScore.field}
            />
          </FormItem>
        )}
      </div>
    </div>
  );
};

export { ProductAttribute };
