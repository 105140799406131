import clsx from 'clsx';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { lengthRestrictions } from '../../../../../../common/constants/length-restrictions';
import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import styles from './faq-Item.module.scss';

type Props = {
  onDeleteButton?: () => void;
  namesPrefix?: string;
  faqItemIdx: number;
};

const FaqItem = ({
  onDeleteButton = () => {},
  namesPrefix = '',
  faqItemIdx,
}: Props) => {
  const { control } = useFormContext();

  const questionField = useController({
    control,
    name: `${namesPrefix}faq.${faqItemIdx}.question` as any,
  });
  const answerField = useController({
    control,
    name: `${namesPrefix}faq.${faqItemIdx}.answer` as any,
  });

  return (
    <Container
      className={clsx(styles.faq__item)}
      onDeleteButton={onDeleteButton}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <FormItem label="Question" message={questionField.fieldState.error?.message}>
        <TextField
          {...questionField.field}
          invalid={questionField.fieldState.invalid}
          limit={lengthRestrictions.product.content.faq.question}
          placeholder="Enter a question"
          rows={2}
        />
      </FormItem>
      <FormItem label="Answer" message={answerField.fieldState.error?.message}>
        <TextField
          {...answerField.field}
          invalid={answerField.fieldState.invalid}
          limit={lengthRestrictions.product.content.faq.answer}
          placeholder="Enter an answer"
          rows={4}
        />
      </FormItem>
    </Container>
  );
};

export { FaqItem };
