import { FormMode, Marketplaces } from 'common/constants/enums';
import {
  IPageAsset,
  PageBannerAsset,
  PagePublisherImage,
  Product,
} from 'common/interfaces/agg-page';
import { getProductsApiClient } from 'common/services/products-api.service';
import { AggPageFormContext, IProductContent } from 'context/page-form-context';
import { PagesContext } from 'context/pages-context';
import { PropsWithChildren, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getAggPageApiClient } from '../../../common/services/agg-pages-api.service';
import { getProductsManagementApiClient } from '../../../common/services/products-management-api.service';
import { isAmazonProduct, isWalmartProduct } from '../../../common/type-guards';
import { utilProductDataPreparing } from '../../../common/utils';

export const UpdateWrapper = ({ children }: PropsWithChildren) => {
  const [params] = useSearchParams();
  const { brand, publisher: publisherName, slug } = useParams();
  const marketplace = params.get('marketplace') as Marketplaces;
  const domain = params.get('domain');

  const formMode = params.get('mode') as FormMode;

  const { changeBrand, changePage, changePageLoading, changePublisher } = useContext(PagesContext);

  const {
    addBannerImage,
    addContentCopies,
    addProduct,
    addPublisherImages,
    changeBuyingGuide,
    changeDomainId,
    changeKeyword,
    changeMarketplace,
    changePageAuthor,
    changePageAuthorId,
    changePageId,
    changeProductsContent,
    changeSlug,
    changeSubtitle,
    setProductsAssets,
    setProductsReviews,
    updateDrafts,
  } = useContext(AggPageFormContext);

  const fetchPageData = async () => {
    changePageLoading(true);
    try {
      const data: { brand?: string; slug: string } = {
        slug,
      };

      if (brand && brand !== 'walmart') {
        data.brand = brand.replace(/-/g, ' ');
      }

      const response = await getProductsApiClient(publisherName).getBrandAttributionPage(
        {
          ...data,
          marketplace,
        },
        domain,
      );

      const { additionalProducts, mainProduct, page, pageBrand } = response.data;

      const pageAuthor = page.author;
      changePageAuthor(pageAuthor);
      changePageAuthorId(pageAuthor?.id);

      let productIdFormat: 'w_item_id' | 'asin';
      if (page.products_marketplace === Marketplaces.Walmart) {
        productIdFormat = 'w_item_id';
      }
      if (!page.products_marketplace || page.products_marketplace === Marketplaces.Amazon) {
        productIdFormat = 'asin';
      }

      if (page.products_marketplace) {
        changeMarketplace(page.products_marketplace);
      }

      const products: Product[] = [mainProduct, ...additionalProducts].filter((product) =>
        Object.hasOwn(product, productIdFormat),);

      if (formMode !== FormMode.DUPLICATE) {
        changePageId(page.id);
        changeKeyword(page.title.trim());
        changeSubtitle(page.subtitle);
        changeSlug(page.slug);
      }

      changePage(page);

      if (page?.domain_id) {
        changeDomainId(page.domain_id);
      }

      changeBrand(pageBrand);

      if (publisherName && formMode !== FormMode.DUPLICATE) {
        const res = await getProductsApiClient().getPublishersList(publisherName);
        // eslint-disable-next-line no-unsafe-optional-chaining
        const [publisher] = res.data?.publishers;

        changePublisher(publisher);
      }

      products.forEach((p) => {
        if (isWalmartProduct(p)) {
          p.product_id = p.w_item_id;
        }

        if (isAmazonProduct(p)) {
          p.product_id = p.asin;
        }

        addProduct(p);
      });

      products.forEach((product) => {
        const { assets, reviews } = utilProductDataPreparing(product);

        setProductsReviews('add', reviews);
        setProductsAssets((prevState) => [
          ...prevState,
          { assets, product_id: product.product_id },
        ]);
      });

      const contentCopiesResponse = await getProductsManagementApiClient().getProductContentCopies(
        products.map((p) => p.product_id),
      );

      const contentCopies = contentCopiesResponse.data.products.reduce((result, elem) => {
        changeProductsContent({
          _id: uuidv4(),
          product_id: elem.asin,
          type: 'origin',
          ...({
            ...elem.content,
            faq: elem.content?.faq || [],
            pros: elem.content?.pros?.filter((p) => p.trim().length > 0),
          } || {}),
          tied: !products.find((p) => p.id === elem.id).picked_product_copy_id,
        });
        // @ts-ignore
        const copies: IProductContent[] = [];
        elem.product_copies.forEach((copy) => {
          if (copy.content) {
            copies.push({
              _id: uuidv4(),
              copy_id: copy.copy_id,
              product_id: elem.asin,
              tied: !!products.find((elem) => elem.picked_product_copy_id === copy.copy_id),
              ...{
                ...copy.content.content,
                faq: copy.content.content?.faq || [],
                pros: copy.content.content?.pros?.filter((p) => p.trim().length > 0) || [],
              },
            });
          }
        });

        result = [...result, ...copies];

        return result;
      }, []);

      addContentCopies(contentCopies);

      const pagePublisherImagesAssets: PagePublisherImage[] = [];

      page.publisherImages?.forEach((item: IPageAsset) => {
        pagePublisherImagesAssets.push({
          assets_type: 'publisherImages',
          file_data: { order: item?.order },
          url: item.img,
        });
      });

      pagePublisherImagesAssets.sort((a, b) => a.file_data.order - b.file_data.order);

      addPublisherImages(pagePublisherImagesAssets);

      const pageBannerAssets: PageBannerAsset[] = [];

      page.assets?.forEach((item: IPageAsset) => {
        if (item.type === 'banner') {
          pageBannerAssets.push({
            assets_type: 'assets',
            file_data: { order: item?.order, type: 'banner' },
            url: item.img,
          });
        }
      });

      pageBannerAssets.sort((a, b) => a.file_data.order - b.file_data.order);

      addBannerImage(pageBannerAssets);

      if (page?.buyingGuide) {
        changeBuyingGuide({
          img: page.buyingGuide?.img || null,
          text: page.buyingGuide?.text || null,
          url: page.buyingGuide?.img || null,
        });
      }

      const draftsResponse = await getAggPageApiClient().getPageDrafts(page.id);
      if (draftsResponse.status === 200) {
        updateDrafts(
          draftsResponse.data.drafts.map((elem) => ({
            ...elem,
            in_draft: Boolean(elem.in_draft),
          })),
        );
      }
    } catch (e: any) {
      let errMessage = '';
      if (e?.response?.status === 404) {
        errMessage = 'Page not found or hidden';
      } else {
        errMessage = e?.message;
      }

      toast.error(errMessage);
    } finally {
      changePageLoading(false);
    }
  };

  useEffect(() => {
    fetchPageData();
  }, []);

  return <div>{children}</div>;
};
