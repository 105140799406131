import IMask from 'imask';

const masked = IMask.createMask(
  {
    blocks: {
      num: {
        mapToRadix: ['.'],
        mask: Number,
        padFractionalZeros: true,
        radix: ',',
        scale: 2,
        thousandsSeparator: '.',
      },
    },
    lazy: false,
    mask: '$num',
  },
);

export const utilFormatMoney = (value?: number | string): string => {
  if (value === undefined) return '';
  if (Number.isNaN(Number(value))) return String(value);

  return `${masked.resolve(`${value}`, { input: true, raw: true })}`;
};
