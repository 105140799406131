import clsx from 'clsx';

import { Loader } from '../../../../common/ui/loader';
import styles from './product-category-suggestion.module.scss';

interface Props {
  onClick?: () => void;
  className?: string;
  error: string;
  suggestion: string;
  loading?: boolean;
}

const ProductCategorySuggestion = ({
  className,
  onClick = () => {},
  suggestion,
  error,
  loading,
}: Props) => (
  <div className={clsx(styles.suggestion, className)} onClick={() => onClick()}>
    {loading ? (
      <Loader size="small" />
    ) : (
      <span className={styles.suggestion_name}>{`Predicted category: ${suggestion}`}</span>
    )}
    {!loading && error && <span className={styles.suggestion_error}>{error}</span>}
  </div>
);

export { ProductCategorySuggestion };
