import { AxiosResponse } from 'axios';

import { AuthorDto, GetAllAuthorsOptions, GetAuthorsResponse } from '../interfaces/authors-api';
import { ApiService } from './api.service';

class AuthorServiceApi extends ApiService {
  getAll = (options: GetAllAuthorsOptions): Promise<AxiosResponse<GetAuthorsResponse>> =>
    this.get('/pages/authors/list', { params: options });

  create = (
    data: Omit<AuthorDto, 'img' | 'created_at' | 'updated_at' | 'id'>,
  ): Promise<AxiosResponse<{ author: { id: number } }>> => this.post('/admin/authors', data);

  updateImg = (id: number, img: File): Promise<AxiosResponse<{ author: AuthorDto }>> =>
    this.post(
      `/admin/authors/${id}/avatar`,
      { img },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

  getById = (id: number): Promise<AxiosResponse<AuthorDto>> =>
    this.get(`/pages/authors/${id}`);

  update = (
    id: number,
    data: Omit<AuthorDto, 'img' | 'created_at' | 'updated_at' | 'id'>,
  ): Promise<AxiosResponse<AuthorDto>> => this.put(`/admin/authors/${id}`, data);

  deleteById = (id: number): Promise<AxiosResponse> => this.delete(`/admin/authors/${id}`);
}

export const AuthorService = new AuthorServiceApi(process.env.REACT_APP_API_HOST);
