import { AggPageState } from 'context/pages-context';
import { CreatePage } from 'modules/pages/pages/create-page/create-page';
import { SuccessPage } from 'modules/pages/pages/success-page/success-page';
import { UpdatePage } from 'modules/pages/pages/update-page/update-page';
import { Route, Routes } from 'react-router-dom';

import { AuthorDetailsPage, AuthorsPage, CreateAuthorPage } from '../modules/authors';
import BrandsPage from '../modules/brands/BrandsPage';
import { DashboardPage } from '../modules/dashboard/DashboardPage';
import { DealsPage } from '../modules/deals';
import { ErrorPage } from '../modules/error-page';
import ExpertsPage from '../modules/experts/ExpertsPage';
import { OrderDetailsPage, OrdersPage } from '../modules/orders';
import { Pages } from '../modules/pages/pages';
import { EditCompareTablePage } from '../modules/pages/pages/edit-compare-table-page';
import { ProductImportPage } from '../modules/product-import';
import ProductsPage from '../modules/products/ProductsPage';
import { TemporaryLinksPage } from '../modules/temporary-links/pages';
import { VWContentPage } from '../modules/video-widget-content';
import { Layout } from '../root/layout/Layout';

export const useRoutes = () => (
  <Routes>
    <Route element={<Layout />} errorElement={<ErrorPage />}>
      <Route path="/">
        <Route element={<DashboardPage />} index />
      </Route>
      <Route path="/brands">
        <Route element={<BrandsPage />} index />
      </Route>
      <Route path="/orders">
        <Route element={<OrdersPage />} index />
        <Route element={<OrderDetailsPage />} path=":orderId" />
      </Route>
      <Route path="/authors">
        <Route element={<AuthorsPage />} index />
        <Route element={<AuthorDetailsPage />} path=":authorId" />
        <Route element={<CreateAuthorPage />} path="create" />
      </Route>
      <Route path="/experts">
        <Route element={<ExpertsPage />} index />
      </Route>
      <Route path="/pages">
        <Route
          element={
            <AggPageState>
              <Pages />
            </AggPageState>
          }
          index
        />
        <Route element={<CreatePage />} path="new" />
        <Route element={<UpdatePage />} path=":slug" />
        <Route element={<UpdatePage />} path=":brand/:slug" />
        <Route element={<UpdatePage />} path=":publisher/:brand/:slug" />
        <Route element={<SuccessPage />} path="success" />
        <Route element={<EditCompareTablePage />} path="edit-compare-table/:slug" />
        <Route element={<EditCompareTablePage />} path="edit-compare-table/:brand/:slug" />
        <Route element={<EditCompareTablePage />} path="edit-compare-table/:publisher/:brand/:slug" />
      </Route>
      <Route path="/products">
        <Route element={<ProductsPage />} index />
      </Route>
      <Route path="/video-widget-editor">
        <Route element={<VWContentPage />} index />
      </Route>
      <Route path="/product-import">
        <Route element={<ProductImportPage />} index />
      </Route>
      <Route path="/temporary-links">
        <Route element={<TemporaryLinksPage />} index />
      </Route>
      <Route path="/daily-deals">
        <Route element={<DealsPage />} index />
      </Route>
    </Route>
    <Route element={<ErrorPage />} path="*" />
  </Routes>
);
