import { PageTitle } from '../../../common/partials/PageTitle/PageTitle';
import { EditCompareTableForm } from '../../components/edit-compare-table-form';
import styles from './edit-compare-table-page.module.scss';

const EditCompareTablePage = () => (
  <div className={styles.container}>
    <div className={styles.title_wrapper}>
      <PageTitle title="Compare products" />
    </div>
    <EditCompareTableForm />
  </div>
);

export { EditCompareTablePage };
