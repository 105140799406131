import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentType, RefundOrderItemData } from '../../../common/interfaces/order-api';

type TError = { message: string; code?: number };

type TRefundOrderState = {
  error: TError;
  isConfirmModalVisible: boolean
  loading: boolean;
};

const initialState: TRefundOrderState = {
  error: null,
  isConfirmModalVisible: false,
  loading: false,
};

export const refundOrderSlice = createSlice({
  initialState,
  name: 'refundOrder',
  reducers: {
    changeConfirmModalVisibility(state, action: PayloadAction<boolean>) {
      state.isConfirmModalVisible = action.payload;
    },
    changeRefundOrderLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    refundOrderError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    refundOrderProcessError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    refundOrderProcessRequest(
      ...args: [
        TRefundOrderState,
        PayloadAction<{ payment: PaymentType; data: RefundOrderItemData }>,
      ]
    ) {
      const [state] = args;
      return state;
    },
    refundOrderRequest(
      ...args: [
        TRefundOrderState,
        PayloadAction<{ payment: PaymentType; data: RefundOrderItemData }>,
      ]
    ) {
      const [state] = args;
      state.error = null;
    },
    refundOrderResponse(state) {
      return state;
    },
  },
});

export const { actions, reducer } = refundOrderSlice;
