import clsx from 'clsx';
import { useContext } from 'react';

import { PageBannerAsset } from '../../../../../../common/interfaces/agg-page';
import { isVideoAsset } from '../../../../../../common/type-guards';
import { Image } from '../../../../../../common/ui/image';
import { AggPageFormContext } from '../../../../../../context/page-form-context';
import styles from './gallery.module.scss';

interface Props {
  bannerImages?: PageBannerAsset[];
  className?: string;
}

const Gallery = ({ bannerImages = [], className }: Props) => {
  const { productsAssets } = useContext(AggPageFormContext);

  const videoAsset = productsAssets[0]?.assets?.filter((elem) => isVideoAsset(elem))[0];

  return (
    <div className={clsx(styles.banner, className)}>
      <div className={styles.content_wrapper}>
        <div className={styles.images_col}>
          <div className={styles.img_1}>
            <Image
              alt="page_gallary_04"
              className={styles.image}
              src={bannerImages[3]?.url || bannerImages[3]?.preview}
            />
          </div>
          <div className={styles.img_1}>
            <Image
              alt="page_gallary_03"
              className={styles.image}
              src={bannerImages[2]?.url || bannerImages[2]?.preview}
            />
          </div>
        </div>

        <div className={styles.image_small}>
          <div className={styles.img_2}>
            <Image
              alt="page_gallary_02"
              className={styles.image}
              src={bannerImages[1]?.url || bannerImages[1]?.preview}
            />
          </div>
        </div>

        {!!videoAsset && (
          <div className={styles.banner_video_container}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video className={styles.banner_video} src={videoAsset?.url} />
          </div>
        )}

        <div className={styles.image_large}>
          <div className={styles.img_3}>
            <Image
              alt="page_gallary_01"
              className={styles.image}
              src={bannerImages[0]?.url || bannerImages[0]?.preview}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Gallery };
