import { AxiosResponse } from 'axios';
import __ from 'lodash';

import { BreadcrumbsListResponse } from '../interfaces/breadcrumbs';
import { ApiService } from './api.service';

class BreadcrumbsServiceApi extends ApiService {
  getList = (params: {
    limit?: number;
    offset?: number;
    name?: string;
  }): Promise<AxiosResponse<BreadcrumbsListResponse>> =>
    this.get('/breadcrumbs/list', {
      params: __.pickBy(
        params,
        (value) => !__.isNull(value) && !__.isUndefined(value) && String(value).length > 0,
      ),
    });

  getSuggestion = (
    productsIds: number[],
  ): Promise<AxiosResponse<{ suggested: { code: string; name: string } }>> =>
    this.get('/breadcrumbs/suggestion', { params: { product_id: productsIds } });
}

export const BreadcrumbsService = new BreadcrumbsServiceApi(process.env.REACT_APP_API_HOST);
