import { AggPageComponent, FormMode } from 'common/constants/enums';
import { IBrand, PagesContext } from 'context/pages-context';
import { useContext, useEffect, useState } from 'react';
import { OnChangeValue } from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { IFetchBestPagesOptions } from '../../../../common/interfaces/pages-api';
import { FormItem } from '../../../../common/ui/formItem';
import { Select } from '../../../../common/ui/select';
import { TSelectOption } from '../../../../common/ui/select-old';
import { TSetFilter, useActions, useAppSelector } from '../../../../hooks';
import { BrandOption, BrandOptionType } from './brand-option';

interface IBrandInputProps {
  formMode: string;
  brandName?: string;
  updateFilter?: TSetFilter<Omit<IFetchBestPagesOptions, 'attribution'>>;
  label?: string;
  placeholder?: string;
  className?: string;
}

export const BrandInput = ({
  brandName,
  className = '',
  formMode,
  label,
  placeholder,
  updateFilter,
}: IBrandInputProps) => {
  const { brandList } = useAppSelector(({ pages }) => pages);
  const { fetchBrandListRequest } = useActions();
  const {
    brand,
    changeBrand,
    error,
  } = useContext(PagesContext);
  const [brandOptions, setBrandOptions] = useState<BrandOptionType[]>([]);
  const [inputSearchValue, setInputSearchValue] = useState<string>('');

  const disabled = formMode === FormMode.UPDATE;
  const value = brandOptions.find(
    (elem) => elem.value === brandName || elem.value === brand?.name,
  );

  const clickHandler = (brand: IBrand) => {
    changeBrand(brand);
    if (updateFilter) updateFilter({ brand: brand?.name });
  };

  const onChangeHandler = (option: OnChangeValue<TSelectOption, false>) => {
    if (option) {
      clickHandler(brandList.brands.find((elem) => elem.name === option.value) || { name: option.value } as IBrand);
    } else {
      clickHandler(null);
    }
  };

  // useEffect(() => {
  //   const groupedOptions: BrandOptionType[] = brandList.brands.reduce((acc, brand) => {
  //     if (brand.external_brands.length > 0) {
  //       acc.push({
  //         label: brand.name,
  //         options: [
  //           {
  //             img: brand.img_logo || brand.img_banner,
  //             label: brand.name,
  //             value: brand.name,
  //           },
  //
  //           ...brand.external_brands.map((extBrand) => ({
  //             brand_group: extBrand.brand_group,
  //             img: '',
  //             label: `${extBrand.name}`,
  //             value: extBrand.name,
  //           })),
  //         ],
  //       });
  //     } else {
  //       acc.push({
  //         img: brand.img_logo || brand.img_banner,
  //         label: brand.name,
  //         value: brand.name,
  //       });
  //     }
  //     return acc;
  //   }, []);
  //   setBrandOptions(groupedOptions);
  // }, [brandList.brands]);

  useEffect(() => {
    const options = brandList.brands.map((brand) => ({
      ...brand,
      external_brands: brand.external_brands.map((externalBrand) => ({
        ...externalBrand,
        label: externalBrand.name,
        value: externalBrand.name,
      })),
      label: brand.name,
      value: brand.name,
    }));
    setBrandOptions(options);
  }, [brandList.brands]);

  useEffect(() => {
    if (brand?.name) {
      fetchBrandListRequest(brand?.name);
    } else {
      fetchBrandListRequest(brandName || inputSearchValue);
    }
  }, [inputSearchValue, brand, brandName]);

  const filterOption = (option: FilterOptionOption<BrandOptionType>, inputSearch: string): boolean =>
    option.data.value.toLowerCase()
      .startsWith(inputSearch.toLowerCase())
    || option.data.external_brands.some((elem) => elem.name.toLowerCase()
      .startsWith(inputSearch.toLowerCase()));

  return (
    <FormItem className={className} label={label} message={error[AggPageComponent.Brand]}>
      <Select<BrandOptionType, false>
        components={{ Option: BrandOption }}
        filterOption={filterOption}
        inputValue={inputSearchValue}
        isClearable
        isDisabled={disabled}
        isLoading={brandList.loading}
        isSearchable
        onChange={onChangeHandler}
        onInputChange={setInputSearchValue}
        options={brandOptions}
        placeholder={placeholder}
        value={value}
      />
    </FormItem>
  );
};
