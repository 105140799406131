import { ApiService } from './api.service';

export interface CreateTemporaryLinkBody {
  expired_at: string;
  publisher_id?: number;
  product_links: Array<{ asin: string; link: string }>;
}

export interface GetPublisherTemporaryLinksQuery {
  limit: number;
  offset: number;
  search?: string;
}

class TemporaryLinkServiceApi extends ApiService {
  createLink = (data: CreateTemporaryLinkBody) =>
    this.post('/admin/products/temporary-links', data);

  getPublisherLinks = (publisherId: number, query: GetPublisherTemporaryLinksQuery) =>
    this.get(`/admin/products/temporary-links/${publisherId}`, {
      params: query,
    });

  deleteTemporaryLink = (link: string, publisherId: number) =>
    this.delete(`/admin/products/temporary-links/${publisherId}`, { link });

  downloadCsvFile = () => this.get('/admin/download/temporary-links');
}

export const TemporaryLinkService = new TemporaryLinkServiceApi(process.env.REACT_APP_API_HOST);
