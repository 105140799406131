import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import {
  DealIcon,
  ImportIcon, LinkIcon,
  MenuAuthorsIcon,
  MenuOrdersIcon,
  MenuPagesIcon,
  VideoIcon
} from '../../../assets/images/icons';
import { Tooltip } from '../tooltip';
import styles from './Navigation.module.scss';

interface INavigationItem {
  title: string;
  to: string;
  icon: ReactElement;
}

const navigationItems: INavigationItem[] = [
  // { icon: <MenuHomeIcon />, title: 'Dashboard', to: '/' },
  { icon: <MenuPagesIcon />, title: 'Pages', to: '/pages' },
  // { icon: <MenuBrandsIcon />, title: 'Brands', to: '/brands' },
  // { icon: <MenuProductsIcon />, title: 'Products', to: '/products' },
  { icon: <MenuOrdersIcon />, title: 'Orders', to: '/orders' },
  // { icon: <MenuExpertsIcon />, title: 'Experts', to: '/experts' },
  { icon: <MenuAuthorsIcon />, title: 'Authors', to: '/authors' },
  { icon: <VideoIcon />, title: 'VW Editor', to: '/video-widget-editor' },
  { icon: <ImportIcon />, title: 'Import', to: '/product-import' },
  { icon: <LinkIcon />, title: 'Hardcode tags', to: '/temporary-links' },
  { icon: <DealIcon />, title: 'Daily Deals', to: '/daily-deals' },
];

interface NavigationItemProps extends NavLinkProps {
  icon: ReactNode;
  isHided: boolean;
  title: string;
}

const NavigationItem = ({ icon, isHided, title, to }: NavigationItemProps) => {
  const getClassName = ({ isActive }: { isActive: boolean; isPending: boolean }) =>
    clsx(styles.nav_item, isActive && styles.nav_item__active, isHided && styles.nav_item_hided);
  return (
    <NavLink className={(props) => getClassName(props)} end to={to}>
      {icon}
      <span>{title}</span>
    </NavLink>
  );
};

export function Navigation({ isHided }: { isHided: boolean }) {
  return (
    <nav className={styles.nav}>
      {navigationItems.map(({ icon, title, to }) => (
        <Tooltip key={to} placement="right" title={isHided ? title : ''}>
          <NavigationItem icon={icon} isHided={isHided} title={title} to={to} />
        </Tooltip>
      ))}
    </nav>
  );
}
