import { PagePublisherImage } from '../../../../../common/interfaces/agg-page';
import { Container } from '../../../../../common/ui/container';
import { Image } from '../../../../../common/ui/image';
import styles from './pablisher-image.module.scss';

interface Props<T extends boolean> {
  item: PagePublisherImage;
  assetIdx: number;
  onDeleteButton?: T extends true ? () => void : never;
  withDeleteButton?: T;
  checked?: boolean;
}

const PublisherImage = <T extends boolean = false>({
  assetIdx,
  checked = false,
  item,
  onDeleteButton,
  withDeleteButton,
}: Props<T>) => {
  const src = item?.url || item?.preview;

  return (
    <Container
      checked={checked}
      className={styles.container}
      onDeleteButton={onDeleteButton}
      withDeleteButton={withDeleteButton}
      withoutBorder
      withShadow
    >
      <Image
        alt={`publisher_image_${assetIdx + 1}`}
        className={styles.image_wrapper}
        src={src}
      />
    </Container>
  );
};

export { PublisherImage };
