import { MouseEvent } from 'react';
import { createPortal } from 'react-dom';

import { useActions, useAppSelector } from '../../../hooks';
import { Button } from '../button';
import { Modal } from '../modal';
import styles from './confirm-modal.module.scss';

const ConfirmModal = () => {
  const {
    cancelAction,
    description,
    isOpen,
    successAction,
    title,
  } = useAppSelector(
    ({ common }) => common.confirmModal,
  );
  const { closeConfirmModal } = useActions();

  const onSuccess = (e: MouseEvent) => {
    e.stopPropagation();
    if (successAction) successAction();
    closeConfirmModal();
  };

  const onCancel = (e: MouseEvent) => {
    e.stopPropagation();
    if (successAction) cancelAction();
    closeConfirmModal();
  };

  if (!isOpen) return null;

  return createPortal(
    <Modal className={styles.confirm_modal} open>
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body className={styles.body}>{description}</Modal.Body>
      <Modal.Actions className={styles.actions}>
        <Button onClick={onCancel} outlined size="small" variant="danger">
          Cancel
        </Button>
        <Button onClick={onSuccess} size="small">
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>,
    document.body,
  );
};

export { ConfirmModal };
