import styles from './ProductLabel.module.scss';

interface IProductLabelProps {
  asin: string;
  title: string;
  imgUrl: string;
}

const ProductLabel = ({ asin, imgUrl, title }: IProductLabelProps) => (
  <div className={styles.product_label}>
    <div className={styles.image_wrapper}>
      <img alt="product_icon" src={imgUrl} />
    </div>
    <div className={styles.title_wrapper}>
      <span className={styles.title}>
        {title}
      </span>
      <span className={styles.subtitle}>{asin}</span>
    </div>
  </div>
);

export { ProductLabel };
