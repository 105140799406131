import clsx from 'clsx';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ChangeOrderStatusData, TOrderStatus } from '../../../../../common/interfaces/order-api';
import { Notification } from '../../../../../common/ui/notification';
import { Table } from '../../../../../common/ui/table';
import { utilFormatDate, utilFormatMoney } from '../../../../../common/utils';
import { useActions } from '../../../../../hooks';
import { OrderStatus, ProductLabel } from '../../../components';
import styles from './OrderItemInfo.module.scss';

interface IOrderItemInfoProps {
  detailsVisible: boolean;
  orderData: {
    address: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    phone: string;
    productId: string;
    imgMedium: string;
    imgSmall: string;
    title: string;
    subtitle: string;
    orderId: number;
    status: TOrderStatus;
    price: number;
    payment: string;
    quantity: number;
    refund_quantity: number;
    email: string
  };
}

const OrderItemInfo = ({ detailsVisible, orderData }: IOrderItemInfoProps) => {
  const { changeOrdersStatusFromDetails } = useActions();
  const [searchParams] = useSearchParams();
  const [isVisible] = useState(detailsVisible);
  const orderItemId = searchParams.get('orderItemId');

  const {
    address,
    createdAt,
    email,
    firstName,
    imgMedium,
    imgSmall,
    lastName,
    orderId,
    payment,
    phone,
    price,
    productId,
    quantity,
    refund_quantity,
    status,
    subtitle,
    title,
  } = orderData;

  const changeOrderStatus = (status: TOrderStatus) => {
    const data: ChangeOrderStatusData = {
      id: orderId,
      order_items: [{ product_id: orderItemId, status }],
    };
    changeOrdersStatusFromDetails(data);
  };

  const isOrderStatusDisabled: boolean = !['purchased', 'shipped to customer', 'new order'].includes(status);

  return (
    <div className={styles.order_item_details}>
      <div className={styles.item_top}>
        <ProductLabel asin={productId} imgUrl={imgSmall || imgMedium} title={title} />
        <OrderStatus
          disabled={isOrderStatusDisabled}
          status={status}
          statusHandler={(status) => changeOrderStatus(status)}
        />
      </div>
      <div className="flex-column raw-gap-10">
        {!!refund_quantity && (
          <Notification
            message={`Refund initiated for ${refund_quantity}/${quantity} pieces. Total refund amount - 
            ${utilFormatMoney(refund_quantity * price)} `}
            type="info"
          />
        )}
      </div>
      {isVisible && (
        <div className={styles.item_main}>
          <div className={styles.main_item_wrapper}>
            <Table className={styles.table_without_border} tableLayout="fixed">
              <Table.Header>
                <Table.Row>
                  {[
                    'order id',
                    'customer',
                    'date',
                    'price',
                    'quantity',
                    'total price',
                    'payment',
                  ].map((elem) => (
                    <Table.Column key={elem}>{elem}</Table.Column>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Column className={styles.first_table_column}>{orderId}</Table.Column>
                  <Table.Column>{`${firstName} ${lastName}`}</Table.Column>
                  <Table.Column>
                    {utilFormatDate(createdAt, { formatStr: 'dd MMM, yy' })}
                  </Table.Column>
                  <Table.Column>{utilFormatMoney(price)}</Table.Column>
                  <Table.Column>{`${quantity - refund_quantity} / ${quantity}`}</Table.Column>
                  <Table.Column>
                    {utilFormatMoney(price * (quantity - refund_quantity))}
                  </Table.Column>
                  <Table.Column className={styles.capitalize_text}>{payment}</Table.Column>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className={styles.main_item_wrapper}>
            <Table className={styles.table_without_border} tableLayout="fixed">
              <Table.Header>
                <Table.Row>
                  <Table.Column>address</Table.Column>
                  <Table.Column />
                  <Table.Column>phone</Table.Column>
                  <Table.Column />
                  <Table.Column>email</Table.Column>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Column className={styles.first_table_column} colSpan={2}>
                    {address}
                  </Table.Column>
                  <Table.Column colSpan={2}>{phone}</Table.Column>
                  <Table.Column colSpan={3}>{email}</Table.Column>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className={styles.main_item_wrapper}>
            <Table className={styles.table_without_border} tableLayout="fixed">
              <Table.Header>
                <Table.Row>
                  <Table.Column>note</Table.Column>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Column className={clsx(styles.first_table_column, styles.capitalize_text)}>
                    {subtitle}
                  </Table.Column>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </div>
      )}
    </div>
  );
};

export { OrderItemInfo };
