import { AxiosResponse } from 'axios';

import { ApiService } from './api.service';

type TGenerateContentProps = { pageID: number, forced?: boolean };
type TGenerateContentResponse = {};

class AiServiceApi extends ApiService {
  generateContent = (data: TGenerateContentProps):
  Promise<AxiosResponse<TGenerateContentResponse>> =>
    this.post('/ext/ai-content/schedule', data);
}
export const AiService = new AiServiceApi(process.env.REACT_APP_API_HOST);
