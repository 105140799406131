export const utilProductsNumber = (publisherName?: string) => {
  if (publisherName === 'miamiherald') {
    return 4;
  }

  if (publisherName === 'reviewed') {
    return 4;
  }

  return 3;
};
