import { IBestPage } from 'common/interfaces/agg-page';
import { IFetchBestPagesOptions } from 'common/interfaces/pages-api';
import { getProductsApiClient } from 'common/services/products-api.service';
import { ErrorMessage } from 'common/ui/error-message/ErrorMessage';
import { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { DownloadIcon, PlusOutlinedIcon } from '../../../../assets/images/icons';
import { FormMode, Marketplaces } from '../../../../common/constants/enums';
import { DraftOptionType } from '../../../../common/constants/select-options';
import { getAggPageApiClient } from '../../../../common/services/agg-pages-api.service';
import { Button } from '../../../../common/ui/button';
import { Select } from '../../../../common/ui/select';
import { utilDownloadCsv, utilPagePathGenerator } from '../../../../common/utils';
import { useActions, useAppSelector, useTableFilter } from '../../../../hooks';
import { PageTitle } from '../../../common/partials/PageTitle/PageTitle';
import { PagesFilter, PagesTable } from '../../components';
import styles from './pages.module.scss';

export type PagesFilterOptions = Omit<IFetchBestPagesOptions, 'attribution'>;

export const pagesFilterOptions: PagesFilterOptions = {
  author: null,
  brand: null,
  domain: '',
  draft: null,
  keyword: '',
  limit: 25,
  marketplace: Marketplaces.Amazon,
  no_cache: 1,
  offset: 0,
  publisher: null,
  status: null
};

interface SelectedPageData {
  path: string;
  id: number;
}

export const Pages = () => {
  const { pageStatusesList, pagesList } = useAppSelector(({ pages }) => pages);
  const { fetchPageStatusesListRequest, fetchPagesListRequest } = useActions();
  const [pageStatusOptions, setPageStatusOptions] = useState<DraftOptionType[]>([]);
  const [selectedPages, setSelectedPages] = useState<SelectedPageData[]>([]);
  const [csvLoader, setCsvLoader] = useState(false);
  const [pagesFilter, setPagesFilter] = useTableFilter<PagesFilterOptions>(pagesFilterOptions);

  const selectedPageHandler = (e: ChangeEvent<HTMLInputElement>, path: string) => {
    const currentId = Number(e.target.id);
    if (selectedPages.find((elem) => elem.id === currentId)) {
      setSelectedPages((prev) => [...prev.filter(({ id }) => id !== currentId)]);
    } else {
      setSelectedPages((prevState) => [...prevState, { id: currentId, path }]);
    }
  };

  const downloadCsvFile = async (
    keyword: string,
    filters: Partial<IFetchBestPagesOptions> = {},
  ) => {
    setCsvLoader(true);
    try {
      const res = await getProductsApiClient().downloadCsvFile(keyword, filters);
      if (res.data) {
        const fileName = res.headers['content-disposition'].match(/filename="(.*)"/)[1];
        utilDownloadCsv(res.data, fileName);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setCsvLoader(false);
    }
  };

  const updatePageStatus = async (id: number, data: Partial<Pick<IBestPage, 'status'>>) => {
    try {
      await getAggPageApiClient().updatePage(id, data);
    } catch (e) {
      console.error(e);
    } finally {
      fetchPagesListRequest({ filters: pagesFilter });
    }
  };

  const multiplePageStatusChange = async (pageIds: number[], status: string) => {
    try {
      const promises = pageIds.map((id) => getAggPageApiClient().updatePage(id, { status }));
      await Promise.all(promises);
      setSelectedPages([]);
      fetchPagesListRequest({ filters: pagesFilter });
    } catch (e) {
      console.error(e);
    }
  };

  const allPagesSelect = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
    const pagesId = pagesList.pages.map((page) => ({
      id: page.page_id,
      path: utilPagePathGenerator(
        {
          brand: page?.brand,
          params: {
            mode: FormMode.DUPLICATE
          },
          publisher: page?.publisher,
          slug: page?.slug
        },
        {},
      ),
    }));

    if (checked && pagesList.pages.length !== selectedPages.length) {
      setSelectedPages(pagesId);
    }

    if (!checked && pagesList.pages.length === selectedPages.length) {
      setSelectedPages([]);
    }
  };

  const openPagesDuplicate = () => {
    selectedPages.forEach(({ path }) => window.open(`/pages/${path}`, '_blank'));
    setSelectedPages([]);
  };

  useEffect(() => {
    fetchPageStatusesListRequest();
  }, []);

  useEffect(() => {
    fetchPagesListRequest({ filters: pagesFilter });
  }, [pagesFilter]);

  useEffect(() => {
    const pagesStatusOptions = pageStatusesList.pageStatuses.map((status) => ({
      label: status,
      value: status,
    }));
    setPageStatusOptions([...pagesStatusOptions]);
  }, [pageStatusesList.pageStatuses]);

  return (
    <div className={styles.pages}>
      <div className={styles.page_top}>
        <PageTitle description="Let’s check you pages today" title="Pages" />
        <div className="d-flex w-100 justify-end column-gap-20">
          <NavLink to="new">
            <Button iconStart={<PlusOutlinedIcon />}>Add New</Button>
          </NavLink>
        </div>
      </div>

      <div className="mt-40 mb-20 w-100 d-flex column-gap-10 justify-space-between">
        {selectedPages.length > 0 ? (
          <div className="d-flex column-gap-10 w-30">
            <Select
              className="w-100"
              onChange={(option) =>
                multiplePageStatusChange(
                  selectedPages.map((elem) => elem.id),
                  option.value,
                )
              }
              options={pageStatusOptions}
              placeholder={`Change status ( ${selectedPages.length} )`}
            />
            <Button fullWidth onClick={openPagesDuplicate} outlined variant="secondary">
              {`Duplicate ( ${selectedPages.length} )`}
            </Button>
          </div>
        ) : (
          <PagesFilter
            filter={pagesFilter}
            statusOptions={pageStatusOptions}
            updateFilter={setPagesFilter}
          />
        )}
        <Button
          iconStart={<DownloadIcon />}
          loading={csvLoader}
          onClick={() => downloadCsvFile(pagesFilter.keyword, pagesFilter)}
          variant="secondary"
        />
      </div>

      {!pagesList.error && (
        <PagesTable
          allPagesSelect={allPagesSelect}
          filters={pagesFilter}
          loading={pagesList.loading}
          pages={pagesList.pages}
          pagination={{
            count: pagesList.totalCount,
            limit: pagesFilter.limit,
            onPagination: setPagesFilter,
          }}
          selectedPageHandler={selectedPageHandler}
          selectedPages={selectedPages}
          statusOptions={pageStatusOptions}
          updatePageStatus={updatePageStatus}
        />
      )}
      {pagesList.error && <ErrorMessage error={pagesList.error.message} />}
    </div>
  );
};
