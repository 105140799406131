import clsx from 'clsx';
import {
  ChangeEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';

import styles from './Modal.module.scss';

type TModalProps = PropsWithChildren<{
  locked?: boolean;
  open: boolean;
  onClose?: () => void;
  className?: string;
}>;

const Modal = ({ children, className, locked, onClose, open }: TModalProps) => {
  const modalRef = useRef(null);

  const dialogClasses = useMemo(() => {
    const _arr = [styles.modal];
    if (!open) _arr.push(styles['modal--closing']);

    return _arr.join(' ');
  }, [open]);

  const onCancel = useCallback(
    (e: ChangeEvent<HTMLDialogElement>) => {
      e.preventDefault();
      if (!locked) onClose();
    },
    [locked, onClose],
  );

  const onClick = useCallback(
    ({ target }: MouseEvent<HTMLDialogElement>) => {
      const { current: el } = modalRef;
      if (target === el && !locked) onClose();
    },
    [locked, onClose],
  );

  const onAnimEnd = useCallback(() => {
    const { current: el } = modalRef;
    if (!open) el.close();
  }, [open]);

  useEffect(() => {
    const { current: el } = modalRef;
    if (open) el.showModal();
  }, [open]);

  return createPortal(
    <dialog
      ref={modalRef}
      className={clsx(dialogClasses, className)}
      onAnimationEnd={onAnimEnd}
      onCancel={onCancel}
      onClick={onClick}
      onClose={onClose}
    >
      {children}
    </dialog>,
    document.body,
  );
};

const Title = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(styles.modal__title, className)}>{children}</div>
);
const Body = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(styles.modal__body, className)}>{children}</div>
);
const Actions = ({ children, className }: PropsWithChildren<{ className?: string }>) => (
  <div className={clsx(styles.modal__actions, className)}>{children}</div>
);

Modal.Title = Title;
Modal.Body = Body;
Modal.Actions = Actions;

export { Modal };
