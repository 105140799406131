import { FormMode } from 'common/constants/enums';
import { AggPageFormState } from 'context/page-form-context';
import { AggPageState } from 'context/pages-context';

import { PageTitle } from '../../../common/partials/PageTitle/PageTitle';
import { PageForm } from '../../page-form/page-form';

export const CreatePage = () => (
  <div className="flex-column">
    <div className="d-flex align-center mb-20">
      <PageTitle title="Add new" withReturn />
    </div>
    <AggPageState>
      <AggPageFormState>
        <PageForm formMode={FormMode.CREATE} />
      </AggPageFormState>
    </AggPageState>
  </div>
);
