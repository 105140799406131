import { useController, useFormContext } from 'react-hook-form';

import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { ProductBannerAsset } from '../../../../page-form/page-form';
import styles from './product-banner.module.scss';

type Props = {
  productIdx?: number;
  assetIdx?: number;
  asset: ProductBannerAsset;
  onDeleteButton?: () => void;
};

const ProductBanner = ({
  asset,
  assetIdx,
  productIdx,
  onDeleteButton = () => {},
}: Props) => {
  const { control } = useFormContext();
  const titleField = useController({
    control,
    name: `productAssets.${productIdx}.assets.${assetIdx}.file_data.title`,
  });
  const textField = useController({
    control,
    name: `productAssets.${productIdx}.assets.${assetIdx}.file_data.text`,
  });

  return (
    <Container
      className={styles.container}
      onDeleteButton={onDeleteButton}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <div className={styles.image_container}>
        <img alt={`product_${productIdx}_banner_${assetIdx}`} src={asset.url || asset.preview} />
      </div>
      <div className={styles.fields_container}>
        <FormItem label="Title" message={titleField.fieldState.error?.message}>
          <TextField
            {...titleField.field}
            invalid={titleField.fieldState.invalid}
            placeholder="Enter title"
          />
        </FormItem>
        <FormItem label="Text" message={textField.fieldState.error?.message}>
          <TextField
            {...textField.field}
            invalid={textField.fieldState.invalid}
            placeholder="Enter your simple wording"
            rows={9}
          />
        </FormItem>
      </div>
    </Container>
  );
};

export { ProductBanner };
