import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import { ILoaderProps, Loader as BaseLoader } from '../loader';
import styles from './Table.module.scss';
import { TColumnProps, TRowProps, TTableProps, TTableSectionProps } from './types';

const Actions = ({ children }: TTableSectionProps) => (
  <caption className={styles.actions}>{children}</caption>
);

const Header = ({ children }: TTableSectionProps) => (
  <thead className={styles.header}>{children}</thead>
);

const Body = ({ children }: TTableSectionProps) => (
  <tbody className={styles.body}>{children}</tbody>
);

const Row = ({ children }: TRowProps) => <tr className={styles.row}>{children}</tr>;

const Column = ({ children, className, ...props }: TColumnProps) => (
  <th className={clsx(styles.column, className && className)} {...props}>
    {children}
  </th>
);

const Footer = ({ children, className, ...props }: TTableSectionProps) => (
  <tfoot className={clsx(styles.footer, className && className)} {...props}>
    {children}
  </tfoot>
);

const Loader = ({ children, loading, size }: Omit<ILoaderProps, 'className'>) => {
  if (!loading) {
    return null;
  }

  return (
    <tr>
      <td>
        <div className={styles.loader_wrapper} />
        <BaseLoader className={styles.loader} size={size || 'medium'}>{children}</BaseLoader>
      </td>
    </tr>
  );
};

const NoResult = ({ children = 'No result', visible = false }: PropsWithChildren<{ visible?: boolean }>) => {
  if (!visible) {
    return null;
  }
  return (
    <tr className="w-100">
      <td align="center" width="100%">
        <div className={styles.no_result}>{children}</div>
      </td>
    </tr>
  );
};

const Table = ({ children, className, tableLayout }: TTableProps) => (
  <div className={clsx(styles.table_wrapper, className && className)}>
    <table className={clsx(styles.table, tableLayout && styles[`table_${tableLayout}`])}>
      {children}
    </table>
  </div>
);

Table.Actions = Actions;
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.Column = Column;
Table.Footer = Footer;
Table.Loader = Loader;
Table.NoResult = NoResult;

export { Table };
