import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class ApiService {
  protected instance: AxiosInstance;

  private host: string;

  private publisher: string;

  constructor(host: string, publisher: string = null) {
    this.host = host;
    this.publisher = publisher;
    this.instance = axios.create();
  }

  private updateInterceptors() {
    this.instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

      if (this.publisher) {
        config.headers['x-picks-publisher'] = this.publisher;
      }

      return config;
    },
    (error) => Promise.reject(error));
  }

  protected async get(url: string, data?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.get(`${this.host}${url}`, data);
  }

  protected async post(url: string,
    data?: object,
    config?: AxiosRequestConfig): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.post(`${this.host}${url}`, data, config);
  }

  protected async put(url: string, data?: object): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.put(`${this.host}${url}`, data);
  }

  protected async delete(url: string, data?: object): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.delete(`${this.host}${url}`, { data });
  }

  protected async patch(url: string, data?: object): Promise<AxiosResponse> {
    this.updateInterceptors();
    return this.instance.patch(`${this.host}${url}`, data);
  }

  protected async postFormData(url: string, data?: any): Promise<AxiosResponse> {
    return this.rawMultipart('post', url, data);
  }

  protected async rawMultipart(method: string, url: string, data: any): Promise<AxiosResponse> {
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }
    this.updateInterceptors();

    return this.instance({
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method,
      url: `${this.host}${url}`,
    });
  }
}
