import clsx from 'clsx';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { ArrowDownIcon, CrossIcon } from '../../../assets/images/icons';
import { useDropdown } from '../dropdown';
import styles from './SelectOld.module.scss';

export type TSelectOption = { label: string; value: string | number };

export type ISelect = {
  options: TSelectOption[];
  onSelect?: (value: number | string | Array<string | number>) => void;
  value?: string | number | Array<string | number>;
  fullwidth?: boolean;
  className?: string;
  placeholder?: string;
  defaultValue?: string | number;
  withoutEmptyOption?: boolean;
  isMulti?: boolean;
  disabled?: boolean;
  withCleaningButton?: boolean;
  variant?: 'secondary';
  size?: 'small' | 'medium'
  invalid?: boolean
  name?: string
};

const SelectOld = forwardRef<HTMLInputElement, ISelect>(({
  className,
  defaultValue,
  disabled = false,
  fullwidth,
  invalid,
  isMulti = false,
  onSelect,
  options,
  placeholder,
  size = 'medium',
  value,
  variant,
  withCleaningButton = true,
  withoutEmptyOption = false,
  ...props
}, selectRef) => {
  const isFirstRender = useRef(true);
  const { isVisible, ref, setIsVisible } = useDropdown(disabled);

  const initialSingleCurrentState = options.find((x) => x.value === value)
    || options.find((x) => x.value === defaultValue)
    || undefined;

  const [singleCurrent, setSingleCurrent] = useState<TSelectOption>(initialSingleCurrentState);
  const [multiCurrent, setMultiCurrent] = useState<TSelectOption[]>(initialMultiCurrentState());
  const [onLabel, setOnLabel] = useState(false);

  const singleValue = singleCurrent?.label && singleCurrent?.label !== 'None' ? singleCurrent?.label : '';
  const multiValue = multiCurrent.length ? multiCurrent.map((elem) => elem.label).join(', ') : '';

  function initialMultiCurrentState() {
    if (Array.isArray(value)) {
      return value.reduce<TSelectOption[]>((result, value) => {
        const item = options.find((x) => x.value === value);
        if (item) {
          result.push(item);
        }
        return result;
      }, []);
    }
    return [];
  }

  const onSelectClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const onSingleOptionClick = (e: React.MouseEvent<HTMLDivElement>, value: TSelectOption) => {
    e.stopPropagation();
    setSingleCurrent(value);
    if (onSelect) onSelect(value.value);
    setIsVisible(false);
  };

  const onMultiOptionClick = (e: React.MouseEvent<HTMLDivElement>, option: TSelectOption) => {
    e.stopPropagation();
    const opt = multiCurrent.find((elem) => elem.value === option.value);
    if (opt) {
      setMultiCurrent((prevState) => [...prevState.filter((elem) => elem.value !== option.value)]);
      if (onSelect) {
        onSelect(
          [...multiCurrent].filter((elem) => elem.value !== option.value).map((elem) => elem.value),
        );
      }
    } else {
      setMultiCurrent((prevState) => [...prevState, option]);
      if (onSelect) onSelect([...multiCurrent, option].map((elem) => elem.value));
    }
  };

  const onOptionClick = (e: React.MouseEvent<HTMLDivElement>, option: TSelectOption) => {
    if (isMulti) {
      onMultiOptionClick(e, option);
    } else {
      onSingleOptionClick(e, option);
    }
  };

  const onCleanButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isMulti) {
      setMultiCurrent([]);
      setOnLabel(false);
      setIsVisible(false);
    } else {
      setSingleCurrent(null);
    }

    if (onSelect && isMulti) {
      onSelect([]);
    } else if (onSelect) {
      onSelect('');
    }
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      if (!isMulti) {
        setSingleCurrent(options.find((x) => x.value === value));
      } else if (Array.isArray(value)) {
        const newValue = value?.reduce<TSelectOption[]>((result, value) => {
          const item = options?.find((x) => x.value === value);
          if (item) {
            result.push(item);
          }
          return result;
        }, []);
        setMultiCurrent(newValue);
      }
    }
  }, [value]);

  return (
    <div
      ref={ref}
      className={clsx(
        styles.select,
        fullwidth && styles.select__fullwidth,
        variant && styles[`select__${variant}`],
        size && styles[`select__${size}`],
        disabled && styles.select__disabled,
        invalid && styles.select__invalid,
        className && className,
      )}
      onClick={onSelectClick}
    >
      <input
        {...props}
        ref={selectRef}
        className={styles.input}
        disabled
        placeholder={placeholder}
        type="text"
        value={isMulti ? multiValue : singleValue}
      />
      <div className={clsx(styles.arrow, isVisible && styles.arrow__open)}>
        <ArrowDownIcon />
      </div>
      <div className={clsx(styles.dropdown, isVisible && styles.dropdown__visible)}>
        {!withoutEmptyOption && (
          <div
            key="1234"
            className={clsx(styles.option, styles.option__first)}
            onClick={(e) => onOptionClick(e, { label: 'None', value: null })}
          >
            None
          </div>
        )}
        {options.length
          && options.map((option) => {
            const checked = isMulti && multiCurrent.find((x) => x.value === option.value);
            return (
              <div
                key={option.value}
                className={clsx(styles.option, checked && styles.option__checked)}
                onClick={(e) => onOptionClick(e, option)}
              >
                {option.label}
              </div>
            );
          })}
      </div>
      {withCleaningButton && !!singleCurrent?.value && (
        <div className={styles.count_label} onClick={onCleanButtonClick}>
          <CrossIcon />
        </div>
      )}
      {withCleaningButton && !!multiCurrent.length && (
        <div
          className={styles.count_label}
          onClick={onCleanButtonClick}
          onMouseLeave={() => setOnLabel(false)}
          onMouseOver={() => setOnLabel(true)}
        >
          {onLabel ? <CrossIcon /> : multiCurrent.length && multiCurrent.length}
        </div>
      )}
    </div>
  );
});

export { SelectOld };
