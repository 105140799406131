import React, { useEffect } from 'react';

import { useActions, useAppSelector, useTableFilter } from '../../../hooks';
import { PageTitle } from '../../common/partials/PageTitle/PageTitle';
import { OrdersTable } from './components';

export interface IOrderFilter {
  limit: number
  offset: number
}

const OrdersPage = () => {
  const { getOrdersRequest } = useActions();
  const { orders, pending, totalOrdersCount } = useAppSelector(({ orderReducer }) => orderReducer);
  const orderFilterOption = { limit: 25, offset: 0 };
  const [orderFilter, setOrderFilter] = useTableFilter<IOrderFilter>(orderFilterOption);

  useEffect(() => {
    getOrdersRequest(orderFilter);
  }, [orderFilter]);

  return (
    <div className="d-flex flex-column">
      <PageTitle
        className="mb-30"
        description="Let’s check you orders today"
        title="Orders"
      />

      <OrdersTable
        filter={orderFilter}
        loading={pending}
        orders={orders}
        ordersCount={totalOrdersCount}
        updateFilter={setOrderFilter}
      />
    </div>
  );
};

export { OrdersPage };
