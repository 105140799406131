import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPublisher } from '../../../context/pages-context';

type TError = { message: string; code?: number };

type TPublisherListInitialState = {
  loading: boolean;
  error: TError;
  publishers: IPublisher[];
  isRequestComplete: boolean;
};

const initialState: TPublisherListInitialState = {
  error: null,
  isRequestComplete: false,
  loading: false,
  publishers: [],
};

const publisherListSlice = createSlice({
  initialState,
  name: 'publisherList',
  reducers: {
    fetchPublisherListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchPublisherListRequest(state): TPublisherListInitialState {
      return {
        ...state,
        error: null,
        ...(state.isRequestComplete ? { isRequestComplete: false } : {}),
      };
    },
    fetchPublisherListResponse(state, action: PayloadAction<IPublisher[]>) {
      state.publishers = action.payload;
      state.isRequestComplete = true;
    },
    setPublisherListFetchingLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = publisherListSlice;
