import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import {
  FieldArrayWithId,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  UseFieldArrayReturn,
  useForm,
} from 'react-hook-form';
import { OnChangeValue } from 'react-select';

import { PlusOutlinedIcon, SaveIcon } from '../../../../assets/images/icons';
import { publisherNames } from '../../../../common/constants/select-options';
import { Button } from '../../../../common/ui/button';
import { FormItem } from '../../../../common/ui/formItem';
import { Loader } from '../../../../common/ui/loader';
import { Select } from '../../../../common/ui/select';
import { dailyDealsFormValidateSchema } from '../../../../common/validationSchemas';
import { IPublisher } from '../../../../context/pages-context';
import { useActions, useAppSelector } from '../../../../hooks';
import { PageTitle } from '../../../common/partials/PageTitle/PageTitle';
import { DealsFormRow } from '../../components/deals-form-row';
import {
  DailyDealsProductListRequestBody,
  DailyDealsRequestBodyProduct,
  IDailyDealsForm,
} from '../../types';
import styles from './deals-page.module.scss';

const dealFormDefaultValues: IDailyDealsForm = {
  publisher: null,
  rows: [{ category: { code: '', name: '' }, products: [], status: 'new' }],
};

const DealsPage = () => {
  const publishersData = useAppSelector(({ pages }) => pages.publisherList);
  const dailyDealsData = useAppSelector(({ dailyDeals }) => dailyDeals);
  const { fetchPublisherListRequest, getDailyDealsRequest, updateDailyListProductsRequest } = useActions();

  const dealsForm = useForm<IDailyDealsForm>({
    defaultValues: dealFormDefaultValues,
    resetOptions: {
      keepDirty: false,
      keepDirtyValues: false,
      keepTouched: false,
    },
    resolver: yupResolver(dailyDealsFormValidateSchema),
  });
  const publisherField = useController({ control: dealsForm.control, name: 'publisher' });
  const rowsFields = useFieldArray({ control: dealsForm.control, keyName: '_id', name: 'rows' });

  const publisherSelectOptions = [
    {
      label: 'ProductPicks',
      value: 0,
    },
    ...publishersData.publishers.map((elem: IPublisher) => ({
      label: publisherNames[elem?.name] || elem?.name,
      value: elem?.id,
    })),
  ];

  const publisherHandler = (option: OnChangeValue<{ label: string; value: number }, false>) => {
    if (option) {
      if (publisherField.field.value === option.value) return;
      dealsForm.setValue('publisher', option.value, { shouldDirty: false, shouldTouch: false });
    } else {
      dealsForm.setValue('publisher', option.value, { shouldDirty: false, shouldTouch: false });
    }
    dealsForm.setValue('publisher', option.value, { shouldDirty: false, shouldTouch: false });
  };

  const onSubmitValid: SubmitHandler<IDailyDealsForm> = ({ publisher, rows }) => {
    const currentProducts: DailyDealsRequestBodyProduct[] = [];
    const excludeProducts: DailyDealsRequestBodyProduct[] = [];

    rows.reverse().forEach((row) => {
      row.products.forEach((elem) => {
        const product: DailyDealsRequestBodyProduct = {
          category_code: row.category.code,
          product_id: elem.id,
        };

        if (elem.status === 'deleted') {
          excludeProducts.push(product);
        } else {
          currentProducts.push(product);
        }
      });
    });

    const requestData: DailyDealsProductListRequestBody = {
      exclude_products: excludeProducts,
      products: currentProducts,
    };

    if (typeof publisher === 'number') {
      requestData.publisher_id = publisher;
    }

    updateDailyListProductsRequest({
      data: requestData,
      publisherName:
        publishersData.publishers.find((elem) => elem.id === publisherField.field.value)?.name
        || '',
    });
  };

  const onDeleteRowCLick = (
    field: FieldArrayWithId<IDailyDealsForm, 'rows', '_id'>,
    index: number,
    productsMethods: UseFieldArrayReturn<IDailyDealsForm, `rows.${number}.products`, '_id'>,
  ) => {
    if (field.status === 'new') {
      rowsFields.remove(index);
    } else {
      rowsFields.update(index, {
        ...field,
        status: 'deleted',
      });

      productsMethods.fields.reverse().forEach((elem, productIndex) => {
        if (elem.status === 'deleted') return;
        if (elem.status === 'new') {
          productsMethods.remove(productIndex);
        } else {
          productsMethods.update(productIndex, { ...elem, status: 'deleted' });
        }
      });
    }
  };

  useEffect(() => {
    fetchPublisherListRequest();
  }, []);

  useEffect(() => {
    if (publishersData.isRequestComplete) {
      getDailyDealsRequest(
        publishersData.publishers.find((elem) => elem.id === publisherField.field.value)?.name
          || '',
      );
    }
  }, [publisherField.field.value, publishersData.isRequestComplete]);

  useEffect(() => {
    if (dailyDealsData.dailyDeals.length) {
      dealsForm.reset(
        {
          publisher: publisherField.field.value,
          rows: dailyDealsData.dailyDeals.map((deal) => ({
            category: { code: deal.code, name: deal.name },
            products: deal.products.map((product) => ({
              basis_price: product.basis_price,
              id: product.id,
              img_large: product.img_large,
              img_medium: product.img_medium,
              page_url: product.page_url,
              price: product.price,
              product_id: product.asin,
              subtitle: product.subtitle,
              title: product.title,
            })),
          })),
        },
        { keepDefaultValues: false, keepDirty: false, keepDirtyValues: false, keepTouched: false },
      );
    } else if (dailyDealsData.dailyDeals.length === 0) {
      dealsForm.reset({ publisher: publisherField.field.value, rows: dealFormDefaultValues.rows });
    }
  }, [dailyDealsData.dailyDeals]);

  return (
    <div className={styles.page_container}>
      <PageTitle description="Make Best Deals pages below" title="Daily Deals" />
      <FormProvider {...dealsForm}>
        <form onSubmit={dealsForm.handleSubmit(onSubmitValid)}>
          <div className={styles.page_actions}>
            <FormItem className={styles.page_actions_item} label="Publisher">
              <Select
                defaultValue={publisherSelectOptions.find((elem) => elem.value === 0)}
                isLoading={publishersData.loading}
                onChange={publisherHandler}
                options={publisherSelectOptions}
                placeholder="Publisher ..."
              />
            </FormItem>
            {!dailyDealsData.loaders.getDailyDeals && !publishersData.loading && (
              <Button
                className={styles.page_actions_item}
                iconStart={<PlusOutlinedIcon />}
                onClick={() =>
                  rowsFields.append(
                    { category: null, products: [], status: 'new' },
                    { shouldFocus: true },
                  )
                }
                variant="secondary"
              >
                Add new section
              </Button>
            )}
          </div>

          {(dailyDealsData.loaders.getDailyDeals || publishersData.loading) && (
            <Loader className={styles.loader}>Fetching daily deals...</Loader>
          )}

          {!dailyDealsData.loaders.getDailyDeals && (
            <>
              <div className={styles.rows_container}>
                {rowsFields.fields.map((field, index) => {
                  if (field.status === 'deleted') return null;

                  return (
                    <DealsFormRow
                      key={field._id}
                      error={dealsForm.getFieldState(`rows.${index}.products`).error?.message}
                      onDelete={(productsMethods) =>
                        onDeleteRowCLick(field, index, productsMethods)
                      }
                      rowIndex={index}
                      status={field.status}
                    />
                  );
                })}
              </div>

              <div className={styles.save_actions}>
                <Button
                  className={styles.page_actions_item}
                  disabled={!dealsForm.formState.isDirty}
                  iconStart={<SaveIcon />}
                  loading={dailyDealsData.loaders.updateProducts}
                  type="submit"
                  variant="secondary"
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export { DealsPage };
