import { OnChangeValue } from 'react-select';

import { SearchIcon } from '../../../../assets/images/icons';
import { FormMode } from '../../../../common/constants/enums';
import {
  draftOptions,
  DraftOptionType,
  marketplaceOptions,
} from '../../../../common/constants/select-options';
import { IFetchBestPagesOptions } from '../../../../common/interfaces/pages-api';
import { Input } from '../../../../common/ui/Input';
import { Select } from '../../../../common/ui/select';
import { TSetFilter, useAppSelector } from '../../../../hooks';
import { AuthorSelect } from '../../../common/author-select';
import { BrandInput } from '../brand-input';
import { DomainSelect } from '../domain-select';
import { PublisherSelect } from '../publisher-select';
import styles from './pages-filter.module.scss';

export interface IPagesFilter {
  filter: Partial<IFetchBestPagesOptions>;
  updateFilter: TSetFilter<Omit<IFetchBestPagesOptions, 'attribution'>>;
  statusOptions: DraftOptionType[];
}

const PagesFilter = ({ filter, statusOptions, updateFilter }: IPagesFilter) => {
  const { pageStatusesList } = useAppSelector(({ pages }) => pages);

  const pageStatusHandler = (option: OnChangeValue<DraftOptionType, false>) => {
    if (option) {
      updateFilter({ status: option.value });
    } else {
      updateFilter({ status: null });
    }
  };

  return (
    <div className={styles.pages_filter}>
      <div className={styles.filter_elem_wrapper}>
        <PublisherSelect formMode={FormMode.SEARCH} updateFilter={updateFilter} />
      </div>
      <div className={styles.filter_elem_wrapper}>
        <Select
          onChange={(option) => {
            updateFilter({ marketplace: option.value });
          }}
          options={marketplaceOptions}
          placeholder="Marketplace ..."
          value={marketplaceOptions.find((elem) => elem.value === filter.marketplace)}
        />
      </div>
      <div className={styles.filter_elem_wrapper}>
        <Input
          fullWidth
          iconBefore={<SearchIcon />}
          name="keyword"
          onChange={({ target: { value } }) => updateFilter({ keyword: value })}
          placeholder="Keyword ..."
          value={filter.keyword}
          variant="secondary"
        />
      </div>
      <div className={styles.filter_elem_wrapper}>
        <BrandInput
          brandName={filter?.brand}
          formMode={FormMode.SEARCH}
          placeholder="Brand ..."
          updateFilter={updateFilter}
        />
      </div>
      <div className={styles.filter_elem_wrapper}>
        <Select
          isClearable
          isLoading={pageStatusesList.loading}
          onChange={pageStatusHandler}
          options={statusOptions}
          placeholder="Google Ads Status ..."
          value={statusOptions.filter((elem) => elem.value === filter.status)}
        />
      </div>
      <div className={styles.filter_elem_wrapper}>
        <Select
          closeMenuOnSelect={false}
          isClearable
          isMulti
          onChange={(newValue) => {
            if (newValue) {
              updateFilter({ draft: newValue.map((option) => option.value) });
            }
          }}
          options={draftOptions}
          placeholder="Flagged Drafts ..."
          value={draftOptions.filter((option) =>
            filter.draft?.some((elem) => elem === option.value),)}
        />
      </div>
      {filter.domain !== 'bestbuyguidebook.com'
        && <div className={styles.filter_elem_wrapper}>
          <AuthorSelect
            onOptionClick={(id) => updateFilter({ author: id })}
            placeholder="Author ..."
          />
        </div>
      }
      <div className={styles.filter_elem_wrapper}>
        {(!filter.publisher || filter.publisher === 0) && (
          <DomainSelect
            initialValue={filter.domain}
            onChange={(option) => {
              const filterPayload: Partial<Omit<IFetchBestPagesOptions, 'attribution'>> = {
                domain: option?.label,
              };
              if (option?.label === 'bestbuyguidebook.com') {
                filterPayload.author = null;
              }
              updateFilter(filterPayload);
            }}
            placeholder="Domain ..."
          />
        )}
      </div>
    </div>
  );
};

export { PagesFilter };
