import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { createAuthorFormSchema } from '../../../common/validationSchemas';
import { useActions, useAppSelector } from '../../../hooks';
import { PageTitle } from '../../common/partials/PageTitle/PageTitle';
import { AuthorForm } from '../components/author-form/author-form';

export type AuthorFormType = {
  first_name: string;
  last_name: string;
  img?: File | string;
  gender: 'male' | 'female';
  info: {
    city: string;
    state: string;
    country: string;
    about: string;
    experience: {
      years: number;
      company?: string;
      specialization: string;
    };
  };
};

const CreateAuthorPage = () => {
  const { changeSuccessModalSTate, createAuthorRequest } = useActions();
  const { pending, successModalOpen } = useAppSelector(({ authorReducer }) => authorReducer);
  const navigate = useNavigate();

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<AuthorFormType>({
    resolver: yupResolver(createAuthorFormSchema),
  });

  const modalActionsHandler = (action: 'toAuthors' | 'addNew') => {
    if (action === 'toAuthors') {
      navigate('/authors');
    }
    if (action === 'addNew') {
      reset();
      clearErrors();
    }
    changeSuccessModalSTate(false);
  };

  const onSubmit = (data: AuthorFormType) => {
    createAuthorRequest(data);
  };

  return (
    <div className="d-flex flex-column">
      <PageTitle className="mb-30" title="Create author" withReturn />
      <div className="d-flex w-75">
        <AuthorForm
          control={control}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
          pending={pending}
          register={register}
        >
          <div className="d-flex w-100 justify-end">
            <div className="w-15">
              <Button fullWidth loading={pending} type="submit">
                Create
              </Button>
            </div>
          </div>
        </AuthorForm>
      </div>
      {successModalOpen && (
        <Modal className="w-20" open={successModalOpen}>
          <Modal.Title>SUCCESS!</Modal.Title>
          <Modal.Body>
            <span className="mb-10 mt-10">Author has been created</span>
          </Modal.Body>
          <Modal.Actions>
            <div className="w-100 d-flex justify-center">
              <Button
                className="w-75"
                onClick={() => modalActionsHandler('toAuthors')}
                size="small"
              >
                To Authors Table
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

export { CreateAuthorPage };
