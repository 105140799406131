import { ICustomerDto, IOrderItemDto, PaymentType, TOrderStatus } from '../../../common/interfaces/order-api';

export class OrderInfoData {
  address: string;

  createdAt: string;

  firstName: string;

  lastName: string;

  phone: string;

  productId: string;

  imgMedium: string;

  imgSmall: string;

  title: string;

  subtitle: string;

  orderId: number;

  status: TOrderStatus;

  price: number;

  payment: PaymentType;

  quantity: number;

  refund_quantity: number;

  email: string;

  constructor(order: IOrderItemDto | undefined, customer: ICustomerDto | undefined) {
    this.address = customer?.address;
    this.createdAt = customer?.created_at;
    this.firstName = customer?.first_name;
    this.lastName = customer?.last_name;
    this.phone = customer?.phone;
    this.title = order?.product?.title;
    this.subtitle = order?.product.subtitle;
    this.orderId = order?.order_id;
    this.productId = order?.product_id;
    this.price = order?.price;
    this.imgMedium = order?.product.img_medium;
    this.imgSmall = order?.product.img_small;
    this.status = order?.status;
    this.payment = order?.payment;
    this.quantity = order?.quantity;
    this.refund_quantity = order?.refund_quantity;
    this.email = customer?.email;
  }
}
