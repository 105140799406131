import { AxiosResponse } from 'axios';
import {
  IAggPageDeleteAssets,
  IAggPageDeleteProductAssets,
  IAggPageKeywordPayload,
  IAggPagePayload,
  IAggPageProductAssets,
  IAggPageProductContent,
  IAggPageProductsPayload,
  IAggPageSubmitAssets,
  IAggPageUpdatePayload,
  IFetchPublisherImagesOptions,
  IPublisherImagesResponse,
  IRenewPageAssetsPayload,
} from 'common/interfaces/agg-page';

import { IDraft } from '../../context/page-form-context';
import {
  CompareTableRequestData
} from '../../modules/pages/components/edit-compare-table-form/edit-compare-table-form';
import { ProductId } from '../../modules/pages/page-form/page-form';
import { ICheckKeywordActionPayload } from '../../store/reducers/products/checkKeywordSlice';
import { Marketplaces } from '../constants/enums';
import { IPageDraftsResponse, IPageStatues, IUpdatePageResponse } from '../interfaces/pages-api';
import { ApiService } from './api.service';

class AggPagesApi extends ApiService {
  createPage(payload: IAggPagePayload) {
    return this.post('/admin/agg-pages', payload);
  }

  updatePage(
    pageID: number,
    payload: IAggPageUpdatePayload,
  ): Promise<AxiosResponse<IUpdatePageResponse>> {
    return this.put(`/admin/agg-pages/${pageID}`, payload);
  }

  deletePage(pageID: number) {
    return this.delete(`/admin/agg-pages/${pageID}`);
  }

  createPageKeyword(payload: IAggPageKeywordPayload) {
    return this.post('/admin/agg-pages/keyword', payload);
  }

  deletePageKeyword(keywordID: number) {
    return this.delete(`/admin/agg-pages/keyword/${keywordID}`);
  }

  submitPageAssets(pageAssets: IAggPageSubmitAssets) {
    return this.postFormData('/admin/agg-pages/assets', pageAssets);
  }

  renewPageAsset(payload: IRenewPageAssetsPayload) {
    return this.post('/admin/agg-pages/renew/assets', payload);
  }

  deletePageAssets(pageID: number, payload: IAggPageDeleteAssets) {
    return this.delete(`/admin/agg-pages/assets/${pageID}`, payload);
  }

  submitProductContent(productContent: Partial<IAggPageProductContent>) {
    return this.post('/admin/agg-pages/products/content', productContent);
  }

  submitProductAssets(productAssets: IAggPageProductAssets) {
    return this.postFormData('/admin/agg-pages/products/assets', productAssets);
  }

  deleteProductAssets(payload: IAggPageDeleteProductAssets) {
    return this.delete('/admin/agg-pages/products/assets', payload);
  }

  updateProductAssets(payload: {
    marketplace: Marketplaces;
    marketplace_item_id: ProductId;
    assets_type: 'video' | 'blogs' | 'assets';
    product_assets: { link: string; data: string }[];
  }) {
    return this.post('/admin/agg-pages/products/renew/assets', payload);
  }

  submitPageProducts(payload: IAggPageProductsPayload) {
    return this.post('/admin/agg-pages/products', payload);
  }

  getPublisherImages(
    options: Partial<IFetchPublisherImagesOptions> = {},
  ): Promise<AxiosResponse<IPublisherImagesResponse>> {
    return this.get('/admin/agg-pages/publisher-images', { params: { limit: 10, ...options } });
  }

  getPagesStatutes(): Promise<AxiosResponse<IPageStatues>> {
    return this.get('/admin/agg-pages/status');
  }

  updatePageDrafts(pageId: number, drafts: IDraft[]) {
    return this.post(`/admin/agg-pages/drafts/${pageId}`, { drafts });
  }

  getPageDrafts(pageId: number): Promise<AxiosResponse<IPageDraftsResponse>> {
    return this.get(`/admin/agg-pages/drafts/${pageId}`, { params: { no_cache: 1 } });
  }

  validateKeyword = (
    data: ICheckKeywordActionPayload,
  ): Promise<AxiosResponse<{ exists: boolean; keyword: string }>> =>
    this.post('/admin/agg-pages/validate/keyword', data);

  updateCompareProductTable = (data: CompareTableRequestData): Promise<AxiosResponse<any>> =>
    this.post('/admin/compare-table/renew', data);
}

let _client: AggPagesApi;

export function getAggPageApiClient(): AggPagesApi {
  if (!_client) {
    _client = new AggPagesApi(process.env.REACT_APP_API_HOST);
  }

  return _client;
}
