import { MouseEvent } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuHomeIcon, UpdateIcon } from '../../../assets/images/icons';
import { Button } from '../button';
import styles from './error-boundary-template.module.scss';

const ErrorBoundaryTemplate = ({
  error,
  resetErrorBoundary,
}: FallbackProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onButtonClick = ({ currentTarget: { name } }: MouseEvent<HTMLButtonElement>) => {
    resetErrorBoundary();

    if (name === 'home') {
      navigate('/');
    }

    if (name === 'update') {
      navigate(location.pathname);
    }
  };

  return <div className={styles.page_container}>
    <div className={styles.main_container}>
      <h1 className={styles.title}>Unexpected error occurred</h1>
      <h2 className={styles.error_title}>Error message: </h2>
      <span className={styles.error_message}>{error?.message}</span>
      <div className={styles.buttons_container}>
        <Button
          fullWidth
          iconStart={<MenuHomeIcon />}
          name="home"
          onClick={(e) => onButtonClick(e)}
          variant="secondary"
        >
          Home
        </Button>
        <Button
          fullWidth
          iconStart={<UpdateIcon />}
          name="update"
          onClick={(e) => onButtonClick(e)}
          variant="secondary"
        >
          Update
        </Button>
      </div>
    </div>
  </div>;
};

export { ErrorBoundaryTemplate };
