import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';

import { Container } from '../container';
import { useDropdown } from '../dropdown';
import styles from './list.module.scss';

interface ListProps {
  anchorElement?: ReactNode;
  disabled?: boolean;
  placement?: 'bottom-left' | 'bottom-right' | 'bottom';
}

interface ListItemProps {
  icon?: ReactNode;
  onClick?: () => void;
}

const List = ({
  anchorElement,
  children,
  disabled,
  placement = 'bottom-left',
}: PropsWithChildren<ListProps>) => {
  const { isVisible, ref, setIsVisible } = useDropdown(disabled);

  return (
    <div ref={ref} className={clsx(styles.list)} onClick={() => setIsVisible(true)}>
      {anchorElement}
      {isVisible && (
        <Container
          className={clsx(
            styles.list_items_container,
            styles[`list_items_container__${placement}`],
          )}
          withoutBorder
          withShadow
        >
          {children}
        </Container>
      )}
    </div>
  );
};

const ListItem = ({ children, icon, onClick }: PropsWithChildren<ListItemProps>) => {
  const onItemClick = () => {
    if (onClick) onClick();
  };

  return (
    <div className={styles.list_item} onClick={onItemClick}>
      {icon && <div className={clsx(styles.icon)}>{icon}</div>}
      <span className={styles.title}>{children}</span>
    </div>
  );
};

List.Item = ListItem;

export { List };
