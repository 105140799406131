import { RichTextEditor } from 'common/ui/rich-text-editor';
import { Controller, useController, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';

import { PageBuyingGuide } from '../../../../common/interfaces/agg-page';
import { Container } from '../../../../common/ui/container';
import { FileUploader } from '../../../../common/ui/file-uploader/FileUploader';
import { Image } from '../../../../common/ui/image';
import { TextField } from '../../../../common/ui/text-field';
import { TPageForm } from '../../page-form/page-form';
import styles from './buying-guide.module.scss';

const newItemDefaultValue: PageBuyingGuide = {
  assets_type: 'buyingGuide',
  file_data: { text: '' },
  type: 'new',
};

const BuyingGuide = () => {
  const { control } = useFormContext<TPageForm>();
  const { append, fields, remove, update } = useFieldArray({ control, name: 'buyingGuide' });
  const newItemForm = useForm<PageBuyingGuide>({
    defaultValues: newItemDefaultValue,
  });
  const newItemFile = useController({ control: newItemForm.control, name: 'file' });

  const hasNotDeletedItems = fields.some((field) => field.type !== 'deleted');

  const onDeleteButtonClick = (field: PageBuyingGuide, idx: number) => {
    if (field.type === 'new') {
      remove(idx);
      return;
    }

    update(idx, { ...field, type: 'deleted' });
  };

  const handleChange = (file: File, preview: string) => {
    newItemFile.field.onChange(file);
    newItemForm.setValue('preview', preview);

    newItemForm.handleSubmit((data) => {
      append(data);
      newItemForm.reset(newItemDefaultValue);
    })();
  };

  return (
    <Container className={styles.buying_guide} withoutBorder>
      {hasNotDeletedItems
        && fields.map((field, idx) => {
          if (field.type === 'deleted') return null;

          return (
            <div key={field.id} className="w-100 d-flex column-gap-20">
              {field.url || field.preview ? (
                <Container
                  className={styles.image_container}
                  onDeleteButton={() => onDeleteButtonClick(field, idx)}
                  withDeleteButton={!!(field?.url || field?.preview)}
                  withoutBorder
                  withShadow
                >
                  <Image alt="buying guide image" src={field?.url || field?.preview} />
                </Container>
              ) : (
                <FileUploader
                  className={styles.image_container}
                  error={newItemForm.formState.errors?.file?.message}
                  format={['image/jpeg', 'image/png', 'image/jpg', 'image/svg']}
                  onChange={(file, preview) => {
                    update(idx, { ...field, file, preview, type: 'new' });
                  }}
                  withoutPreview
                />
              )}
              <Controller
                control={control}
                name={`buyingGuide.${idx}.file_data.text`}
                render={(fieldData) => {
                  const onTextFieldBlur = (field: PageBuyingGuide, idx: number) => {
                    fieldData.field.onBlur();
                    if (field.type) return;

                    update(idx, { ...field, type: 'renew' });
                  };

                  const handleChangeEditor: ReactQuill.ReactQuillProps['onChange'] = (
                    content,
                    delta,
                    source,
                    editor,
                  ): void => {
                    fieldData.field.onChange(editor.getHTML());
                    if (!field.type) onTextFieldBlur(field, idx);
                  };

                  return (
                    <RichTextEditor
                      onChange={handleChangeEditor}
                      value={fieldData.field.value}
                    />
                  );
                }}
              />
            </div>
          );
        })}

      {!hasNotDeletedItems && (
        <div className="d-flex w-100 column-gap-20">
          <FileUploader
            className={styles.image_container}
            error={newItemForm.formState.errors?.file?.message}
            format={['image/jpeg', 'image/png', 'image/jpg', 'image/svg']}
            onChange={handleChange}
            value={newItemFile.field.value}
            withoutPreview
          />
          <TextField
            className="w-100"
            disabled={!newItemFile.field.value}
            placeholder="write yout buying guide..."
            {...newItemForm.register('file_data.text')}
          />
        </div>
      )}
    </Container>
  );
};

export { BuyingGuide };
