import { useContext } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { AggPageFormContext } from '../../../../context/page-form-context';
import { ProductId, TPageForm } from '../../page-form/page-form';
import { ProductBannersForm } from './components/product-banners-form';

type Props = {
  currentProductId: ProductId;
  fields: FieldArrayWithId<TPageForm, 'productAssets'>[]
};

const ProductsBanners = ({ currentProductId, fields }: Props) => {
  const { control } = useFormContext<TPageForm>();
  const { products } = useContext(AggPageFormContext);

  if (products?.length === 0) {
    return null;
  }

  return (
    <div className="w-100">
      {fields.map((field, productIdx) => {
        if (field?.product_id !== currentProductId) {
          return null;
        }

        return (
          <ProductBannersForm
            key={field.id}
            control={control}
            currentProductId={currentProductId}
            productIdx={productIdx}
          />
        );
      })}
    </div>
  );
};

export { ProductsBanners };
