import { useController, useFormContext } from 'react-hook-form';

import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { ProductPostAsset } from '../../../../page-form/page-form';
import styles from './product-post.module.scss';

type Props = {
  productIdx?: number;
  assetIdx?: number;
  asset: ProductPostAsset;
  onDeleteButton?: () => void;
};

const ProductPost = ({ asset, assetIdx, productIdx, onDeleteButton = () => {} }: Props) => {
  const { control } = useFormContext();
  const textField = useController({
    control,
    name: `productAssets.${productIdx}.assets.${assetIdx}.file_data.text`,
  });

  return (
    <Container
      className={styles.container}
      onDeleteButton={onDeleteButton}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <div className={styles.image_container}>
        <img
          alt={`product_${productIdx}_card_${assetIdx}`}
          className=""
          height={100}
          src={asset.url || asset.preview}
          width={100}
        />
      </div>
      <div className={styles.fields_container}>
        <FormItem label="Text" message={textField.fieldState.error?.message}>
          <TextField
            {...textField.field}
            invalid={textField.fieldState.invalid}
            placeholder="Enter your simple wording"
            rows={10}
          />
        </FormItem>
      </div>
    </Container>
  );
};

export { ProductPost };
