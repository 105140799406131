import React, { ChangeEvent, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';

import { BinIcon, ClockIcon, EditIcon, MenuDotsIcon } from '../../../../assets/images/icons';
import { FormMode } from '../../../../common/constants/enums';
import { IBestPage } from '../../../../common/interfaces/agg-page';
import { IFetchBestPagesOptions } from '../../../../common/interfaces/pages-api';
import { Button } from '../../../../common/ui/button';
import { Checkbox } from '../../../../common/ui/checkbox';
import { List } from '../../../../common/ui/list';
import { Modal } from '../../../../common/ui/modal';
import { Notification } from '../../../../common/ui/notification';
import { Pagination } from '../../../../common/ui/pagination';
import { Select } from '../../../../common/ui/select';
import { TSelectOption } from '../../../../common/ui/select-old';
import { Table } from '../../../../common/ui/table';
import { TextField } from '../../../../common/ui/text-field';
import { Tooltip } from '../../../../common/ui/tooltip';
import {
  utilFormatDate,
  utilPagePathGenerator,
  utilProductsNumber,
} from '../../../../common/utils';
import { TSetFilter, useActions, useAppSelector } from '../../../../hooks';
import { ItemsPreview } from './components/items-preview';
import styles from './pages-table.module.scss';

const pagesTableStructure = ['keyword', 'Mav+', 'status', 'actions'];

interface IPagesTableProps {
  pages: IBestPage[];
  loading: boolean;
  selectedPages: { id: number; path: string }[];
  selectedPageHandler: (e: ChangeEvent<HTMLInputElement>, path: string) => void;
  updatePageStatus: (id: number, data: Partial<Pick<IBestPage, 'status'>>) => void;
  statusOptions: TSelectOption[];
  pagination: {
    count: number;
    onPagination: TSetFilter<Omit<IFetchBestPagesOptions, 'attribution'>>;
    limit: number;
  };
  allPagesSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  filters: Omit<IFetchBestPagesOptions, 'attribution'>;
}

const PagesTable = ({
  allPagesSelect,
  filters,
  loading,
  pages,
  pagination,
  selectedPageHandler,
  selectedPages,
  statusOptions,
  updatePageStatus,
}: IPagesTableProps) => {
  const deletePageData = useAppSelector(({ pages }) => pages.deletePage);
  const { deletePageFromListById, deletePageRequest } = useActions();
  const [deletedSlug, setDeletedSlug] = useState('');
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [params, setParams] = useSearchParams();
  const deletedPageSlug = params.get('deletedPageSlug');
  const deletedPageId = params.get('deletedPageId');
  const navigate = useNavigate();

  const onConfirmDeletePageClick = () => {
    if (deletedPageId) {
      deletePageRequest({
        onSuccess: () => {
          setIsDeletionModalOpen(false);
          params.delete('deletedPageSlug');
          params.delete('deletedPageId');
          setParams();
          setDeletedSlug('');
          deletePageFromListById(Number(deletedPageId));
        },
        pageId: Number(deletedPageId),
      });
    }
  };

  const onDeletePageClick = (slug: string, pageId: number) => {
    setParams((prev) => ({ ...prev, deletedPageId: pageId, deletedPageSlug: slug }));
    setIsDeletionModalOpen(true);
  };

  const onCloseModalClick = () => {
    setIsDeletionModalOpen(false);
    params.delete('deletedPageSlug');
    params.delete('deletedPageId');
    setParams();
    setDeletedSlug('');
  };

  return (
    <>
      {isDeletionModalOpen && (
        <Modal locked onClose={() => {}} open>
          <Modal.Title>Page deleting</Modal.Title>
          <Modal.Body>
            <span>You are going to delete the page. Are you sure?</span>
            <br />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <span>Confirm deletion by entering page slug:</span>
            <h4>{deletedPageSlug}</h4>
            <TextField
              className="mt-10"
              disabled={deletePageData.loading}
              fullWidth
              onChange={({ target: { value } }) => setDeletedSlug(value)}
              value={deletedSlug}
            />
            <br />
            {deletePageData.error && (
              <Notification message={deletePageData.error.message} type="error" />
            )}
          </Modal.Body>
          <Modal.Actions>
            <Button
              disabled={!(deletedPageSlug === deletedSlug)}
              iconStart={<BinIcon />}
              loading={deletePageData.loading}
              onClick={onConfirmDeletePageClick}
              variant="danger"
            >
              Delete
            </Button>
            <Button
              disabled={deletePageData.loading}
              onClick={() => onCloseModalClick()}
              outlined
              variant="danger"
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <Table tableLayout="fixed">
        {pages.length > 0 && (
          <Table.Header>
            <Table.Row>
              {pagesTableStructure.map((elem, index) => {
                if (!index) {
                  return (
                    <Table.Column key={elem}>
                      <Checkbox
                        checked={pages.length === selectedPages.length}
                        onChange={allPagesSelect}
                      >
                        <div className={styles.cell_name}>{elem}</div>
                      </Checkbox>
                    </Table.Column>
                  );
                }
                return <Table.Column key={elem}>{elem}</Table.Column>;
              })}
            </Table.Row>
          </Table.Header>
        )}
        <Table.Body>
          <Table.Loader loading={loading} />
          <Table.NoResult visible={pages?.length === 0 && !loading} />
          {pages.length > 0
            && pages.map((page) => {
              const productsCount = utilProductsNumber(page.publisher?.name);

              const editPath = '/pages/edit-compare-table'
                + `${page.publisher?.name ? `/${page.publisher?.name}` : ''}`
                + `${page.brand.name ? `/${page.brand.name}` : ''}/${page.slug}`
                + `?marketplace=${filters.marketplace}`;

              const mavCount = page.products.reduce((result, product) => {
                if (product.is_mav_product) {
                  return result + 1;
                }
                return result;
              }, 0);

              return (
                <Table.Row key={page.page_id}>
                  <Table.Column>
                    <Checkbox
                      checked={!!selectedPages.find((elem) => elem.id === page.page_id)}
                      id={String(page.page_id)}
                      onChange={(e) =>
                        selectedPageHandler(
                          e,
                          `${utilPagePathGenerator({
                            brand: page?.brand,
                            publisher: page?.publisher,
                            slug: page?.slug,
                          })}?mode=${FormMode.DUPLICATE}`,
                        )
                      }
                    >
                      <ItemsPreview<IBestPage['products'][number]>
                        elemCount={productsCount}
                        items={page?.products}
                        subtitle={page.brand?.name}
                        title={page.keyword}
                      >
                        {(elem, index) => (
                          <ItemsPreview.PreviewImage
                            key={elem?.id}
                            alt={`page_product_${index}`}
                            src={elem.img_large}
                          />
                        )}
                      </ItemsPreview>
                    </Checkbox>
                  </Table.Column>
                  <Table.Column>
                    <span className={styles.mav_count}>
                      {`${mavCount}/${page.products.length}`}
                    </span>
                  </Table.Column>
                  <Table.Column>
                    <Select
                      onChange={(option) => {
                        // @ts-ignore
                        updatePageStatus(page.page_id, { status: option.value });
                      }}
                      options={statusOptions}
                      placeholder="Google Ads Status ..."
                      value={statusOptions.filter((elem) => elem.value === page.status)}
                    />
                  </Table.Column>
                  <Table.Column>
                    <div className="d-flex justify-space-between">
                      <NavLink
                        to={`${utilPagePathGenerator({
                          brand: page?.brand,
                          params: {
                            domain: page.page_domain,
                            marketplace: filters.marketplace,
                          },
                          publisher: page?.publisher,
                          slug: page?.slug,
                        })}`}
                      >
                        <Button outlined variant="secondary">
                          Details
                        </Button>
                      </NavLink>
                      <NavLink
                        target="_blank"
                        to={`${utilPagePathGenerator({
                          brand: page?.brand,
                          params: {
                            domain: page.page_domain,
                            marketplace: filters.marketplace,
                            mode: FormMode.DUPLICATE,
                          },
                          publisher: page?.publisher,
                          slug: page?.slug,
                        })}`}
                      >
                        <Button outlined variant="secondary">
                          Duplicate
                        </Button>
                      </NavLink>
                      <Tooltip
                        title={utilFormatDate(page.created_at, {
                          formatStr: 'dd MMM yyyy',
                        })}
                      >
                        <Button iconStart={<ClockIcon />} outlined variant="secondary" />
                      </Tooltip>

                      <List
                        anchorElement={
                          <Button iconStart={<MenuDotsIcon />} outlined variant="secondary" />
                        }
                        placement="bottom-right"
                      >
                        <List.Item icon={<EditIcon />} onClick={() => navigate(editPath)}>
                          Edit compare table
                        </List.Item>
                        <List.Item
                          icon={<BinIcon />}
                          onClick={() => onDeletePageClick(page.slug, page.page_id)}
                        >
                          Delete
                        </List.Item>
                      </List>
                    </div>
                  </Table.Column>
                </Table.Row>
              );
            })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.Column colSpan={pagesTableStructure.length}>
              <Pagination
                className={styles.pagination}
                count={pagination.count}
                fullwidth
                limit={pagination.limit}
                limitHandler={(limit) => pagination.onPagination({ limit })}
                onChange={(offset, limit) => {
                  pagination.onPagination({ limit, offset });
                }}
              />
            </Table.Column>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export { PagesTable };
