import 'react-day-picker/dist/style.css';

import clsx from 'clsx';
import { format } from 'date-fns';
import { memo, MouseEvent, PropsWithChildren, ReactNode, useState } from 'react';
import { ActiveModifiers, DayPicker, DayPickerProps, SelectSingleEventHandler } from 'react-day-picker';

import { DateIcon } from '../../../assets/images/icons';
import { Button, TButtonProps } from '../button';
import { Container } from '../container';
import { Modal } from '../modal';
import styles from './single-date-picker.module.scss';

interface SingleDatePickerProps {
  value?: Date;
  onChange?: SelectSingleEventHandler;
  buttonProps?: Pick<TButtonProps, 'variant' | 'outlined' | 'size'>;
  anchorElement?: ReactNode;
  disabled?: DayPickerProps['disabled']

}

const SingleDatePicker = memo(
  ({ anchorElement, buttonProps, children, disabled, onChange, value }: PropsWithChildren<SingleDatePickerProps>) => {
    const [isShow, setIsShow] = useState(false);

    const modalSwitcher = (e?: MouseEvent) => {
      e?.stopPropagation();
      setTimeout(() => setIsShow((prevState) => !prevState));
    };

    const onSelect = (
      date: Date,
      selectedDay: Date,
      activeModifiers: ActiveModifiers,
      e: MouseEvent,
    ) => {
      if (onChange) onChange(date, selectedDay, activeModifiers, e);
      modalSwitcher();
    };

    return (
      <>
        <div onClick={modalSwitcher}>
          {anchorElement || <Button
            {...buttonProps}
            className={styles.date_button}
            fullWidth
            iconStart={<DateIcon />}
            variant="secondary"
          >
            {value && format(value, 'PP')}
            {!value && children}
            {!value && !children && 'Select date'}
          </Button>}
        </div>
        {isShow && (
          <Modal onClose={modalSwitcher} open={isShow}>
            <Modal.Body>
              <Container className={clsx(styles.date_container)} withoutBorder>
                <DayPicker
                  disabled={disabled}
                  mode="single"
                  onDayClick={(day, activeModifiers, e) => {
                    e.stopPropagation();

                    return { activeModifiers, day, e };
                  }}
                  onSelect={onSelect}
                  selected={value}
                />
              </Container>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  },
);

export { SingleDatePicker };
