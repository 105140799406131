import { PagesContext } from 'context/pages-context';
import { ChangeEvent, useContext, useEffect, useRef } from 'react';

import { AggPageComponent, FormMode } from '../../../../common/constants/enums';
import { FormItem } from '../../../../common/ui/formItem';
import { Input } from '../../../../common/ui/Input';
import { AggPageFormContext } from '../../../../context/page-form-context';
import { useActions, useAppSelector } from '../../../../hooks';
import { ICheckKeywordActionPayload } from '../../../../store/reducers/products/checkKeywordSlice';

type Props = {
  formMode: FormMode
};

export const KeywordInput = ({ formMode }: Props) => {
  const { brand, changeError, error, publisher } = useContext(PagesContext);
  const { checkKeyword, clearCheckKeywordState } = useActions();
  const { checkingKeyword } = useAppSelector(({ products }) => products);
  const isFirstRender = useRef(true);

  const { changeKeyword, domainId, keyword } = useContext(AggPageFormContext);

  const keywordHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    changeKeyword(value);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (formMode === FormMode.UPDATE || formMode === FormMode.SEARCH) {
      return;
    }

    if (error) {
      changeError(null, AggPageComponent.Keyword);
    }

    clearCheckKeywordState();

    const checkKeywordPayload: ICheckKeywordActionPayload = {
      brand_id: brand?.id,
      keyword,
      publisher_id: publisher?.id
    };

    if (domainId) {
      checkKeywordPayload.domain_id = domainId;
    }

    checkKeyword(checkKeywordPayload);
  }, [keyword, brand, publisher, domainId]);

  useEffect(
    () => () => {
      clearCheckKeywordState();
    },
    [],
  );

  return (
    <div className="flex-column align-start w-75">
      <FormItem
        className="w-100"
        label="Keyword"
        message={
          checkingKeyword?.message?.text
          || error[AggPageComponent.Keyword]
          || checkingKeyword?.error?.message
        }
        type={checkingKeyword.message ? checkingKeyword.message?.type : 'error'}
      >
        <Input
          fullWidth
          id="keyword"
          invalid={Boolean(
            checkingKeyword?.message?.type === 'error'
              || checkingKeyword?.error?.message
              || error[AggPageComponent.Keyword],
          )}
          name="keyword"
          onChange={keywordHandler}
          placeholder="Keyword"
          value={keyword}
        />
      </FormItem>
    </div>
  );
};
