import { fork } from 'redux-saga/effects';

import { authorSagasWatcher } from './author-sagas';
import { commonSagasWatcher } from './common-sagas';
import { compareProductTableWatcher } from './compare-product-table';
import { couponSagasWatcher } from './coupons-sagas';
import { dailyDealsSagasWatcher } from './daily-deals-sagas';
import { orderSagasWatcher } from './order-sagas';
import { pagesSagasWatcher } from './pages-sagas';
import { productsSagaWatcher } from './products-sagas';
import { temporaryLinksSagaWatcher } from './temporary-links';

export default function* rootSaga() {
  yield fork(orderSagasWatcher);
  yield fork(authorSagasWatcher);
  yield fork(productsSagaWatcher);
  yield fork(pagesSagasWatcher);
  yield fork(commonSagasWatcher);
  yield fork(couponSagasWatcher);
  yield fork(dailyDealsSagasWatcher);
  yield fork(compareProductTableWatcher);
  yield fork(temporaryLinksSagaWatcher);
}
