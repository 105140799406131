import clsx from 'clsx';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArrowDownIcon } from '../../../../assets/images/icons';
import styles from './PageTitle.module.scss';

type Props = {
  title: string;
  description?: string;
  withReturn?: boolean;
  className?: string
};

const PageTitle = ({ className, description, title, withReturn }: Props) => {
  const navigate = useNavigate();

  const onIconClick = (e: MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className={clsx(styles.title, className && className)}>
      {withReturn && (
        <div className="d-flex align-center">
          <ArrowDownIcon className={styles.icon} onClick={onIconClick} />
        </div>
      )}
      <div className="flex-column raw-gap-10">
        <h1>{title}</h1>
        {description && <span>{description}</span>}
      </div>
    </div>
  );
};

export { PageTitle };
