import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Control, Controller, FieldErrors, useController, UseFormRegister } from 'react-hook-form';

import { genderOptions } from '../../../../common/constants/select-options';
import { AuthorDto } from '../../../../common/interfaces/authors-api';
import { AvatarUploader } from '../../../../common/ui/avatar-uploader';
import { FormItem } from '../../../../common/ui/formItem';
import { SelectOld } from '../../../../common/ui/select-old';
import { TextField } from '../../../../common/ui/text-field';
import { AuthorFormType } from '../../create-author-page';

type Props = PropsWithChildren<{
  control: Control<AuthorFormType>;
  register: UseFormRegister<AuthorFormType>;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  errors: FieldErrors<AuthorFormType>;
  pending: boolean;
  author?: AuthorDto;
}>;

const AuthorForm = ({ author, children, control, errors, onSubmit, pending }: Props) => {
  const firstName = useController(({ control, name: 'first_name' }));
  const lastName = useController(({ control, name: 'last_name' }));
  const company = useController(({ control, name: 'info.experience.company' }));
  const specialization = useController(({ control, name: 'info.experience.specialization' }));
  const years = useController(({ control, name: 'info.experience.years' }));
  const city = useController(({ control, name: 'info.city' }));
  const state = useController(({ control, name: 'info.state' }));
  const country = useController(({ control, name: 'info.country' }));
  const about = useController(({ control, name: 'info.about' }));

  return (
    <form className="d-flex flex-column raw-gap-20 w-100" onSubmit={onSubmit}>
      <div className="d-flex column-gap-20 w-100">
        <Controller
          control={control}
          name="img"
          render={({ field }) => (
            <FormItem message={errors?.img?.message}>
              <AvatarUploader
                defaultValue={author?.img}
                onChange={(file) => field.onChange(file)}
              />
            </FormItem>
          )}
        />
        <div className="d-flex flex-column raw-gap-20 w-70">
          <FormItem label="First name" message={firstName.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...firstName.field}
              invalid={firstName.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem label="Last name" message={lastName.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...lastName.field}
              invalid={lastName.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <Controller
            control={control}
            name="gender"
            render={({ field, fieldState: { error, invalid } }) => (
              <FormItem label="Gender" message={error?.message}>
                <SelectOld
                  ref={field.ref}
                  disabled={pending}
                  invalid={invalid}
                  name={field.name}
                  onSelect={(value) => field.onChange(value)}
                  options={genderOptions}
                  value={author?.gender}
                  variant="secondary"
                  withoutEmptyOption
                />
              </FormItem>
            )}
          />

          <FormItem label="Company" message={company.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...company.field}
              invalid={company.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem
            label="Specialization"
            message={specialization.fieldState.error?.message}
          >
            <TextField
              disabled={pending}
              {...specialization.field}
              invalid={specialization.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem label="Years" message={years.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...years.field}
              invalid={years.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>
        </div>
        <div className="d-flex flex-column raw-gap-20 w-70">
          <FormItem label="City" message={city.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...city.field}
              invalid={city.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem label="State" message={state.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...state.field}
              invalid={state.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem label="Country" message={country.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...country.field}
              invalid={country.fieldState.invalid}
              variant="secondary"
            />
          </FormItem>

          <FormItem label="About" message={about.fieldState.error?.message}>
            <TextField
              disabled={pending}
              {...about.field}
              invalid={about.fieldState.invalid}
              rows={11}
              variant="secondary"
            />
          </FormItem>
        </div>
      </div>
      {children}
    </form>
  );
};

export { AuthorForm };
