import { AxiosResponse } from 'axios';

import { TAddProductToSchedulerResponse } from '../interfaces/import-product-scheduler-api';
import { ApiService } from './api.service';

class ImportProductSchedulerApi extends ApiService {
  addProductToScheduler(data: { asins: string[] }): Promise<AxiosResponse<TAddProductToSchedulerResponse>> {
    return this.post('/ext/scrapping/schedule', data);
  }
}

let _client: ImportProductSchedulerApi;

export function getImportProductSchedulerApi(): ImportProductSchedulerApi {
  if (!_client) {
    _client = new ImportProductSchedulerApi(process.env.REACT_APP_API_HOST);
  }

  return _client;
}
