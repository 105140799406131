import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef, MouseEvent, ReactNode } from 'react';

import styles from './Button.module.scss';

export interface TButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger'
  iconStart?: ReactNode
  fullWidth?: boolean
  outlined?: boolean
  loading?: boolean
  size?: 'small' | 'medium'
}

const Button = forwardRef<HTMLButtonElement, TButtonProps>(({
  children,
  className = '',
  disabled = false,
  form,
  fullWidth = false,
  iconStart,
  loading = false,
  name,
  onClick,
  outlined = false,
  size = 'medium',
  type = 'button',
  variant = 'primary',
}, ref) => {
  const onButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onClick && !loading && !disabled) onClick(e);
  };

  return (
    <button
      ref={ref}
      className={clsx(
        styles.button,
        styles[`button__${variant}`],
        outlined && styles[`button__${variant}__outlined`],
        styles[`button__${size}`],
        fullWidth && styles.button__fullWith,
        className,
      )}
      disabled={disabled}
      form={form}
      name={name}
      onClick={onButtonClick}
      type={type}
    >
      <div className={clsx(styles.button__content, loading && styles.button__content__hidden)}>
        {iconStart && <div className={clsx(styles.icon, !children && styles.icon__large)}>{iconStart}</div>}
        {children && <span className={styles.button__title}>{children}</span>}
      </div>
      {loading && <div className={styles.button__loader} />}
    </button>
  );
});

export { Button };
