export const utilDownloadCsv = (csvStringData: string, fileName = 'download') => {
  if (fileName === 'download') {
    window.open(`data:text/csv,${csvStringData}`, '_self');
    return;
  }

  const a = document.createElement('a');

  a.download = fileName;
  a.href = `data:text/csv,${csvStringData}`;
  a.target = '_self';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
