import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { OnChangeValue, OptionProps } from 'react-select';

import { AuthorListItemDto } from '../../../common/interfaces/authors-api';
import { FormItem } from '../../../common/ui/formItem';
import { Image } from '../../../common/ui/image/Image';
import { Select } from '../../../common/ui/select';
import { useActions, useAppSelector } from '../../../hooks';
import styles from './author-select.module.scss';

type AuthorSelectProps = {
  initialValue?: number;
  onOptionClick?: (authorId: number) => void;
  onChange?: (value: string) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean
  className?: string
};

interface AuthorSelectOption extends AuthorListItemDto {
  label: string;
  value: number;
}

const AuthorOption = ({ data, innerProps, isSelected }: OptionProps<AuthorSelectOption, false>) => {
  const abb = `${data?.first_name[0].toUpperCase()}${data?.last_name[0].toUpperCase()}`;

  return (
    <div
      {...innerProps}
      key={innerProps.id}
      className={clsx(styles.card, isSelected && styles.card_checked)}
    >
      {data.img ? (
        <Image alt="author_avatar" className={styles.avatar} src={data.img} />
      ) : (
        <div className={styles.avatar_without_image}>{abb}</div>
      )}
      <div className={styles.info}>
        <div className={styles.card__title}>{data.last_name}</div>
        <div className={styles.card__subtitle}>{data.first_name}</div>
      </div>
    </div>
  );
};

const AuthorSelect = ({
  className = '',
  disabled,
  error,
  initialValue = null,
  label,
  onChange,
  onOptionClick,
  placeholder,
}: AuthorSelectProps) => {
  const { authors, pending } = useAppSelector((state) => state.authorReducer);

  const { getAuthors } = useActions();
  const [inputValue, setInputValue] = useState<string>('');
  const [authorOptions, setAuthorOptions] = useState<AuthorSelectOption[]>([]);

  const textFieldHandler = (value: string) => {
    if (onChange) {
      onChange(value);
    }
    setInputValue(value);
  };

  const optionHandler = (option: OnChangeValue<AuthorSelectOption, false>) => {
    if (onOptionClick && option) {
      onOptionClick(option.id);
    } else {
      onOptionClick(null);
    }
  };

  useEffect(() => {
    if (inputValue?.length) {
      const [firstn, lastn] = inputValue.split(' ');
      getAuthors({ firstn, lastn });
    } else {
      getAuthors(null);
    }
  }, [inputValue]);

  useEffect(() => {
    setAuthorOptions(
      authors.map((author) => ({
        label: `${author.first_name} ${author.last_name}`,
        value: author.id,
        ...author,
      })),
    );
  }, [authors]);

  return (
    <FormItem
      className={className}
      label={label}
      message={error}
      type={error ? 'error' : null}
    >
      <Select
        components={{ Option: AuthorOption }}
        inputValue={inputValue}
        isClearable
        isDisabled={disabled}
        isLoading={pending}
        isSearchable
        onChange={optionHandler}
        onInputChange={textFieldHandler}
        options={authorOptions}
        placeholder={placeholder}
        value={authorOptions.find((elem) => elem.value === initialValue)}
      />
    </FormItem>
  );
};

export { AuthorSelect };
