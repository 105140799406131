import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { PagePublisherImage } from '../../../../common/interfaces/agg-page';
import { Button } from '../../../../common/ui/button';
import { TPageForm } from '../../page-form/page-form';
import { PublisherImagesForm } from './publihser-images-form';
import { PublisherImageList } from './publisher-image-list';

const PublisherImages = () => {
  const { control } = useFormContext<TPageForm>();
  const [isShowPublisherImages, setIsShowPublisherImages] = useState(false);

  const methods = useFieldArray({
    control,
    name: 'publisherImages',
  });

  const onImageClick = (item: PagePublisherImage) => {
    const itemIdx = methods.fields.findIndex((elem) => elem.url === item.url);
    if (itemIdx + 1) {
      methods.remove(itemIdx);
    } else {
      methods.append(item);
    }
  };

  return (
    <>
      <div className="d-flex justify-end w-100">
        <Button onClick={() => setIsShowPublisherImages((prevState) => !prevState)} outlined variant="primary">
          Show uploaded
        </Button>
      </div>
      <div className="flex-column raw-gap-20">
        {isShowPublisherImages && (
          <PublisherImageList
            onImageClick={(data) => onImageClick(data)}
            publisherImages={methods.fields}
          />
        )}
        <PublisherImagesForm methods={methods} />
      </div>
    </>
  );
};

export { PublisherImages };
