import { AxiosResponse } from 'axios';
import { IProductReview } from 'modules/common/products/interfaces/products';

import { IProductContent } from '../../context/page-form-context';
import { ProductId } from '../../modules/pages/page-form/page-form';
import { Marketplaces } from '../constants/enums';
import { Product } from '../interfaces/agg-page';
import { ApiService } from './api.service';

interface RenewProductReviewsPayload {
  marketplace_item_id: ProductId;
  marketplace: Marketplaces;
  reviews: IProductReview[];
}

export interface BaseProductContentPayload {
  marketplace_item_id: string;
  marketplace: Marketplaces;
  bottom_line: IProductContent['bottomLine'];
  summary: IProductContent['summary'];
  pros: IProductContent['pros'];
  faq: IProductContent['faq'];
}

export interface NewProductContentPayload extends BaseProductContentPayload {
  new_copy: true;
}

export interface UpdateProductContentPayload extends BaseProductContentPayload {
  new_copy: false;
  item_copy_id: number;
}

export type ProductContentPayload = NewProductContentPayload | UpdateProductContentPayload;

export interface AttachProductContentPayload {
  page_id: number;
  products: Array<{ product_id: number; product_copy_id?: number }>;
  exclude_product_ids?: number[]
}

interface ProductContentCopies {
  copy_id: number;
  content: {
    asin: string;
    content: Omit<IProductContent, 'product_id'>;
  };
}

interface GetProductContentCopiesResponseItem {
  products: Array<{
    id: number;
    asin: string;
    content: IProductContent;
    product_copies: ProductContentCopies[];
  }>;
}

class ProductsManagementApi extends ApiService {
  renewProductReviews = (payload: RenewProductReviewsPayload) =>
    this.post('/admin/products/reviews', payload);

  updateProduct = (
    data: { payload: Partial<Product>; marketplace: Marketplaces },
    product_id: ProductId,
  ) => this.put(`/admin/products/update/${product_id}`, data);

  productContent = (payload: ProductContentPayload) =>
    this.post('/admin/agg-pages/products/content', payload);

  attachProductContent = (payload: AttachProductContentPayload) =>
    this.post('/admin/agg-pages/products/options', payload);

  getProductContentCopies = (
    asins: string | Array<String>,
  ): Promise<AxiosResponse<GetProductContentCopiesResponseItem>> =>
    this.get('/products/copy-entries', { params: { asins } });
}

let _client: ProductsManagementApi;

export function getProductsManagementApiClient(): ProductsManagementApi {
  if (!_client) {
    _client = new ProductsManagementApi(process.env.REACT_APP_API_HOST);
  }

  return _client;
}
