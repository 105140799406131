import { Control, useController } from 'react-hook-form';
import { Rating } from 'react-simple-star-rating';

import { Container } from '../../../../../../common/ui/container';
import { FormItem } from '../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../common/ui/text-field';
import { TPageForm } from '../../../../../pages/page-form/page-form';
import styles from './review-card.module.scss';

type Props = {
  productIdx: number;
  reviewIdx: number;
  onDelete: () => void;
  control: Control<TPageForm>;
};

const ReviewCard = ({ control, onDelete, productIdx, reviewIdx }: Props) => {
  const textField = useController({
    control,
    name: `reviews.${productIdx}.reviews.${reviewIdx}.review.text`,
  });
  const ratingField = useController({
    control,
    name: `reviews.${productIdx}.reviews.${reviewIdx}.review.rating`,
  });

  return (
    <Container
      className={styles.review_card}
      onDeleteButton={onDelete}
      withDeleteButton
      withoutBorder
      withShadow
    >
      <FormItem label="Rating">
        <div className="d-flex column-gap-10 align-center">
          <Rating
            initialValue={Number(ratingField.field.value)}
            onClick={(value) => ratingField.field.onChange(value)}
            size={18}
          />
          <span>{ratingField.field.value}</span>
        </div>
      </FormItem>
      <FormItem label="Review text" message={textField.fieldState.error?.message}>
        <TextField
          placeholder="Enter your text"
          resizable
          rows={12}
          {...textField.field}
          invalid={textField.fieldState.invalid}
        />
      </FormItem>
    </Container>
  );
};

export { ReviewCard };
