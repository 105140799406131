import { useContext, useState } from 'react';

import { CrossIcon, PlusOutlinedIcon, SearchIcon } from '../../../../../assets/images/icons';
import { CreateCouponRequestBody } from '../../../../../common/services/coupons-service.api';
import { Button } from '../../../../../common/ui/button';
import { useConfirmModal } from '../../../../../common/ui/confirm-modal';
import { Container } from '../../../../../common/ui/container';
import { Divider } from '../../../../../common/ui/divider';
import { FormItem } from '../../../../../common/ui/formItem';
import { Input } from '../../../../../common/ui/Input';
import { Loader } from '../../../../../common/ui/loader';
import { Modal } from '../../../../../common/ui/modal';
import { AggPageFormContext } from '../../../../../context/page-form-context';
import { useActions, useAppSelector } from '../../../../../hooks';
import { CouponDto } from '../../../../../store/reducers/coupons/getProductsCouponListReducer';
import { Coupon } from '../../../components/coupon';
import { ProductId } from '../../../page-form/page-form';
import { CreateCouponModal } from '../create-coupon-modal/create-coupon-modal';
import styles from './coupon-modal.module.scss';

interface CouponsModalProps {
  open: boolean;
  onClose?: () => void;
  productCoupons: CouponDto[];
  product_id: ProductId;
}

const CouponsModal = ({
  onClose,
  open,
  productCoupons = [],
  product_id,
}: CouponsModalProps) => {
  const {
    couponAttaching,
    getList,
    updateCoupon,
  } = useAppSelector(({ couponsReducer }) => couponsReducer);
  const { products } = useContext(AggPageFormContext);
  const {
    couponAttachingRequest,
    getCouponList,
    getCouponListClearState,
    setCouponListState,
    updateCouponRequest,
  } = useActions();
  const [isCreateCouponModalOpen, setIsCreateCouponModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const confirmAttaching = useConfirmModal((data: { code: string }) => ({
    description: 'You are going to use this coupon for a current product. Confirm?',
    successAction: () => attachingHandler(data.code, 'attach'),
    title: 'Warning',
  }));
  const confirmDetaching = useConfirmModal((data: { code: string }) => ({
    description: 'You are going to detach this coupon from a current product. Confirm?',
    successAction: () => attachingHandler(data.code, 'detach'),
    title: 'Warning',
  }));

  const onCloseClick = () => {
    if (onClose) onClose();
    getCouponListClearState();
    setSearch('');
  };

  const onSaveCouponClick = (data: CreateCouponRequestBody, place?: 'searchList' | 'productList') => {
    updateCouponRequest({ data, place });
  };

  if (!open) return null;

  const attachingHandler = (coupon_code: string, mode: 'attach' | 'detach') => {
    couponAttachingRequest({
      cb: () => {
        setSearch('');
        setCouponListState({ coupons: [] });
      },
      data: {
        asins: [product_id],
        coupon_code,
      },
      mode,
      productIds: products.map(({ product_id }) => product_id),
    });
  };

  return (
    <Modal
      className={styles.modal}
      onClose={() => {
      }}
      open={open}
    >
      <CreateCouponModal
        onCLose={() => setIsCreateCouponModalOpen(false)}
        open={isCreateCouponModalOpen}
      />
      <Modal.Title>
        COUPONS
      </Modal.Title>
      <div className={styles.create_button_wrapper}>
        <Button
          iconStart={<PlusOutlinedIcon />}
          onClick={() => {
            setIsCreateCouponModalOpen((prevState) => !prevState);
          }}
        >
          Create coupon
        </Button>
      </div>
      <Modal.Body className={styles.modal_body}>
        <Container className={styles.main} withoutBorder>
          <Container className={styles.section}>
            <div className={styles.section_title}>
              <h3 className={styles.title}>{productCoupons.length ? 'Product coupons' : 'Add coupon'}</h3>
              <Loader loading={getList.loading || couponAttaching.loading} />
            </div>
            <Divider variant="secondary" />
            <Container className={styles.list} withoutBorder>
              {!!productCoupons.length
                && productCoupons.map((coupon) => (
                  <Coupon
                    key={coupon.code}
                    checked
                    data={coupon}
                    deleteCouponHandler={() => confirmDetaching.openModal({ code: coupon.code })}
                    loading={updateCoupon.loading}
                    onSave={(data) => onSaveCouponClick(data, 'productList')}
                    withDeleteButton
                  />
                ))}
              {!productCoupons.length && (
                <>
                  <FormItem className="w-100" type="warn">
                    <Input
                      fullWidth
                      iconAfter={search.length ? <CrossIcon onClick={() => setSearch('')} /> : null}
                      iconBefore={<SearchIcon />}
                      onChange={(e) => {
                        setSearch(e.target.value);
                        getCouponList({ code: e.target.value });
                      }}
                      placeholder="Type coupon's code ..."
                      value={search}
                    />
                  </FormItem>
                  {!!getList.coupons.length && (
                    <Container className={styles.list} withoutBorder>
                      <span>Search result:</span>
                      {getList.coupons.map((coupon) => (
                        <Coupon
                          key={coupon.code}
                          data={coupon}
                          loading={updateCoupon.loading}
                          onClick={() => confirmAttaching.openModal({ code: coupon.code })}
                          onSave={(data) => onSaveCouponClick(data, 'searchList')}
                        />
                      ))}
                    </Container>
                  )}
                </>
              )}
            </Container>
          </Container>
        </Container>
      </Modal.Body>
      <Modal.Actions>
        <Button onClick={onCloseClick} outlined variant="danger">
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export { CouponsModal };
