import clsx from 'clsx';
import { useController, useForm } from 'react-hook-form';

import { PlusOutlinedIcon } from '../../../../../../../assets/images/icons';
import { Button } from '../../../../../../../common/ui/button';
import { Container } from '../../../../../../../common/ui/container';
import { FileUploader } from '../../../../../../../common/ui/file-uploader/FileUploader';
import { FormItem } from '../../../../../../../common/ui/formItem';
import { TextField } from '../../../../../../../common/ui/text-field';
import { ProductId } from '../../../../../page-form/page-form';
import styles from './video-asset-creator.module.scss';

export type VideoAssetCreatorType = {
  id?: string;
  url?: string;
  file?: File;
  preview?: string;
  assets_type: 'video';
  product_id: ProductId;
  file_data: { text: string; order?: number };
  type?: 'added' | 'deleted' | 'updated';
};

type Props = {
  currentProductId: ProductId;
  onAddButton?: (data: VideoAssetCreatorType) => void;
  onDeleteButton?: () => void;
};

const VideoAssetCreator = ({ currentProductId, onAddButton, onDeleteButton }: Props) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<VideoAssetCreatorType>({
    defaultValues: { assets_type: 'video', type: 'added' },
  });

  const textField = useController({ control, name: 'file_data.text' });
  const fileField = useController({
    control,
    name: 'file',
    rules: { required: 'File is required' },
  });

  const clearForm = () => {
    setValue('file_data.text', '');
    setValue('file', null);
    setValue('product_id', '');
    setValue('preview', null);
  };

  const onSubmit = (data: VideoAssetCreatorType) => {
    data.product_id = currentProductId;

    if (onAddButton) onAddButton(data);

    clearForm();
  };

  const onDeleteHandler = () => {
    if (onDeleteButton) onDeleteButton();

    clearForm();
  };

  return (
    <Container className={styles.card_container} onDeleteButton={onDeleteHandler} withDeleteButton>
      <div className={clsx(styles.media_container)}>
        <FileUploader
          error={errors?.file?.message}
          format={['video/mp4']}
          onChange={(file, preview) => {
            fileField.field.onChange(file);
            setValue('preview', preview);
          }}
          value={fileField.field.value}
        />
      </div>

      <div className={styles.media_container}>
        <FormItem label="Text for video" message={textField.fieldState.error?.message}>
          <TextField
            fullWidth
            placeholder="Write your simple wording"
            resizable
            rows={5}
            {...textField.field}
            invalid={textField.fieldState.invalid}
          />
        </FormItem>
        <Button
          iconStart={<PlusOutlinedIcon />}
          onClick={handleSubmit(onSubmit)}
          size="small"
          type="button"
        >
          Add
        </Button>
      </div>
    </Container>
  );
};

export { VideoAssetCreator };
