import clsx from 'clsx';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { CheckedIcon } from '../../../../assets/images/icons';
import { Tooltip } from '../../../../common/ui/tooltip';
import { TPageForm } from '../../page-form/page-form';
import styles from './draft-status.module.scss';

type TDraftIcon = {
  sectionName?: 'Video' | 'Page gallery' | 'Product content' | 'Buying guide';
};

const DraftStatus = ({ sectionName }: TDraftIcon) => {
  const { control, register, setValue } = useFormContext<TPageForm>();
  const { fields } = useFieldArray({ control, name: 'drafts' });
  const index = fields.length ? fields.findIndex((elem) => elem.section === sectionName) : -1;
  const value = useWatch({ control, name: `drafts.${index}.in_draft` });

  return (
    <Tooltip placement="top" title={value ? 'Draft' : 'Done'}>
      <div className={clsx(styles.wrapper, !value && styles.wrapper__checked)}>
        <CheckedIcon />
        <input
          {...register(`drafts.${index}.in_draft`)}
          onChange={(e) => {
            setValue(`drafts.${index}.in_draft`, e.target.checked);
          }}
          type="checkbox"
        />
      </div>
    </Tooltip>
  );
};

export { DraftStatus };
