import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useEffect, useState } from 'react';

import { Loader } from '../../common/ui/loader';

interface Props {
  children: ReactElement;
}

export function InternalAuthProvider({ children }: Props): JSX.Element {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
  } = useAuth0();

  const [tokenLoading, setTokenLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user) return;

    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        localStorage.setItem('token', accessToken);
        // console.log("accessToken:")
        // console.log(accessToken)
        setTokenLoading(false);
      } catch (e) {
        loginWithRedirect({ redirectUri: window.location.origin });
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, loginWithRedirect, user]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect({ redirectUri: window.location.origin });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (tokenLoading || isLoading) {
    return (
      <div className="d-flex align-center justify-center h-100vh w-100vw">
        <Loader loading size="large" />
      </div>
    );
  }

  return children;
}
