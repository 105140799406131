import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DailyDeals } from '../../../common/interfaces/daily-deals';
import { DailyDealsProductListRequestBody } from '../../../modules/deals/types';

type DailyDealsInitialState = {
  loaders: {
    getDailyDeals: boolean;
    updateProducts: boolean;
  };
  dailyDeals: DailyDeals[];
};

const initialState: DailyDealsInitialState = {
  dailyDeals: [],
  loaders: {
    getDailyDeals: true,
    updateProducts: false,
  },
};

const dailyDealsSlice = createSlice({
  initialState,
  name: 'dailyDeals',
  reducers: {
    clearDailyDeals(state) {
      return { ...state, dailyDeals: [] };
    },
    getDailyDealsRequest(
      ...args: [DailyDealsInitialState, PayloadAction<string>]
    ): DailyDealsInitialState {
      const [state] = args;

      return {
        ...state,
        loaders: { ...state.loaders, getDailyDeals: true },
      };
    },
    getDailyDealsResponse(state, action: PayloadAction<DailyDeals[]>) {
      state.loaders.getDailyDeals = false;
      state.dailyDeals = action.payload;
    },
    setDailyDealsLoaders(
      state,
      action: PayloadAction<Partial<Record<keyof DailyDealsInitialState['loaders'], boolean>>>,
    ): DailyDealsInitialState {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          ...action.payload,
        },
      };
    },
    updateDailyListProductsRequest(
      ...args: [
        DailyDealsInitialState,
        PayloadAction<{
          data: DailyDealsProductListRequestBody;
          publisherName: string;
        }>,
      ]
    ): DailyDealsInitialState {
      const [state] = args;

      return {
        ...state,
        loaders: { ...state.loaders, updateProducts: true },
      };
    },
  },
});

export const { actions, reducer } = dailyDealsSlice;
