import { AxiosResponse } from 'axios';

import {
  ChangeOrderStatusData,
  IOrderDto,
  IOrderItemDto,
  PaymentType,
  RefundOrderItemData,
  TGetAllOrdersOptionsType,
  TGetAllOrdersResponse,
  TGetOneOrderResponse,
} from '../interfaces/order-api';
import { ApiService } from './api.service';

class OrderApi extends ApiService {
  getAll = (options: TGetAllOrdersOptionsType): Promise<TGetAllOrdersResponse> =>
    this.get('/order/list', { params: options });

  getOne = (id: number): Promise<TGetOneOrderResponse> => this.get(`/order/${id}`);

  refund = (payment: PaymentType, data: RefundOrderItemData): Promise<any> =>
    this.post(`/${payment}/refund`, data);

  changeStatus = ({
    id,
    order_items,
  }: ChangeOrderStatusData): Promise<
  AxiosResponse<{ orders: IOrderDto; order_items: IOrderItemDto[] }>
  > => this.put(`/order/status/${id}`, { order_items });
}

let _client: OrderApi;

export function getOrderApi(): OrderApi {
  if (!_client) {
    _client = new OrderApi(process.env.REACT_APP_ORDER_API_URL);
  }

  return _client;
}
