import { MouseEvent } from 'react';

import { Image } from '../../../../../../common/ui/image';
import styles from './items-preview.module.scss';

interface Props<T extends any> {
  title?: string;
  subtitle?: string;
  items: T[];
  elemCount: number;
  children?: (value: T, index: number, array: T[]) => JSX.Element;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const PreviewImage = ({ alt, src }: { src: string; alt: string }) => (
  <li>
    <Image alt={alt} className={styles.image} src={src} />
  </li>
);

const ItemsPreview = <T extends any>({
  children,
  elemCount,
  items,
  onClick,
  subtitle,
  title,
}: Props<T>) => {
  const onElemClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onClick) onClick(e);
  };

  return (
    <div className="d-flex" onClick={onElemClick}>
      <ul className={styles.imagesContainer}>{items.slice(0, elemCount).map(children)}</ul>
      <div className="flex-column justify-start ml-10">
        {title && <h5 className={styles.title}>{title}</h5>}
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    </div>
  );
};

ItemsPreview.PreviewImage = PreviewImage;

export { ItemsPreview };
