import clsx from 'clsx';
import { ChangeEvent, forwardRef, useState } from 'react';

import { CameraIcon } from '../../../assets/images/icons';
import { utilFileSize } from '../../utils';
import styles from './AvatarUploader.module.scss';

interface Props {
  size?: 'small' | 'middle' | 'large';
  defaultValue?: string;
  onChange?: (file: File) => void;
  name?: string;
}

const AvatarUploader = forwardRef<HTMLInputElement, Props>(
  ({ defaultValue, name, onChange, size = 'middle' }, inputRef) => {
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState('');

    const inputHandler = async (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      const reader = new FileReader();
      const file = e.target?.files[0];

      if (file?.size > 1024 * 45 * 1000) {
        setError(`Image can not be larger 45,0 MB. Upload File size ${utilFileSize(file.size, 1)}`);
        return;
      }

      if (/\.(jpe?g|png|svg)$/i.test(file?.name)) {
        if (file) {
          reader.readAsDataURL(file);
        }
        reader.onloadend = () => {
          if (onChange) onChange(file);
          setPreview(reader.result);
          setError('');
        };
      } else {
        setError('Image must be in .jpg .jpeg .png .svg formats');
      }
    };

    return (
      <label className={clsx(styles.avatar_uploader, styles[`avatar_uploader__${size}`])}>
        <input
          ref={inputRef}
          className={styles.html_input}
          name={name}
          onChange={inputHandler}
          type="file"
        />
        <div className={styles.avatar_uploader__img}>
          {preview ? (
            <img alt="user_avatar" src={preview} />
          ) : defaultValue ? (
            <img alt="user_avatar" src={defaultValue} />
          ) : (
            <CameraIcon />
          )}
        </div>
        {error && <span className={styles.error}>{error}</span>}
      </label>
    );
  },
);

export { AvatarUploader };
