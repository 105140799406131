import { AggPageFormContext } from 'context/page-form-context';
import { useContext } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { SortableContainer } from '../../../../common/ui/draggable-container';
import { ProductId, TPageForm } from '../../page-form/page-form';
import { ProductsCardsForm } from './components/product-cards-form';

type Props = {
  currentProductId: ProductId;
  fields: FieldArrayWithId<TPageForm, 'productAssets'>[]
};

const ProductsCards = ({ currentProductId, fields }: Props) => {
  const { control } = useFormContext<TPageForm>();
  const { products } = useContext(AggPageFormContext);

  if (products?.length === 0) {
    return null;
  }

  return (
    <div className="w-100 w-100">
      <SortableContainer>
        {fields.map((field, productIdx) => {
          if (field?.product_id !== currentProductId) {
            return null;
          }

          return (
            <ProductsCardsForm
              key={field.id}
              control={control}
              currentProductId={currentProductId}
              productIdx={productIdx}
            />
          );
        })}
      </SortableContainer>
    </div>
  );
};

export { ProductsCards };
