import { AggPageFormState } from 'context/page-form-context';
import { AggPageState } from 'context/pages-context';
import { useSearchParams } from 'react-router-dom';

import { FormMode } from '../../../../common/constants/enums';
import { PageTitle } from '../../../common/partials/PageTitle/PageTitle';
import { PageForm } from '../../page-form/page-form';
import { UpdateWrapper } from '../../update-wrapper/UpdateWrapper';

export const UpdatePage = () => {
  const [params] = useSearchParams();
  const formMode = params.get('mode') as FormMode;
  const pageTitle = formMode === FormMode.DUPLICATE ? 'Create page duplicate' : 'Update page';

  return (
    <div className="flex-column">
      <div className="d-flex align-center mb-20">
        <PageTitle title={pageTitle} withReturn />
      </div>
      <AggPageState>
        <AggPageFormState>
          <UpdateWrapper>
            <PageForm formMode={formMode || FormMode.UPDATE} />
          </UpdateWrapper>
        </AggPageFormState>
      </AggPageState>
    </div>
  );
};
