export const lengthRestrictions = {
  product: {
    content: {
      faq: {
        answer: 90,
        question: 90
      },
      pros: 50,
      summary: 500
    },
    title: 40
  }
};
