import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import { DataIcon } from '../../../assets/images/icons';
import styles from './no-data.module.scss';

interface Props {
  size?: 'small' | 'middle' | 'large';
  className?: string;
  fullWidth?: boolean;
}

const NoData = ({
  children = 'Nothing yet',
  className = '',
  fullWidth = false,
  size = 'middle',
}: PropsWithChildren<Props>) => (
  <div className={clsx(styles.no_data, styles[size], className, fullWidth && styles.fullWith)}>
    <div className={styles.image_wrapper}>
      <DataIcon />
    </div>
    <div className={styles.title}>{children}</div>
  </div>
);

export { NoData };
