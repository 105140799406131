import { bindActionCreators } from '@reduxjs/toolkit';

import { actions } from '../store/actions';
import { useAppDispatch } from './useAppDispatch';

export const useActions = () => {
  const dispatch = useAppDispatch();

  return bindActionCreators(actions, dispatch);
};
