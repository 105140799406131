// eslint-disable-next-line import/no-extraneous-dependencies
import { Identifier } from 'dnd-core';
import { PropsWithChildren, useRef } from 'react';
import { DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type ContainerProps = PropsWithChildren;

const SortableContainer = ({ children }: ContainerProps) => (
  <DndProvider backend={HTML5Backend}>
    {children}
  </DndProvider>
);

type WrapperProps = PropsWithChildren<{
  index: number;
  id: string;
  type?: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  className?: string
}>;

type DragItem = {
  index: number;
  id: string;
  type?: string;
};

const ItemWrapper = ({ children, className, id, index, moveItem, type = 'elem' }: WrapperProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
  DragItem,
  void,
  { handlerId: Identifier | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    }),
    item: () => ({ id, index }),
    type
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return <div ref={ref} className={className} data-handler-id={handlerId} style={{ opacity }}>{children}</div>;
};

SortableContainer.ItemWrapper = ItemWrapper;

export { SortableContainer };
