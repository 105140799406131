import { AxiosResponse } from 'axios';

import { DailyDealsProductListRequestBody } from '../../modules/deals/types';
import { ApiService } from './api.service';

class DailyDealsApiServiceServiceApi extends ApiService {
  updateProductsList = (
    data: DailyDealsProductListRequestBody
  ): Promise<AxiosResponse> => this.post('/admin/daily-deals/products', data);
}

export const DailyDealsService = new DailyDealsApiServiceServiceApi(process.env.REACT_APP_API_HOST);
