import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPublisherTemporaryLinksQuery } from 'common/services/temporary-link-service.api';

export interface ProductTemporaryLink {
  product_id: number;
  publisher_id: number;
  link: string;
  expired_at: string;
  created_at: string;
}

type Status = 'idle' | 'pending' | 'succeeded' | 'failed';

export interface TemporarayLinksSliceState {
  status: Status;
  tempLinks: ProductTemporaryLink[];
  limit: number;
  offset: number;
  count: number;
  error: Error | null;
}

export const TEMPORARY_LINKS_DEFAULT_LIMIT = 10;
export const TEMPORARY_LINKS_DEFAULT_OFFSET = 0;

const initialState: TemporarayLinksSliceState = {
  count: 0,
  error: null,
  limit: TEMPORARY_LINKS_DEFAULT_LIMIT,
  offset: TEMPORARY_LINKS_DEFAULT_OFFSET,
  status: 'idle',
  tempLinks: [],
};

export interface GetPublisherTemporaryLinksPayload {
  publisherId: number;
  query: GetPublisherTemporaryLinksQuery;
}

const temporaryLinksSlice = createSlice({
  initialState,
  name: 'temporaryLinks',
  reducers: {
    getPublisherTemporaryLinks(
      state,
      action: PayloadAction<GetPublisherTemporaryLinksPayload>,
    ) {
      state.status = 'pending';
      state.limit = action.payload.query.limit;
      state.offset = action.payload.query.offset;
    },
    setPublisherTemporaryLinks(state, action: PayloadAction<{ tempLinks : ProductTemporaryLink[], count: number }>) {
      state.status = 'succeeded';
      state.tempLinks = action.payload.tempLinks;
      state.count = action.payload.count;
    },
    setTemporaryLinksError(state, action: PayloadAction<Error>) {
      state.status = 'failed';
      state.error = action.payload;
    },
    setTemporaryLinksLimit(state, action: PayloadAction<number>) {
      state.limit = action.payload;
    },
    setTemporaryLinksOffset(state, action: PayloadAction<number>) {
      state.offset = action.payload;
    }
  },
});

export const { actions, reducer } = temporaryLinksSlice;
