export enum FormMode {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  SEARCH = 'SEARCH',
  DUPLICATE = 'DUPLICATE',
}

export enum Marketplaces {
  Amazon = 'amazon',
  Walmart = 'walmart',
}

export enum DraftStatusNames {
  ProductContent = 'Product content',
  BuyingGuide = 'Buying guide',
  BannerGallery = 'Page gallery',
  Videos = 'Video',
}

export enum AggPageComponent {
  Keyword = 'keyword',
  Description = 'description',
  Brand = 'brand',
  Product = 'product',
  Slug = 'slug',
  Publisher = 'publisher',
  PagesSearch = 'search',
  PageAuthor = 'author',
  Default = 'default',
}
