import clsx from 'clsx';
import { CSSProperties, forwardRef, MouseEventHandler, PropsWithChildren } from 'react';

import { DeleteButton } from '../../../modules/common/partials/buttons/DeleteButton';
import styles from './Container.module.scss';

interface Border {
  variant?: 'primary' | 'secondary';
  type?: 'solid' | 'dashed';
}

interface Props extends PropsWithChildren {
  withDeleteButton?: boolean;
  onDeleteButton?: () => void;
  border?:Border;
  className?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  withoutBorder?: boolean;
  withShadow?: boolean;
  checked?: boolean;
  style?: CSSProperties
}

const Container = forwardRef<HTMLHRElement, Props>(({
  border = { type: 'solid', variant: 'secondary' },
  checked = false,
  children,
  className,
  onClick,
  onDeleteButton,
  withDeleteButton = false,
  withShadow = false,
  withoutBorder = false,
  style,
}, ref) => {
  const classes = clsx(
    styles.container,
    !withoutBorder && styles[`container__${border.type}`],
    !withoutBorder && styles[`container__${border.variant}`],
    withShadow && styles.container__shadow,
    checked && styles.container__checked,
    className,
  );
  const deleteButtonHandler = () => {
    if (onDeleteButton) onDeleteButton();
  };

  return (
    <div ref={ref} className={classes} onClick={onClick} style={style}>
      {children}
      {withDeleteButton && <DeleteButton handler={deleteButtonHandler} />}
    </div>
  );
});

export { Container };
