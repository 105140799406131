import clsx from 'clsx';

import { PageBannerAsset } from '../../../../../../common/interfaces/agg-page';
import { Container } from '../../../../../../common/ui/container';
import { Image } from '../../../../../../common/ui/image';
import styles from './page-banner.module.scss';

interface Props {
  item: PageBannerAsset;
  assetIdx: number;
  onDeleteButton?: () => void;
}

const PageBanner = ({ assetIdx, item, onDeleteButton = () => {} }: Props) => (
  <Container
    className={clsx(styles.container, assetIdx < 4 && styles.container__selected)}
    onDeleteButton={onDeleteButton}
    withDeleteButton
    withoutBorder
    withShadow
  >
    <Image
      alt={`product_banner_${assetIdx + 1}`}
      className={styles.image_wrapper}
      src={item?.url || item?.preview}
    />
  </Container>
);

export { PageBanner };
