import clsx from 'clsx';
import { ImgHTMLAttributes, ReactNode, SyntheticEvent, useState } from 'react';

import { ImageIcon } from '../../../assets/images/icons';
import styles from './Image.module.scss';

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & { withoutSkeleton?: boolean, errorIcon?: ReactNode };

const Image = ({ alt = '', className, errorIcon, onError, onLoad, withoutSkeleton = false, ...props }: ImageProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    if (onLoad) onLoad(e);
    setIsLoading(false);
  };

  const handleImageError = (e: SyntheticEvent<HTMLImageElement>) => {
    if (onError) onError(e);
    setIsLoading(false);
    setIsError(true);
  };

  return (
    <div className={clsx(className, styles.image_wrapper, isLoading && !withoutSkeleton && styles.skeleton)}>
      <img
        alt={alt}
        {...props}
        className={clsx(styles.image, ((isLoading && !withoutSkeleton) || isError) && styles.image_hidden)}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {!isLoading && isError && (errorIcon || <ImageIcon />)}
    </div>
  );
};

export { Image };
