// eslint-disable-next-line import/no-import-module-exports
import * as _ from 'lodash';

const PAD_LENGTH = 2;
const BASE = 20;

function seed(val: number) {
  return Math.floor(Math.abs(Math.sin(val)) * 1e2);
}

function prefix(productID: number) {
  return _.padEnd(seed(productID).toString(), PAD_LENGTH, '9');
}

function suffix(productID: number) {
  return _.padEnd(seed(productID * 2).toString(), PAD_LENGTH, '9');
}

/**
 * Packs the ID into code, for the same product ID returns same code
 * */
export function packProductID(productID: number): string {
  // eslint-disable-next-line
  const parts = parseInt([prefix(productID), productID, suffix(productID)].join(''));
  return parts.toString(BASE);
}

/**
 * Unpacks product code into ID
 * for invalid values returns 0
 * */
export function unpackProductID(linkID: string): number {
  const decoded = parseInt(linkID, BASE).toString();
  const prefixCode = decoded.slice(0, PAD_LENGTH);
  // eslint-disable-next-line
  const core = parseInt(decoded.slice(PAD_LENGTH, -PAD_LENGTH));
  const suffixCode = decoded.slice(-PAD_LENGTH);
  return suffix(core) === suffixCode && prefix(core) === prefixCode ? core : 0;
}
