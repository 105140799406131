import clsx from 'clsx';
import React, { ChangeEvent, forwardRef, ReactNode, TextareaHTMLAttributes, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';

import styles from './text-field.module.scss';

interface ITextFieldProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  fullWidth?: boolean;
  variant?: 'secondary';
  iconBefore?: ReactNode;
  iconAfter?: ReactNode;
  invalid?: boolean;
  resizable?: boolean;
  limit?: number
}

type Ref = HTMLTextAreaElement;

const TextField = forwardRef<Ref, ITextFieldProps>(
  (
    {
      autoComplete = 'off',
      className,
      defaultValue,
      disabled = false,
      fullWidth = false,
      iconAfter,
      iconBefore,
      id,
      invalid,
      limit,
      name,
      onBlur,
      onChange,
      placeholder,
      resizable = false,
      rows = 1,
      value,
      variant
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const mergedRefs = mergeRefs([ref, inputRef]);
    const handler = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e);
      }
    };

    const isCountError = limit && typeof value === 'string' && value.length > limit;

    return (
      <div
        className={clsx(
          styles.input,
          fullWidth && styles.input__fullwidth,
          disabled && styles.input__disabled,
          variant && styles[`input__${variant}`],
          (invalid || isCountError) && styles.input__invalid,
          !resizable && styles.input__unresizable,
          className && className,
        )}
      >
        {iconBefore && <div className={styles.icon_before}>{iconBefore}</div>}
        <textarea
          ref={mergedRefs}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={handler}
          placeholder={placeholder}
          rows={rows}
          value={value ?? ''}
        />
        {iconAfter && <div className={styles.icon_after}>{iconAfter}</div>}
        {Boolean(limit) && (
          <span
            className={clsx(styles.count, isCountError && styles.count__warning)}
          >
            {`${typeof value === 'string' ? value.length : 0}/${limit}`}
          </span>
        )}
      </div>
    );
  },
);

export { TextField };
