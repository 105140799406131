import { PageBannerAsset, PagePublisherImage, Product } from 'common/interfaces/agg-page';
import { IProductReview } from 'modules/common/products/interfaces/products';
import { ChangeEvent, createContext, Dispatch, SetStateAction, useState } from 'react';

import { Marketplaces } from '../common/constants/enums';
import { AuthorDto } from '../common/interfaces/authors-api';
import { ProductAssets, ProductId } from '../modules/pages/page-form/page-form';

export interface IProductContent {
  product_id: ProductId;
  bottomLine: string;
  faq: { question: string; answer: string }[];
  pros: string[];
  summary?: string;
  type: 'deleted' | 'origin' | 'new';
  _id: string
  copy_id?: number
  tied?: boolean
}

export enum ResourceStatus {
  Existing = 'existing',
  New = 'new',
}

export interface IPublisherImage {
  img: any;
  url?: string;
  status?: ResourceStatus;
  name?: string;
}

export interface IBuyingGuide {
  img?: any;
  url?: string;
  text?: string;
  file?: File;
}

export interface IDraft {
  id?: number;
  page_id?: number;
  section: 'Video' | 'Page gallery' | 'Product content' | 'Buying guide';
  in_draft: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface ProductSubtitleForUpdate {
  title: string;
  product_id: ProductId;
}

interface IAggPageFormContext {
  isGenerateContent: boolean;
  contentCopies: any[]
  productTitlesForUpdate: ProductSubtitleForUpdate[];
  productTitlesForUpdateHandler: (item: ProductSubtitleForUpdate) => void;
  setIsGenerateContent: Dispatch<SetStateAction<boolean>>;
  drafts: IDraft[];
  changeDraft: (e: ChangeEvent<HTMLInputElement>) => void;
  updateDrafts: (drafts: IDraft[]) => void;
  keyword: string;
  changeKeyword: (item: string) => void;
  keywordId: number;
  changeKeywordId: (item: number) => void;
  addContentCopies: (copies: any[], lazy?: boolean, deletedProductId?: string) => void,
  subtitle: string;
  changeSubtitle: (item: string) => void;
  changeDomainId: (domainId: number) => void;
  slug: string;
  changeSlug: (item: string) => void;
  pageAuthor: AuthorDto;
  changePageAuthor: (item: AuthorDto) => void;
  pageAuthorId: number;
  changePageAuthorId: (id: number) => void;
  pageId: number;
  changePageId: (item: number) => void;
  productsContent: Partial<IProductContent>[];
  changeProductsContent: (item: Partial<IProductContent>) => void;
  deleteProductContentByProductId: (
    deletedProductId: ProductId,
    newProductContent?: Partial<IProductContent>[],
  ) => void;
  excludeProductIds: { product_id: ProductId, id: number }[];
  addExcludeProductId: (product_id: ProductId, id: number) => void;
  domainId: number;
  products: Product[];
  setProducts: (products: Product[]) => void;
  addProduct: (product: Product) => void;
  deleteProduct: (index: number) => void;
  replaceProduct: (replacedProductId: ProductId, newProduct: Product) => void;
  changeProductValue: (index: number, value: string) => void;
  bannerImages: PageBannerAsset[];
  addBannerImage: (bannerImage: PageBannerAsset[]) => void;
  previewPublisherImages: IPublisherImage[];
  publisherImages: PagePublisherImage[];
  addPublisherImages: (data: PagePublisherImage[]) => void;
  setPreviewPublisherImages: (publisherImages: IPublisherImage[]) => void;
  deletePublisherImage: (index: number) => void;
  buyingGuide: IBuyingGuide;
  changeBuyingGuide: (buyingGuide: { file?: File; img?: any; url?: string; text?: string }) => void;
  productsReviews: { product_id: ProductId; reviews: IProductReview[] }[];
  setProductsReviews: (
    mode: 'replace' | 'add',
    ...args: { product_id: ProductId; reviews: IProductReview[] }[]
  ) => void;
  deleteProductReviewsByProductId: (
    replacedProductId: ProductId,
    newAmazonProductReviews?: { product_id: ProductId; reviews: IProductReview[] }[],
  ) => void;
  clearChangedProductData: (product_id: ProductId) => void;
  productsAssets: ProductAssets[];
  marketplace: Marketplaces;
  changeMarketplace: (value: Marketplaces) => void;
  setProductsAssets: Dispatch<SetStateAction<ProductAssets[]>>;
}

export const AggPageFormContext = createContext<IAggPageFormContext>({
  addBannerImage: () => {},
  addContentCopies: () => {},
  addExcludeProductId: () => {},
  addProduct: () => {},
  addPublisherImages: () => {},
  bannerImages: [],
  buyingGuide: {},
  changeBuyingGuide: () => {},
  changeDomainId: () => {},
  changeDraft: () => {},
  changeKeyword: () => {},
  changeKeywordId: () => {},
  changeMarketplace: () => {},
  changePageAuthor: () => {},
  changePageAuthorId: () => {},
  changePageId: () => {},
  changeProductValue: () => {},
  changeProductsContent: () => {},
  changeSlug: () => {},
  changeSubtitle: () => {},
  clearChangedProductData: () => {},
  contentCopies: [],
  deleteProduct: () => {},
  deleteProductContentByProductId: () => {},
  deleteProductReviewsByProductId: () => {},
  deletePublisherImage: () => {},
  domainId: null,
  drafts: [],
  excludeProductIds: [],
  isGenerateContent: false,
  keyword: '',
  keywordId: null,
  marketplace: null,
  pageAuthor: null,
  pageAuthorId: null,
  pageId: null,
  previewPublisherImages: [],
  productTitlesForUpdate: [],
  productTitlesForUpdateHandler: () => {},
  products: [],
  productsAssets: [],
  productsContent: [],
  productsReviews: [],
  publisherImages: [],
  replaceProduct: () => {},
  setIsGenerateContent: () => {},
  setPreviewPublisherImages: () => {},
  setProducts: () => {},
  setProductsAssets: () => {},
  setProductsReviews: () => {},
  slug: '',
  subtitle: '',
  updateDrafts: () => {},
});

export const AggPageFormState = ({ children }: { children: React.ReactNode }) => {
  // inputs:
  const [keyword, setKeyword] = useState('');
  const changeKeyword = (item: string) => setKeyword(item);
  const [keywordId, setKeywordId] = useState(null);
  const changeKeywordId = (item: number) => setKeywordId(item);

  const [subtitle, setSubtitle] = useState('');
  const changeSubtitle = (item: string) => setSubtitle(item);

  const [slug, setSlug] = useState('');
  const changeSlug = (item: string) => setSlug(item);

  const [pageId, setPageId] = useState(null);
  const changePageId = (item: number) => setPageId(item);

  const [excludeProductIds, setExcludeProductIds] = useState<{ product_id: ProductId, id: number }[]>([]);
  const addExcludeProductId: IAggPageFormContext['addExcludeProductId'] = (product_id, id) => {
    setExcludeProductIds((prev) => [...prev, { id, product_id }]);
  };

  const [contentCopies, setContentCopies] = useState<any[]>([]);

  const addContentCopies = (copies: any[], lazy: boolean = false, deletedProductId?: string) => {
    setContentCopies((prevState) => {
      if (lazy) {
        return [...prevState, ...copies];
      }
      return [...copies];
    });

    if (deletedProductId) {
      setContentCopies((prevState) => prevState.filter((elem) => elem.product_id !== deletedProductId));
    }
  };

  const [products, setProducts] = useState<Product[]>([]);
  const addProduct = (product: Product) => {
    setProducts((prev) => [...prev, product]);
  };
  const deleteProduct = (index: number) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const replaceProduct = (replacedProductId: ProductId, newProduct: Product) => {
    setProducts((prevState) =>
      prevState.map((elem) => {
        if (elem.product_id === replacedProductId) {
          return newProduct;
        }
        return elem;
      }),);
  };

  const [drafts, setDrafts] = useState<IDraft[]>([]);

  const updateDrafts = (updatedDrafts: IDraft[]) => {
    setDrafts(updatedDrafts);
  };

  const changeDraft = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedDrafts = drafts.map((elem) => {
      if (elem.section === e.target.name) {
        return { ...elem, in_draft: e.target.checked };
      }
      return elem;
    });
    setDrafts(updatedDrafts);
  };

  const changeProductValue = (index: number, value: string) => {
    setProducts((products) =>
      products.map((product, _index) => {
        if (_index === index) {
          return { ...product, value };
        }
        return product;
      }),);
  };

  const [isGenerateContent, setIsGenerateContent] = useState(false);

  const [marketplace, setMarketplace] = useState<Marketplaces>(Marketplaces.Amazon);
  const changeMarketplace: IAggPageFormContext['changeMarketplace'] = (value) => {
    setMarketplace(value);
  };

  const [bannerImages, setBannerImages] = useState<PageBannerAsset[]>([]);
  const addBannerImage = (bannerImages: PageBannerAsset[]) => {
    setBannerImages(bannerImages);
  };

  const [pageAuthor, setPageAuthor] = useState<AuthorDto>(null);

  const changePageAuthor = (item: AuthorDto) => {
    setPageAuthor(item);
  };

  const [pageAuthorId, setPageAuthorId] = useState<number>(null);

  const changePageAuthorId = (id: number) => {
    setPageAuthorId(id);
  };

  const [domainId, setDomainId] = useState<IAggPageFormContext['domainId']>(null);

  const changeDomainId: IAggPageFormContext['changeDomainId'] = (domainId) => {
    setDomainId(domainId);
  };

  const [previewPublisherImages, setPreviewPublisherImages] = useState([]);
  const [publisherImages, setPublisherImages] = useState<PagePublisherImage[]>([]);
  const addPublisherImages = (publisherImages: PagePublisherImage[]) => {
    setPublisherImages((prev) => [...prev, ...publisherImages]);
  };
  const deletePublisherImage = (index: number) => {
    setPublisherImages(publisherImages.filter((_, i) => i !== index));
  };

  const [buyingGuide, setBuyingGuide] = useState<IBuyingGuide>({});
  const changeBuyingGuide = (buyingGuide: {
    file?: File;
    img?: string;
    text?: string;
    url?: string;
  }) => {
    setBuyingGuide((prev) => ({ ...prev, ...buyingGuide }));
  };

  const [productsContent, setProductsContent] = useState<Partial<IProductContent>[]>([]);
  const changeProductsContent = (productContent: Partial<IProductContent>) =>
    setProductsContent((prev) => {
      const existing = prev.find((x) => x?.product_id === productContent?.product_id);
      let upcoming = productContent;
      if (existing) {
        const index = productsContent.findIndex((x) => x.product_id === existing.product_id);
        productsContent.splice(index, 1);

        upcoming = { ...existing, ...productContent };
      }

      return [...prev, upcoming];
    });

  const deleteProductContentByProductId: IAggPageFormContext['deleteProductContentByProductId'] = (
    deletedProductId,
    newProductContent = [],
  ) => {
    setProductsContent((prevState) => [
      ...prevState.filter((elem) => elem.product_id !== deletedProductId),
      ...newProductContent,
    ]);
  };

  const [amazonProductReviews, setAmazonProductReviews] = useState<
  { product_id: string; reviews: IProductReview[] }[]
  >([]);

  const setAmazonReviews: IAggPageFormContext['setProductsReviews'] = (mode, ...args) => {
    setAmazonProductReviews((prevState) => {
      if (mode === 'add') return [...prevState, ...args];
      if (mode === 'replace') return [...args];
      return prevState;
    });
  };

  // eslint-disable-next-line max-len
  const deleteAmazonProductReviewsByProductId: IAggPageFormContext['deleteProductReviewsByProductId'] = (replacedProductId, newAmazonProductReviews) => {
    setAmazonProductReviews((prevState) => {
      const filteredData = prevState.filter((elem) => elem.product_id !== replacedProductId);
      if (newAmazonProductReviews?.length) {
        return [...filteredData, ...newAmazonProductReviews];
      }
      return filteredData;
    });
  };

  const [productTitlesForUpdate, setProductTitlesForUpdate] = useState<ProductSubtitleForUpdate[]>(
    [],
  );

  const productTitlesForUpdateHandler = (item: ProductSubtitleForUpdate) => {
    setProductTitlesForUpdate((prevState) => {
      if (prevState.some((elem) => elem.product_id === item.product_id)) {
        return prevState.map((elem) => {
          if (elem.product_id === item.product_id) {
            return item;
          }
          return elem;
        });
      }
      return [...prevState, item];
    });
  };

  const clearChangedProductData: IAggPageFormContext['clearChangedProductData'] = (product_id) => {
    setProductTitlesForUpdate((prevState) =>
      prevState.filter((elem) => elem.product_id !== product_id),);
  };

  const [productsAssets, setProductsAssets] = useState<IAggPageFormContext['productsAssets']>([]);

  return (
    <AggPageFormContext.Provider
      value={{
        addBannerImage,
        addContentCopies,
        addExcludeProductId,
        addProduct,
        addPublisherImages,
        bannerImages,
        buyingGuide,
        changeBuyingGuide,
        changeDomainId,
        changeDraft,
        changeKeyword,
        changeKeywordId,
        changeMarketplace,
        changePageAuthor,
        changePageAuthorId,
        changePageId,
        changeProductValue,
        changeProductsContent,
        changeSlug,
        changeSubtitle,
        clearChangedProductData,
        contentCopies,
        deleteProduct,
        deleteProductContentByProductId,
        deleteProductReviewsByProductId: deleteAmazonProductReviewsByProductId,
        deletePublisherImage,
        domainId,
        drafts,
        excludeProductIds,
        isGenerateContent,
        keyword,
        keywordId,
        marketplace,
        pageAuthor,
        pageAuthorId,
        pageId,
        previewPublisherImages,
        productTitlesForUpdate,
        productTitlesForUpdateHandler,
        products,
        productsAssets,
        productsContent,
        productsReviews: amazonProductReviews,
        publisherImages,
        replaceProduct,
        setIsGenerateContent,
        setPreviewPublisherImages,
        setProducts,
        setProductsAssets,
        setProductsReviews: setAmazonReviews,
        slug,
        subtitle,
        updateDrafts,
      }}
    >
      {children}
    </AggPageFormContext.Provider>
  );
};
