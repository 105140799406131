import clsx from 'clsx';
import {
  ChangeEvent,
  DragEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';

import { ErrorIcon, PlusOutlinedIcon } from '../../../assets/images/icons';
import { Container } from '../container';
import styles from './FileUploader.module.scss';

type ImageFormatProps = 'image/png' | 'image/jpeg' | 'image/svg' | 'image/jpg';
type VideoFormatProps = 'video/mp4';
type Format =
  | [VideoFormatProps]
  | [ImageFormatProps]
  | [ImageFormatProps, ImageFormatProps]
  | [ImageFormatProps, ImageFormatProps, ImageFormatProps]
  | [ImageFormatProps, ImageFormatProps, ImageFormatProps, ImageFormatProps];

type FileUploaderProps = {
  onChange?: (file: File, src: string) => void;
  value?: File
  format: Format;
  error?: string;
  className?: string
  withoutPreview?: boolean
};

const FileUploader = forwardRef<ForwardRefRenderFunction<HTMLInputElement>, FileUploaderProps>(
  ({ className, error, format, onChange, value, withoutPreview = false }, ref) => {
    const inputRef = useRef(null);
    const [drag, setDrag] = useState(false);
    const [preview, setPreview] = useState(null);

    const formats = format.join(', ');

    let file: File;

    const dragStartHandler = (e: DragEvent) => {
      e.preventDefault();
      setDrag(true);
    };

    const dragLeaveHandler = (e: DragEvent) => {
      e.preventDefault();
      setDrag(false);
    };

    const onDropHandler = (e: DragEvent) => {
      e.preventDefault();
      const reader = new FileReader();
      file = e.dataTransfer.files[0];

      if (file) {
        reader.readAsDataURL(file);
      }

      let src: string;

      reader.onloadend = () => {
        src = URL.createObjectURL(file);

        if (!withoutPreview) setPreview(src);
        if (onChange) onChange(file, src);
        setDrag(false);
      };
    };

    const inputChangeHandler = async (e: ChangeEvent) => {
      e.preventDefault();
      const reader = new FileReader();
      file = inputRef.current.files[0];

      if (file) {
        reader.readAsDataURL(file);
      }

      let src: string;

      reader.onloadend = () => {
        src = URL.createObjectURL(file);

        if (!withoutPreview) setPreview(src);
        if (onChange) onChange(file, src);
      };
    };

    useEffect(() => {
      if (!value && preview) {
        setPreview(null);
      }
    }, [value]);

    return (
      <Container
        border={{ type: 'dashed', variant: 'primary' }}
        className={clsx(
          styles.uploader,
          drag && styles.bg_drag_over,
          error && styles.uploader__invalid,
          className
        )}
      >
        {preview && formats.includes('video') && (
          <video className={styles.media} controls src={preview}>
            <track kind="captions" label="english_captions" src="" srcLang="en" />
            Your browser does not support HTML5 video.
          </video>
        )}
        {preview && formats.includes('image') && (
          <img alt={preview} className={styles.media} height="100%" src={preview} width="100%" />
        )}
        {!preview && drag && (
          <div
            className={clsx(styles.drop_area)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragOver={(e) => dragStartHandler(e)}
            onDragStart={(e) => dragStartHandler(e)}
            onDrop={(e) => onDropHandler(e)}
          >
            <PlusOutlinedIcon />
            Drop & Drop your file here
          </div>
        )}

        {!preview && !drag && (
          <div className="w-100 h-100">
            <label
              className={clsx(styles.drop_area)}
              onDragLeave={(e) => dragLeaveHandler(e)}
              onDragOver={(e) => dragStartHandler(e)}
              onDragStart={(e) => dragStartHandler(e)}
            >
              <input
                ref={mergeRefs([ref, inputRef])}
                accept={formats}
                onChange={(e) => inputChangeHandler(e)}
                type="file"
              />
              {!error ? <PlusOutlinedIcon /> : <ErrorIcon />}
              {!error ? 'Drop & Drop your file here' : error}
            </label>
          </div>
        )}
      </Container>
    );
  },
);

export { FileUploader };
