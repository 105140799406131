import { TOrderStatus } from '../../../../common/interfaces/order-api';

export enum OrdersStatus {
  New = 'new order',
  Purchased = 'purchased',
  WarehouseReceived = 'received in warehouse',
  Shipped = 'shipped to customer',
  CustomerReceived = 'customer received',
  ReturnRequest = 'return requested',
  ReturnAccept = 'return accepted',
  ReturnReceived = 'return received',
  InitRefund = 'init refund',
}

export const orderOptions = {
  [OrdersStatus.New]: { outlined: false, type: 'success' },
  [OrdersStatus.Purchased]: { outlined: false, type: 'success' },
  [OrdersStatus.WarehouseReceived]: { outlined: false, type: 'success' },
  [OrdersStatus.Shipped]: { outlined: false, type: 'warning' },
  [OrdersStatus.CustomerReceived]: { outlined: false, type: 'success' },
  [OrdersStatus.ReturnRequest]: { outlined: true, type: 'warning' },
  [OrdersStatus.ReturnAccept]: { outlined: true, type: 'info' },
  [OrdersStatus.ReturnReceived]: { outlined: true, type: 'success' },
  [OrdersStatus.InitRefund]: { outlined: false, type: 'warning' },
};

export const options: { label: string; value: TOrderStatus }[] = [
  { label: 'New Order', value: 'new order' },
  { label: 'Purchased on amazon', value: 'purchased' },
  { label: 'Received in warehouse', value: 'received in warehouse' },
  { label: 'Return received', value: 'return received' },
  { label: 'Return accepted', value: 'return accepted' },
  { label: 'Customer received', value: 'customer received' },
  { label: 'Shipped to customer', value: 'shipped to customer' },
  { label: 'Return requested', value: 'return requested' },
  { label: 'Init refund', value: 'init refund' },
];
