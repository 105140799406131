import { MouseEvent } from 'react';

import { Button } from '../../../../common/ui/button';
import { MultiTextField } from '../../../../common/ui/multi-text-field';
import styles from './ImportPanel.module.scss';

type TImportPanel = {
  asins: string[];
  loading: boolean;
  inputHandler: (inputValue: string, items: string[]) => void;
  onSendClick: (e: MouseEvent<HTMLButtonElement>) => void;
  sendBtnDisabled: boolean
  inputValue: string
};

const ImportPanel = ({ asins, inputHandler, inputValue, loading, onSendClick, sendBtnDisabled }: TImportPanel) => (
  <div className="d-flex column-gap-20">
    <div className={styles.multi_text_field_wrapper}>
      <MultiTextField
        inputValue={inputValue}
        onChange={inputHandler}
        placeholder="Input ASINs"
        values={asins}
        variant="secondary"
      />
    </div>
    <Button className={styles.send_button} disabled={sendBtnDisabled} loading={loading} onClick={onSendClick}>
      Send
    </Button>
  </div>
);

export { ImportPanel };
