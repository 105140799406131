import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../../common/ui/button';
import styles from './ErorrPage.module.scss';

const ErrorPage = () => (
  <div className={styles.error_page} id="error-page">
    <main className={styles.main}>
      <h1 className={styles.title}>404 error</h1>
      <p className={styles.description}>{'This page doesn\'t exist.'}</p>
      <Link to="/">
        <Button outlined variant="secondary">
          Get me back home
        </Button>
      </Link>
    </main>
  </div>
);

export { ErrorPage };
