import { createContext, ReactNode, useState } from 'react';

import { AggPageComponent } from '../common/constants/enums';

export type TPublisherName = 'reviewed' | 'miamiherald';

export interface IPublisher {
  id?: number;
  name?: string;
  display_name?: string;
  domain?: string;
  reverse_proxy_path?: string;
  allowed_monitor_pages?: boolean;
  created_at?: string;
  updated_at?: string;
}

export interface IExternalBrand {
  brand_group: 'aos' | 'advon'
  name: string
  system_id: number
}

export interface IBrand {
  id: number;
  name: string;
  fullname: string;
  img_logo: string;
  img_banner: string;
  page_url: string;
  status: string;
  exists: boolean;
  created_at: string;
  updated_at: string;
  parsed_at: string;
  ext_brands_count: number;
  external_brands: IExternalBrand[];
}

type AggPageError = Record<Partial<AggPageComponent>, string>;

interface IAggPageContext {
  pageLoading: boolean,
  changePageLoading: (value: boolean) => void,
  publisher: Partial<IPublisher>
  changePublisher: (item: Partial<IPublisher>) => void
  brand: Partial<IBrand>
  changeBrand: (item: Partial<IBrand>) => void
  error: AggPageError
  changeError: (message: string, component: AggPageComponent) => void
  page: {
    title?: string
    slug?: string
    page_domain?: string
    publisher_id?: number
  }
  changePage: (page: IAggPageContext['page']) => void
}

export const PagesContext = createContext<IAggPageContext>({
  brand: {},
  changeBrand: () => {
  },
  changeError: () => {
  },
  changePage: () => {
  },
  changePageLoading: () => {
  },
  changePublisher: () => {
  },
  error: null,
  page: {},
  pageLoading: false,
  publisher: {},

});

export const AggPageState = ({ children }: { children: ReactNode }) => {
  const [publisher, setPublisher] = useState<Partial<IPublisher>>({});
  const changePublisher = (item: Partial<IPublisher>) => {
    setPublisher(item);
  };

  const [brand, setBrand] = useState<Partial<IBrand>>({});
  const changeBrand = (item: Partial<IBrand>) => {
    setBrand(item);
  };

  const [page, setPage] = useState<IAggPageContext['page']>({});

  const changePage: IAggPageContext['changePage'] = (page) => {
    setPage(page);
  };

  const [pageLoading, setPageLoading] = useState(false);

  const changePageLoading: IAggPageContext['changePageLoading'] = (value) => {
    setPageLoading(value);
  };

  const errorInitState: AggPageError = {
    [AggPageComponent.Keyword]: null,
    [AggPageComponent.Description]: null,
    [AggPageComponent.Brand]: null,
    [AggPageComponent.Product]: null,
    [AggPageComponent.Slug]: null,
    [AggPageComponent.Publisher]: null,
    [AggPageComponent.PagesSearch]: null,
    [AggPageComponent.PageAuthor]: null,
    [AggPageComponent.Default]: null,
  };
  const [error, setError] = useState<AggPageError>(errorInitState);
  const changeError = (message: string, component: AggPageComponent) => {
    if (!component) {
      setError(errorInitState);
      return;
    }

    setError((e) => ({
      ...e,
      [component]: message,
    }));
  };

  return (
    <PagesContext.Provider
      value={{
        brand,
        changeBrand,
        changeError,
        changePage,
        changePageLoading,
        changePublisher,
        error,
        page,
        pageLoading,
        publisher,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};
