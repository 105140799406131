import clsx from 'clsx';
import { get } from 'lodash';
import {
  FieldArrayWithId,
  useController,
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';

import { Container } from '../../../../common/ui/container';
import { FormItem } from '../../../../common/ui/formItem';
import { IDailyDealsForm } from '../../types';
import { DealProduct } from '../deal-product';
import { DealProductInput } from '../deal-product-input';
import { ProductCategorySelect } from '../product-category-select';
import styles from './deals-form-row.module.scss';

interface Props {
  status: 'new' | 'deleted;';
  rowIndex: number;
  error?: string;
  onDelete?: (
    productsMethods: UseFieldArrayReturn<IDailyDealsForm, `rows.${number}.products`, '_id'>,
  ) => void;
}

const DealsFormRow = ({ error, rowIndex, onDelete = () => {}, status }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IDailyDealsForm>();

  const productFields = useFieldArray({
    control,
    keyName: '_id',
    name: `rows.${rowIndex}.products`,
  });
  const categoryField = useController({ control, name: `rows.${rowIndex}.category` });

  const onDeleteField = (
    field: FieldArrayWithId<IDailyDealsForm, `rows.${number}.products`, '_id'>,
    index: number,
  ) => {
    if (field.status === 'new') {
      productFields.remove(index);
    } else {
      productFields.update(index, { ...field, status: 'deleted' });
    }
  };

  return (
    <FormItem message={error}>
      <Container
        className={clsx(styles.deals_row, error && styles.error_border)}
        onDeleteButton={() => onDelete(productFields)}
        withDeleteButton={rowIndex !== 0}
      >
        <div className={styles.row_actions}>
          <FormItem
            className={styles.action_item_wrapper}
            label="Products category"
            message={
              get(errors, `rows.${rowIndex}.category.message`)
              || get(errors, `rows.${rowIndex}.category.code.message`)
            }
          >
            <ProductCategorySelect
              disabled={status !== 'new'}
              isSuggestionVisible={Boolean(productFields.fields.length > 2 && status === 'new')}
              name={categoryField.field.name}
              onBlur={categoryField.field.onBlur}
              onChange={(option) =>
                categoryField.field.onChange({ code: option.value, name: option.label })
              }
              productsIds={productFields.fields.map((elem) => elem.id)}
              value={categoryField.field.value}
            />
          </FormItem>
        </div>

        <div className={styles.items_container}>
          {productFields.fields.length < 5 && (
            <div className={styles.item_wrapper}>
              <DealProductInput
                onChange={(product) => productFields.append(product)}
                rowIndex={rowIndex}
              />
            </div>
          )}

          {productFields.fields.map((field, productIndex) => {
            if (field.status === 'deleted') return null;

            return (
              <div key={field.id} className={styles.item_wrapper}>
                <DealProduct onDelete={() => onDeleteField(field, productIndex)} product={field} />
              </div>
            );
          })}
        </div>
      </Container>
    </FormItem>
  );
};

export { DealsFormRow };
