import { AxiosResponse } from 'axios';

import { DomainListOption, DomainsListResponse } from '../interfaces/domains';
import { ApiService } from './api.service';

class DomainsApi extends ApiService {
  getDomains = (options?: Partial<DomainListOption>): Promise<AxiosResponse<DomainsListResponse>> =>
    this.get('/admin/domains', { params: { limit: 20, ...options } });
}

export const domainsApiService = new DomainsApi(process.env.REACT_APP_API_HOST);
