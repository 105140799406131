import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateCouponRequestBody } from '../../../common/services/coupons-service.api';

type TError = { message: string; code?: number };

export type TCreateCouponInitialState = {
  error: TError;
  message: {
    type: 'success' | 'error';
    text: string;
  };
  loading: boolean;
};

const initialState: TCreateCouponInitialState = {
  error: null,
  loading: false,
  message: null,
};

export const createCouponSlice = createSlice({
  initialState,
  name: 'createCoupon',
  reducers: {
    createCouponClearState() {
      return initialState;
    },
    createCouponError(state, action: PayloadAction<TCreateCouponInitialState['error']>) {
      state.error = action.payload;
      state.loading = false;
    },
    createCouponRequest(
      ...args: [
        TCreateCouponInitialState,
        PayloadAction<{
          data: CreateCouponRequestBody;
          cb?: () => void;
        }>,
      ]
    ) {
      const [state] = args;

      state.loading = true;
      state.error = null;
    },
    createCouponResponse(state) {
      state.loading = false;
    },
  },
});

export const { actions, reducer } = createCouponSlice;
