import { Control, FieldArrayWithId, useFieldArray } from 'react-hook-form';

import { isVideoAsset } from '../../../../../common/type-guards';
import { SortableContainer } from '../../../../../common/ui/draggable-container';
import { ProductId, TPageForm } from '../../../page-form/page-form';
import { AssetsContainer } from '../../assets-container';
import styles from '../ProductsVideos.module.scss';
import { VideoAsset } from './components/video-asset';
import { VideoAssetCreator } from './components/video-asset-creator';

type Props = {
  productIdx: number;
  control: Control<TPageForm>;
  currentProductId: ProductId;
};

const ProductVideoAssetsForm = ({ control, currentProductId, productIdx }: Props) => {
  const { append, fields, move, remove, update } = useFieldArray({
    control,
    name: `productAssets.${productIdx}.assets`,
  });

  const onDeleteButton = (
    assetIdx: number,
    asset: FieldArrayWithId<TPageForm, `productAssets.${number}.assets`>,
  ) => {
    if (asset.type === 'deleted') return;

    if (asset.type === 'added') {
      remove(assetIdx);
    } else {
      update(assetIdx, { ...asset, type: 'deleted' });
    }
  };

  return (
    <div className="w-100 flex-column raw-gap-20">
      <AssetsContainer basedVisibleCount={4} className={styles.items_container}>
        <div className={styles.item_wrapper}>
          <VideoAssetCreator
            currentProductId={currentProductId}
            onAddButton={(data) => append(data, { shouldFocus: false })}
          />
        </div>
        {fields.map((asset, assetIdx) => {
          if (!isVideoAsset(asset) || asset.type === 'deleted') {
            return null;
          }

          return (
            <SortableContainer.ItemWrapper
              key={asset.id}
              className={styles.item_wrapper}
              id={asset.id}
              index={assetIdx}
              moveItem={(from, to) => move(from, to)}
            >
              <VideoAsset
                assetIdx={assetIdx}
                data={asset}
                onDeleteButton={() => onDeleteButton(assetIdx, asset)}
                prefix={`productAssets.${productIdx}.assets.`}
                update={update}
              />
            </SortableContainer.ItemWrapper>
          );
        })}
      </AssetsContainer>
    </div>
  );
};

export { ProductVideoAssetsForm };
