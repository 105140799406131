import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBrand } from '../../../context/pages-context';

type TError = { message: string; code?: number };

type TBrandListInitialState = {
  loading: boolean;
  error: TError;
  brands: IBrand[];
};

const initialState: TBrandListInitialState = {
  brands: [],
  error: null,
  loading: false,
};

const brandListSlice = createSlice({
  initialState,
  name: 'brandList',
  reducers: {
    fetchBrandListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchBrandListRequest(...args: [TBrandListInitialState, PayloadAction<string>]) {
      const [state] = args;
      state.error = null;
    },
    fetchBrandListResponse(state, action: PayloadAction<IBrand[]>) {
      state.brands = action.payload;
    },
    setBrandListFetchingLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = brandListSlice;
