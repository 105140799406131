import { useController, useForm } from 'react-hook-form';

import { PageBannerAsset } from '../../../../../../common/interfaces/agg-page';
import { Container } from '../../../../../../common/ui/container';
import { FileUploader } from '../../../../../../common/ui/file-uploader/FileUploader';
import styles from './page-banner-creator.module.scss';

interface Props {
  onChange?: (data: PageBannerAsset) => void
}

const PageBannerCreator = ({ onChange }: Props) => {
  const {
    control,
    handleSubmit, setValue,
  } = useForm<PageBannerAsset>({
    defaultValues: {
      assets_type: 'assets',
      file_data: { type: 'banner' },
      type: 'new',
    },
  });

  const { field, formState: { errors } } = useController({ control, name: 'file' });

  const clearForm = () => {
    setValue('preview', null);
  };

  const handleChange = (file: File, preview: string) => {
    field.onChange(file);
    setValue('preview', preview);

    handleSubmit((data) => {
      if (onChange) onChange(data);
      field.onChange('');

      clearForm();
    })();
  };

  return (
    <Container
      border={{ type: 'solid', variant: 'secondary' }}
      className={styles.container}
      withoutBorder
    >
      <FileUploader
        className={styles.image_uploader}
        error={errors?.file?.message}
        format={['image/jpeg', 'image/png', 'image/jpg', 'image/svg']}
        onChange={handleChange}
        value={field.value}
        withoutPreview
      />
    </Container>
  );
};

export { PageBannerCreator };
