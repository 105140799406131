import clsx from 'clsx';
import { DeleteButton } from 'modules/common/partials/buttons/DeleteButton';

import { EditIcon, SuccessArrowIcon } from '../../../../assets/images/icons';
import { CreateCouponRequestBody } from '../../../../common/services/coupons-service.api';
import { Container } from '../../../../common/ui/container';
import { SingleDatePicker } from '../../../../common/ui/single-date-picker';
import { utilFormatDate } from '../../../../common/utils';
import { CouponDto } from '../../../../store/reducers/coupons/getCouponListReducer';
import styles from './coupon.module.scss';

interface CouponProps {
  data: CouponDto;
  onClick?: () => void;
  checked?: boolean;
  withDeleteButton?: boolean;
  deleteCouponHandler?: () => void;
  onSave?: (data: CreateCouponRequestBody) => void;
  loading?: boolean;
}

const Coupon = ({
  checked = false,
  data: {
    code,
    created_at,
    discount_amount,
    expired_at,
    updated_at,
  },
  deleteCouponHandler,
  loading = false,
  onClick,
  onSave,
  withDeleteButton = false,
}: CouponProps) => {
  const deleteCoupon = () => {
    if (deleteCouponHandler && !loading) deleteCouponHandler();
  };

  return (
    <Container
      className={clsx(styles.coupon, loading && styles.coupon_loading)}
      onClick={onClick}
      withoutBorder
      withShadow
    >
      {checked && (
        <div className={styles.mark}>
          <SuccessArrowIcon />
        </div>
      )}
      <Container className={styles.main} withoutBorder>
        <Container className={styles.info} withoutBorder>
          <span className={styles.title}>{code}</span>
          <span className={styles.amount}>
            {discount_amount * 100}
            %
          </span>
        </Container>
        <Container className={styles.dates_container} withoutBorder>
          <div className={styles.expired_date_wrapper_container}>
            <span className={styles.date}>
              {`Expired: ${loading ? 'updating ...    ' : utilFormatDate(expired_at, {
                formatStr: 'yyyy-MM-dd',
              })}`}
            </span>
            <SingleDatePicker
              anchorElement={<div
                className={clsx(styles.icon_btn, styles.edit_icon, loading && styles.disabled)}
              >
                <EditIcon />
              </div>}
              disabled={{ before: new Date() }}
              onChange={(day) => onSave({
                code,
                discount_amount,
                expired_at: day.toString(),
              })}
              value={new Date(expired_at)}
            />
          </div>
          <span className={styles.date}>
            {updated_at !== created_at
              ? `Updated: ${utilFormatDate(updated_at, { formatStr: 'yyyy-MM-dd' })}`
              : `Created: ${utilFormatDate(created_at, { formatStr: 'yyyy-MM-dd' })}`}
          </span>
        </Container>
      </Container>
      {withDeleteButton && <DeleteButton handler={deleteCoupon} />}
    </Container>
  );
};

export { Coupon };
