import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TConfirmModalInitialState = {
  title: string
  description: string
  successAction: () => void
  cancelAction?: () => void
  isOpen: boolean
};

const initialState: TConfirmModalInitialState = {
  cancelAction: () => {
  },
  description: null,
  isOpen: false,
  successAction: () => {
  },
  title: null
};

const confirmModalSlice = createSlice({
  initialState,
  name: 'confirmModal',
  reducers: {
    closeConfirmModal(state) {
      return { ...state, isOpen: false, ...initialState };
    },
    openConfirmModal(state, action: PayloadAction<Omit<TConfirmModalInitialState, 'isOpen'>>) {
      return { ...state, isOpen: true, ...action.payload };
    }
  }
});

export const { actions, reducer } = confirmModalSlice;
