import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ICustomerDto,
  IOrderDto,
  IOrderItemDto,
  TGetOneOrderResponse,
} from '../../common/interfaces/order-api';
import { IOrderFilter } from '../../modules/orders';

type TError = { message: string; code?: number };

type TOrderInitialState = {
  orders: IOrderDto[];
  orderData: {
    orderItems: IOrderItemDto[];
    customer: ICustomerDto;
  }
  pending: boolean;
  error: TError;
  totalOrdersCount: number;
};

const initialState: TOrderInitialState = {
  error: {
    code: null,
    message: null,
  },
  orderData: null,
  orders: [],
  pending: false,
  totalOrdersCount: null,
};

export const orderSlice = createSlice({
  initialState,
  name: 'order',
  reducers: {
    getOrderByIdError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },
    getOrderByIdRequest(...args: [TOrderInitialState, PayloadAction<number>]) {
      const [state] = args;
      return {
        ...state,
        error: null,
        pending: true,
      };
    },
    getOrderByIdResponse(state, { payload }: PayloadAction<TGetOneOrderResponse['data']>) {
      return {
        ...state,
        orderData: payload,
        pending: false,
      };
    },
    getOrdersError(state, action: PayloadAction<TError>) {
      state.pending = false;
      state.error = action.payload;
    },

    getOrdersRequest(...args: [TOrderInitialState, PayloadAction<IOrderFilter>]) {
      const [state] = args;
      return {
        ...state,
        error: null,
        pending: true,
      };
    },
    getOrdersResponse(state, action: PayloadAction<{ orders: IOrderDto[]; count: number }>) {
      state.pending = false;
      state.orders = action.payload.orders;
      state.totalOrdersCount = action.payload.count;
      state.error = null;
    },
  },
});

export const orderReducer = orderSlice.reducer;
