import clsx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './tooltip.module.scss';

export interface ITooltipProps {
  placement?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
  title: string;
  className?: string
}

const Tooltip = ({ children, className = '', placement = 'top', title }: ITooltipProps) => (
  <div className={clsx(styles.container, className)}>
    {!!title.length && <div className={clsx(styles.title, styles[`title__${placement}`])}>{title}</div>}
    {children}
  </div>
);

export { Tooltip };
