import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './FormItem.module.scss';

type Props = PropsWithChildren<{
  label?: string
  message?: string
  className?: string
  type?: 'error' | 'success' | 'warn'
}>;

const FormItem = ({ children, className = '', label, message, type = 'error' }: Props) => (
  <label className={clsx(styles.form_item, className)}>
    {label && <div className={styles.label}>{label}</div>}
    {children}
    {message && <p className={clsx(styles.message, styles[type])}>{message}</p>}
  </label>
);

export { FormItem };
