import axios, { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { Product } from '../../common/interfaces/agg-page';
import { getAggPageApiClient } from '../../common/services/agg-pages-api.service';
import { getProductsApiClient } from '../../common/services/products-api.service';
import { actions } from '../actions';

export interface GetBrandAttributionPageResponse {
  page: {
    id: number;
  };
  additionalProducts: Product[]
  mainProduct: Product
  productsCompareTable: {
    tableColumns: string[];
    tableRows: (Product & { row: { attribute_value: string; attribute_score: number }[] })[];
  };
}

type UpdateCompareProductTableSagaType = Generator<StrictEffect, any, AxiosResponse<any>>;

function* updateCompareProductTableSaga(
  action: ReturnType<typeof actions.updateCompareProductTableRequest>,
): UpdateCompareProductTableSagaType {
  yield put(actions.setUpdateCompareProductTableLoading(true));
  try {
    yield call(getAggPageApiClient().updateCompareProductTable, action.payload.data);
    yield put(actions.updateCompareProductTableResponse());
    toast.success('Success! Compare table was updated');
    if (action.payload.cb) action.payload.cb();
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      toast.error(e.message);
    }
  } finally {
    yield put(actions.setUpdateCompareProductTableLoading(false));
  }
}

type GetCompareProductTableSagaType = Generator<
StrictEffect,
any,
AxiosResponse<GetBrandAttributionPageResponse>
>;

function* getCompareProductTableSaga(
  action: ReturnType<typeof actions.getCompareProductTableRequest>,
): GetCompareProductTableSagaType {
  const { publisher, ...otherData } = action.payload.data;
  yield put(actions.setGetCompareProductTableLoading(true));
  try {
    const { data } = yield call(
      getProductsApiClient(publisher).getBrandAttributionPage,
      otherData,
    );
    yield put(actions.getCompareProductTableResponse(data));
  } catch (e: unknown) {
    if (axios.isAxiosError(e)) {
      toast.error(e.message);
    }
  } finally {
    yield put(actions.setGetCompareProductTableLoading(false));
  }
}

export function* compareProductTableWatcher() {
  yield takeEvery(actions.updateCompareProductTableRequest.type, updateCompareProductTableSaga);
  yield takeEvery(actions.getCompareProductTableRequest.type, getCompareProductTableSaga);
}
