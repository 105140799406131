import { Button } from '../../../../common/ui/button';
import { TMessage } from '../../types';
import styles from './MessagesModalContent.module.scss';

type TMessagesModalContent = {
  messages: TMessage[];
  onModalButtonClose: () => void;
};

const MessagesModalContent = ({ messages, onModalButtonClose }: TMessagesModalContent) => (
  <div className={styles.modal_content}>
    <div className={styles.modal_content__messages}>
      {messages.map((mes) => (
        <div key={mes.message} className={styles.message}>
          <span className={styles.message__title}>{mes.message}</span>
          <div className={styles.message__content}>
            {mes.asins.map((asin) => (
              <div key={asin} className={styles.item_wrapper}>
                <div className={styles.message__content__item}>{asin}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
    <div className={styles.modal_content__actions}>
      <Button fullWidth onClick={onModalButtonClose} size="small">
        Close
      </Button>
    </div>
  </div>
);

export { MessagesModalContent };
