import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { App } from 'root/app/app';

import { SortableContainer } from './common/ui/draggable-container';
import { ErrorBoundaryTemplate } from './common/ui/error-boundary-template';
import { InternalAuthProvider } from './root/auth/internal-auth-provider';
import { store } from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const history = createBrowserHistory({ window });

root.render(
  <Auth0Provider
    audience="https://productpicks.org"
    cacheLocation="localstorage"
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    redirectUri={window.location.origin}
  >
    <Provider store={store}>
      <InternalAuthProvider>
        <HistoryRouter history={history}>
          <ErrorBoundary FallbackComponent={ErrorBoundaryTemplate}>
            <SortableContainer>
              <App />
            </SortableContainer>
          </ErrorBoundary>
        </HistoryRouter>
      </InternalAuthProvider>
    </Provider>
  </Auth0Provider>,
);
