import { useEffect } from 'react';
import { OnChangeValue } from 'react-select';

import { FormItem } from '../../../../common/ui/formItem';
import { Select } from '../../../../common/ui/select';
import { useActions, useAppSelector } from '../../../../hooks';

interface DomainOption {
  label: string
  value: number
}

type DomainSelectProps = {
  onChange?: (option: DomainOption) => void;
  error?: string;
  label?: string;
  placeholder?: string;
  initialValue?: number | string
  disabled?: boolean
  className?: string
};

export const DomainSelect = ({
  className = '',
  disabled = false,
  error,
  initialValue = null,
  label,
  onChange,
  placeholder
}: DomainSelectProps) => {
  const { domains, loading } = useAppSelector(({ common }) => common.domains.domainList);
  const { fetchDomainListRequest } = useActions();

  const options: DomainOption[] = domains?.length ? domains.map((d) => ({ label: d.name, value: d.id })) : [];
  const value = options.find((elem) => elem.value === initialValue || elem.label === initialValue);

  const handleChange = (option: OnChangeValue<DomainOption, false>) => {
    if (option && option.label && onChange) {
      onChange(option);
    } else {
      onChange(null);
    }
  };

  useEffect(() => {
    fetchDomainListRequest({});
  }, []);

  return (
    <FormItem className={className} label={label} message={error} type={error ? 'error' : null}>
      <Select
        isClearable
        isDisabled={disabled}
        isLoading={loading}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </FormItem>
  );
};
