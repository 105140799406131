import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AttachProductsRequestBody } from '../../../common/services/coupons-service.api';

type TError = { message: string; code?: number };

export type TCouponAttachingInitialState = {
  error: TError;
  message: {
    type: 'success' | 'error';
    text: string;
  };
  loading: boolean;
};

const initialState: TCouponAttachingInitialState = {
  error: null,
  loading: false,
  message: null,
};

export const couponAttachingSlice = createSlice({
  initialState,
  name: 'couponAttaching',
  reducers: {
    couponAttachingClearState() {
      return initialState;
    },
    couponAttachingError(state, action: PayloadAction<TCouponAttachingInitialState['error']>) {
      state.error = action.payload;
      state.loading = false;
    },
    couponAttachingRequest(
      ...args: [
        TCouponAttachingInitialState,
        PayloadAction<{
          data: AttachProductsRequestBody;
          mode: 'attach' | 'detach'
          cb?: () => void;
          productIds?: string[]
        }>,
      ]
    ) {
      const [state] = args;
      state.loading = true;
    },
    couponAttachingResponse(state) {
      state.loading = false;
    },
  },
});

export const { actions, reducer } = couponAttachingSlice;
