import clsx from 'clsx';
import React, { Children, PropsWithChildren, useState } from 'react';

import { ArrowDownIcon, PlusOutlinedIcon } from '../../../../assets/images/icons';
import { Button } from '../../../../common/ui/button';
import styles from './assets-container.module.scss';

type Props = PropsWithChildren<{
  className?: string;
  basedVisibleCount?: number;
  withAddButton?: boolean;
  onAddButtonClick?: () => void;
  startPosition?: 0 | 1;
}>;

const AssetsContainer = ({
  basedVisibleCount = 1,
  children,
  className,
  onAddButtonClick,
  startPosition = 1,
  withAddButton,
}: Props) => {
  const [isShowAll, setIsShowAll] = useState(false);

  const sorted = Children.toArray(children).filter(Boolean);

  const childrenCount = sorted.length;

  const newItemHandler = () => {
    if (!isShowAll) {
      setIsShowAll(true);
    }

    if (onAddButtonClick) onAddButtonClick();
  };

  return (
    <>
      {withAddButton && (
        <Button
          iconStart={<PlusOutlinedIcon />}
          onClick={newItemHandler}
          size="small"
          variant="secondary"
        >
          Add new
        </Button>
      )}
      <div className={className}>
        {sorted.map((child, index) => {
          if (index > basedVisibleCount && !isShowAll) return null;

          return child;
        })}
      </div>
      {childrenCount > basedVisibleCount + 1 && (
        <Button
          iconStart={<ArrowDownIcon className={clsx(isShowAll && styles.button_icon__rotated)} />}
          onClick={() => setIsShowAll((prevState) => !prevState)}
          variant="secondary"
        >
          {!isShowAll && `Show all (${childrenCount - startPosition})`}
          {isShowAll && 'Show less'}
        </Button>
      )}
    </>
  );
};

export { AssetsContainer };
