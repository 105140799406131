import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError, AxiosResponse } from 'axios';
import { AggPageComponent, FormMode, Marketplaces } from 'common/constants/enums';
import {
  IAggPageDeleteAssets,
  IAggPageDeleteProductAssets,
  IAggPageKeywordPayload,
  IAggPagePayload,
  IAggPageProductAssets,
  IAggPageProductContent,
  IAggPageProductsPayload,
  IAggPageRenewAsset,
  IAggPageSubmitAssets,
  IAggPageUpdatePayload,
  IBestPage,
  PageBannerAsset,
  PageBuyingGuide,
  PagePublisherImage,
  Product,
} from 'common/interfaces/agg-page';
import { getAggPageApiClient } from 'common/services/agg-pages-api.service';
import {
  AttachProductContentPayload,
  getProductsManagementApiClient,
  NewProductContentPayload,
  UpdateProductContentPayload,
} from 'common/services/products-management-api.service';
import { AggPageFormContext, IDraft, IProductContent } from 'context/page-form-context';
import { PagesContext } from 'context/pages-context';
import jwt_decode from 'jwt-decode';
import * as _ from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useController, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CopyIcon } from '../../../assets/images/icons';
import { marketplaceOptions } from '../../../common/constants/select-options';
import { DomainDto } from '../../../common/interfaces/domains';
import { AiService } from '../../../common/services/ai-service.api';
import { isBannerAsset, isCardAsset, isPostAsset, isVideoAsset } from '../../../common/type-guards';
import { Button } from '../../../common/ui/button';
import { Checkbox } from '../../../common/ui/checkbox';
import { Divider } from '../../../common/ui/divider';
import { FormItem } from '../../../common/ui/formItem';
import { Input } from '../../../common/ui/Input';
import { Loader } from '../../../common/ui/loader';
import { Notification } from '../../../common/ui/notification';
import { Select } from '../../../common/ui/select';
import { utilConsecutiveRequests, utilPagePathGenerator } from '../../../common/utils';
import { pageFormValidationSchema } from '../../../common/validationSchemas';
import { useActions, useAppSelector } from '../../../hooks';
import { AuthorSelect } from '../../common/author-select';
import { IProductReview } from '../../common/products/interfaces/products';
import {
  BannerGallery,
  BrandInput,
  BuyingGuide,
  ContentGeneration,
  EditableProductSwitcher,
  KeywordInput,
  ProductContent,
  ProductReviews,
  ProductsBanners,
  ProductsCards,
  ProductsList,
  ProductsPosts,
  ProductsVideos,
  SlugInput,
} from '../components';
import { DomainSelect } from '../components/domain-select';
// eslint-disable-next-line max-len
import { VideoAssetCreatorType } from '../components/products-videos/product-video-assets-form/components/video-asset-creator';
import { PublisherImages } from '../components/publisher-images';
import { PublisherSelect } from '../components/publisher-select';
import { SectionTitle } from '../components/section-title';
import styles from './page-form.module.scss';

interface IResponseData {
  keyword: string;
  message: string;
}

export type ProductId = string;

interface IAggPageFormProps {
  formMode: FormMode;
}

export type ProductReviewsType = { product_id: string; reviews: IProductReview[] };
export type ProductVideoAssetType = { product_id: ProductId; assets: VideoAssetCreatorType[] };

export interface ProductAssets {
  product_id: ProductId;
  assets: Array<ProductVideoAsset | ProductBannerAsset | ProductPostAsset | ProductCardAsset>;
}

export interface ProductAsset {
  id?: string;
  product_id: ProductId;
  file?: File;
  preview?: string;
  url?: string;
  type?: 'added' | 'deleted' | 'updated';
}

export interface ProductVideoAsset extends ProductAsset {
  assets_type: 'video';
  file_data: {
    order?: number;
    text: string;
  };
}

export interface ProductBannerAsset extends ProductAsset {
  assets_type: 'assets';
  file_data: {
    type: 'banner';
    order?: number;
    title: string;
    text: string;
  };
}

export interface ProductCardAsset extends ProductAsset {
  assets_type: 'assets';
  file_data: {
    type: 'card';
    order?: number;
    text: string;
    title: string;
  };
}

export interface ProductPostAsset extends ProductAsset {
  assets_type: 'blogs';
  link: string;
  file_data: {
    order?: number;
    text: string;
  };
}

export interface TPageForm {
  drafts: IDraft[];
  content: IProductContent[];
  reviews: ProductReviewsType[];
  productAssets: ProductAssets[];
  pageBanners: PageBannerAsset[];
  marketplace: Marketplaces;
  publisherImages: PagePublisherImage[];
  buyingGuide: PageBuyingGuide[];
}

const getDomainsExplanation = (currentDomainId: number, domains: DomainDto[] = []) => {
  const currentDomainData = domains.find((d) => d.id === currentDomainId);
  let domainsString = '';
  let allowedDomains: DomainDto[] = [];

  if (currentDomainData && !currentDomainData.settings?.showAllPages) {
    domainsString = currentDomainData?.name;
    allowedDomains = [currentDomainData];
  } else {
    allowedDomains = domains.filter((d) => d.settings?.showAllPages);
    domainsString = allowedDomains.map((d) => d.name).join(', ');
  }

  const domainExplanation = `Domain is an optional value. ${
    currentDomainId
      ? `If you choose ${currentDomainData?.name}`
      : "If you don't choose domain option"
  } page will appear on the next domains: ${domainsString}`;

  return { allowedDomains, domainExplanation };
};

const PageForm = ({ formMode }: IAggPageFormProps) => {
  const { brand, changeError, error, pageLoading, publisher } = useContext(PagesContext);
  const { resetPublisherImagesListState } = useActions();
  const { domains } = useAppSelector(({ common }) => common.domains.domainList);

  const {
    bannerImages,
    buyingGuide,
    changeDomainId,
    changeKeywordId,
    changePageAuthorId,
    changePageId,
    changeSubtitle,
    domainId,
    drafts,
    excludeProductIds,
    isGenerateContent,
    keyword,
    marketplace,
    pageAuthor,
    pageAuthorId,
    pageId,
    productTitlesForUpdate,
    products,
    productsAssets,
    publisherImages,
    slug,
    subtitle
  } = useContext(AggPageFormContext);

  const { allowedDomains, domainExplanation } = getDomainsExplanation(domainId, domains);

  const methods = useForm<TPageForm>({
    defaultValues: { marketplace: Marketplaces.Amazon },
    resolver: yupResolver(pageFormValidationSchema),
  });

  const marketplaceField = useController({
    control: methods.control,
    name: 'marketplace',
  });

  const { append, fields, remove, update } = useFieldArray({
    control: methods.control,
    name: 'productAssets',
  });

  // Form update process if products list was changed
  useEffect(() => {
    // Updating
    if (productsAssets.length && productsAssets.length === fields.length) {
      const filteredAssets = productsAssets.filter(
        (elem) => !fields.some((field) => field.product_id === elem.product_id),
      );
      if (filteredAssets.length) {
        const indexToReplace = fields.findIndex(
          (field) => !productsAssets.some((elem) => elem.product_id === field.product_id),
        );
        update(indexToReplace, filteredAssets[0] as ProductVideoAssetType);
      }
      return;
    }

    // Adding
    if (productsAssets.length > fields.length) {
      const newProductReviews = productsAssets.filter(
        (elem) => !fields.some((field) => field.product_id === elem.product_id),
      );
      if (newProductReviews.length) {
        newProductReviews.forEach((reviews) => append(reviews as ProductVideoAssetType));
      }
    }

    // Deleting
    if (productsAssets.length < fields.length) {
      fields.forEach((field, i) => {
        if (!productsAssets.some((elem) => elem.product_id === field.product_id)) {
          remove(i);
        }
      });
    }
  }, [productsAssets]);

  useEffect(() => {
    if (publisherImages.length) {
      methods.setValue('publisherImages', publisherImages);
    }
  }, [publisherImages]);

  useEffect(() => {
    if (bannerImages.length) {
      methods.setValue('pageBanners', bannerImages);
    }
  }, [bannerImages]);

  useEffect(() => {
    if (!_.isEmpty(buyingGuide)) {
      methods.setValue('buyingGuide', [
        {
          assets_type: 'buyingGuide',
          file_data: { text: buyingGuide?.text },
          url: buyingGuide.url,
        },
      ]);
    }
  }, [buyingGuide]);

  useEffect(() => {
    methods.setValue('marketplace', marketplace);
  }, [marketplace]);

  useEffect(() => {
    methods.setValue('drafts', drafts);
  }, [drafts]);

  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [errorLogs, setErrorLogs] = useState([]);
  const [encryptBrand, setEncryptBrand] = useState(false);
  const navigate = useNavigate();
  const { checkingKeyword } = useAppSelector(({ products }) => products);
  const infoContainerRef = useRef(null);
  const domain = params.get('domain');

  const createInfoMessage = (message: string, error: boolean = false) => {
    const text = document.createElement('p');
    text.innerText = message;
    if (error) {
      text.className = styles.text_red;
    }

    infoContainerRef.current.appendChild(text);
  };

  const submitHandler = async (formData: TPageForm) => {
    infoContainerRef.current.innerHTML = '';
    if (Object.hasOwn(params, 'saveError')) {
      setParams((prev) => ({ ...prev, saveError: 0 }));
    }
    changeError('', AggPageComponent.Default);

    let isErrorOccurred = false;

    if (keyword.trim().length === 0) {
      changeError('Field is required', AggPageComponent.Keyword);
      isErrorOccurred = true;
    } else {
      changeError(null, AggPageComponent.Keyword);
    }

    const productWithBestPick = products.find((item) => item.value === 'Best Pick');

    if (!productWithBestPick) {
      changeError('Page must contain at least one "Best Pick" product', AggPageComponent.Product);
      isErrorOccurred = true;
    } else {
      changeError(null, AggPageComponent.Product);
    }

    if (!pageAuthorId && domainId !== 7) {
      changeError('Field is required', AggPageComponent.PageAuthor);
      isErrorOccurred = true;
    } else {
      changeError(null, AggPageComponent.PageAuthor);
    }

    if (formData.marketplace === Marketplaces.Amazon && !brand?.id) {
      changeError('Field is required', AggPageComponent.Brand);
      isErrorOccurred = true;
    } else {
      changeError(null, AggPageComponent.Brand);
    }

    if (isErrorOccurred) {
      changeError('Please solve all errors above', AggPageComponent.Default);
      return;
    }

    if (error.slug) {
      changeError('Please solve all errors above', AggPageComponent.Default);
      return;
    }

    if (checkingKeyword?.message && checkingKeyword.message.type === 'error') {
      changeError('Please solve all errors above', AggPageComponent.Default);
      return;
    }

    setLoading(true);

    let _keywordId: number;
    let _pageId: number = pageId;
    let pageSlug: string;
    let _page: IBestPage;
    try {
      //
      // step 1 - create keyword/page
      //

      if (formMode === FormMode.CREATE || formMode === FormMode.DUPLICATE) {
        const createKeywordPayload: IAggPageKeywordPayload = {
          keyword,
          marketplace: formData.marketplace,
        };

        if (brand) {
          createKeywordPayload.brand_id = brand.id;
        }

        if (publisher?.id) {
          createKeywordPayload.publisher_id = publisher.id;
        }

        if (domainId) {
          createKeywordPayload.domain_id = domainId;
        }

        console.log('#1 createKeywordPayload::', createKeywordPayload);

        createInfoMessage(`Saving keyword: ${keyword}`);

        const createPageKeywordResponse = await getAggPageApiClient().createPageKeyword(
          createKeywordPayload,
        );

        if (createPageKeywordResponse.status === 201) {
          createInfoMessage('Ok');
        } else {
          createInfoMessage(createPageKeywordResponse.statusText, true);
        }

        console.log('#1 createPageKeywordResponse::', createPageKeywordResponse);

        _keywordId = createPageKeywordResponse.data.keyword.id;

        console.log('keyword_id::', _keywordId);

        changeKeywordId(_keywordId);
      }

      //
      // step 1 - update page
      //

      if (formMode === FormMode.UPDATE) {
        const pageUpdatePayload: IAggPageUpdatePayload = {
          keyword,
        };
        if (subtitle) {
          pageUpdatePayload.subtitle = subtitle;
        }
        if (slug) {
          pageUpdatePayload.slug = slug.replace(/\s/g, '-');
        }
        if (pageAuthorId !== pageAuthor?.id) {
          pageUpdatePayload.author_id = pageAuthorId;
        }

        console.log('#1 pageUpdatePayload::', pageUpdatePayload);
        console.log('pageId', pageId);

        createInfoMessage(`Updating keyword: ${keyword}`);

        const updatePageResponse = await getAggPageApiClient().updatePage(
          pageId,
          pageUpdatePayload,
        );

        console.log('#1 updatePageResponse::', updatePageResponse);
        _keywordId = updatePageResponse.data.page.keyword_id;

        const updateDraftsResponse = await getAggPageApiClient().updatePageDrafts(pageId, [
          ...formData.drafts.map((elem) => ({ in_draft: elem.in_draft, section: elem.section })),
        ]);

        if (updateDraftsResponse.status === 200) {
          createInfoMessage('Ok');
        } else {
          createInfoMessage(updateDraftsResponse.statusText);
        }
      }

      //
      // step 2 - add product content
      //

      const baseContentForUpload: IAggPageProductContent[] = [];
      const newContentCopies: NewProductContentPayload[] = [];
      const updatedContentCopies: UpdateProductContentPayload[] = [];

      formData.content.forEach((p) => {
        if (p?.type === 'origin') {
          baseContentForUpload.push({
            bottom_line: p?.bottomLine,
            faq: p?.faq || [],
            marketplace: formData.marketplace,
            marketplace_item_id: p.product_id,
            pros: p?.pros?.filter((p) => p?.trim().length > 0) || [],
            summary: p?.summary,
          });
        } else if (p.type === 'new') {
          newContentCopies.push({
            bottom_line: p?.bottomLine,
            faq: p?.faq,
            marketplace: formData.marketplace,
            marketplace_item_id: p.product_id,
            new_copy: true,
            pros: p?.pros?.filter((p) => p?.trim().length > 0) || [],
            summary: p?.summary,
          });
        } else {
          updatedContentCopies.push({
            bottom_line: p?.bottomLine,
            faq: p?.faq,
            item_copy_id: p.copy_id,
            marketplace: formData.marketplace,
            marketplace_item_id: p.product_id,
            new_copy: false,
            pros: p?.pros?.filter((p) => p?.trim().length > 0) || [],
            summary: p?.summary,
          });
        }
      });

      createInfoMessage('Loading products content');

      await Promise.all(
        baseContentForUpload.map((c) => getAggPageApiClient().submitProductContent(c)),
      );

      await Promise.all(
        updatedContentCopies.map((c) => getProductsManagementApiClient().productContent(c)),
      );

      const responseNewCopies = await Promise.all(
        newContentCopies.map((c) => getProductsManagementApiClient().productContent(c)),
      );

      const contentTiedData: AttachProductContentPayload['products'] = [];

      formData.content.forEach((elem) => {
        const product = products.find((p) => p?.product_id === elem.product_id);
        if (product?.id && elem.tied && product?.picked_product_copy_id !== elem.copy_id) {
          contentTiedData.push({
            product_copy_id:
              elem.copy_id
              || responseNewCopies.find((elem) => elem?.data?.product.id === product.id)?.data
                ?.product_copy_id
              || null,
            product_id: product?.id,
          });
        }
      });

      createInfoMessage('Loading products reviews');

      await Promise.all(
        formData.reviews.map((productReview) =>
          getProductsManagementApiClient().renewProductReviews({
            marketplace: formData.marketplace,
            marketplace_item_id: productReview.product_id,
            reviews: productReview.reviews,
          }),),
      );

      //
      // step 3 - update products
      //

      if (productTitlesForUpdate.length) {
        const productsTitlesForUpdatePromises = productTitlesForUpdate.map((elem) => {
          const { product_id, ...data } = elem;
          return getProductsManagementApiClient().updateProduct.bind(
            _,
            {
              marketplace: formData.marketplace,
              payload: data,
            },
            product_id,
          );
        });

        console.log('#3 pageProductsPayload::');
        createInfoMessage('Updating products');

        await utilConsecutiveRequests(productsTitlesForUpdatePromises);
        createInfoMessage('Ok');
      }

      //
      // step 4 - add products
      //

      const remappedProducts = products.map((product: Product, index: number) => ({
        marketplace_item_id: product.product_id,
        order: product.order || index + 1,
        value: product.value,
      }));

      const productsPayload: IAggPageProductsPayload = {
        keyword_id: _keywordId,
        marketplace: formData.marketplace,
        products: remappedProducts,
      };

      if (excludeProductIds.length) {
        productsPayload.exclude_marketplace_item_ids = excludeProductIds.map(
          ({ product_id }) => product_id,
        );
      }
      console.log('#4 pageProductsPayload::', productsPayload);
      createInfoMessage('Loading products');

      const productsResponse = await getAggPageApiClient().submitPageProducts(productsPayload);
      console.log('#4 pageProductsResponse::', productsResponse);
      if (productsResponse.status === 201) {
        createInfoMessage('Ok');
      } else {
        createInfoMessage(productsResponse.statusText, true);
      }

      //
      // step 5 - submit product assets
      //
      const formAssetsForDelete: IAggPageDeleteProductAssets[] = [];
      const formAssetsForUpload: IAggPageProductAssets[] = [];
      const formAssetsForUpdate: {
        marketplace: Marketplaces;
        marketplace_item_id: ProductId;
        assets_type: 'video' | 'blogs' | 'assets';
        product_assets: { link: string; data: string }[];
      }[] = [];

      formData.productAssets.forEach((elem) => {
        elem.assets.forEach(({ assets_type, product_id, type, url }) => {
          if (type === 'deleted') {
            formAssetsForDelete.push({
              assets_type,
              link: url,
              marketplace: formData.marketplace,
              marketplace_item_id: product_id,
            });
          }
        });
      });

      formData.productAssets.forEach((elem) => {
        const updatedVideos: { link: string; data: string }[] = [];
        const updatedBlogs: { link: string; data: string }[] = [];
        const updatedAssets: { link: string; data: string }[] = [];
        let videosOrder = 1;
        let postsOrder = 1;
        let bannersOrder = 1;
        let cardsOrder = 1;

        elem.assets
          .filter((elem) => elem.type !== 'deleted')
          .forEach((asset) => {
            const { assets_type, file, file_data, product_id, type, url } = asset;

            if (isVideoAsset(asset)) {
              asset.file_data.order = videosOrder;
              videosOrder += 1;
              if (type === 'added') {
                formAssetsForUpload.push({
                  assets_type,
                  file,
                  file_data: JSON.stringify(file_data),
                  marketplace: formData.marketplace,
                  marketplace_item_id: product_id,
                });
              } else {
                updatedVideos.push({ data: JSON.stringify(file_data), link: url });
              }
            }

            if (isPostAsset(asset)) {
              asset.file_data.order = postsOrder;
              postsOrder += 1;
              if (type === 'added') {
                formAssetsForUpload.push({
                  assets_type,
                  file,
                  file_data: JSON.stringify(file_data),
                  marketplace: formData.marketplace,
                  marketplace_item_id: product_id,
                });
              } else {
                updatedBlogs.push({ data: JSON.stringify(file_data), link: url });
              }
            }

            if (isCardAsset(asset)) {
              asset.file_data.order = cardsOrder;
              cardsOrder += 1;
              if (type === 'added') {
                formAssetsForUpload.push({
                  assets_type,
                  file,
                  file_data: JSON.stringify(file_data),
                  marketplace: formData.marketplace,
                  marketplace_item_id: product_id,
                });
              } else {
                updatedAssets.push({ data: JSON.stringify(file_data), link: url });
              }
            }

            if (isBannerAsset(asset)) {
              asset.file_data.order = bannersOrder;
              bannersOrder += 1;
              if (type === 'added') {
                formAssetsForUpload.push({
                  assets_type,
                  file,
                  file_data: JSON.stringify(file_data),
                  marketplace: formData.marketplace,
                  marketplace_item_id: product_id,
                });
              } else {
                updatedAssets.push({ data: JSON.stringify(file_data), link: url });
              }
            }
          });

        formAssetsForUpdate.push({
          assets_type: 'video',
          marketplace: formData.marketplace,
          marketplace_item_id: elem.product_id,
          product_assets: updatedVideos,
        });

        formAssetsForUpdate.push({
          assets_type: 'blogs',
          marketplace: formData.marketplace,
          marketplace_item_id: elem.product_id,
          product_assets: updatedBlogs,
        });

        formAssetsForUpdate.push({
          assets_type: 'assets',
          marketplace: formData.marketplace,
          marketplace_item_id: elem.product_id,
          product_assets: updatedAssets,
        });
      });

      const assetsForUpload = [...formAssetsForUpload];

      console.log(assetsForUpload);

      const productAssetsUploadPromise = assetsForUpload.map((asset) => {
        console.log('#5 submit asset::', asset);
        return getAggPageApiClient().submitProductAssets.bind(getAggPageApiClient(), asset);
      });

      createInfoMessage('Loading product assets');
      await utilConsecutiveRequests(productAssetsUploadPromise);

      const productAssetsUpdatedPromise: (() => Promise<AxiosResponse<any, any>>)[] = [];

      formAssetsForUpdate.forEach((elem) => {
        console.log('# update asset::', elem);
        if (elem.product_assets.length) {
          productAssetsUpdatedPromise.push(
            getAggPageApiClient().updateProductAssets.bind(getAggPageApiClient(), elem),
          );
        }
      });

      createInfoMessage('Updating product assets');

      await utilConsecutiveRequests(productAssetsUpdatedPromise);

      // step 6 - delete product assets
      //

      const assetsForDelete = [...formAssetsForDelete];

      const productAssetsDeletePromise = assetsForDelete.map((asset) => {
        console.log('#6 delete asset::', asset);
        return getAggPageApiClient().deleteProductAssets.bind(getAggPageApiClient(), asset);
      });

      createInfoMessage('Delete product assets');

      await utilConsecutiveRequests(productAssetsDeletePromise);

      //
      // step 7 - create page
      //

      let createPageResponse;

      if (formMode === FormMode.CREATE || formMode === FormMode.DUPLICATE) {
        const aggPagePayload: IAggPagePayload = {
          keyword_id: _keywordId,
          marketplace: formData.marketplace,
        };

        if (publisher?.id) {
          aggPagePayload.publisher_id = publisher.id;
        }

        if (pageAuthorId) {
          aggPagePayload.author_id = pageAuthorId;
        }

        if (domainId) {
          aggPagePayload.domain_id = domainId;
        }

        createInfoMessage('Saving page');

        console.log('#7 aggCreatePagePayload::', aggPagePayload);

        createPageResponse = await getAggPageApiClient().createPage(aggPagePayload);
        console.log('#7 createPageResponse::', createPageResponse);
        if (createPageResponse.status === 201) {
          createInfoMessage('Ok');
          _pageId = createPageResponse.data.page[0].id;
          _page = createPageResponse.data.page[0];
        } else {
          createInfoMessage(createPageResponse.statusText);
        }
        console.log('_pageId::', _pageId);

        changePageId(_pageId);
      }

      if (isGenerateContent) {
        createInfoMessage('Requesting to generate page content');
        const generatePageContentResponse = await AiService.generateContent({
          pageID: _pageId,
        });

        if (generatePageContentResponse.status === 201) {
          createInfoMessage('Requesting to generate page content - SUCCESS');
        }
      }

      // attach product content

      const attachProductContentPayload: AttachProductContentPayload = {
        page_id: _pageId,
        products: contentTiedData,
      };

      if (excludeProductIds.length) {
        attachProductContentPayload.exclude_product_ids = excludeProductIds.map(({ id }) => id);
      }

      await getProductsManagementApiClient().attachProductContent(attachProductContentPayload);

      // 8 Update Draft

      const updateDraftsResponse = await getAggPageApiClient().updatePageDrafts(_pageId, [
        ...formData.drafts.map((elem) => ({ in_draft: elem.in_draft, section: elem.section })),
      ]);

      if (updateDraftsResponse.status === 200) {
        createInfoMessage('Ok');
      } else {
        createInfoMessage(updateDraftsResponse.statusText);
      }

      pageSlug = createPageResponse?.data?.page[0]?.slug
        ? createPageResponse.data.page[0].slug
        : slug.replace(/\s/g, '-');

      //
      // step 9 - submit page assets
      //

      const formPageAssetsForUpload: IAggPageSubmitAssets[] = [];
      const formPageAssetsForDelete: IAggPageDeleteAssets[] = [];
      const formPageAssetsForRenew: IAggPageRenewAsset[] = [];

      const pageBannerAssetsForRenew: IAggPageRenewAsset['page_assets'] = [];
      const pagePublisherImagesForRenew: IAggPageRenewAsset['page_assets'] = [];

      formData.buyingGuide.forEach((elem, __, arr) => {
        if (elem.type === 'new') {
          formPageAssetsForUpload.push({
            assets_type: elem.assets_type,
            file: elem.file,
            file_data: JSON.stringify(elem.file_data),
            page_id: _pageId,
          });
        }

        if (elem.type === 'deleted' && !arr.some((asset) => asset.type === 'new')) {
          formPageAssetsForDelete.push({
            assets_type: elem.assets_type,
            link: elem.url,
          });
        }

        if (elem.type === 'renew') {
          formPageAssetsForRenew.push({
            assets_type: elem.assets_type,
            page_assets: [
              // @ts-ignore
              _.pickBy({ data: JSON.stringify(elem.file_data), img: elem.url }, _.identity),
            ],
            page_id: _pageId,
          });
        }
      });

      let pageBannersOrder = 1;
      formData.pageBanners.forEach((elem) => {
        if (elem.type === 'deleted') {
          formPageAssetsForDelete.push({ assets_type: elem.assets_type, link: elem.url });
          return;
        }

        elem.file_data.order = pageBannersOrder;
        pageBannersOrder += 1;

        if (elem.type === 'new') {
          formPageAssetsForUpload.push({
            assets_type: elem.assets_type,
            file: elem.file,
            file_data: JSON.stringify(elem.file_data),
            page_id: _pageId,
          });

          return;
        }

        pageBannerAssetsForRenew.push({ data: JSON.stringify(elem.file_data), img: elem.url });
      });

      let publisherImagesOrder = 1;
      formData.publisherImages.forEach((elem) => {
        if (elem.type === 'deleted') {
          formPageAssetsForDelete.push({ assets_type: elem.assets_type, link: elem.url });
          return;
        }

        elem.file_data = { ...elem?.file_data, order: publisherImagesOrder };
        publisherImagesOrder += 1;

        if (elem.type === 'new') {
          formPageAssetsForUpload.push({
            assets_type: elem.assets_type,
            file: elem.file,
            file_data: JSON.stringify(elem.file_data),
            page_id: _pageId,
          });

          return;
        }

        pagePublisherImagesForRenew.push({ data: JSON.stringify(elem.file_data), img: elem.url });
      });

      formPageAssetsForRenew.push(
        {
          assets_type: 'assets',
          page_assets: pageBannerAssetsForRenew,
          page_id: _pageId,
        },
        {
          assets_type: 'publisherImages',
          page_assets: pagePublisherImagesForRenew,
          page_id: _pageId,
        },
      );

      const pageAssetsUploadPromise = formPageAssetsForUpload.map((asset) => {
        console.log('#9 submit page asset::', asset);
        return getAggPageApiClient().submitPageAssets.bind(getAggPageApiClient(), asset);
      });

      console.log('#9 renew page assets::', formPageAssetsForRenew);
      const pageAssetsRenewPromise = formPageAssetsForRenew.map((asset) =>
        getAggPageApiClient().renewPageAsset.bind(getAggPageApiClient(), asset),);

      await utilConsecutiveRequests(pageAssetsRenewPromise);

      createInfoMessage('Loading page assets');

      await utilConsecutiveRequests(pageAssetsUploadPromise);

      //
      // step 10 - delete page assets
      //

      createInfoMessage('Delete page assets');

      console.log('#10 delete page assets::', formPageAssetsForDelete);

      const pageAssetsForDeletePromise = formPageAssetsForDelete.map((asset) =>
        getAggPageApiClient().deletePageAssets.bind(getAggPageApiClient(), _pageId, asset),);

      await utilConsecutiveRequests(pageAssetsForDeletePromise);

      createInfoMessage('Success!');

      setErrorLogs([]);

      navigate('/pages/success', {
        state: {
          brand,
          domain: domain || domains.find((d) => d.id === domainId)?.name,
          domains: allowedDomains,
          marketplace: marketplaceField.field.value,
          publisher,
          slug: pageSlug,
        },
      });
    } catch (e: unknown) {
      const token = localStorage.getItem('token');
      const tokenData = jwt_decode(token);
      setErrorLogs((prev) => [...prev, e, tokenData]);
      const error = e as AxiosError;
      const data = error.response?.data as IResponseData;

      if (formMode === FormMode.CREATE && _keywordId && !_pageId) {
        createInfoMessage('Delete page products...');
        const deleteProductsResponse = await getAggPageApiClient().submitPageProducts({
          exclude_marketplace_item_ids: [...products.map((prod) => prod.product_id)],
          keyword_id: _keywordId,
          marketplace: formData.marketplace,
          products: [],
        });

        if (deleteProductsResponse.status === 201) {
          createInfoMessage('Delete page products - SUCCESS');
        }

        createInfoMessage('Delete keyword...');
        const deletePageKeywordResponse = await getAggPageApiClient().deletePageKeyword(_keywordId);

        if (deletePageKeywordResponse.status === 201) {
          createInfoMessage('Delete keyword - SUCCESS');
        }
      } else if (
        (formMode === FormMode.CREATE || formMode === FormMode.DUPLICATE)
        && _keywordId
        && _pageId
      ) {
        navigate(
          `${utilPagePathGenerator({
            brand: _page?.brand,
            params: {
              marketplace: marketplaceField.field.value,
              saveError: 1,
            },
            publisher: _page?.publisher,
            slug: _page.slug,
          })}`,
        );
      }

      if (data?.message === 'Duplicate keyword') {
        changeError(data.message, AggPageComponent.Keyword);
        createInfoMessage(data.message, true);
      } else {
        changeError(error.message, AggPageComponent.Default);
        createInfoMessage(error.message, true);
      }
      console.log('error::', error);
    } finally {
      setLoading(false);
    }
  };

  const cancelHandler = () => {
    navigate('/pages');
  };

  useEffect(() => {
    if (Number(params.get('saveError'))) {
      changeError(
        'Page have been created, but some assets have been saved wrong. Try save assets again',
        AggPageComponent.Default,
      );
    }
  }, [params]);

  useEffect(
    () => () => {
      resetPublisherImagesListState({ loadedPublisherImages: [], nextPage: null });
    },
    [],
  );

  if (pageLoading && formMode === FormMode.UPDATE) {
    return (
      <div className="d-flex align-center justify-center mt-80">
        <Loader className="mt-80" loading size="large">
          Page data is fetching ...
        </Loader>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(submitHandler, (errors) => {
          console.info(errors);
        })}
      >
        {formMode === FormMode.CREATE && (
          <Notification
            message={
              'The Marketplace can only be changed before adding a product. '
              + 'Walmart products can only be added for Reviewed pages'
            }
            type="warn"
          />
        )}
        <div className="d-flex justify-space-between align-center">
          <div className="flex-column raw-gap-10 w-100">
            <div className="d-flex align-center column-gap-10 align-start w-75">
              <FormItem
                className="w-30"
                label="Marketplace"
                message={marketplaceField.formState?.errors?.marketplace?.message}
              >
                <Select
                  isDisabled
                  onChange={(option) => {
                    marketplaceField.field.onChange(option.value);
                  }}
                  options={marketplaceOptions}
                  placeholder="Marketplace ..."
                  value={marketplaceOptions.find((el) => el.value === marketplaceField.field.value)}
                />
              </FormItem>
              {marketplaceField.field.value === Marketplaces.Amazon && (
                <BrandInput
                  className="w-30"
                  formMode={formMode}
                  label="Brand"
                  placeholder="Brand ..."
                />
              )}
              <PublisherSelect
                className="w-30"
                formMode={formMode}
                label="Publisher"
                placeholder="Publisher ..."
              />
            </div>
            <div className="d-flex align-center column-gap-10 align-start w-75">
              {domainId !== 7 && (
                <AuthorSelect
                  className="w-30"
                  error={error.author}
                  initialValue={pageAuthor ? pageAuthorId : null}
                  label="Author"
                  onOptionClick={changePageAuthorId}
                  placeholder="Author ..."
                />
              )}
              {!publisher?.id && (
                <DomainSelect
                  className="w-30"
                  disabled={formMode === FormMode.UPDATE || formMode === FormMode.DUPLICATE}
                  initialValue={domainId || ''}
                  label="Domain"
                  onChange={(option) => {
                    changeDomainId(option?.value);
                    if (option?.value === 7) {
                      changePageAuthorId(null);
                    }
                  }}
                  placeholder="Domain ..."
                />
              )}
            </div>
          </div>
          <ContentGeneration />
        </div>
        {formMode === FormMode.CREATE && (
          <Notification fullWidth message={domainExplanation} type="info" />
        )}

        <div className="flex-column raw-gap-10">
          {formMode === FormMode.UPDATE && (
            <div className="d-flex w-75 align-center column-gap-20">
              <SlugInput hideBrand={encryptBrand} />
              <Checkbox
                checked={encryptBrand}
                className="w-30"
                onChange={(e) => setEncryptBrand(e.target.checked)}
              >
                Hide brand for URL
              </Checkbox>
            </div>
          )}
          {formMode === FormMode.UPDATE && !publisher?.id && allowedDomains.length > 1 && (
            <Notification
              fullWidth
              message={`The page available on: ${allowedDomains
                .map((d) =>
                  utilPagePathGenerator(
                    {
                      brand,
                      publisher,
                      slug,
                    },
                    { absolute: true, domain: d?.name, public: true, withHash: encryptBrand },
                  ),)
                .join(',  ')}`}
              type="info"
            />
          )}
          <KeywordInput formMode={formMode} />
          <FormItem className="flex-column align-start w-75" label="Subtitle">
            <Input
              fullWidth
              id="subtitle"
              name="subtitle"
              onChange={(e) => changeSubtitle(e.target.value)}
              placeholder="Simple text here"
              value={subtitle || ''}
            />
          </FormItem>
        </div>

        <section className="w-100 h-100 flex-column raw-gap-20">
          <SectionTitle sectionName="Page gallery" withDraftIcon>
            Banner gallery
          </SectionTitle>
          <BannerGallery />
        </section>

        <section className="w-100 h-100 flex-column raw-gap-20">
          <SectionTitle>Products</SectionTitle>
          <ProductsList marketplace={marketplaceField.field.value} />
        </section>

        <section className="w-100 h-100 flex-column raw-gap-20">
          <SectionTitle>Publisher images</SectionTitle>
          <PublisherImages />
        </section>

        <section className="w-100 h-100 flex-column raw-gap-20">
          <SectionTitle sectionName="Buying guide" withDraftIcon>
            Buying guide
          </SectionTitle>
          <BuyingGuide />
        </section>

        <Divider variant="secondary" />
        {products.length > 0 && (
          <EditableProductSwitcher>
            {(productId, contentId, setCurrenContentId) => (
              <>
                <section className="w-100 h-100 flex-column raw-gap-20 pt-10">
                  <SectionTitle sectionName="Product content" withDraftIcon>
                    Product content
                  </SectionTitle>
                  <ProductContent
                    currentContentId={contentId}
                    currentProductId={productId}
                    injectCreateButton
                    setCurrenContentId={setCurrenContentId}
                  />
                </section>

                <section className="w-100 h-100 flex-column raw-gap-20">
                  <SectionTitle>Product reviews</SectionTitle>
                  <ProductReviews currentProductId={productId} />
                </section>

                <section className="w-100 h-100 flex-column raw-gap-20">
                  <SectionTitle sectionName="Video" withDraftIcon>
                    Videos
                  </SectionTitle>
                  <ProductsVideos currentProductId={productId} fields={fields} />
                </section>

                <section className="w-100 h-100 flex-column raw-gap-20">
                  <SectionTitle>Banners</SectionTitle>
                  <ProductsBanners currentProductId={productId} fields={fields} />
                </section>

                <section className="w-100 h-100 flex-column raw-gap-20">
                  <SectionTitle>Features</SectionTitle>
                  <ProductsCards currentProductId={productId} fields={fields} />
                </section>

                <section className="w-100 h-100 flex-column raw-gap-20">
                  <SectionTitle>Posts</SectionTitle>
                  <ProductsPosts currentProductId={productId} fields={fields} />
                </section>
              </>
            )}
          </EditableProductSwitcher>
        )}

        {error[AggPageComponent.Default] && (
          <div className="flex-column raw-gap-20 justify-center">
            <Notification fullWidth message={error[AggPageComponent.Default]} type="error" />
            {!!errorLogs.length && (
              <Button
                className="w-15"
                iconStart={<CopyIcon />}
                onClick={() => navigator.clipboard.writeText(JSON.stringify(errorLogs))}
                outlined
                variant="danger"
              >
                Copy error logs
              </Button>
            )}
          </div>
        )}

        <div className="flex-column raw-gap-10 justify-center w-100">
          <Divider variant="secondary" />
          <div className="d-flex align-center w-100">
            <div className="w-30 d-flex column-gap-20">
              <Button fullWidth loading={loading} type="submit">
                Save
              </Button>
              <Button
                disabled={loading}
                fullWidth
                onClick={cancelHandler}
                outlined
                type="button"
                variant="secondary"
              >
                Cancel
              </Button>
            </div>
            <div ref={infoContainerRef} className={styles.info_container} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export { PageForm };
