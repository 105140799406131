import { AggPageComponent, FormMode } from 'common/constants/enums';
import { PagesContext } from 'context/pages-context';
import { useContext, useEffect, useState } from 'react';

import { IFetchBestPagesOptions } from '../../../../common/interfaces/pages-api';
import { FormItem } from '../../../../common/ui/formItem';
import { Select } from '../../../../common/ui/select';
import { TSelectOption } from '../../../../common/ui/select-old';
import { AggPageFormContext } from '../../../../context/page-form-context';
import { TSetFilter, useActions, useAppSelector } from '../../../../hooks';

interface IPublisherSelectProps {
  formMode: string;
  updateFilter?: TSetFilter<Omit<IFetchBestPagesOptions, 'attribution'>>;
  label?: string;
  placeholder?: string;
  className?: string
}

export const PublisherSelect = ({
  className = '',
  formMode,
  label,
  placeholder,
  updateFilter,
}: IPublisherSelectProps) => {
  const { changePublisher, error, publisher } = useContext(PagesContext);
  const { changeDomainId } = useContext(AggPageFormContext);
  const { loading, publishers, ...publisherListData } = useAppSelector(
    ({ pages }) => pages.publisherList,
  );

  const { fetchPublisherListRequest } = useActions();
  const [selectOptions, setSelectOptions] = useState<TSelectOption[]>([
    {
      label: 'ProductPicks',
      value: 0,
    },
  ]);

  const disabled = formMode === FormMode.UPDATE;
  const defaultValue = selectOptions.find((e) => e.value === 0);
  const value = selectOptions.find((e) => e.value === publisher.id);

  const handleChange = (value: string) => {
    const item = publishers.find((elem) => elem?.id === Number(value));

    changePublisher(item || {});

    if (publisher?.id !== 0) {
      changeDomainId(null);
    }

    if (updateFilter) {
      const publisherField = value === 'ProductPicks' ? null : Number(value);
      const updateData: Partial<Omit<IFetchBestPagesOptions, 'attribution'>> = { publisher: publisherField };

      if (value !== 'ProductPicks') {
        updateData.domain = null;
      }

      updateFilter(updateData);
    }
  };

  useEffect(() => {
    setSelectOptions([
      {
        label: 'ProductPicks',
        value: 0,
      },
      ...publishers.map((publisher) => ({
        label: publisher.display_name || publisher.name,
        value: publisher.id,
      })),
    ]);
  }, [publishers]);

  useEffect(() => {
    fetchPublisherListRequest();
  }, []);

  return (
    <FormItem
      className={className}
      label={label}
      message={error?.[AggPageComponent.Publisher] || publisherListData.error?.message}
    >
      <Select
        defaultValue={defaultValue}
        isDisabled={disabled}
        isLoading={loading}
        isSearchable={false}
        onChange={(option) => handleChange(option.value as string)}
        options={selectOptions}
        placeholder={placeholder}
        value={value}
      />
    </FormItem>
  );
};
