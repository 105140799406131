import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { IProductContent } from '../../context/page-form-context';
import { IProductReview } from '../../modules/common/products/interfaces/products';
import { ProductAssets, ProductId } from '../../modules/pages/page-form/page-form';
import { Product } from '../interfaces/agg-page';

export const utilProductDataPreparing = (product: Product) => {
  const reviewsInit: IProductReview[] = _.get(product, 'scrapped.reviews', []);
  const reviews: { product_id: ProductId; reviews: IProductReview[] } = {
    product_id: product.product_id,
    reviews: reviewsInit,
  };

  let productContent: IProductContent = null;

  if (product?.picked_product_copy_id === null) {
    productContent = {
      _id: uuidv4(),
      product_id: product.product_id,
      type: 'origin',
      ...(product.content || {}),
    } as IProductContent;
  }

  const cards: ProductAssets['assets'] = [];
  const banners: ProductAssets['assets'] = [];

  const posts: ProductAssets['assets'] = product?.blogs?.map((elem) => ({
    assets_type: 'blogs',
    file_data: { text: elem.text },
    id: uuidv4(),
    link: elem.link,
    product_id: product?.product_id,
    url: elem.img,
  })) || [];

  const videos: ProductAssets['assets'] = product?.video?.map(({ order, src, text }) => ({
    assets_type: 'video',
    file_data: { order, text },
    id: uuidv4(),
    product_id: product.product_id,
    url: src,
  })) || [];

  product?.assets?.forEach(({ img, order, text, title, type }) => {
    if (!img) return;

    if (type && type === 'banner') {
      banners.push({
        assets_type: 'assets',
        file_data: {
          order,
          text,
          title,
          type: 'banner',
        },
        id: uuidv4(),
        product_id: product.product_id,
        url: img,
      });
    }

    if (type && type === 'card') {
      cards.push({
        assets_type: 'assets',
        file_data: { order, text, title, type: 'card' },
        id: uuidv4(),
        product_id: product.product_id,
        url: img,
      });
    }
  });

  banners.sort((a, b) => a.file_data.order - b.file_data.order);
  cards.sort((a, b) => a.file_data.order - b.file_data.order);

  return {
    assets: [...videos, ...posts, ...banners, ...cards],
    content: productContent,
    reviews,
  };
};
