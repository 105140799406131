import { useContext } from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { AggPageFormContext } from '../../../../context/page-form-context';
import { TPageForm } from '../../page-form/page-form';
import { ProductVideoAssetsForm } from './product-video-assets-form';

type Props = {
  currentProductId: string;
  fields: FieldArrayWithId<TPageForm, 'productAssets'>[]
};

const ProductsVideos = ({ currentProductId, fields }: Props) => {
  const { products } = useContext(AggPageFormContext);
  const { control } = useFormContext<TPageForm>();

  if (products?.length === 0) {
    return null;
  }

  return (
    <div className="w-100 h-100 flex-column raw-gap-20 p-0">
      {fields.map((field, productIdx) => {
        if (field?.product_id !== currentProductId) {
          return null;
        }

        return (
          <ProductVideoAssetsForm
            key={field.id}
            control={control}
            currentProductId={currentProductId}
            productIdx={productIdx}
          />
        );
      })}
    </div>
  );
};

export { ProductsVideos };
