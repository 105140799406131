import { PropsWithChildren } from 'react';

import { Divider } from '../../../../common/ui/divider';
import { DraftStatus } from '../draft-status';
import styles from './section-title.module.scss';

type Props = {
  withDraftIcon: true,
  sectionName:'Video' | 'Page gallery' | 'Product content' | 'Buying guide'
} | { withDraftIcon?: false, sectionName?: never };

const SectionTitle = ({ children, sectionName, withDraftIcon }: PropsWithChildren<Props>) => (
  <div className="d-flex flex-column column">
    <div className={styles.title_wrapper}>
      <h2 className={styles.title}>{children}</h2>
      {withDraftIcon && <DraftStatus sectionName={sectionName} />}
    </div>
    <Divider variant="secondary" />
  </div>
);

export { SectionTitle };
