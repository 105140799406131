import clsx from 'clsx';
import React, { ChangeEvent, forwardRef, PropsWithChildren } from 'react';

import styles from './Checkbox.module.scss';

type TCheckboxProps = PropsWithChildren<{
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  id?: string;
  className?: string;
  disabled?: boolean
}>;

type Ref = HTMLInputElement;

const Checkbox = forwardRef<Ref, TCheckboxProps>(({
  checked = true,
  children,
  className,
  disabled,
  id,
  name,
  onChange,
}, ref) => {
  const handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label className={clsx(styles.checkbox, className)}>
      <input
        ref={ref}
        checked={checked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handler}
        type="checkbox"
        value=""
      />
      <span>{children}</span>
    </label>
  );
});

export { Checkbox };
