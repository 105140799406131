import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { PlusOutlinedIcon } from '../../../assets/images/icons';
import { Button } from '../../../common/ui/button';
import { useActions, useTableFilter } from '../../../hooks';
import { PageTitle } from '../../common/partials/PageTitle/PageTitle';
import { AuthorFilter } from './components/author-filter';
import { AuthorsTable } from './components/authors-table';

export type AuthorsFilterType = {
  offset: number;
  limit: number;
  firstn?: string;
  lastn?: string;
};

const authorFilterInitial: AuthorsFilterType = {
  limit: 10,
  offset: 0,
};

const AuthorsPage = () => {
  const { getAuthors } = useActions();
  const [authorFilter, setAuthorFilter] = useTableFilter(authorFilterInitial);

  useEffect(() => {
    getAuthors(authorFilter);
  }, [authorFilter]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-space-between">
        <PageTitle className="mb-30" description="Let’s check authors today" title="Authors" />
        <Link to="/authors/create">
          <Button iconStart={<PlusOutlinedIcon />}>Add New</Button>
        </Link>
      </div>
      <AuthorFilter className="mb-20" setFilter={setAuthorFilter} />
      <AuthorsTable filter={authorFilter} setFilter={setAuthorFilter} />
    </div>
  );
};

export { AuthorsPage };
