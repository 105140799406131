import clsx from 'clsx';
import { ChangeEvent } from 'react';

import { SearchIcon } from '../../../../../assets/images/icons';
import { TextField } from '../../../../../common/ui/text-field';
import { TSetFilter } from '../../../../../hooks';
import { AuthorsFilterType } from '../../authors-page';

type Props = {
  setFilter: TSetFilter<AuthorsFilterType>;
  className?: string
};
const AuthorFilter = ({ className = '', setFilter }: Props) => {
  const searchAuthorHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const [firstn, lastn] = e.target.value.split(' ');
    setFilter({ firstn, lastn });
  };

  return (
    <div className={clsx('d-flex w-100 column-gap-10', className)}>
      <TextField
        className="w-20"
        iconBefore={<SearchIcon />}
        onChange={searchAuthorHandler}
        placeholder="Author name ..."
        variant="secondary"
      />
    </div>
  );
};

export { AuthorFilter };
