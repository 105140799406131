import ReactSelect, { ClearIndicatorProps, Props, StylesConfig } from 'react-select';

import { ArrowDownIcon, CrossIcon } from '../../../assets/images/icons';
import styles from './Select.module.scss';

const DropdownIndicator = () => <ArrowDownIcon className={styles.dropdown_indicator} />;

const ClearIndicator = <T, P extends boolean = false>({ innerProps, }: ClearIndicatorProps<T, P>) => (
  <div {...innerProps}>
    <CrossIcon className={styles.clear_indicator} />
  </div>
);

const getStyles = <T, P extends boolean>(): StylesConfig<T, P> => ({
  control: (base, { isDisabled, isFocused }) => ({
    ...base,
    backgroundColor: '#F4F9FB',
    border: isFocused ? 0 : 0,
    borderRadius: '10px',
    boxShadow: isFocused ? 'none' : 'none',
    cursor: isDisabled ? 'no-drop' : 'pointer',
    height: '40px',
    lineHeight: '116%',
    opacity: isDisabled ? '0.5' : '1',
    pointerEvents: 'auto',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  menu: (base) => ({
    ...base,
    border: 'none',
    borderRadius: '8px',
    boxShadow: '1px 5px 10px rgba(21, 30, 34, 0.2)',
    fontSize: '14px',
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: '0 5px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#F4F9FB' : '#FFFFFF',
    color: '#214254',
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#214254',
    fontFamily: 'inherit',
    fontSize: '14px',
    fontWeight: '300',
    opacity: '0.7',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#214254',
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: '300',
  }),
  valueContainer: (base, props) => ({
    ...base,
    display: props.isMulti ? 'flex' : base.display,
    flexWrap: props.isMulti ? 'nowrap' : base.flexWrap,
    overflowX: props.isMulti ? 'hidden' : base.overflowX,
  }),
});

type SelectProps<OptionType extends unknown, IsMulti extends boolean = false> = Props<
OptionType,
IsMulti
>;

const Select = <OptionType, IsMulti extends boolean = false>(
  { components, isSearchable = false, ...props }: SelectProps<OptionType, IsMulti>) => (
    <ReactSelect
      classNamePrefix="custom-select"
      components={{ ClearIndicator, DropdownIndicator, ...components }}
      isSearchable={isSearchable}
      styles={getStyles<OptionType, IsMulti>()}
      {...props}
    />
  );

export { Select };
