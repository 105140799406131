import { ChangeEvent, useContext, useEffect } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { PlusOutlinedIcon } from '../../../../assets/images/icons';
import { Product } from '../../../../common/interfaces/agg-page';
import { Button } from '../../../../common/ui/button';
import { Portal } from '../../../../common/ui/portal';
import { AggPageFormContext } from '../../../../context/page-form-context';
import { ProductContentForm } from '../../../common/products/product-content-form';
import { TPageForm } from '../../page-form/page-form';

interface ProductContentProps {
  currentProductId: string;
  currentContentId?: string;
  setCurrenContentId?: (value: ((prevState: string) => string) | string) => void;
  injectCreateButton?: boolean;
}

const ProductContent = ({
  currentContentId,
  currentProductId,
  injectCreateButton = false,
  setCurrenContentId,
}: ProductContentProps) => {
  const { addContentCopies, contentCopies, products, productsContent } = useContext(AggPageFormContext);

  const { control } = useFormContext<TPageForm>();
  const { append, fields, remove, update } = useFieldArray({ control, name: 'content' });

  const addNewContent = (product: Product) => {
    const copyId = uuidv4();
    addContentCopies(
      [
        {
          _id: copyId,
          content: {
            bottomLine: '',
            faq: [],
            pros: [],
            summary: '',
          },
          product_id: product.product_id,
          tied: false,
          type: 'new',
        },
      ],
      true,
    );

    if (setCurrenContentId) {
      setTimeout(() => {
        setCurrenContentId(copyId);
      }, 0);
    }
  };

  const onTiedChange = (e: ChangeEvent<HTMLInputElement>, field: FieldArrayWithId<TPageForm, 'content', 'id'>) => {
    if (!e.target.checked) return;

    fields.forEach((elem, index) => {
      if (elem._id !== field._id && elem.product_id === field.product_id && elem.tied) {
        update(index, { ...elem, tied: false });
      }
    });
  };

  useEffect(() => {
    [...productsContent, ...contentCopies].forEach((content) => {
      if (!fields.some((field) => field._id === content._id)) {
        append(content);
      }
    });
  }, [productsContent, contentCopies]);

  useEffect(() => {
    fields.reverse().forEach((field, i) => {
      const exist = products.some(
        (elem) => elem.product_id === field.product_id,
      );
      if (!exist) {
        remove(i);
      }
    });
  }, [products]);

  if (products?.length === 0) {
    return null;
  }

  return (
    <div className="flex-column w-100 raw-gap-20">
      {injectCreateButton
        && products.map((product) => (
          <Portal
            key={product.product_id}
            id={`add-new-product-content-copy-${product.product_id}`}
          >
            <Button
              disabled={
                currentProductId !== product.product_id
                || contentCopies.filter((elem) => elem.product_id === product.product_id).length > 2
              }
              iconStart={<PlusOutlinedIcon />}
              onClick={() => addNewContent(product)}
              outlined
              size="small"
              variant="secondary"
            />
          </Portal>
        ))}

      {fields?.length > 0
        && fields.map((field, i) => {
          if (field._id !== currentContentId) return null;

          return (
            <ProductContentForm
              key={field._id}
              control={control}
              index={i}
              onTiedChange={(e) => onTiedChange(e, field)}
              withSetContentButton
            />
          );
        })}
    </div>
  );
};

export { ProductContent };
