import { useController, useForm } from 'react-hook-form';

import { PagePublisherImage } from '../../../../../common/interfaces/agg-page';
import { Container } from '../../../../../common/ui/container';
import { FileUploader } from '../../../../../common/ui/file-uploader/FileUploader';
import styles from './publisher-image-creator.module.scss';

interface Props {
  onChange?: (data: any) => void;
}

const PublisherImageCreator = ({ onChange }: Props) => {
  const { control, handleSubmit, setValue } = useForm<PagePublisherImage>({
    defaultValues: {
      assets_type: 'publisherImages',
      type: 'new',
    },
  });

  const { field, formState: { errors } } = useController({ control, name: 'file' });

  const clearForm = () => {
    setValue('preview', null);
  };

  const handleChange = (file: File, preview: string) => {
    field.onChange(file);
    setValue('preview', preview);

    handleSubmit((data) => {
      if (onChange) onChange(data);
      field.onChange('');

      clearForm();
    })();
  };

  return <Container
    border={{ type: 'solid', variant: 'secondary' }}
    className={styles.container}
    withoutBorder
  >
    <FileUploader
      className={styles.image_uploader}
      error={errors?.file?.message}
      format={['image/jpeg', 'image/png', 'image/jpg', 'image/svg']}
      onChange={handleChange}
      value={field.value}
      withoutPreview
    />
  </Container>;
};

export { PublisherImageCreator };
