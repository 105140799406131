import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TError = { message: string; code?: number };

type TDeletePageInitialState = {
  error: TError;
  loading: boolean;
};

const initialState: TDeletePageInitialState = {
  error: null,
  loading: false,
};

const deletePageSlice = createSlice({
  initialState,
  name: 'deletePage',
  reducers: {
    deletePageError(state, action: PayloadAction<TError>) {
      state.loading = false;
      state.error = action.payload;
    },
    deletePageRequest(...args:[ TDeletePageInitialState, PayloadAction<{ pageId: number; onSuccess?: () => void }>]) {
      const [state] = args;
      state.loading = true;
      state.error = null;
    },
    deletePageResponse(state) {
      state.loading = false;
    },
  },
});

export const { actions, reducer } = deletePageSlice;
