import { AxiosResponse } from 'axios';

import { CouponDto } from '../../store/reducers/coupons/getCouponListReducer';
import { ApiService } from './api.service';

export interface CreateCouponRequestBody {
  code: string;
  discount_amount: number;
  expired_at: string;
}

export interface AttachProductsRequestBody {
  coupon_code: string;
  asins: string[];
}

export type GetListRequestBody = { asin: string; code?: never } | { code: string; asin?: never };

export type DetachProductRequestBody = AttachProductsRequestBody;

class CouponsServiceApi extends ApiService {
  create = (data: CreateCouponRequestBody) => this.post('/admin/product-coupons', data);

  update = (data: Partial<CreateCouponRequestBody>) => this.put('/admin/product-coupons', data);

  attachProducts = (data: AttachProductsRequestBody) =>
    this.post('/admin/product-coupons/attach', data);

  detachProducts = (data: DetachProductRequestBody) =>
    this.post('/admin/product-coupons/detach', data);

  getList = (params: GetListRequestBody): Promise<AxiosResponse<CouponDto[]>> =>
    this.get('/admin/product-coupons', { params });
}

export const CouponsService = new CouponsServiceApi(process.env.REACT_APP_API_HOST);
