import { useFieldArray, useFormContext } from 'react-hook-form';

import { Divider } from '../../../../common/ui/divider';
import { TPageForm } from '../../page-form/page-form';
import { Gallery } from './components/gallery';
import { PageBannersForm } from './components/page-banners-form/page-banners-form';

const BannerGallery = () => {
  const { control } = useFormContext<TPageForm>();

  const methods = useFieldArray({
    control,
    name: 'pageBanners',
  });

  return (
    <div className="flex-column">
      {methods.fields.length !== 0 && (
        <>
          <Gallery bannerImages={methods.fields} className="mb-10" />
          <Divider variant="secondary" />
        </>
      )}
      <PageBannersForm methods={methods} />
    </div>
  );
};

export { BannerGallery };
