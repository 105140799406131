import { AggPageFormContext } from 'context/page-form-context';
import { PagesContext } from 'context/pages-context';
import { ChangeEvent, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { CopyIcon } from '../../../../assets/images/icons';
import { AggPageComponent, Marketplaces } from '../../../../common/constants/enums';
import { FormItem } from '../../../../common/ui/formItem';
import { Input } from '../../../../common/ui/Input';
import { utilPagePathGenerator } from '../../../../common/utils';
import { utilCopyToClipboard } from '../../../../common/utils/utilCopyToClipboard';

export const SlugInput = ({ hideBrand }: { hideBrand?: boolean }) => {
  const { brand, changeError, error, page, publisher } = useContext(PagesContext);
  const { changeSlug, slug } = useContext(AggPageFormContext);
  const [params] = useSearchParams();
  const marketplace = params.get('marketplace') as Marketplaces;
  const domain = params.get('domain') as Marketplaces;

  const url = utilPagePathGenerator(
    {
      brand,
      params: { marketplace },
      publisher,
      slug: page.slug,
    },
    {
      absolute: true,
      domain,
      public: true,
      withHash: hideBrand
    },
  );

  const textBefore = `${url.split('/').slice(0, -1).join('/')}/`;

  const inputHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    changeSlug(value);
    if (!value.length) {
      changeError('Required field', AggPageComponent.Slug);
    } else {
      changeError(null, AggPageComponent.Slug);
    }
  };

  return (
    <div className="flex-column align-start w-100">
      <FormItem className="w-100" label="Slug" message={error[AggPageComponent.Slug]}>
        <Input
          iconAfter={<CopyIcon onClick={() => utilCopyToClipboard(textBefore + slug)} />}
          invalid={!!error[AggPageComponent.Slug]}
          onChange={inputHandler}
          textBefore={textBefore}
          value={slug}
        />
      </FormItem>
    </div>
  );
};
