import { FieldArrayWithId, UseFieldArrayReturn } from 'react-hook-form';

import { SortableContainer } from '../../../../../common/ui/draggable-container';
import { TPageForm } from '../../../page-form/page-form';
import { AssetsContainer } from '../../assets-container';
import { PublisherImage } from '../publisher-image';
import { PublisherImageCreator } from '../publisher-image-creator';
import styles from './publisher-images-form.module.scss';

interface Props {
  methods?: UseFieldArrayReturn<TPageForm, 'publisherImages'>;
}

const PublisherImagesForm = ({ methods: { append, fields, remove, swap, update } }: Props) => {
  const onDeleteButton = (assetIdx: number, asset: FieldArrayWithId<TPageForm, 'publisherImages'>) => {
    if (asset.type === 'deleted') return;

    if (asset.type === 'new' || asset.type === 'renew') {
      remove(assetIdx);
    } else {
      update(assetIdx, { ...asset, type: 'deleted' });
    }
  };

  return (
    <div className={styles.form}>
      <AssetsContainer basedVisibleCount={4} className={styles.items_container}>
        <div className={styles.item_wrapper}>
          <PublisherImageCreator onChange={(data) => append(data)} />
        </div>
        {fields.map((asset, assetIdx) => {
          if (asset.type === 'deleted') return null;

          return (
            <SortableContainer.ItemWrapper
              key={asset.id}
              className={styles.item_wrapper}
              id={asset.id}
              index={assetIdx}
              moveItem={(from, to) => swap(from, to)}
            >
              <PublisherImage
                assetIdx={assetIdx}
                item={asset}
                onDeleteButton={() => onDeleteButton(assetIdx, asset)}
                withDeleteButton
              />
            </SortableContainer.ItemWrapper>
          );
        })}
      </AssetsContainer>
    </div>);
};

export { PublisherImagesForm };
