import * as yup from 'yup';

import { Marketplaces } from '../constants/enums';

const validationMessages = {
  gender: 'Must be "Male" or "Female"',
  incorrect: 'Incorrect value',
  notEmpty: "Can't be empty",
  onlyNumber: 'Should be number',
  required: 'Field is required',
};

export const createAuthorFormSchema = yup
  .object({
    first_name: yup.string().required(validationMessages.required).trim(),
    gender: yup
      .mixed()
      .oneOf(['male', 'female'], validationMessages.gender)
      .required(validationMessages.required),
    info: yup.object({
      about: yup.string().required(validationMessages.required).trim(),
      city: yup.string().required(validationMessages.required).trim(),
      country: yup.string().required(validationMessages.required).trim(),
      experience: yup.object({
        company: yup.string().trim().nullable(),
        specialization: yup.string().required(validationMessages.required).trim(),
        years: yup
          .number()
          .typeError(validationMessages.onlyNumber)
          .required(validationMessages.required),
      }),
      state: yup.string().required(validationMessages.required).trim(),
    }),
    last_name: yup.string().required(validationMessages.required).trim(),
  })
  .required();

export const amazonProductAsinValidationSchema = yup.object({
  product_id: yup
    .string()
    .required(validationMessages.required)
    .trim()
    .length(10, 'Should be 10 symbols'),
});

export const walmartProductIdValidationSchema = yup.object({
  product_id: yup
    .number()
    .typeError('Should be only numbers')
    .required(validationMessages.required)
    .min(10000000, 'Min length 8 numbers')
    .max(9999999999, 'Min length 10 numbers'),
});

export const marketplaceValidationSchema = yup
  .string()
  .oneOf([Marketplaces.Walmart, Marketplaces.Amazon], 'Must be one of: Amazon, Walmart')
  .required(validationMessages.required);

export const brandValidationSchema = yup.string().when('marketplace', {
  is: Marketplaces.Amazon,
  otherwise: (schema) => schema,
  then: (schema) => schema.required(validationMessages.required),
});

export const productContentValidationSchema = yup.array().of(
  yup.object({
    faq: yup.array().of(
      yup.object({
        answer: yup.string().trim().required(validationMessages.required),
        question: yup.string().trim().required(validationMessages.required),
      }),
    ),
  }),
);

export const pageFormValidationSchema = yup.object({
  content: productContentValidationSchema,
  marketplace: marketplaceValidationSchema,
});

export const createTemporaryLinkValidateSchema = yup.object().shape({
  date: yup.date().required(validationMessages.required).min(new Date(), 'At least tomorrow'),
  publisher_id: yup.number().nullable(),
  tags: yup.array().of(
    yup.object().shape({
      asin: yup
        .string()
        .required(validationMessages.required)
        .trim()
        .min(10)
        .max(10, validationMessages.incorrect),
      tag: yup.string().required(validationMessages.required).trim(),
    }),
  ),
});

export const dailyDealsFormValidateSchema = yup.object().shape({
  rows: yup.array().of(
    yup.object().shape({
      category: yup.object({
        code: yup.string().required(validationMessages.required)
      }).shape({
      }).required(validationMessages.required),
      products: yup
        .array()
        .min(1, validationMessages.notEmpty)
        .required(validationMessages.required),
    }),
  ),
});
