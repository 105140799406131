import clsx from 'clsx';
import { useState } from 'react';
import { OptionProps } from 'react-select';

import { ArrowDownIcon } from '../../../../assets/images/icons';
import { Image } from '../../../../common/ui/image';
import { IBrand, IExternalBrand } from '../../../../context/pages-context';
import styles from './brand-input.module.scss';

export interface ExternalBrandOptionType extends IExternalBrand {
  label: string;
  value: string;
}

export interface BrandOptionType extends Omit<Partial<IBrand>, 'external_brands'> {
  label: string;
  value: string;
  external_brands?: ExternalBrandOptionType[];
}

const BrandOption = ({
  data,
  getValue,
  innerProps: { key },
  isSelected,
  selectOption,
}: OptionProps<BrandOptionType, false>) => {
  const currentValue = getValue();

  const [showSubOptions, setShowSubOptions] = useState(isSelected);

  const handleMainOptionClick = () => {
    // Выбираем основную опцию
    selectOption(data);
  };

  const toggleSubOptions = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setShowSubOptions(!showSubOptions);
  };

  return (
    <div
      key={key}
      className={clsx(styles.container)}
    >
      <div
        className={clsx(styles.option, isSelected && styles.selected)}
        onClick={handleMainOptionClick}
      >
        <div className={styles.image_wrapper}>
          <Image
            alt="brand_logo"
            className={styles.image_wrapper}
            height={30}
            src={data.img_logo || data.img_banner}
            width={30}
          />
        </div>
        <span className={styles.label}>{data.label}</span>
        {!!data.ext_brands_count && <div className={styles.arrow_wrapper}>
          <ArrowDownIcon
            className={clsx(styles.arrow, showSubOptions && styles.rotate)}
            onClick={(e) => toggleSubOptions(e)}
          />
        </div>}
      </div>
      <div className={clsx(styles.list, showSubOptions && styles.open)}>
        {!!data.ext_brands_count && data.external_brands.map((externalBrand) => <div
          key={externalBrand.system_id}
          className={(clsx(
            styles.option,
            styles.subOption,
            currentValue.some((elem) => elem.value === externalBrand.value) && styles.selected,
          ))}
          onClick={handleMainOptionClick}
        >
          <span className={styles.label}>{externalBrand.label}</span>
          {externalBrand.brand_group === 'aos' && <div className={clsx(styles.image_wrapper, 'ml-auto')}>
            <Image
              alt="brand_logo"
              className={styles.aos_icon}
              height={15}
              src="https://aos-uat.advoncommerce.com/favicon.ico"
              width={15}
            />
          </div>}
        </div>)}
      </div>
    </div>
  );
};

export { BrandOption };
