import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainDto, DomainListOption, DomainsListResponse } from '../../../common/interfaces/domains';

type TError = { message: string; code?: number };

type DomainListInitialState = {
  loading: boolean;
  error: TError;
  domains: DomainDto[];
  totalCount: number
};

const initialState: DomainListInitialState = {
  domains: [],
  error: null,
  loading: false,
  totalCount: null,
};

const domainListSlice = createSlice({
  initialState,
  name: 'domain-list',
  reducers: {
    fetchDomainListError(state, action: PayloadAction<TError>) {
      state.error = action.payload;
    },
    fetchDomainListRequest(
      ...args: [
        DomainListInitialState,
        PayloadAction<Partial<DomainListOption>>,
      ]
    ) {
      const [state] = args;
      state.error = null;
    },
    fetchDomainListResponse(
      state,
      action: PayloadAction<DomainsListResponse>,
    ) {
      state.domains = action.payload.domains;
      state.totalCount = action.payload?.totalCount;
    },
    resetDomainListState(
      state,
      action: PayloadAction<Partial<DomainListInitialState>>,
    ) {
      return { ...state, ...action.payload };
    },
    setDomainsListLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { actions, reducer } = domainListSlice;
