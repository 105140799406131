import axios, { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { call, put, StrictEffect, takeEvery } from 'redux-saga/effects';

import { DailyDealsResponse } from '../../common/interfaces/daily-deals';
import { DailyDealsService } from '../../common/services/daily-deals-api.service';
import { PagesService } from '../../common/services/pages-api.service';
import { actions } from '../actions';

type FetchDailyDealsSagaType = Generator<StrictEffect, any, AxiosResponse<DailyDealsResponse>>;

function* getDailyDeals(
  action: ReturnType<typeof actions.getDailyDealsRequest>,
): FetchDailyDealsSagaType {
  try {
    const data = yield call(PagesService.dailyDeals, action.payload);

    yield put(actions.getDailyDealsResponse(data.data.dealEventProducts));
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      toast.error(`Code ${err.status}: ${err.message}`);
    }
    yield put(actions.setDailyDealsLoaders({ updateProducts: false }));
  }
}

type UpdateDailyDealsProductsSagaType = Generator<StrictEffect, any, AxiosResponse>;

function* updateDailyDealsProducts(
  action: ReturnType<typeof actions.updateDailyListProductsRequest>,
): UpdateDailyDealsProductsSagaType {
  toast.loading('Saving....', { id: 'saga-loader' });
  try {
    yield call(DailyDealsService.updateProductsList, action.payload.data);

    toast.success('Daily Deals were updated successfully!');

    yield put(actions.getDailyDealsRequest(action.payload.publisherName));
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      toast.error(`Code ${err.status}: ${err.message}`);
    }
  } finally {
    toast.remove('saga-loader');

    yield put(actions.setDailyDealsLoaders({ updateProducts: false }));
  }
}

export function* dailyDealsSagasWatcher() {
  yield takeEvery(actions.getDailyDealsRequest.type, getDailyDeals);
  yield takeEvery(actions.updateDailyListProductsRequest.type, updateDailyDealsProducts);
}
